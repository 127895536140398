import { mdiEyeOff } from '@mdi/js';
import { Icon as MDIcon } from "@mdi/react";

const EyeIcon = ({
  width = 20,
  height = 20,
  color = "black",
  ...otherProps
}) => (
  <MDIcon path={mdiEyeOff} {...otherProps}/>
);

export default EyeIcon;
