import {
    mdiCheckboxBlankOutline,
    mdiCheckboxIntermediate,
    mdiCheckboxMarkedOutline,
} from "@mdi/js";
import Icon from "../../../../../assets/svg/Icon";
import Avatar from "../../../../../components/Avatar";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import Table from "../../../../../components/Table";
import {
    TripRequirementFileStatus,
    TripUserRoleOptions,
} from "../../../../../constants";
import { getStatusFromFiles } from "../../../../../helpers";

export default function RequirementUsersTable(props: {
    participants: any[];
    onUserClick: (u: any) => any;
    selectedUsers: any[];
    onUserSelected: any;
    onSelectAll: any;
}) {
    const {
        participants,
        onUserClick,
        selectedUsers,
        onUserSelected,
        onSelectAll,
    } = props;
    const tableHeaders = ["", "Name / Email", "Role", "Status"];
    const tableHeadersClasses = ["text-left p-[.2rem]", "", "", "text-center"];
    const customHeaders = [
        <div className="cursor-pointer" onClick={onSelectAll}>
            <Icon
                materialIcon={
                    selectedUsers.length
                        ? selectedUsers.length < participants.length
                            ? mdiCheckboxIntermediate
                            : mdiCheckboxMarkedOutline
                        : mdiCheckboxBlankOutline
                }
                className={`w-7 cursor-pointer ${
                    selectedUsers.length &&
                    selectedUsers.length === participants.length
                        ? "text-orange-600"
                        : "text-neutral-600"
                } text-neutral-600 rounded-lg text-xs bg-white`}
            />
        </div>,
        "Name / Email",
        "Role",
        "Status",
    ];

    return (
        <>
            <Table
                headers={tableHeaders}
                tableHeadersClasses={tableHeadersClasses}
                isLoading={!participants}
                customHeaders={customHeaders}
            >
                {participants?.map((u: any) => {
                    const roleLabel = TripUserRoleOptions.find(
                        (rO) => rO.value === u?.role
                    );
                    const selected = selectedUsers.includes(u.userEmail);
                    const status = getStatusFromFiles(u.requirementFiles);
                    const customStatusLabel =
                        status === TripRequirementFileStatus.PENDING
                            ? "Needs Review"
                            : undefined;
                    return (
                        <tr
                            key={u.userEmail}
                            className={"hover:bg-neutral-50 cursor-pointer"}
                            onClick={() => onUserClick(u)}
                        >
                            <td
                                style={{ width: "50px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onUserSelected(u);
                                }}
                            >
                                {selected ? (
                                    <Icon
                                        materialIcon={mdiCheckboxMarkedOutline}
                                        className="w-7 cursor-pointer text-orange-600 rounded-lg text-xs bg-white"
                                    />
                                ) : (
                                    <Icon
                                        materialIcon={mdiCheckboxBlankOutline}
                                        className="w-7 cursor-pointer text-neutral-800"
                                    />
                                )}
                            </td>
                            <td>
                                <div className="flex gap-3 items-center justify-start">
                                    <Avatar
                                        src={
                                            !!u?.user?.avatar_thumb?.length
                                                ? u?.user?.avatar_thumb
                                                : u?.user?.avatar
                                        }
                                        className="w-6 h-6"
                                    />

                                    <span className="text-left">
                                        {u.user?.firstName
                                            ? `${u.user?.firstName} ${u.user?.lastName}`
                                            : u?.userEmail}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="flex gap-3 text-left items-center justify-start">
                                    <div className="w-full">
                                        <div>{roleLabel?.label}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-start">
                                    <span className="w-full text-left">
                                        <StatusBadgeComponent
                                            status={status}
                                            customText={customStatusLabel}
                                        />
                                    </span>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </Table>
        </>
    );
}
