import React from "react";
import { IAttachment, IContact } from "../../../../types/chat.type";
import Icon from "../../../../assets/svg/Icon";
import Avatar from "../../../../components/Avatar";

interface VideoModalProps {
    isOpen: boolean;
    Videos: IAttachment[];
    currentVideoIndex: number;
    onClose: () => void;
    onNext: () => void;
    onPrev: () => void;
    sender: IContact;
    timestamp: number;
}

const VideoModal: React.FC<VideoModalProps> = ({
    isOpen,
    Videos,
    currentVideoIndex,
    onClose,
    onNext,
    onPrev,
    sender,
    timestamp,
}) => {
    if (!isOpen) return null;

    const currentVideo = Videos[currentVideoIndex];
    return (
        <div
            onClick={onClose}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="bg-white rounded-lg overflow-hidden shadow-lg relative"
            >
                <div className="pt-4 pl-10 pr-10 flex items-center">
                    <div className="flex items-center flex-grow">
                        <Avatar
                            src={sender?.avatarThumb || sender?.avatar}
                            className="w-8 h-8 mr-2"
                        />
                        <div>
                            <p className="font-bold text-start">
                                {sender?.firstName} {sender?.lastName}
                            </p>
                            <p className="text-xs text-gray-500">
                                {new Date(timestamp).toLocaleString()}
                            </p>
                        </div>
                    </div>
                    <div className="cursor-pointer mr-4">
                        <a
                            href={currentVideo.url}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            <Icon
                                icon="DownloadIcon"
                                className="w-6 h-6 m-0 fill-gray-400 text-gray-400"
                            />
                        </a>
                    </div>
                    <div className="cursor-pointer" onClick={onClose}>
                        <Icon
                            icon="CloseIcon"
                            className="w-6 h-6 m-0 fill-gray-400 text-gray-400 cursor-pointer"
                        />
                    </div>
                </div>
                <div className="flex justify-center items-center p-4">
                    {!!currentVideoIndex ? (
                        <button
                            onClick={onPrev}
                            className="text-gray-500 hover:text-gray-700 mr-2 bg-neutral-200 p-2 rounded-full"
                        >
                            <Icon
                                icon="ChevronLeftIcon"
                                className="w-6 h-6 m-0 fill-gray-400 text-gray-400"
                            />
                        </button>
                    ) : (
                        <div className="w-12 p-2"></div>
                    )}
                    <video
                        src={currentVideo.url}
                        className="max-w-full max-h-96 object-cover"
                        controls
                        autoPlay
                    />
                    {currentVideoIndex < Videos.length - 1 ? (
                        <button
                            onClick={onNext}
                            className="text-gray-500 hover:text-gray-700 ml-2 bg-neutral-200 p-2 rounded-full"
                        >
                            <Icon
                                icon="ChevronRightIcon"
                                className="w-6 h-6 m-0 fill-gray-400 text-gray-400"
                            />
                        </button>
                    ) : (
                        <div className="w-12 p-2"></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
