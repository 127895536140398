import { mdiBullhornVariantOutline } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";

const HornIcon = ({
    width = 20,
    height = 20,
    color = "black",
    ...otherProps
}) => <MDIcon path={mdiBullhornVariantOutline} {...otherProps} />;

export default HornIcon;