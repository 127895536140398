import React, { useState } from "react";
import { mdiAirplane, mdiPassport, mdiCalendarPlus } from "@mdi/js";
import Icon from "../../../../../assets/svg/Icon";

interface InfoCardsProps {
    handleType: (type: string) => void;
}

const InfoCards = ({ handleType }: InfoCardsProps) => {
    const [currentType, setCurrentType] = useState<string>();

    const items = [
        {
            icon: mdiAirplane,
            title: "Arrival/departure info",
            value: "arrival_departure_info",
        },
        {
            icon: mdiPassport,
            title: "Passport",
            value: "passport",
        },
        {
            icon: mdiCalendarPlus,
            title: "Other requirement",
            value: "other_requirement",
        },
    ];

    const handleCurrentType = (type: string) => {
        setCurrentType(type);
        handleType(type);
    };

    return (
        <div className="flex justify-between gap-4">
            {items.map((item, index) => (
                <div
                    key={index}
                    onClick={() => handleCurrentType(item.value)}
                    className={`flex flex-col justify-top gap-2 bg-gray-100 border border-gray-200 rounded-lg p-2 w-48 shadow-sm hover:shadow-md transition-shadow cursor-pointer ${
                        currentType === item?.value ? "border-orange-600 bg-red-50" : ""}`}
                >
                    <Icon
                        materialIcon={item.icon}
                        className={`w-7 text-neutral-400 ${
                            currentType === item?.value ? "text-orange-600" : ""
                        }`}
                    />
                    <div className={`text-sm text-neutral-400 font-semibold ${currentType === item?.value ? "text-orange-600" : ""}`}>
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InfoCards;
