import { ButtonSize } from "../constants";
import Button from "./ButtonSquare";

interface ButtonPlusProps {
    onClick: () => void;
    label?: string;
    showButton?: boolean;
}
export const ButtonPlus = ({ onClick, label, showButton }: ButtonPlusProps) => {
    if (!showButton) return null;

    return (
        <span className="mr-6 text-primary_orange-900 font-semibold cursor-pointer" onClick={onClick}>
            <Button type="button" className="!rounded-full !w-8 mr-1" size={ButtonSize.SMALL} label="+" /> {label}
        </span>
    )
}
