import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethods, isLoadingData, PlansState, selectPaymentMethods } from "../../features/plans/plansSlice";
import IconVisa from "../../assets/svg/cards/visa_inc_logo.svg";
import IconMastercard from "../../assets/svg/cards/Symbol.svg";
import IconAmex from "../../assets/svg/cards/american_express_symbol.svg";
import IconUnion from "../../assets/svg/cards/unionpay_international_logo.svg";
import { IPaymentMethods } from "../../types/plan.type";
import { useAppSelector } from "../../app/hooks";

interface CardSelectionProps {
    onSelectCard: (cardId: string) => void;
    onAddNewCard: () => void;
    paymentMethods: IPaymentMethods[];
    isLoading: boolean;
}



const brandIcons: { [key: string]: string } = {
    visa: IconVisa,
    mastercard: IconMastercard,
    amex: IconAmex,
    unionpay: IconUnion,
};

const CardSelection: React.FC<CardSelectionProps> = ({
    onSelectCard,
    onAddNewCard,
    paymentMethods,
    isLoading,
}) => {
    const [selectedCard, setSelectedCard] = useState<IPaymentMethods | null>(null);
    
    useEffect(() => {
        getPaymentMethods();
    }, []);


    const handleSelectCard = (cardId: string) => {
        const card = paymentMethods.find((card: IPaymentMethods) => card.id === cardId);
        setSelectedCard(card || null);
        onSelectCard(cardId);
    };

    if (isLoading) {
        return( <div className="p-4 bg-gray-100 rounded-lg mb-2">
            <div className="flex items-center justify-between p-3 border-b last:border-b-0 cursor-pointer">
                <div className="flex items-center">
                    <span>Loading</span>
                </div>
            </div>
        </div>)
    }

    if (paymentMethods.length === 0) {
        return (
            <div className="p-4 bg-gray-100 rounded-lg mb-2">
                <div className="flex items-center justify-between p-3 border-b last:border-b-0 cursor-pointer">
                    <div className="flex items-center">
                        <span>No cards found</span>
                    </div>
                </div>
                <div className="flex justify-start mt-4">
                    <button
                        onClick={onAddNewCard}
                        className="text-orange-500 font-medium"
                    >
                        Add new card
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="p-4 bg-gray-100 rounded-lg mb-2">
                {paymentMethods.map((card: IPaymentMethods) => (
                    <div
                        key={card.id}
                        className="flex items-center justify-between p-3 border-b last:border-b-0 cursor-pointer"
                        onClick={() => handleSelectCard(card.id)}
                    >
                        <div className="flex items-center">
                            <input
                                type="radio"
                                name="selectedCard"
                                checked={selectedCard?.id === card.id}
                                onChange={() => handleSelectCard(card.id)}
                                className="mr-2"
                            />
                            <img
                                src={brandIcons[card.card.brand] || IconVisa}
                                alt={card.card.brand}
                                className="w-8 h-8 mr-3"
                            />
                            <span>
                                {card.card.brand} •••• {card.card.last4}
                            </span>
                        </div>
                    </div>
                ))}

                <div className="flex justify-start mt-4">
                    <button
                        onClick={onAddNewCard}
                        className="text-orange-500 font-medium"
                    >
                        Add new card
                    </button>
                </div>
            </div>
        </>
    );
};

export default CardSelection;
