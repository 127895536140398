/* eslint-disable import/no-anonymous-default-export */
import { mdiAccountCogOutline } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";

export default ({
    width = 20,
    height = 20,
    color = "black",
    ...otherProps
}) => <MDIcon path={mdiAccountCogOutline} {...otherProps} />;
