import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../features/authentication/userSlice";
import tripReducer from "../features/trips/tripSlice";
import hotelReducer from "../features/hotels/hotelSlice";
import organizationReducer from "../features/organizations/organizationSlice";
import othersReducer from "../features/others/othersSlice";
import plansReducer from "../features/plans/plansSlice";
import paymentsReducer from "../features/payments/paymentSlice";
import messagesReducer from "../features/trips/Trip/TripMessages/messagesSlice";
import restaurantReducer from "../features/restaurants/restaurantSlice";
import localPartnerReducer from "../features/localPartners/localPartnerSlice";
import layoutReducer from "../features/layout/layoutSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import chatReducer from "../features/chat/chatSlice";
import homeSlice from "../features/home/homeSlice";
import userprofileReducer from "../features/UserProfile/UserProfileSlice";
import adminSlice from "../features/admin/adminSlice";
import configSlice from "../features/configSlice";

import FB from "../api/firebase";
import insightsSlice from "../features/insights/insightsSlice";

const Firebase = new FB();
export const store = configureStore({
    reducer: {
        user: userReducer,
        trip: tripReducer,
        home: homeSlice,
        hotel: hotelReducer,
        organization: organizationReducer,
        others: othersReducer,
        insights: insightsSlice,
        restaurant: restaurantReducer,
        localPartner: localPartnerReducer,
        layout: layoutReducer,
        notifications: notificationsReducer,
        userprofile: userprofileReducer,
        admin: adminSlice,
        config: configSlice,
        plans: plansReducer,
        payments: paymentsReducer,
        messages: messagesReducer,
        chat: chatReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: Firebase,
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
