import FB from "../../api/firebase";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import chatApi from "../../api/chat";
import { handleError } from "../authentication/userSlice";
import {
    IChatEntity,
    IChatMessage,
    IChatPushPayload,
    IContact,
    IResponseChatEntity,
    IResponseChatMessage,
} from "../../types/chat.type";
import server from "../../api/server";
import {
    initializeConversationsListener,
    membersChangeDetected,
    newMessageDetected,
    offConversationListeners,
} from "./listener/chatListener";
import chat from "../../api/chat";
import { IReportComplaint } from "../../types/complaint.type";
import { reportComplaint } from "../admin/adminSlice";

interface ChatState {
    conversations: IChatEntity[];
    selectedConversation: IChatEntity | null;
    filteredConversations: IChatEntity[];
    serverUsers: IContact[];
    contactSearchLists: any;
    isLoading: boolean;
    error: string | null;
    blockModalOpen: boolean;
    reportModalOpen: boolean;
}

const initialState: ChatState = {
    conversations: [],
    selectedConversation: null,
    filteredConversations: [],
    serverUsers: [],
    contactSearchLists: [],
    isLoading: false,
    error: null,
    blockModalOpen: false,
    reportModalOpen: false,
};

const TRUNCATE_CHARACTERS = 12;

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setConversations: (state, action: PayloadAction<IChatEntity[]>) => {
            state.conversations = action.payload
                .map((conv) => ({
                    ...conv,
                    messages: conv.messages || [],
                }))
                .sort((a, b) => {
                    const lastMessageA = a.lastMessageTimestamp || 0;
                    const lastMessageB = b.lastMessageTimestamp || 0;

                    return lastMessageB - lastMessageA;
                });
        },
        addOrUpdateConversation: (
            state,
            action: PayloadAction<{
                conversation: IChatEntity;
                user: any;
            }>
        ) => {
            const { conversation, user } = action.payload;
            const existingConversationIndex = state.conversations.findIndex(
                (conv) => conv.id === conversation.id
            );
            const newMessagesArray = Object.values(conversation.messages || {});
            const lastMessage = newMessagesArray[newMessagesArray.length - 1];

            if (existingConversationIndex !== -1) {
                const existingConversation =
                    state.conversations[existingConversationIndex];
                const existingMessagesArray = Object.values(
                    existingConversation.messages || {}
                );
                const existingMessagesLength = existingMessagesArray.length;
                const newMessagesLength = newMessagesArray.length;

                state.conversations[existingConversationIndex] = {
                    ...conversation,
                    messages: newMessagesArray,
                };

            } else {
                state.conversations.push({
                    ...conversation,
                    messages: newMessagesArray,
                });
            }
        },
        setServerUsers: (state, action: PayloadAction<IContact[]>) => {
            state.serverUsers = action.payload;
        },
        setContactSearchLists: (state, action: PayloadAction<any>) => {
            state.contactSearchLists = action.payload;
        },
        setSelectConversation: (state, action: PayloadAction<IChatEntity>) => {
            state.selectedConversation = action.payload;
        },
        selectConversation: (state, action: PayloadAction<IChatEntity>) => {
            const existingConversation = state.conversations.find(
                (conv) => conv.id === action.payload.id
            );
            if (existingConversation) {
                existingConversation.messages = action.payload.messages;
            }
            state.selectedConversation = existingConversation
                ? { ...existingConversation }
                : action.payload;
        },
        addMessage: (
            state,
            action: PayloadAction<{ message: IChatMessage }>
        ) => {
            const { message } = action.payload;
            if (state.selectedConversation) {
                if (!state.selectedConversation.messages) {
                    state.selectedConversation.messages = [];
                }
                const messageExists = state.selectedConversation.messages.some(
                    (msg) => msg.id === message.id
                );
                if (!messageExists) {
                    state.selectedConversation.messages.push(message);
                }
            }
        },
        toggleBlockModal: (state, action: PayloadAction<boolean>) => {
            state.blockModalOpen = action.payload;
        },
        toggleReportModal: (state, action: PayloadAction<boolean>) => {
            state.reportModalOpen = action.payload;
        },
        filterConversations: (state, action: PayloadAction<string>) => {
            const searchTerm = action.payload.toLowerCase();
            state.filteredConversations = state.conversations.filter(
                (conversation) =>
                    conversation.title.toLowerCase().includes(searchTerm)
            );
        },
    },
});

export const {
    isLoading,
    setConversations,
    setServerUsers,
    setContactSearchLists,
    setSelectConversation,
    selectConversation,
    addMessage,
    addOrUpdateConversation,
    toggleBlockModal,
    toggleReportModal,
    filterConversations,
} = chatSlice.actions;

export const fetchServerUsers = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(isLoading(true));
        const currentUser = getState().user.profile;
        const contactsResponse = await server.getContacts(10, 0, "");
        const firebase = new FB();
        const db = firebase.database;

        const contacts = contactsResponse.data.data;
        dispatch(setServerUsers(contacts));

        dispatch(isLoading(false));
    } catch (error) {
        dispatch(isLoading(false));
        dispatch(handleError(error));
    }
};

export const getUsersContacts =
    (search: string, take: number = 15, skip: number = 0): AppThunk =>
    async (dispatch) => {
        try {
            const response = await server.getUsersContacts(search, take, skip);
            dispatch(setContactSearchLists(response.data.data));

            return response?.data;
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const getTripsUserAndFetch =
    (tripId: number): AppThunk =>
    async () => {
        try {
            const members = await server.getTripsUsers(tripId);
            return members.data;
        } catch (error) {
            console.error("Error getting trip members", error);
        }
    };

export const addImageToChat =
    (conversationId: string, formData: any): any =>
    async () => {
        try {
            const response = await server.addImageToChat(
                conversationId,
                formData
            );
            return response.data;
        } catch (error) {
            return;
        }
    };

export const addVideoToChat =
    (conversationId: string, formData: any): any =>
    async () => {
        try {
            const response = await server.addVideoToChat(
                conversationId,
                formData
            );
            return response.data;
        } catch (error) {
            return;
        }
    };

export const copyImageToTrip =
    (tripId: number, chatId: string, attachments: any): any =>
    async () => {
        try {
            const response = await server.copyImageToTrip(
                tripId,
                chatId,
                attachments
            );
            return response.data;
        } catch (error) {
            return;
        }
    };

export const fetchAndListenToConversations =
    (): AppThunk => async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const firebase = new FB();
            const db = firebase.database;
            const user = getState().user.profile;

            const handleNewMessage = newMessageDetected(dispatch, getState);
            const handleMembersChange = membersChangeDetected(
                dispatch,
                getState
            );

            initializeConversationsListener(
                db,
                handleNewMessage,
                handleMembersChange
            );

            const formattedConversations = await fetchInitialConversations(
                getState
            );
            dispatch(setConversations(formattedConversations));
            dispatch(isLoading(false));

            return () => {
                offConversationListeners(
                    db,
                    handleNewMessage,
                    handleMembersChange
                );
            };
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

const fetchInitialConversations = async (getState: any) => {
    const conversationResponse = await chatApi.getConversations();
    const user = getState().user.profile;
    return conversationResponse.map((conv: IResponseChatEntity) =>
        formatConversation(conv, user)
    );
};

export const formatConversation = (
    conv: IResponseChatEntity,
    user: { uid: string }
): IChatEntity => {
    const isGroup = conv.isGroup;

    const members: IContact[] = Object.keys(conv.members).map(
        (uid: string) => ({
            ...conv.members[uid],
            isCurrentUser: uid === user?.uid,
        })
    );

    // first check if exist conv.deletedMembers
    if (!conv.deletedMembers) {
        conv.deletedMembers = {};
    }
    const deletedMembers: IContact[] = Object.keys(conv.deletedMembers).map(
        (uid: string) => ({
            ...conv.deletedMembers[uid],
            isCurrentUser: uid === user?.uid,
            contactType: "deleted",
        })
    );
    const allMembers = [...members, ...deletedMembers];

    const otherMember = !isGroup
        ? members.find((member) => member.uid !== user?.uid)
        : null;

    const title = isGroup
        ? conv.title
        : otherMember?.firstName + " " + otherMember?.lastName;
    const truncatedTitle =
        title.length > 60 ? title.substring(0, 60) + "..." : title;
    const avatar = isGroup
        ? conv.avatar
        : otherMember?.avatar || otherMember?.avatarThumb;

    let messagesArray: IChatMessage[] = conv.messages
        ? Object.values(conv.messages).map((msg: IResponseChatMessage) => {
              return {
                  id: msg.id,
                  content: msg.content,
                  sender:
                      allMembers.find(
                          (member: IContact) => member.uid === msg.senderId
                      ) || ({} as IContact),
                  readBy: msg.readBy,
                  timestamp: msg.timestamp,
                  mediaUrl: undefined,
                  mediaType: undefined,
                  attachments: msg.attachments || [],
                  attachOnTrip: msg.attachOnTrip || undefined,
              };
          })
        : [];

    messagesArray = messagesArray.sort((a, b) => a.timestamp - b.timestamp);
    messagesArray = messagesArray.filter(
        (msg) => msg.content || msg?.attachments?.length
    );

    const lastMessageObj: IChatMessage | null =
        messagesArray.length > 0
            ? messagesArray[messagesArray.length - 1]
            : null;
    const lastMessageTimestamp = lastMessageObj ? lastMessageObj.timestamp : 0;

    return {
        id: conv.id,
        title: truncatedTitle,
        isGroup,
        members,
        deletedMembers: deletedMembers,
        messages: messagesArray,
        avatar,
        lastMessageTimestamp,
        isBlockedBy: conv.isBlockedBy,
        isFriend: conv.isFriend,
    };
};

export const sendChatPushNotification =
    (payload: IChatPushPayload): any =>
    async () => {
        try {
            const response = await server.sendChatPushNotification(payload);
            return response.data;
        } catch (error) {
            return;
        }
    };

export const handleBlockUser = (): AppThunk => async (dispatch, getState) => {
    try {
        const user = getState().user.profile;
        const conversation = getState().chat.selectedConversation;
        if (!conversation) {
            return;
        }
        await chat.blockUser(user.uid, conversation.id);
        const updateSelectedConversation = {
            ...conversation,
            isBlockedBy: user.uid,
        };
        setSelectConversation(updateSelectedConversation);
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const handleUnBlockUser = (): AppThunk => async (dispatch, getState) => {
    try {
        const conversation = getState().chat.selectedConversation;
        if (!conversation) {
            return;
        }
        await chat.unBlockUser(conversation.id);
        const updateSelectedConversation = {
            ...conversation,
            isBlockedBy: undefined,
        };
        setSelectConversation(updateSelectedConversation);
    } catch (error) {
        dispatch(handleError(error));
    }
};
export const handleAcceptUser = (): AppThunk => async (dispatch, getState) => {
    try {
        const user = getState().user.profile;
        const conversation = getState().chat.selectedConversation;
        if (!conversation) {
            return;
        }
        const updateSelectedConversation = {
            ...conversation,
            isFriend: true,
        };
        setSelectConversation(updateSelectedConversation);
    } catch (error) {
        dispatch(handleError(error));
    }
};
export const handleReportUser =
    (reason: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const user = getState().user.profile;
            const conversation = getState().chat.selectedConversation;
            if (!conversation) {
                return;
            }
            const userReport = conversation.members.find(
                (member) => member.uid !== user.uid
            );
            if (!userReport) {
                return;
            }
            await chat.blockUser(user.uid, conversation.id);
            const updateSelectedConversation = {
                ...conversation,
                isBlockedBy: user.uid,
            };
            setSelectConversation(updateSelectedConversation);
            const complaintPayload: IReportComplaint = {
                reason,
                comments: "",
                itemId: Number(userReport.id),
                itemType: "USER",
                title: `Report User ${userReport.firstName} ${userReport.lastName}`,
            };
            dispatch(reportComplaint(complaintPayload));
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const selectCurrentUser = (state: RootState) => state.user.profile;
export const selectConversations = (state: RootState) =>
    state.chat.conversations;
export const selectSelectedConversation = (state: RootState) =>
    state.chat.selectedConversation;
export const selectContactSearchLists = (state: RootState) =>
    state.chat.contactSearchLists;
export const selectLoading = (state: RootState) => state.chat.isLoading;
export const selectError = (state: RootState) => state.chat.error;

export default chatSlice.reducer;
