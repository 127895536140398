import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PAGE_SECTIONS } from "../../constants";
import { getSettings, selectProfile } from "../authentication/userSlice";
import { setCurrentSection } from "../layout/layoutSlice";
import NotificationsSettingsView from "./notificationsSettings";
import { TimeSettings } from "./timeSettings/TimeSettings";

export default function UserPreferences() {
    const dispatch = useAppDispatch();
    const { id } = useAppSelector(selectProfile);
    useEffect(() => {
        if (id) dispatch(getSettings());
        return () => {
            // cleanup
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.PREFERENCES_USER,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-10 sm:mt-0">
            <div className="flex flex-col mb-6 items-start">
                <div className="text-left font-semibold text-lg flex-grow mb-2">
                    Preferences
                </div>
                <TimeSettings />
            </div>
            <div className="flex flex-col mb-6 items-start">
                <div className="text-left font-semibold text-lg flex-grow mb-4">
                    Notifications
                </div>
                <NotificationsSettingsView />
            </div>
        </div>
    );
}
