import { FC, InputHTMLAttributes, useState } from "react";
import { Controller } from "react-hook-form";

import Modal from "../Modal";
import Input from "../Input/Input";
import Icon from "../../assets/svg/Icon";
import colors from "tailwindcss/colors";
import FlightLinesAutocomplete from "./FlightLinesAutocomplete";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    className?: string;
    control?: any;
    onNew?: any;
    markRequired?: boolean;
    currentValue?: any;
}

const FlightLinesAutocompleteWithValid: FC<InputProps> = ({
    name,
    error,
    label,
    control,
    onChange,
    onNew,
    markRequired,
    currentValue,
    ...rest
}) => {
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
    const handleChange = (other: any, handleOnChangeValidator: Function) => {
        if (onChange) onChange(other);
        if (handleOnChangeValidator) handleOnChangeValidator(other);
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <>
                    <Modal
                        closeOnBackClick
                        open={showSearchModal}
                        onClose={() => {
                            setShowSearchModal(false);
                        }}
                    >
                        <div>
                            <div className="pl-2 text-sm font-semibold text-neutral-900">
                                Airline
                            </div>
                            <FlightLinesAutocomplete
                                name={name}
                                onChange={(a: any) => {
                                    handleChange(a, onChange);
                                    setShowSearchModal(false);
                                }}
                                onBlur={onBlur}
                                placeholder={"Search airline by name"}
                                ref={ref}
                                {...rest}
                            />
                            <div className="mx-2">
                                <div className="w-full text-center text-sm font-semibold text-neutral-800 my-4">
                                    Or complete it manually
                                </div>
                                <Input
                                    label={label}
                                    onChange={(e: any) => {
                                        handleChange(e.target.value, onChange);
                                    }}
                                    onBlur={onBlur}
                                    value={currentValue}
                                    placeholder={"Type the name of the airline"}
                                    {...rest}
                                />
                            </div>
                        </div>
                    </Modal>
                    <div className="flex flex-row items-center">
                        <Input
                            readOnly
                            label={label}
                            onClick={() => setShowSearchModal(true)}
                            value={currentValue || ""}
                            markRequired={markRequired}
                            placeholder={"Select airline"}
                            className={"cursor-pointer bg-transparent"}
                            type="text"
                            error={error}
                        />
                        {!!currentValue && (
                            <div className="">
                                <Icon
                                    icon={"CloseIcon"}
                                    color={colors.gray[400]}
                                    width={20}
                                    className="text-gray-400 cursor-pointer"
                                    onClick={() => {
                                        handleChange(undefined, onChange);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        />
    );
};

export default FlightLinesAutocompleteWithValid;
