import moment from "moment";
import StatusBadgeComponent from "../../../../../../components/StatusBadge/StatusBadgeComponent";
import RequirementAction from "./RequirementAction";
import { TripRequirementFileStatus } from "../../../../../../constants";
import { useMemo, useState } from "react";
import ButtonSquared from "../../../../../../components/ButtonSquare";
import Modal, { Modal_Size } from "../../../../../../components/Modal";
import TripRequirementFileForm from "../../../TripRequirements/TripRequirement/RequirementFileForm";
import { Paragraph } from "../../../../../../components/Paragraph/Paragraph";

interface DocumentRequirementsModalProps {
    requirement: any;
    onUpdate: any;
    onClose?: any;
    askResubmission?: any;
    open?: boolean;
}
export default function DocumentRequirementsModal(
    props: DocumentRequirementsModalProps
) {
    const { requirement, onUpdate, onClose, askResubmission } = props;
    const [uploadModal, setUploadModal] = useState(false);

    const showUploadButton = useMemo(() => {
        return [
            TripRequirementFileStatus.REJECTED,
            TripRequirementFileStatus.NOT_SUBMITTED,
        ].includes(requirement?.status);
    }, [requirement?.status]);
    const buttonLabel = useMemo(() => {
        switch (requirement?.type) {
            case "arrival_departure_info":
                return "Add travel details";
            case "passport":
                return "Upload passport";
            default:
                return "Complete requirement";
        }
    }, [requirement?.type]);
    const open = useMemo(() => {
        return props.open || false;
    }, [props.open]);

    const customBadgeText = useMemo(() => {
        if (requirement?.status === TripRequirementFileStatus.PENDING) {
            return "Needs Review";
        }
        return undefined;
    }, [requirement?.status]);

    return (
        <>
            {requirement && uploadModal && !!open && (
                <Modal
                    open={open}
                    size={Modal_Size.lg}
                    onClose={() => {
                        setUploadModal(false);
                    }}
                >
                    <TripRequirementFileForm
                        requirement={requirement}
                        userEmail={requirement?.userEmail}
                        onSubmit={onUpdate}
                        onCancel={() => setUploadModal(false)}
                    />
                </Modal>
            )}

            {requirement && !uploadModal && (
                <Modal open={open} size={Modal_Size.lg} onClose={onClose}>
                    <div className="md:p-2 flex flex-col">
                        <div className="flex">
                            <div className="text-lg font-semibold flex-grow">
                                {requirement.name}
                            </div>
                            <div className="">
                                <StatusBadgeComponent
                                    size="base"
                                    status={requirement?.status}
                                    customText={customBadgeText}
                                />
                            </div>
                        </div>
                        <div className="py-2 text-left text-sm text-neutral-400">
                            Deadline:{" "}
                            {moment(requirement?.deadline).format(
                                "DD MMM, YYYY"
                            )}
                        </div>
                        <Paragraph className="text-left text-sm">
                            {requirement.description}
                        </Paragraph>
                        <div className="md:py-3">
                            {requirement?.files?.map((file: any) => (
                                <RequirementAction
                                    key={file.id}
                                    tripId={requirement.tripId}
                                    requirement={requirement}
                                    file={file}
                                    onActionTaken={onUpdate}
                                />
                            ))}
                        </div>

                        {showUploadButton && (
                            <div className="md:p-3">
                                <div className="flex items-center">
                                    <ButtonSquared
                                        className="p-4 mx-auto"
                                        type="button"
                                        label={buttonLabel}
                                        onClick={() => {
                                            setUploadModal(true);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {requirement?.status ===
                            TripRequirementFileStatus.APPROVED && (
                            <div className="flex justify-start">
                                <ButtonSquared
                                    className="p-4"
                                    type="button"
                                    label="Request re-submission"
                                    onClick={() => {
                                        askResubmission();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
