import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { dietarySchema } from "./DietaryEdit";
import { useForm } from "react-hook-form";
import { CheckboxGroup } from "./CheckboxGroup";
import CheckBox from "../../../../components/CheckBox";

export default function DietaryInfo(props: any) {
    const { profileExtra } = props;
    const { reset, control, register, watch } = useForm({
        resolver: yupResolver(dietarySchema),
    });
    const noRestrictions = watch("dietaryNoRestrictions");
    useEffect(() => {
        reset({
            //no restrictions
            dietaryNoRestrictions: profileExtra?.dietaryNoRestrictions,
            dietaryNoRestrictionsComment:
                profileExtra?.dietaryNoRestrictionsComment,
            // Dietary Preferences and Restrictions
            dietaryVegetarian: profileExtra?.dietaryVegetarian,
            dietaryVegetarianComment: profileExtra?.dietaryVegetarianComment,
            dietaryVegan: profileExtra?.dietaryVegan,
            dietaryVeganComment: profileExtra?.dietaryVeganComment,
            dietaryGlutenIntolerance: profileExtra?.dietaryGlutenIntolerance,
            dietaryGlutenIntoleranceComment:
                profileExtra?.dietaryGlutenIntoleranceComment,
            dietaryLactoseIntolerance: profileExtra?.dietaryLactoseIntolerance,
            dietaryLactoseIntoleranceComment:
                profileExtra?.dietaryLactoseIntoleranceComment,
            dietaryOther: profileExtra?.dietaryOther,
            dietaryOtherComment: profileExtra?.dietaryOtherComment,

            // Preferred Beverages
            beverageCoffee: profileExtra?.beverageCoffee,
            beverageCoffeeComment: profileExtra?.beverageCoffeeComment,
            beverageTea: profileExtra?.beverageTea,
            beverageTeaComment: profileExtra?.beverageTeaComment,
            beverageJuice: profileExtra?.beverageJuice,
            beverageJuiceComment: profileExtra?.beverageJuiceComment,
            beverageSoda: profileExtra?.beverageSoda,
            beverageSodaComment: profileExtra?.beverageSodaComment,
            beverageWater: profileExtra?.beverageWater,
            beverageWaterComment: profileExtra?.beverageWaterComment,
            beverageOther: profileExtra?.beverageOther,
            beverageOtherComment: profileExtra?.beverageOtherComment,

            // Alcohol Preferences
            alcoholNoAlcohol: profileExtra?.alcoholNoAlcohol,
            alcoholNoAlcoholComment: profileExtra?.alcoholNoAlcoholComment,
            alcoholWine: profileExtra?.alcoholWine,
            alcoholWineComment: profileExtra?.alcoholWineComment,
            alcoholBeer: profileExtra?.alcoholBeer,
            alcoholBeerComment: profileExtra?.alcoholBeerComment,
            alcoholSpirits: profileExtra?.alcoholSpirits,
            alcoholSpiritsComment: profileExtra?.alcoholSpiritsComment,
            alcoholOther: profileExtra?.alcoholOther,
            alcoholOtherComment: profileExtra?.alcoholOtherComment,

            // Additional Notes
            additionalNotes: profileExtra?.additionalNotes,
        });
    }, [profileExtra, reset]);

    return (
        <>
            <div className="flex flex-col">
                <form action="#" method="POST">
                    <div className="space-y-6">
                        <CheckBox
                            control={control}
                            name={"dietaryNoRestrictions"}
                            label="I have no: dietary restrictions, dietary/beverage preferences, other allergies, mobility restrictions, or other needs to share"
                            disabled={true}
                        />
                        {!noRestrictions && (
                            <>
                                <CheckboxGroup
                                    title="Dietary Preferences and Restrictions"
                                    control={control}
                                    options={[
                                        {
                                            name: "dietaryVegetarian",
                                            label: "Vegetarian",
                                        },
                                        {
                                            name: "dietaryVegan",
                                            label: "Vegan",
                                        },
                                        {
                                            name: "dietaryGlutenIntolerance",
                                            label: "Gluten intolerance",
                                        },
                                        {
                                            name: "dietaryLactoseIntolerance",
                                            label: "Lactose intolerance",
                                        },
                                        {
                                            name: "dietaryOther",
                                            label: "Other allergies/restrictions",
                                            span: true,
                                        },
                                    ]}
                                    setValue={() => {}}
                                    watch={watch}
                                    register={register}
                                    disabled={true}
                                />

                                <CheckboxGroup
                                    title="Preferred Beverages"
                                    control={control}
                                    options={[
                                        {
                                            name: "beverageCoffee",
                                            label: "Coffee",
                                        },
                                        { name: "beverageTea", label: "Tea" },
                                        {
                                            name: "beverageJuice",
                                            label: "Juice",
                                        },
                                        { name: "beverageSoda", label: "Soda" },
                                        {
                                            name: "beverageWater",
                                            label: "Water",
                                            span: true,
                                        },
                                        {
                                            name: "beverageOther",
                                            label: "Other",
                                            span: true,
                                        },
                                    ]}
                                    setValue={() => {}}
                                    watch={watch}
                                    register={register}
                                    disabled={true}
                                />

                                <CheckboxGroup
                                    title="Alcohol Preferences"
                                    control={control}
                                    options={[
                                        {
                                            name: "alcoholNoAlcohol",
                                            label: "No alcohol",
                                        },
                                        { name: "alcoholWine", label: "Wine" },
                                        { name: "alcoholBeer", label: "Beer" },
                                        {
                                            name: "alcoholSpirits",
                                            label: "Spirits",
                                        },
                                        {
                                            name: "alcoholOther",
                                            label: "Other",
                                            span: true,
                                        },
                                    ]}
                                    setValue={() => {}}
                                    watch={watch}
                                    register={register}
                                    disabled={true}
                                />

                                <div>
                                    <h3 className="font-semibold text-lg text-left pb-4 text-base">
                                        Additional Notes (including other
                                        allergies and physical limitations)
                                    </h3>
                                    <textarea
                                        className="w-full border rounded-md p-2"
                                        {...register("additionalNotes")}
                                        placeholder="Comments"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}
