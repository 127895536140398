import {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATION_LOADING } from "../api/firebase";
import serverApi from "../api/server";
import { getSettings, setAuth } from "../features/authentication/userSlice";
import { UserTypeModal } from "../components/Modal/UserTypeModal";
import { getCurrentPlan } from "../features/plans/plansSlice";
import { UserTypeVariant } from "../components/UserType/UserType";
import chat from "../api/chat";
import { TripsLimitReachedModal } from "../features/userPlan/TripsLimitReachedModal";
import { ChoosePlanModal } from "../components/Modal/ChoosePlanModal";
interface FirebaseUser {
    email: string;
    displayName: string;
}

interface AuthSContext {
    status: string;
    user?: FirebaseUser | null | undefined;
    token?: string;
    signOut?: Function;
    signUpWithEmailAndPassword?: Function;
    signInWithEmailAndPassword?: Function;
    signInWithCustomToken?: Function;
    signInWithFacebook?: Function;
    signInWithGoogle?: Function;
    updateEmailAddress?: Function;
    sendPasswordResetEmail?: Function;
    sendEmailVerification?: Function;
    userHasOnlyEmailProvider?: Function;
    setAuthState?: Function;
    currentUserType?: string | null;
    tripsLimitReached?: boolean;
    isFreeSupplier?: boolean;
    tripsCount?: number;
    getTripsCount?: Function;
    setShowTripsReachedModal: Function;
    setShowPlanModal: Function;
}

interface AuthSState {
    status: string;
    user: FirebaseUser | null | undefined;
    token?: string | undefined;
    setShowTripsReachedModal: Function;
}

const AuthStateContext = createContext<AuthSContext>({
    status: AUTHENTICATION_LOADING,
    user: undefined,
    token: undefined,
    setShowTripsReachedModal: () => {},
    setShowPlanModal: () => {},
});

export default function AuthStateProvider({
    children,
    Firebase,
}: any): JSX.Element {
    const [authState, setAuthState] = useState<AuthSState>({
        status: AUTHENTICATION_LOADING,
        user: undefined,
        token: undefined,
        setShowTripsReachedModal: () => {},
    });
    const [openUserTypeModal, setOpenUserTypeModal] = useState(false);
    const [currentUserType, setCurrentUserType] = useState<string | null>(null);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const currentPlan = useSelector((state: any) => state.plans.currentPlan);
    const [tripsCount, setTripsCount] = useState<number>(2);
    const [showTripsReachedModal, setShowTripsReachedModal] =
        useState<boolean>(false);

    const signOut = () => Firebase.signOut(setAuthState);
    const dispatch = useDispatch();
    const signUpWithEmailAndPassword = (
        email: string,
        password: string,
        name: string,
        callback: string,
        onError: Function
    ) => {
        const expandedOnError = (error: any) => {
            // If there is an error with the login we will rollback to the last auth state.
            setAuthState(authState);
            onError && onError(error);
        };
        Firebase.signUpWithEmailAndPassword(
            email,
            password,
            name,
            setAuthState,
            callback,
            expandedOnError
        );
    };

    const signInWithCustomToken = (token: string, onError: Function) => {
        return Firebase.signInWithCustomToken(token, onError);
    };

    const signInWithEmailAndPassword = (
        email: string,
        password: string,
        callback: Function,
        onError: Function
    ) => {
        const expandedOnError = (error: any) => {
            // If there is an error with the login we will rollback to the last auth state.
            setAuthState(authState);
            onError && onError(error);
        };
        return Firebase.signInWithEmailAndPassword(
            email,
            password,
            setAuthState,
            callback,
            expandedOnError
        );
    };
    const signInWithFacebook = (callback: string, onError: any) =>
        Firebase.signInWithFacebook(setAuthState, callback, onError);
    const signInWithGoogle = (callback: Function) =>
        Firebase.signInWithGoogle(setAuthState, callback);
    const updateEmailAddress = (email: string, callback: Function) =>
        Firebase.updateEmailAddress(email, callback);
    const sendPasswordResetEmail = (
        email: string,
        callback: Function,
        onError: Function
    ) => Firebase.sendPasswordResetEmail(email, callback, onError);
    const sendEmailVerification = (callback: Function, onError: Function) =>
        Firebase.sendEmailVerification(callback, onError);
    const userHasOnlyEmailProvider = () => Firebase.userHasOnlyEmailProvider();

    useEffect(() => {
        let unsubscribe = Firebase.authState(setAuthState, setToken);
        let unsubscribeToken = Firebase.onIdTokenChanged(
            setAuthState,
            setToken
        );

        return () => {
            unsubscribe();
            unsubscribeToken();
            return;
        };
        // eslint-disable-next-line
    }, []);

    const setToken = (token: string) => {
        serverApi.setAuthToken(token);
        chat.setAuthToken(token);
        dispatch(
            setAuth({
                auth: {
                    token: token,
                },
            })
        );

        dispatch(getSettings());
    };

    const handleCloseUserTypeModal = () => {
        setOpenUserTypeModal(false);
    };

    useEffect(() => {
        if (userProfile?.id && !userProfile?.userType) {
            setOpenUserTypeModal(true);
        }

        if (userProfile?.userType) {
            setCurrentUserType(userProfile.userType);
        }

        if (userProfile?.id) {
            dispatch(getCurrentPlan());
        }
    }, [userProfile]);

    useEffect(() => {
        if (
            userProfile?.userType === UserTypeVariant.SUPPLIER &&
            currentPlan?.subscriptionStatus === "free"
        ) {
            getTripsCount();
        }
    }, [currentPlan?.subscriptionStatus, userProfile?.userType]);

    const getTripsCount = useCallback(async () => {
        try {
            const response = await serverApi.getTripsCount();
            setTripsCount(response?.data?.count);
        } catch (error) {
            setTripsCount(-1);
        }
    }, []);

    const handleForceClosePlanModal = () => {
        setShowPlanModal(false);
        setOpenUserTypeModal(false);
    }

    const isFreeSupplier = useMemo(() => {
        return (
            !userProfile.isAdmin &&
            userProfile?.userType === UserTypeVariant.SUPPLIER &&
            currentPlan?.subscriptionStatus === "free"
        );
    }, [
        currentPlan?.subscriptionStatus,
        userProfile.isAdmin,
        userProfile?.userType,
    ]);

    const maxNumberOfTrips = useMemo(() => {
        return (
            currentPlan?.planPermissions?.find((i: any) => {
                return i.name === "maxNumberOfTrips";
            })?.value || 9999999
        );
    }, [currentPlan?.planPermissions]);

    const tripsLimitReached = useMemo(() => {
        if (userProfile.isAdmin) {
            return false;
        }
        return (
            !currentPlan ||
            (userProfile?.userType === UserTypeVariant.SUPPLIER &&
                tripsCount >= maxNumberOfTrips)
        );
    }, [currentPlan?.subscriptionStatus, userProfile?.userType, tripsCount]);

    return (
        <AuthStateContext.Provider
            value={{
                status: authState.status,
                user: authState.user,
                token: authState.token,
                signOut,
                signUpWithEmailAndPassword,
                signInWithEmailAndPassword,
                signInWithCustomToken,
                signInWithFacebook,
                signInWithGoogle,
                updateEmailAddress,
                sendPasswordResetEmail,
                sendEmailVerification,
                userHasOnlyEmailProvider,
                currentUserType,
                setAuthState,
                tripsLimitReached,
                getTripsCount,
                setShowTripsReachedModal,
                isFreeSupplier,
                tripsCount,
                setShowPlanModal,
            }}
        >
            {openUserTypeModal && (
                <UserTypeModal
                    open={openUserTypeModal}
                    handleClose={handleCloseUserTypeModal}
                    currentUserType={userProfile?.userType}
                />
            )}

            {!!showPlanModal && (
                <ChoosePlanModal
                    open={showPlanModal}
                    handleClose={() => {
                        setShowPlanModal(false);
                    }}
                    showDefault={false}
                    onChangePlan={() => {
                        setShowPlanModal(false);
                    }}
                    userType={userProfile?.userType}
                    closeText={"Close"}
                    forceCloseModal={handleForceClosePlanModal}
                />
            )}

            {showTripsReachedModal && (
                <TripsLimitReachedModal
                    open={showTripsReachedModal}
                    onClose={() => {
                        setShowTripsReachedModal(false);
                    }}
                />
            )}

            {children}
        </AuthStateContext.Provider>
    );
}

export const useAuthStateContext = () => useContext(AuthStateContext);
