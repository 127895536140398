import { NOTIFICATION_TYPES } from "../../constants/notifications";

export const hasComments = (notificationType: any) => {
    return [
        NOTIFICATION_TYPES.USER_FEEDBACK_RESPONSE,
        NOTIFICATION_TYPES.USER_CHANGE_ROLE,
        NOTIFICATION_TYPES.USER_CHANGE_EMAIL,
    ].includes(notificationType);
};

export const convertLinksToAnchors = (text: string) => {
    return text.replace(
        /((https?:\/\/|www\.)?[\w.-]+\.[a-z]{2,6}\b([\/\w?=&%-.]*)?)/gi,
        (match: string, prefix: string) => {
            let url = match.startsWith("http") ? match : `http://${match}`;
            return `<a href="${url}" target="_blank">${match}</a>`;
        }
    );
};