import { MouseEventHandler, useState } from "react";
import Modal, { Modal_Size } from ".";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { TextDivider } from "../TextDivider/TextDivider";
import { ChoosePlanModal } from "./ChoosePlanModal";
import { useSelector } from "react-redux";

export interface ModalProps {
    open?: boolean;
    handleCancel: Function;
    handleSubmit: Function;
    plan: any;
}

export const CancelPlanModal: React.FunctionComponent<ModalProps> = (props) => {
    const { open, handleCancel, handleSubmit, plan } = props;
    const [showChangePlan, setShowChangePlan] = useState(false);
    const user = useSelector((state: any) => state.user.profile);

    const handleClickCloseChangePlan = (closeAll?: boolean) => {
        setShowChangePlan(false)
        if (closeAll) {
            handleCancel();
        }
    };
    return (
        <>
            <Modal open={open} onClose={handleCancel} size={Modal_Size.small}>
                <div className="px-6">
                    <div className="text-xl text-center font-semibold mb-6">
                        Are you sure you want <br />
                        to cancel your subscription?
                    </div>
                    <div className="text-sm font-base text-center mx-8">
                        If you cancel your plan now, your account will remain
                        active until{" "}
                        {plan?.subscriptionStatus === "trialing"
                            ? plan?.trialEndDate
                            : plan?.currentPeriodEnds}
                    </div>
                    <div className="flex flex-row gap-4 mt-8 w-full justify-center text-right">
                        <ButtonSquared
                            outlined={true}
                            label="Confirm"
                            onClick={() => handleSubmit()}
                            size={ButtonSize.NORMAL}
                        />
                        <ButtonSquared
                            label="Not now"
                            onClick={() => handleCancel()}
                            size={ButtonSize.NORMAL}
                        />
                    </div>
                    <TextDivider text={"or"} />
                    <div className="flex flex-row items-center justify-center mb-4">
                        <div
                            onClick={() => setShowChangePlan(true)}
                            className="w-fit text-md font-semibold text-orange-600 cursor-pointer"
                        >
                            Change Plan
                        </div>
                    </div>
                </div>
            </Modal>
            <ChoosePlanModal
                open={showChangePlan}
                handleClose={() => handleClickCloseChangePlan(false)}
                onChangePlan={() => handleClickCloseChangePlan(true)}
                userType={user?.userType}
                forceCloseModal={() => setShowChangePlan(false)}
            />
        </>
    );
};
