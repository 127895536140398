import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../components/ButtonSquare";
import Input from "../../../components/Input";
import SelectInput from "../../../components/InputSelect";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ButtonSize,
    OrganizationConsortia,
    OrganizationConsortiaOptions,
    OrganizationType,
    OrganizationTypeOptions,
} from "../../../constants";
import PhoneInput from "../../../components/PhoneInput";
import {
    createOrganization,
    selectedOrganization,
    updateOrganization,
} from "../organizationSlice";
import HotelGroupsAutocompleteWithValid from "../../../components/HotelGroupsAutocomplete";
import SelectInputWithValid from "../../../components/InputSelect";
import HostAgenciesAutocompleteWithValid from "../../../components/HostAgenciesAutocomplete";
import Modal from "../../../components/Modal";
import HotelGroupForm from "./HotelGroupForm";
import HostAgencyForm from "./HostAgencyForm";
import Avatar from "../../../components/Avatar";
import Icon from "../../../assets/svg/Icon";
import { useNavigate } from "react-router";
import defaultImage from "../../../assets/images/placeholder.png";
import { mdiPlus } from "@mdi/js";
import CruiseLineForm from "./CruiseLineForm";
import CruiseLinesAutocompleteWithValid from "../../../components/CruiseLinesAutocomplete";

const schema = ({
    multipleConsortia,
    personal,
}: {
    multipleConsortia?: boolean;
    personal?: boolean;
}) =>
    yup
        .object({
            name: yup.string().required("This field is required"),
            email: yup.string().email().required("This field is required"),
            phone: personal
                ? yup.string().nullable()
                : yup.string().required("This field is required"),
            type: yup.string().required("This field is required"),
            ...(personal
                ? {}
                : {
                      website: yup.string().url().nullable(),
                      consortia: multipleConsortia
                          ? yup.array().nullable()
                          : yup.string().nullable(),
                      consortiaOther: yup.string().when("consortia", {
                          is: (val: string) =>
                              val === OrganizationConsortia.OTHER,
                          then: yup.string().required("This field is required"),
                      }),
                      hotelGroup: yup.object().when("type", {
                          is: (val: string) =>
                              val === OrganizationType.HOTEL_CHAIN,
                          then: yup.object().required("This field is required"),
                      }),
                      cruiseLine: yup.object().when("type", {
                          is: (val: string) =>
                              val === OrganizationType.CRUISE_LINE,
                          then: yup.object().required("This field is required"),
                      }),
                      hostAgency: yup.object().when("type", {
                          is: (val: string) => val === OrganizationType.AGENCY,
                          then: yup.object().nullable().optional(),
                      }),
                      agencyIATA: yup.string().when("type", {
                          is: (val: string) => val === OrganizationType.AGENCY,
                          then: yup.string().required("This field is required"),
                      }),
                      agencyCLIA: yup.string().when("type", {
                          is: (val: string) => val === OrganizationType.AGENCY,
                          then: yup.string().nullable().optional(),
                      }),
                  }),
        })
        .required("This field is required");

interface OrganizationFormProps {
    toggleEdit?: any;
}

export default function OrganizationForm(props: OrganizationFormProps) {
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [hotelGroupFormOpen, setHotelGroupFormOpen] = useState(false);
    const [cruiseLineFormOpen, setCruiseLineFormOpen] = useState(false);
    const [hostAgencyFormOpen, setHostAgencyFormOpen] = useState(false);
    const [preview, setPreview] = useState<string | undefined>("");
    const organization = useAppSelector(selectedOrganization);
    const currentUser = useAppSelector((state: any) => state?.user?.profile);
    const navigate = useNavigate();
    const [orgType, setOrgType] = useState<string>();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        control,
        watch,
        setValue,
    } = useForm({
        shouldUnregister: true,
        resolver: yupResolver(
            schema({
                multipleConsortia: orgType !== OrganizationType.AGENCY,
                personal: orgType === OrganizationType.PERSONAL,
            })
        ),
    });
    const dispatch = useAppDispatch();
    const values = getValues();
    const watchType: OrganizationType | undefined = watch("type");
    const watchAgency: any | undefined = watch("hostAgency");
    const watchHotelGroup: any | undefined = watch("hotelGroup");
    const watchCruiseLine: any | undefined = watch("cruiseLine");
    const watchConsortia = watch("consortia");
    useEffect(() => {
        setIsUpdate(!!organization?.id);
    }, [organization]);
    useEffect(() => {
        if (organization?.id) {
            reset({
                name: organization.name,
                phone: organization.phone,
                type: organization.type,
                email:
                    organization.email ||
                    (orgType === OrganizationType.PERSONAL
                        ? currentUser?.email
                        : undefined),
                website: organization.website,
                consortia:
                    organization.type !== OrganizationType.AGENCY
                        ? organization?.consortia?.split(",")
                        : organization?.consortia,
                consortiaOther: organization?.consortiaOther,
                hotelGroup: organization?.hotelGroup,
                cruiseLine: organization?.cruiseLine,
                hostAgency: organization?.hostAgency,
                agencyIATA: organization?.agencyIATA,
                agencyCLIA: organization?.agencyCLIA,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization?.id]);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const handleSubmitForm = async (data: any) => {
        const formData = new FormData();
        if (selectedFile) formData.append("image", selectedFile);
        formData.append("name", data.name || "");
        formData.append("type", data.type || "");
        formData.append("phone", data.phone || "");
        formData.append("email", data.email || "");
        formData.append("website", data.website || "");
        formData.append("position", data.position || "");
        formData.append("agencyIATA", data.agencyIATA || "");
        formData.append("agencyCLIA", data.agencyCLIA || "");
        formData.append("consortia", data?.consortia || "");
        if (consortiaOtherEnabled(data.consortia)) {
            formData.append("consortiaOther", data.consortiaOther || "");
        } else {
            formData.append("consortiaOther", "");
        }

        formData.append("hostAgencyId", data?.hostAgency?.id || "");
        formData.append("hotelGroupId", data?.hotelGroup?.id || "");
        formData.append("cruiseLineId", data?.cruiseLine?.id || "");
        await dispatch(
            isUpdate
                ? updateOrganization(Number(organization.id), formData)
                : createOrganization(formData, navigateBack)
        );

        if (isUpdate) {
            navigateBack();
        }
    };

    const navigateBack = (organizationId?: any, section?: string) => {
        let orgId =
            typeof organizationId === "number" ||
            typeof organizationId === "string"
                ? organizationId
                : "";
        if (section) {
            orgId += `/${section}`;
        }
        navigate(`/organizations/${organization?.id || orgId}`);
    };

    const hgEnabled = (orgType: OrganizationType | undefined) =>
        orgType === OrganizationType.HOTEL_CHAIN;
    const hotelGroupEnabled = useMemo(() => {
        setOrgType(watchType);
        return hgEnabled(watchType);
    }, [watchType]);

    const haEnabled = (orgType: OrganizationType | undefined) =>
        orgType === OrganizationType.AGENCY;
    const hostAgencyEnabled = useMemo(() => {
        return haEnabled(watchType);
    }, [watchType]);

    const clEnabled = (orgType: OrganizationType | undefined) =>
        orgType === OrganizationType.CRUISE_LINE;
    const cruiseLineEnabled = useMemo(() => {
        return clEnabled(watchType);
    }, [watchType]);

    const consortiaOtherEnabled = (consortia: string) =>
        !hostAgencyEnabled
            ? consortia?.includes(OrganizationConsortia.OTHER)
            : consortia === OrganizationConsortia.OTHER;
    const coEnabled = useMemo(() => {
        return consortiaOtherEnabled(watchConsortia || values?.consortia);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchConsortia, values?.consortia, hostAgencyEnabled]);

    useEffect(() => {
        if (hotelGroupEnabled) {
            setValue("hotelGroup", organization?.hotelGroup);
        } else {
            setValue("hotelGroup", undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotelGroupEnabled, organization?.hotelGroupId]);

    useEffect(() => {
        const consortiaOrgValue = !!organization?.consortia?.length
            ? organization?.consortia?.split(",")
            : [];

        const newConsortia = hostAgencyEnabled
            ? consortiaOrgValue[0]
            : consortiaOrgValue;
        setValue("consortia", newConsortia);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostAgencyEnabled]);

    useEffect(() => {
        if (cruiseLineEnabled) {
            setValue("cruiseLine", organization?.cruiseLine);
        } else {
            setValue("cruiseLine", undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cruiseLineEnabled, organization?.cruiseLineId]);

    // useEffect(() => {
    //     if (hostAgencyEnabled) {
    //         setValue("hostAgency", organization?.hostAgency);
    //         setValue("agencyIATA", organization?.agencyIATA);
    //         setValue("agencyCLIA", organization?.agencyCLIA);
    //     } else {
    //         setValue("hostAgencyId", undefined);
    //         setValue("agencyIATA", undefined);
    //         setValue("agencyCLIA", undefined);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [hostAgencyEnabled]);

    const handleHotelGroupSubmitFinish = (hotelGroup: any) => {
        setValue("hotelGroup", hotelGroup);
        setHotelGroupFormOpen(false);
    };
    const handleHotelGroupFormCancel = () => {
        setHotelGroupFormOpen(false);
    };

    const handleCruiseLineSubmitFinish = (cruiseLine: any) => {
        setValue("cruiseLine", cruiseLine);
        setCruiseLineFormOpen(false);
    };
    const handleCruiseLineFormCancel = () => {
        setCruiseLineFormOpen(false);
    };

    const handleHostAgencySubmitFinish = (newAgency: any) => {
        setValue("hostAgency", newAgency);
        setHostAgencyFormOpen(false);
    };
    const handleHostAgencyFormCancel = () => {
        setHostAgencyFormOpen(false);
    };

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
    };
    return (
        <>
            <Modal
                closeOnBackClick
                open={hotelGroupFormOpen}
                onClose={() => {
                    setHotelGroupFormOpen(false);
                }}
            >
                <HotelGroupForm
                    onCancel={handleHotelGroupFormCancel}
                    onSubmitFinish={handleHotelGroupSubmitFinish}
                />
            </Modal>
            <Modal
                closeOnBackClick
                open={cruiseLineFormOpen}
                onClose={handleCruiseLineFormCancel}
            >
                <CruiseLineForm
                    onCancel={handleCruiseLineFormCancel}
                    onSubmitFinish={handleCruiseLineSubmitFinish}
                />
            </Modal>
            <Modal
                closeOnBackClick
                open={hostAgencyFormOpen}
                onClose={handleHostAgencyFormCancel}
            >
                <HostAgencyForm
                    onCancel={handleHostAgencyFormCancel}
                    onSubmitFinish={handleHostAgencySubmitFinish}
                />
            </Modal>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex flex-row items-center text-left gap-4 max-w-xs">
                    <label
                        htmlFor="file-upload"
                        className="text-left w-24 h-24 relative"
                    >
                        {preview || organization?.image ? (
                            <div className="w-24 h-24">
                                <Avatar
                                    squared
                                    src={
                                        preview ||
                                        organization?.image ||
                                        defaultImage
                                    }
                                    className="w-24 h-24"
                                />
                            </div>
                        ) : (
                            <div className="items-center justify-center w-24 h-24 bg-neutral-200 rounded-lg border-dashed border border-1 border-neutral-400 flex">
                                <Icon
                                    materialIcon={mdiPlus}
                                    className="w-7 h-7 text-neutral-800"
                                />
                            </div>
                        )}
                        <div className="inline">
                            <div className="bg-orange-500 p-1 cursor-pointer rounded-full inline-block absolute right-[-5px] bottom-[-5px]">
                                <Icon
                                    icon={"CameraIcon"}
                                    className="w-5 text-white"
                                    color="white"
                                />
                            </div>
                            <input
                                onChange={onSelectFile}
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only hidden"
                            />
                        </div>
                    </label>
                    <div className="text-neutral-800 text-sm">
                        Upload your logo for your organization's profile
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-x-12 lg:grid-cols-6 mb-8 flex-wrap pb-4">
                    <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                        <SelectInput
                            markRequired
                            control={control}
                            defaultValue={organization?.type}
                            label="Type of organization"
                            id="type"
                            name="type"
                            options={OrganizationTypeOptions}
                        />
                    </div>
                    {watchType && (
                        <>
                            {hostAgencyEnabled && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <HostAgenciesAutocompleteWithValid
                                        control={control}
                                        currentValue={watchAgency}
                                        label="Search host agencies"
                                        id="hostAgency"
                                        name="hostAgency"
                                        error={errors?.hostAgency?.message}
                                        onNew={() =>
                                            setHostAgencyFormOpen(true)
                                        }
                                    />
                                </div>
                            )}
                            {hotelGroupEnabled && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <HotelGroupsAutocompleteWithValid
                                        markRequired
                                        control={control}
                                        currentValue={watchHotelGroup}
                                        label="Hotel Group"
                                        placeholder="Search here"
                                        id="hotelGroup"
                                        name="hotelGroup"
                                        onNew={() =>
                                            setHotelGroupFormOpen(true)
                                        }
                                        error={errors?.hotelGroup?.message}
                                    />
                                </div>
                            )}

                            {cruiseLineEnabled && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <CruiseLinesAutocompleteWithValid
                                        markRequired
                                        control={control}
                                        currentValue={watchCruiseLine}
                                        label="Cruise Line"
                                        placeholder="Search here"
                                        id="cruiseLine"
                                        name="cruiseLine"
                                        onNew={() => setCruiseLineFormOpen(true)}
                                        error={errors?.cruiseLine?.message}
                                    />
                                </div>
                            )}

                            <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                <Input
                                    markRequired
                                    register={register}
                                    label="Organization name"
                                    type="text"
                                    id="name"
                                    name="name"
                                    error={errors["name"]?.message}
                                />
                            </div>
                            <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                <PhoneInput
                                    markRequired={
                                        orgType !== OrganizationType.PERSONAL
                                    }
                                    label="Organization Phone"
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                <Input
                                    instructions={
                                        "Email address to receive notifications related to this organization"
                                    }
                                    markRequired
                                    register={register}
                                    label="Email address"
                                    type="text"
                                    id="email"
                                    name="email"
                                    error={errors["email"]?.message}
                                />
                            </div>
                            {orgType !== OrganizationType.PERSONAL && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <Input
                                        register={register}
                                        label="Website"
                                        type="text"
                                        id="website"
                                        name="website"
                                        error={errors["website"]?.message}
                                    />
                                </div>
                            )}
                            {orgType !== OrganizationType.PERSONAL && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <SelectInputWithValid
                                        control={control}
                                        label="Consortia"
                                        id="consortia"
                                        name="consortia"
                                        defaultValue={values?.consortia || []}
                                        options={OrganizationConsortiaOptions}
                                        error={errors["consortia"]?.message}
                                        isMulti={!hostAgencyEnabled}
                                    />
                                </div>
                            )}
                            {coEnabled && (
                                <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                    <Input
                                        markRequired
                                        register={register}
                                        label="Consortia Other"
                                        type="text"
                                        id="consortiaOther"
                                        name="consortiaOther"
                                        error={
                                            errors["consortiaOther"]?.message
                                        }
                                    />
                                </div>
                            )}
                            {hostAgencyEnabled && (
                                <>
                                    <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                        <Input
                                            markRequired
                                            numeric
                                            maxLength={8}
                                            register={register}
                                            label="IATA Number"
                                            type="text"
                                            id="agencyIATA"
                                            name="agencyIATA"
                                            error={
                                                errors["agencyIATA"]?.message
                                            }
                                        />
                                    </div>
                                    <div className="text-left break-words col-span-6 lg:col-span-3 mt-6">
                                        <Input
                                            register={register}
                                            label="CLIA Number"
                                            type="text"
                                            id="agencyCLIA"
                                            name="agencyCLIA"
                                            error={
                                                errors["agencyCLIA"]?.message
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                {!!watchType && (
                    <div className="w-full px-4 mt-8 gap-4 text-right flex lg:justify-end mb-6">
                        <Button
                            size={ButtonSize.NORMAL}
                            outlined
                            label="Cancel"
                            onClick={navigateBack}
                        />
                        <Button
                            size={ButtonSize.NORMAL}
                            type="submit"
                            label={"Save"}
                        />
                    </div>
                )}
            </form>
        </>
    );
}
