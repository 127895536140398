import React from "react";
import Icon from "../../assets/svg/Icon";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";

interface PaymentStatusModalProps {
    status: "success" | "failed" | null;
    onClose: () => void;
    planName?: string;
    price?: string;
    description?: string | null;
}

const PaymentStatusModal: React.FC<PaymentStatusModalProps> = ({
    status,
    onClose,
    description,
    planName,
    price,
}) => {
    return (
        <div className="p-6 rounded-lg w-96 text-center">
            {status === "success" && (
                <div className="flex justify-center mb-6">
                    <div className="bg-orange-100 rounded-full p-4 flex items-center justify-center">
                        <Icon
                            icon={"CheckIcon"}
                            className="text-orange-600 w-10 h-10"
                        />
                    </div>
                </div>
            )}

            {status === "failed" && (
                <div className="flex justify-center mb-6">
                    <div className="bg-red-100 rounded-full p-4 flex items-center justify-center">
                        <Icon
                            icon={"FailedIcon"}
                            className="text-red-600 w-10 h-10"
                        />
                    </div>
                </div>
            )}

            {status === "success" ? (
                <h2 className="text-xl font-semibold mb-4">
                    Thanks for subscribing!
                </h2>
            ) : (
                <h2 className="text-xl font-semibold mb-4">
                    Sorry. Payment failed. {description}
                </h2>
            )}

            {status === "success" && (
                <>
                    {/* <div className="text-sm text-gray-500 text-left mb-2">
                        {startDate} - {endDate}
                    </div> */}
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-lg font-semibold">
                            {planName}
                        </span>
                        <span className="text-lg font-semibold">{price}</span>
                    </div>
                </>
            )}

            {status === "failed" && (
                <>
                    <div className="text-sm text-gray-500 text-center mb-4">
                        Please review your payment information and try again. If
                        the issue persists, please contact support at{" "}
                        <span className="text-pink-600">info@famguru.app</span>.
                    </div>
                </>
            )}

            <ButtonSquared
                outlined
                label={
                    status === "success"
                        ? "Start using premium features"
                        : "Back to account"
                }
                onClick={onClose}
                className="p-4 min-w-[320px]"
                size={ButtonSize.NORMAL}
            />
        </div>
    );
};

export default PaymentStatusModal;
