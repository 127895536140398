import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/ButtonSquare";
import {
    addActivityToLibrary,
    selectedTrip,
    selectedTripActivity,
} from "../../../tripSlice";
import { selectProfile } from "../../../../authentication/userSlice";
import Icon from "../../../../../assets/svg/Icon";
import {
    ButtonSize,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../../../../../constants";
import DotsMenu from "../../../../../components/DotsMenu";
import { useMemo } from "react";
import ButtonSquared from "../../../../../components/ButtonSquare";

interface ActivityDetailButtonsI {
    setDeleteActivityModalOpen: (v: boolean) => void;
}

const ActivityDetailButtons = ({
    setDeleteActivityModalOpen,
}: ActivityDetailButtonsI) => {
    const navigate = useNavigate();
    const trip = useAppSelector(selectedTrip);
    const selectedActivity = useAppSelector(selectedTripActivity);
    const profile = useAppSelector(selectProfile);
    const dispatch = useAppDispatch();

    const handleEditClick = () => {
        navigate(`edit`);
    };

    const handleAddPrivateInsight = () => {
        const activityType = selectedActivity?.type?.toLowerCase();
        navigate(`add-private-${activityType}-insight`);
    };

    const handleAddToLibrary = () => {
        const tripId = trip?.id;
        const activityId = selectedActivity?.id;
        dispatch(addActivityToLibrary(tripId, activityId));
    };

    const handleGoToLibraryElement = () => {
        const organizationId = trip?.organization?.id;
        const activityType = selectedActivity?.type;
        const libraryElementId = selectedActivity?.libraryElementId;
        navigate(
            `/organizations/${organizationId}/library/${activityType}/${libraryElementId}`
        );
    };

    const canEdit = profile?.isAdmin || (trip?.canEdit && !trip?.suspended);
    const userOnTrip = trip?.users?.find(
        (u: { userEmail: string }) => u.userEmail === profile?.email
    );
    const canAddReview =
        !trip?.suspended &&
        userOnTrip?.status === UserStatusOnTrip.CONFIRMED &&
        userOnTrip?.role !== UserRoleOnTrip.VIEWER;

    const DotsMenuOptions = useMemo(() => {
        const options = [
            {
                icon: "LibraryIcon",
                key: "library",
                label: "Add to Library",
            },
            {
                icon: "TrashIcon",
                key: "delete",
                label: "Delete",
            },
        ];
        return options;
    }, []);

    const handleDotsMenuClick = (key: string) => {
        switch (key) {
            case "library":
                !!selectedActivity?.libraryElementId
                    ? handleGoToLibraryElement()
                    : handleAddToLibrary();
                break;
            case "delete":
                setDeleteActivityModalOpen(true);
                break;
            case "insights":
                handleAddPrivateInsight();
                break;
            default:
                break;
        }
    };

    return (
        <div className="flex gap-2">
            {canEdit && (
                <div className="flex gap-4 items-center">
                    <div
                        className="text-base font-semibold text-orange-600 cursor-pointer"
                        onClick={handleAddPrivateInsight}
                    >
                        Add insights
                    </div>
                    <Button
                        outlined
                        label={
                            <Icon
                                icon="PencilIconOutline"
                                className="w-6 h-6 text-neutral-400 hover:border-neutral-300"
                            />
                        }
                        onClick={handleEditClick}
                        className="hover:!border-neutral-300 !border-neutral-400 !px-2"
                        size={ButtonSize.FIT}
                    />
                    <DotsMenu
                        wrapperClass={
                            "p-2 hover:!border-neutral-300 !border-neutral-400 z-50 border rounded-md"
                        }
                        className="w-6 h-6 text-neutral-400 hover:border-neutral-300"
                        options={DotsMenuOptions}
                        handleClickMenu={handleDotsMenuClick}
                    />
                </div>
            )}
            {!canEdit && canAddReview && (
                <ButtonSquared
                    label={"Add insights"}
                    onClick={handleAddPrivateInsight}
                    size={ButtonSize.FIT}
                />
            )}
        </div>
    );
};

export default ActivityDetailButtons;
