import moment from "moment";
import { toast, ToastPosition } from "react-toastify";
import {
    activityTypeOnModel,
    ComplaintStatus,
    ComplaintType,
    ComplaintTypeToShow,
    EditUserRoleOnTrip,
    GeneralStatus,
    IMAGES_MIME_TYPES_SUPPORTED,
    PAGE_SECTIONS,
    SUPPORT_MODULE_SECTIONS_OPTIONS,
    TOAST_TYPE,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../constants";
import FileResizer from "react-image-file-resizer";
import JSZip from "jszip";
import { ITransfers, paymentIntents } from "../types/plan.type";

export const normalizeError = (error: any) => {
    const errorData = error.response?.data;

    let nError;
    if (
        errorData &&
        typeof errorData === "object" &&
        errorData.code &&
        errorData.message &&
        errorData.detail
    ) {
        nError = `${errorData.code} - ${errorData.message}: ${errorData.detail}`;
    } else if (typeof errorData === "string") {
        nError = errorData;
    } else {
        nError =
            errorData?.message || error?.message || error.response?.statusText;
    }

    return nError;
};

export const sendToast = (
    type: TOAST_TYPE = TOAST_TYPE.ERROR,
    message: string = "Unknown Error",
    position: ToastPosition = toast.POSITION.TOP_RIGHT
) => {
    switch (type) {
        case TOAST_TYPE.ERROR:
            toast.error(message, {
                position: position,
            });
            break;
        case TOAST_TYPE.SUCCESS:
            toast.success(message, {
                position: position,
            });
            break;
        case TOAST_TYPE.WARNING:
            toast.warning(message, {
                position: position,
            });
            break;
        default:
            break;
    }
    return;
};
export const groupBy = (list: Array<any>, field: string) =>
    list.reduce((group, item) => {
        const fieldValue = item[field];
        group[fieldValue] = group[fieldValue] ?? [];
        group[fieldValue].push(item);
        return group;
    }, {});

export const getImageSize = (file: any) => {
    return new Promise<any>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e: any) => {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;

            //Validate the File Height and Width.
            image.onload = function (r: any) {
                var height = image.height;
                var width = image.width;
                return resolve({
                    height: height,
                    width: width,
                });
            };
            image.onerror = (err) => {
                return reject(err);
            };
        };
    });
};

export async function asyncForEach(array: Array<any>, callback: Function) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export const getStatusFromFiles = (files: any[]) => {
    let status = GeneralStatus.NOT_SUBMITTED;
    if (files?.length > 0) {
        const filesSorted = files.slice().sort((a, b) => {
            return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
        });
        return filesSorted[0].status;
    }
    return status;
};

export const normalizeReviews = (review: any) => {
    const images = Object.keys(review?.images || {}).map(function (key) {
        return review.images[key];
    });
    const activityTypeKey =
        activityTypeOnModel[
            review.activity?.type as keyof typeof activityTypeOnModel
        ];
    const activity =
        review.activity[activityTypeKey][review.activity.type.toLowerCase()];

    let location = "";

    if (
        activity?.city &&
        activity?.city !== "null" &&
        activity?.city !== "undefined"
    ) {
        location += `${activity?.city}, `;
    }

    if (
        activity?.country &&
        activity?.country !== "null" &&
        activity?.country !== "undefined"
    ) {
        location += `${activity?.country}`;
    }

    return {
        id: review.id,
        title: review.activity?.title || activity.name,
        type: review?.activity?.type,
        date: review.createdAt,
        activityDate: formatDate(review?.activity?.startDate),
        formattedDate: formatDate(review.createdAt),
        comments: review.comments,
        stars: review.stars,
        location,
        user: `${review?.user?.firstName}, ${review?.user?.lastName}`,
        keywords: review.keywords || "",
        keywordsArray: [],
        images,
    };
};

export const getFormattedDate = (date: number) => {
    return moment.utc(date).format(DATE_FORMAT);
};

export const normalizeTransfers = (transfer: any): ITransfers => {
    const date = getFormattedDate(transfer.createAt);
    const last10 = transfer?.invoiceCode?.slice(-10);
    
    return {
        ...transfer,
        createAt: date,
        invoiceCode: last10,
    }
}

export const formatDate = (
    date: Date | string,
    format: string = DATE_FORMAT
) => {
    return moment.utc(date).format(format);
};

export const getUserPermissionsOnTrip = (trip: any, userEmail: string) => {
    let status = null;
    let role = null;
    if (trip && trip?.users) {
        const users = trip.users as any[];
        const user: any =
            users?.length === 1
                ? users[0]
                : users?.find((u) => {
                      return u.userEmail === userEmail;
                  });
        status =
            user?.userEmail === userEmail
                ? user?.status
                : UserStatusOnTrip.CONFIRMED;
        role =
            user?.userEmail === userEmail ? user?.role : UserRoleOnTrip.VIEWER;
    }
    const canEdit =
        EditUserRoleOnTrip.includes(role) &&
        status === UserStatusOnTrip.CONFIRMED;
    return { status, role, canEdit };
};

export async function dataURItoBlob(dataURI: any) {
    try {
        const response = await fetch(dataURI, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        });
        const blob = await response.blob();
        const fContentType = response.headers.get("Content-Type");
        const fExtension =
            fContentType && IMAGES_MIME_TYPES_SUPPORTED[fContentType];
        const myFile = new File([blob], `image.${fExtension || "png"}`, {
            type: blob.type,
        });
        return myFile;
    } catch (error) {
        return null;
    }
}

export const atLeast1UpperCaseLetter = (value: string) => {
    var passw = /([A-Z]+)/;
    return !!value?.match(passw);
};

export const atLeast1Number = (value: string) => {
    var passw = /([0-9]+)/;
    return !!value?.match(passw);
};

export const getSelectOptionLabel = (
    value: any,
    options: { value: any; label: string }[]
) => {
    return options.find((o) => o.value === value)?.label;
};

export const getUriComponent = (component: any): string => {
    return component ? encodeURIComponent(component) : "";
};

export const normalizeComplaint = (complaint: any) => {
    return {
        ...complaint,
        date: moment(complaint.createdAt).format(DATE_FORMAT),
        userFormatted: `${complaint?.user?.firstName}, ${complaint?.user?.lastName}`,
        typeFormatted: ComplaintTypeToShow[complaint.itemType as ComplaintType],
        statusNormalized: [
            ComplaintStatus.ACCEPTED,
            ComplaintStatus.REJECTED,
        ].includes(complaint.status)
            ? complaint.status
            : ComplaintStatus.PENDING,

        complaintMessages: (complaint?.complaintMessages || []).map(
            (m: any) => {
                return {
                    ...m,
                    date: moment(m.createdAt).format(DATE_FORMAT),
                    userFormatted: `${m?.user?.firstName}, ${m?.user?.lastName}`,
                };
            }
        ),
    };
};

export const normalizeSupportModules = (module: {
    [key: string]: any;
    section: PAGE_SECTIONS;
}) => {
    const section = SUPPORT_MODULE_SECTIONS_OPTIONS.find(
        (s) => s.value === module?.section
    );
    return {
        ...module,
        section: section?.value,
        sectionLabel: section?.label,
    };
};

export const basicUserDataComplete = (user: any) => {
    return !!user?.agency?.length && !!user?.country && !!user?.phone;
};

export const dietaryRestrictionsComplete = (profileExtra: any) =>
    profileExtra?.dietaryNoRestrictions ||
    profileExtra?.dietaryVegetarian ||
    profileExtra?.dietaryVegetarianComment ||
    profileExtra?.dietaryVegan ||
    profileExtra?.dietaryVeganComment ||
    profileExtra?.dietaryGlutenIntolerance ||
    profileExtra?.dietaryGlutenIntoleranceComment ||
    profileExtra?.dietaryFoodAllergies ||
    profileExtra?.dietaryFoodAllergiesComment ||
    profileExtra?.dietaryPreferredBeverages ||
    profileExtra?.dietaryPreferredBeveragesComment ||
    profileExtra?.dietaryAlcohol ||
    profileExtra?.dietaryAlcoholComment ||
    profileExtra?.dietaryOtherComment;

export const getInstagramUserName = (link: string) => {
    let username = link;
    const urlMatch = link.match(
        /(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_.]+)/im
    );
    if (urlMatch) {
        username = urlMatch[1];
    }
    if (username) {
        username = username.split("?")[0];
    }
    return username;
};

export const incompleteUserData = (user: any, profileExtra: any) => {
    const requiredFieldsArray = [];
    if (!user?.country?.length) {
        requiredFieldsArray.push("Location");
    }
    if (!user?.phone?.length) {
        requiredFieldsArray.push("Phone");
    }
    if (!dietaryRestrictionsComplete(profileExtra)) {
        requiredFieldsArray.push("Dietary Info");
    }
    return requiredFieldsArray;
};

export const timeToMeridianTime = (time?: string) => {
    return time ? moment(time, "HH:mm").format("hh:mm A") : "";
};

export const parseMomentUtcToLocal = (date: string | moment.Moment) => {
    return moment(moment.utc(date).format(DATE_FORMAT), DATE_FORMAT);
};

export const parseMomentUtc = (date: string | moment.Moment) => {
    return moment.utc(moment.utc(date).format(DATE_FORMAT), DATE_FORMAT);
};

export const parsePhoneNumber = (phone?: string) => {
    if (!phone || phone === "undefined") {
        return "N/A";
    }
    return phone;
};

export const DATE_FORMAT = "MM/DD/YYYY";

export const calculateActivitiesDates = (startDate: any, endDate: any) => {
    if (startDate) {
        let dates = [];
        const start = moment.utc(startDate).startOf("day");
        const end = moment.utc(endDate).startOf("day");
        dates.push(start.clone().format("YYYY-MM-DD"));
        while (start.add(1, "days").diff(end) < 0) {
            dates.push(start.clone().format("YYYY-MM-DD"));
        }
        dates.push(end.clone().format("YYYY-MM-DD"));
        if (dates[0] === dates[dates.length - 1]) {
            dates = [dates[0]];
        }
        return dates;
    }
};

export const nonUndefinedString = (data: string | null | undefined) => {
    return data && data !== "undefined" ? data : "";
};

export const getYoutubeVideoId = (url: string) => {
    const [a, , b] = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
        return b.split(/[^0-9a-z_-]/i)[0];
    } else {
        return a;
    }
};

export const isYoutubeVideo = (url: string) => {
    const validYoutube =
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
    return validYoutube.test(url);
};

export const resizeImageFile = (file: any): Promise<Blob> =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            1024,
            1024,
            "JPEG",
            70,
            0,
            (uri: any) => {
                resolve(uri);
            },
            "blob"
        );
    });

export const downloadZipFileFromLinkks = async (
    filesArray: {
        name: string;
        link: string;
    }[],
    zipName: string = "Download"
) => {
    const zip = new JSZip();
    const request = async (file: { name: string; link: string }) => {
        const response = await fetch(file.link);
        const blob = await response.blob();
        zip.file(file.name, blob, { binary: true });
    };

    await Promise.all(
        filesArray.map((file) => {
            return request(file);
        })
    );

    zip.generateAsync({
        type: "blob",
    }).then(function (content) {
        const url = window.URL.createObjectURL(
            new Blob([content], { type: "application/zip" })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${zipName}.zip`);
        document.body.appendChild(link);
        link.click();
    });
};
