import React, { useState } from "react";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import CardSelection from "./CardSelection";
import { IPlan } from "../../types/plan.type";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    createPaymentMethod,
    getCurrentPlan,
    isLoadingData,
    payUserSubscription,
    selectPaymentMethods,
} from "../../features/plans/plansSlice";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    CardElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import PaymentStatusModal from "./PaymentStatusModal"; 
import Modal, { Modal_Size } from ".";
import { useAppSelector } from "../../app/hooks";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface PaymentSubscriptionProps {
    selectedPlan: IPlan;
    handleSetPLan: (plan: any) => void;
}

const schema = yup
    .object({
        cardHolder: yup.string().required("Cardholder name is required"),
        country: yup.string().required("Country is required"),
    })
    .required();

const PaymentSubscription: React.FC<PaymentSubscriptionProps> = ({
    selectedPlan,
    handleSetPLan,
}) => {
    const isLoading = useAppSelector(isLoadingData);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const currentUser = useSelector((state: any) => state?.user?.profile);
    const [showCardSelection, setShowCardSelection] = useState(false);
    const [showAddNewCard, setShowAddNewCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [paymentStatus, setPaymentStatus] = useState<
        "success" | "failed" | null
    >(null);
    const [description, setDescription] = useState<string | null>(null);
    const paymentMethods = useAppSelector(selectPaymentMethods);

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            cardHolder: currentUser.firstName,
            country: currentUser.country,
        },
    });

    const { offerData } = selectedPlan.data;

    const handlePayment = async (paymentMethodId: string) => {
        const priceId = selectedPlan.data.offerData.price_id;
        const planId = selectedPlan.id;
        const userId = currentUser.id;

        try {
            await dispatch(
                payUserSubscription(
                    currentUser.email,
                    paymentMethodId,
                    priceId,
                    planId,
                    userId
                )
            );
            await dispatch(getCurrentPlan());
            setPaymentStatus("success");
        } catch (error: any) {
            setDescription(error.message);
            setPaymentStatus("failed");
        }
    };

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }
    
        if (showAddNewCard) {
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement!,
                billing_details: {
                    email: currentUser.email,
                },
            });
    
            if (error) {
                setPaymentStatus("failed");
                setDescription(error.message || ''); 
            } else {
                const errorMessage: any = await dispatch(
                    createPaymentMethod(currentUser.email, paymentMethod)
                );
    
                if (errorMessage) {
                    setPaymentStatus("failed");
                    setDescription(errorMessage); 
                } else {
                    await handlePayment(paymentMethod.id);
                }
            }
        } else if (selectedCard) {
            handlePayment(selectedCard);
        }
    };    

    const handleSelectCard = (cardId: string) => {
        setSelectedCard(cardId);
    };

    const handleAddNewCard = () => {
        setShowAddNewCard(true);
    };

    const handleCardSelection = async () => {
        setShowCardSelection(true);
    };

    const handleCloseModal = () => {
        paymentStatus !== "failed" && handleSetPLan(selectedPlan);
        paymentStatus === "success" && window.location.reload();
        setPaymentStatus(null);
    };

    return (
        <div className="p-2">
            <h2 className="text-2xl font-semibold mb-2 text-left">
                {selectedPlan?.name}
            </h2>

            <p className="text-left text-gray-600 mb-2">
                Subscribe to {selectedPlan?.name} Monthly
            </p>

            <div className="flex items-baseline mb-4">
                <span className="text-4xl font-bold text-left">
                    ${offerData.price}
                </span>
                <span className="text-sm text-gray-500 ml-2">
                    {!!offerData?.priceDescription?.length && "/"}
                    {offerData?.priceDescription}
                </span>
            </div>

            <hr className="my-4" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="text-sm font-bold text-left mb-4">
                    Pay with card
                </h3>

                <p className="text-left text-sm font-medium text-gray-700 mb-1">
                    Email
                </p>
                <p className="text-left text-sm text-gray-500 mb-4">
                    {currentUser.email}
                </p>

                {showAddNewCard ? (
                    <CardElement />
                ) : paymentMethods.length > 0 ? (
                    showCardSelection ? (
                        <CardSelection
                            onSelectCard={handleSelectCard}
                            onAddNewCard={handleAddNewCard}
                            paymentMethods={paymentMethods}
                            isLoading={isLoading}
                        />
                    ) : (
                        <div className="flex my-4 gap-1">
                            <ButtonSquared
                                outlined
                                label={"Select card"}
                                onClick={handleCardSelection}
                                className="p-4 min-w-[320px]"
                                size={ButtonSize.NORMAL}
                            />
                            <ButtonSquared
                                outlined
                                label="Add new card"
                                onClick={handleAddNewCard}
                                className="p-4 min-w-[320px]"
                                size={ButtonSize.NORMAL}
                            />
                        </div>
                    )
                ) : (
                    <ButtonSquared
                        outlined
                        label="Add new card"
                        onClick={handleAddNewCard}
                        className="p-4 min-w-[650px] mb-4"
                        size={ButtonSize.NORMAL}
                    />
                )}

                <p className="text-left text-sm font-medium text-gray-700 mb-1">
                    Cardholder name
                </p>
                <input
                    type="text"
                    {...register("cardHolder")}
                    className="mt-1 w-full border-b border-gray-400 mb-3"
                    placeholder="John"
                />
                {errors.cardHolder && (
                    <p className="text-red-600 text-sm">
                        {errors.cardHolder.message}
                    </p>
                )}

                <div className="my-2">
                    <ButtonSquared
                        type="submit"
                        label={isLoading ? `Loading` : `Subscribe`}
                        className="w-full"
                        onClick={onSubmit}
                        size={ButtonSize.FIT}
                        disabled={!errors || isLoading || !stripe || !elements}
                    />
                </div>
            </form>

            <p className="text-xs text-gray-400 text-left mt-2">
                By confirming your subscription, you allow FamGuru to charge you
                for future payments in accordance with our terms. You can always
                cancel your subscription.
            </p>

            <hr className="my-4" />

            <div className="text-center text-xs text-gray-500">
                Powered by <span className="font-semibold">Stripe</span> |{" "}
                <a
                    href="https://famguru.app/terms"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms
                </a>{" "}
                |{" "}
                <a
                    href="https://famguru.app/privacy-policy"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy
                </a>
            </div>

            <Modal
                open={paymentStatus !== null}
                size={Modal_Size.xs}
                onClose={handleCloseModal}
            >
                <PaymentStatusModal
                    status={paymentStatus}
                    description={description}
                    onClose={handleCloseModal}
                    planName={selectedPlan.name}
                    price={`$${offerData.price}`}
                />
            </Modal>
        </div>
    );
};

const PaymentSubscriptionWrapper: React.FC<PaymentSubscriptionProps> = ({
    selectedPlan,
    handleSetPLan,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentSubscription
                selectedPlan={selectedPlan}
                handleSetPLan={handleSetPLan}
            />
        </Elements>
    );
};

export default PaymentSubscriptionWrapper;
