import React, { useEffect, useMemo, useState } from "react";
import {
    IAttachment,
    IChatMessage,
    IContact,
} from "../../../../types/chat.type";
import { ButtonSize } from "../../../../constants";
import ButtonSquared from "../../../../components/ButtonSquare";
import ImageModal from "../modals/ImageModal";
import Avatar from "../../../../components/Avatar";
import { useChatForm } from "../../hooks/useChat.hook";
import VideoModal from "../modals/VideoModal";
import Icon from "../../../../assets/svg/Icon";

interface ImageMessageBubbleProps {
    message: IChatMessage;
    currentUser: boolean;
    onSave: () => void;
    onDontSave: () => void;
    onMemberClick?: (user: IContact) => void;
    isGroup?: boolean;
}

const FileMessageBubble: React.FC<ImageMessageBubbleProps> = ({
    message: { content, attachments, sender, timestamp, attachOnTrip },
    currentUser,
    onSave,
    onDontSave,
    isGroup,
    onMemberClick,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { getFormattedDate } = useChatForm();
    const formattedDate = getFormattedDate(timestamp);

    const files: IAttachment[] = useMemo(() => {
        return attachments || [];
    }, [attachments]);

    useEffect(() => {
        setShowOptions(
            !attachOnTrip && files.some((file) => file.type === "photo")
        );
    }, [attachOnTrip, files]);

    const openModal = (index: number) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showNextImage = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex + 1) % mediaFiles.length
        );
    };

    const showPrevImage = () => {
        setCurrentImageIndex(
            (prevIndex) =>
                (prevIndex - 1 + mediaFiles.length) % mediaFiles.length
        );
    };

    const onSaveImage = () => {
        onSave();
        setShowOptions(false);
    };

    const onDontSaveImage = () => {
        onDontSave();
        setShowOptions(false);
    };

    const handleUserClick = () => {
        onMemberClick && onMemberClick(sender);
    };

    const mediaFiles = useMemo(() => {
        return files?.filter(
            (file) => file?.type === "photo" || file?.type === "video"
        );
    }, [files]);

    const isVideo = useMemo(() => {
        return mediaFiles[currentImageIndex]?.type === "video";
    }, [mediaFiles, currentImageIndex]);

    const binaryFiles = useMemo(() => {
        return files?.filter((file) => file?.type === "file");
    }, [files]);

    return (
        <div
            className={`flex mb-4  ${
                currentUser ? "justify-end" : "items-start"
            }`}
        >
            {isGroup && !currentUser && (
                <span onClick={handleUserClick}>
                    <Avatar
                        src={sender?.avatar}
                        className="mr-2 w-7 h-7 cursor-pointer"
                    />
                </span>
            )}
            <div className="flex flex-col">
                {isGroup && !currentUser && (
                    <div className="mb-0 text-start">
                        <span
                            className="text-xs text-gray-400 cursor-pointer"
                            onClick={handleUserClick}
                        >
                            {sender?.firstName} {sender?.lastName}
                        </span>
                    </div>
                )}
                <div
                    className={`p-2 mb-4 rounded-lg w-60 shadow-md ${
                        currentUser ? "bg-[#ffeae1]" : "bg-[#f4f3f3]"
                    }`}
                >
                    <p className="mb-1 font-medium text-start">{content}</p>
                    <div className="grid grid-cols-2 gap-3 cursor-pointer">
                        {mediaFiles.slice(0, 3).map((file, index) => (
                            <img
                                key={index}
                                src={file.thumbnail}
                                alt=""
                                onClick={() => openModal(index)}
                                className="w-full h-auto object-cover rounded-md cursor-pointer"
                            />
                        ))}
                        {mediaFiles.length > 3 && (
                            <div
                                className="relative w-25 h-25 cursor-pointer"
                                onClick={() => openModal(3)}
                            >
                                <img
                                    src={mediaFiles[3].thumbnail}
                                    alt="More files"
                                    className="w-full h-full object-cover rounded-md"
                                />
                                <div className="absolute inset-0 bg-black opacity-40 rounded-md"></div>
                                <div className="absolute inset-0 flex items-center justify-center text-white text-lg font-bold">
                                    +{mediaFiles.length - 3}
                                </div>
                            </div>
                        )}
                    </div>
                    {showOptions && isGroup && currentUser && (
                        <div>
                            <p className="mt-2 text-justify font-semibold">
                                Do you also want to save it to your trip
                                gallery?
                            </p>
                            <div className="mt-2 flex justify-between w-full gap-3">
                                <ButtonSquared
                                    outlined={true}
                                    label={"No"}
                                    onClick={onDontSaveImage}
                                    size={ButtonSize.SMALL}
                                    className="text-xs"
                                />
                                <ButtonSquared
                                    outlined={false}
                                    label={"Yes"}
                                    onClick={onSaveImage}
                                    size={ButtonSize.SMALL}
                                    className="text-xs"
                                />
                            </div>
                        </div>
                    )}
                    {binaryFiles.length > 0 && (
                        <div className="flex flex-row gap-2 flex-wrap mt-2">
                            {binaryFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-1 cursor-pointer"
                                >
                                    <a
                                        href={file?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div
                                            className="flex flex-col items-center gap-1 cursor-pointer"
                                        >
                                            <Icon
                                                icon="FileOutlineIcon"
                                                className="w-8 h-8 text-gray-500"
                                            />
                                            <div className="text-xs font-medium text-neutral-700 truncate w-16 text-center">
                                                {file?.name}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex justify-end pt-1.5">
                        <span className="text-xs text-gray-400 min-w-max">
                            {formattedDate}
                        </span>
                    </div>
                </div>

                {isVideo ? (
                    <VideoModal
                        isOpen={isModalOpen}
                        Videos={mediaFiles}
                        currentVideoIndex={currentImageIndex}
                        onClose={closeModal}
                        onNext={showNextImage}
                        onPrev={showPrevImage}
                        sender={sender}
                        timestamp={timestamp}
                    />
                ) : (
                    <ImageModal
                        isOpen={isModalOpen}
                        images={mediaFiles}
                        currentImageIndex={currentImageIndex}
                        onClose={closeModal}
                        onNext={showNextImage}
                        onPrev={showPrevImage}
                        sender={sender}
                        timestamp={timestamp}
                    />
                )}
            </div>
        </div>
    );
};

export default FileMessageBubble;
