import React from "react";
import Icon from "../../assets/svg/Icon";
import { VALID_IMAGES_FORMATS, VALID_VIDEOS_FORMATS } from "../../constants";
import useDropMediaFileInput from "../../hooks/useDropMediaFileInput";

const DropMediaFileInput = React.forwardRef(
    (
        { onChange, preview, mediaClass, iconClass, className, ...others }: any,
        ref: any
    ) => {
        const {
            changeFile,
            dragOverlay,
            handleDrag,
            handleDragIn,
            handleDrop,
            handleDragOut,
        } = useDropMediaFileInput(onChange);

        return (
            <label
                htmlFor="media-file-upload"
                className={`${
                    dragOverlay ? "bg-gray-300" : ""
                } rounded-xl cursor-pointer min-w-[200px]`}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                {preview ? (
                    <div className={mediaClass}>
                        {preview.type.startsWith('video/') ? (
                            <video src={preview.src} width="100%" controls />
                        ) : (
                            <img src={preview.src} width="100%" alt="" />
                        )}
                    </div>
                ) : (
                    <>
                        <div
                            className={`w-[200px] flex items-center flex-nowrap justify-center gap-2 box-border min-h-full py-9 px-6 text-base font-medium border-orange-600 rounded-xl border border-dashed break-words whitespace-pre overflow-hidden ${className}`}
                        >
                            <div className="">
                                <Icon
                                    className={`${iconClass} w-6 h-6 text-orange-600`}
                                    icon="ImageAddIcon" 
                                />
                            </div>
                            <div className="text-left text-orange-600 w-full break-words whitespace-pre-line overflow-hidden">
                                Browse or drop media files.
                            </div>
                        </div>
                    </>
                )}
                <input
                    onChange={changeFile}
                    ref={ref}
                    id="media-file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only hidden"
                    accept={[...VALID_IMAGES_FORMATS, ...VALID_VIDEOS_FORMATS].join(", ")}
                    {...others}
                />
            </label>
        );
    }
);

export default DropMediaFileInput;
