import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../../../../components/ButtonSquare";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import GoogleMap from "../../../../../components/Map";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import { ActivityType } from "../../../../../constants";
import { selectedTrip } from "../../../tripSlice";
import ContactForm from "../ContactForm";
import ContactCard from "../ContactCard";
import useActivityContact from "../../../../../hooks/useActivityContact";
import moment from "moment";
import useGoogleMaps from "../../../../../hooks/usePlacesService";
import DatePickerMaterial from "../../../../../components/DatePickerMaterial";
import accountImage from "../../../../../assets/images/accountAdd.png";
import fileImage from "../../../../../assets/images/files.png";
import DropImageFileInput from "../../../../../components/DropImageFileInput/DropImageFileInput";
import GalleryImage from "../../../../../components/ImagesGallery/Image";
import DropFileInput from "../../../../../components/DropFileInput/DropFileInput";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import TextareaWithValid from "../../../../../components/TextArea";
import TimePickerMaterial from "../../../../../components/TimePickerMaterial";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import Input from "../../../../../components/Input";
import Icon from "../../../../../assets/svg/Icon";
import {
    selectedRestaurant,
    setSelectedRestaurant,
} from "../../../../restaurants/restaurantSlice";
import RestaurantsAutocompleteWithValid from "../../../../../components/RestaurantsAutocomplete";
import InputSwitch from "../../../../../components/InputSwitch/InputSwitch";
import InputLabel from "../../../../../components/InputLabel";
import InputWithValid from "../../../../../components/Input";
import { schemaActivityRestaurant } from "./schemas";
import { useActivityFormContext } from "./context/ActivityForm.context";
import RestaurantForm from "../../../../restaurants/RestaurantForm";
import { selectProfile } from "../../../../authentication/userSlice";
import { parseMomentUtcToLocal } from "../../../../../helpers";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import PhoneInput from "../../../../../components/PhoneInput";

export default function EditRestaurantActivityForm(props: any) {
    const [searchParams] = useSearchParams();
    const [editingRest, setEditingRest] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [mapMarker, setMapMarker] = useState<any | null>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    const profile = useAppSelector(selectProfile);
    const [showEndHourInput, setShowEndHourInput] = useState<boolean>(false);
    const [addNew, setAddNew] = useState(false);
    let { activityId } = useParams();
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schemaActivityRestaurant),
    });
    const mapService = useGoogleMaps();
    const activityForm = useActivityFormContext();
    const {
        contacts,
        contactsModalOpen,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactSubmit,
        handleContactToggle,
        selectedContact,
        setSelectedContact,
        setContacts,
    } = useActivityContact([]);

    const {
        activity,
        navigateBack,
        onSubmit,
        currentSection,
        isLibraryElement,
    } = props;

    const currentRestaurant = useAppSelector(selectedRestaurant);
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue("contacts", contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    useEffect(() => {
        setValue("isLibraryElement", !!isLibraryElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLibraryElement]);

    useEffect(() => {
        if (
            activity?.restaurantActivity?.restaurant?.id !==
            currentRestaurant?.id
        ) {
            handleSelectRest(currentRestaurant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRestaurant?.id, currentRestaurant?.name]);

    useEffect(() => {
        if (activity && activityId) {
            setIsUpdate(true);
            const {
                startHour = "09:00",
                endHour = "09:00",
                contacts,
                images,
                files,
                title,
            } = activity;
            let serverRestaurant = {
                ...activity?.restaurantActivity?.restaurant,
            };
            dispatch(
                setSelectedRestaurant({ selectedRestaurant: serverRestaurant })
            );
            const startDateInit =
                activity?.startDate || searchParams.get("date") || new Date();
            reset({
                title: title || "",
                startDate: moment.utc(startDateInit),
                description: activity.description,
                website: activity?.website || currentRestaurant?.website,
                phone: activity?.phone || currentRestaurant?.phone,
                startHour: moment(startHour, "HH:mm"),
                endHour: moment(endHour, "HH:mm"),
                restaurant: serverRestaurant,
            });
            setShowEndHourInput(activity.startHour !== activity.endHour);
            setStartDate(moment.utc(startDateInit));
            setContacts(Object.values(contacts || {}));
            activityForm.handleImagesAndFilePreview(images, files);
            if (
                serverRestaurant?.lat &&
                mapService.initialized &&
                !mapService.isLoading
            ) {
                const marker = {
                    position: {
                        lat: serverRestaurant?.lat,
                        lng: serverRestaurant?.lng,
                    },
                    title: serverRestaurant?.name,
                };
                setMapMarker(marker);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity?.id, mapService.initialized]);

    const handleUpdateForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity: any = {
            type: ActivityType.RESTAURANT,
            restaurant: currentRestaurant,
            title: data.title,
            description: data.description,
            website: data.website,
            phone: data.phone,
            startDate: moment(startDate).toDate(),
            endDate: moment(startDate).toDate(),
            startHour: startHour,
            endHour: showEndHourInput ? endHour : startHour,
            contacts: data.contacts,
            deletedImages: activityForm.deletedImagesState.length
                ? activityForm.deletedImagesState
                : undefined,
            deletedFiles: activityForm.deletedFilesState.length
                ? activityForm.deletedFilesState
                : undefined,
            images: activityForm.tempImages.map((img) => img.path),
            files: activityForm.tempFiles.map((file) => file.path),
        };

        const addedFromLibrary = searchParams.get("fromLibrary") === "true";
        if (addedFromLibrary) {
            activity.libraryElementId = props.activity?.id;
            // Remove deleted images and files from the library element images and files
            activity.imagesFromLibraryElement = Object.values(
                props.activity?.images || {}
            ).filter(
                (img: any) =>
                    !activityForm.deletedImagesState.includes(img.originalName)
            );
            activity.filesFromLibraryElement = Object.values(
                props.activity?.files || {}
            ).filter(
                (file: any) =>
                    !activityForm.deletedFilesState.includes(file.originalName)
            );
            delete activity.deletedImages;
            delete activity.deletedFiles;
        }
        await onSubmit(activity);
    };

    const handleChangeRest = (restaurant: any) => {
        setValue("restaurant", restaurant);
        dispatch(setSelectedRestaurant({ selectedRestaurant: restaurant }));

        if (restaurant?.lat) {
            const marker = {
                position: {
                    lat: restaurant.lat,
                    lng: restaurant.lng,
                },
                title: restaurant?.name,
            };
            setMapMarker(marker);
        } else {
            setMapMarker(null);
        }
    };

    const handleSwitchChange = (e: any) => {
        setShowEndHourInput(!!e.target.checked);
    };

    const handleRestModalToggle = (open: boolean) => {
        setAddModalOpen(open);
    };
    const handleSelectRest = (restaurant: any) => {
        const title = restaurant?.name;
        setValue("title", title);
        setEditingRest(false);
    };

    return (
        (activity || !isUpdate) && (
            <>
                <Modal
                    open={addModalOpen}
                    size={Modal_Size.lg}
                    onClose={() => {
                        handleRestModalToggle(false);
                    }}
                >
                    <RestaurantForm
                        addNew={addNew}
                        name={searchValue}
                        onCancel={() => {
                            handleRestModalToggle(false);
                        }}
                        onChange={() => handleRestModalToggle(false)}
                    />
                </Modal>
                <Modal
                    open={contactsModalOpen}
                    onClose={() => handleContactToggle(false)}
                    hideCloseButton
                >
                    <ContactForm
                        onClose={() => handleContactToggle(false)}
                        onSubmit={handleContactSubmit}
                        contact={contacts && contacts[selectedContact]}
                    />
                </Modal>

                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleUpdateForm)}
                    className="text-left lg:px-6"
                >
                    {currentRestaurant && !editingRest && (
                       <div className="col-span-6 mb-6 flex flex-row items-center gap-2">
                            <Input
                                register={register}
                                name="title"
                                id="title"
                                type="text"
                                label="Title"
                                defaultValue={activity?.title || ""}
                                placeholder="Activity title"
                            />
                            <Icon
                                onClick={() => {
                                    setEditingRest(true);
                                }}
                                title="Replace Restaurant"
                                icon={"FileReplaceIcon"}
                                className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                            />

                            {currentRestaurant.userId === profile.id && (
                                <Icon
                                    onClick={() => {
                                        handleRestModalToggle(true);
                                        setAddNew(false);
                                    }}
                                    title="Edit Restaurant"
                                    icon={"PencilIcon"}
                                    className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                                />
                            )}
                        </div>
                    )}
                    {editingRest && (
                        <div className="flex flex-col">
                            <div className="text-base font-semibold text-neutral-300">
                                Please select the restaurant you want to add
                            </div>{" "}
                        </div>
                    )}

                    {editingRest && (
                        <div className="mt-6 searchbox">
                            <RestaurantsAutocompleteWithValid
                                control={control}
                                className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                                onChange={handleChangeRest}
                                onTextChange={setSearchValue}
                                name="restaurant"
                                //defaultValue={currentRestaurant}
                                placeholder="Search"
                                handleSelect={handleSelectRest}
                            />
                        </div>
                    )}

                    {editingRest && (
                        <div className="ml-4 mt-4 flex text-sm">
                            We couldn't find the restaurant. Would you like to{" "}
                            <span
                                onClick={() => {
                                    handleRestModalToggle(true);
                                    setAddNew(true);
                                }}
                                className="mx-2 cursor-pointer font-semibold text-orange-600"
                            >
                                add
                            </span>
                            one?
                        </div>
                    )}
                    {currentRestaurant && !editingRest && (
                        <>
                            {errors["restaurant"]
                                ? "Incomplete address information"
                                : ""}
                            <div className="grid grid-cols-6 gap-x-12 mt-6 shadow-sm border border-gray-300 rounded-xl">
                                {mapMarker && (
                                    <div className="col-span-6">
                                        <GoogleMap marker={mapMarker} />
                                    </div>
                                )}
                                {!!currentRestaurant.fullAddress && (
                                    <div className="mt-6 col-span-6 lg:col-span-3 px-4 pb-4 ">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Address
                                        </div>
                                        <div className="text-base">
                                            {currentRestaurant.fullAddress}
                                        </div>
                                    </div>
                                )}
                                {!!currentRestaurant?.zipCode?.length &&
                                    currentRestaurant.zipCode !==
                                        "undefined" && (
                                        <div className="mt-6 col-span-6 lg:col-span-3 p-4 ">
                                            <div className="text-sm font-medium text-neutral-600">
                                                Postal code
                                            </div>
                                            <div className="text-base">
                                                {currentRestaurant.zipCode}
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <div className="grid grid-cols-6 gap-x-12">
                                {currentRestaurant.phone && (
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Phone number
                                        </div>
                                        <div className="text-base">
                                            {currentRestaurant.phone}
                                        </div>
                                    </div>
                                )}
                                {currentRestaurant.website && (
                                    <div className="mt-6 col-span-6">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Website from Google
                                        </div>
                                        <Paragraph className="text-base">
                                            <a
                                                className="text-base block mt-1 mb-2 break-all"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={currentRestaurant.website}
                                            >
                                                {currentRestaurant.website}
                                            </a>
                                        </Paragraph>
                                    </div>
                                )}
                            </div>
                            <div className="mb-6 grid grid-cols-6 gap-x-12">
                                {!isLibraryElement && (
                                    <>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <DatePickerMaterial
                                                control={control}
                                                minDate={
                                                    parseMomentUtcToLocal(
                                                        trip?.startDate
                                                    ).toDate() as any
                                                }
                                                maxDate={
                                                    moment
                                                        .utc(trip?.endDate)
                                                        .endOf("day")
                                                        .toDate() as any
                                                }
                                                name="startDate"
                                                value={startDate}
                                                id="startDate"
                                                label="Start date"
                                                onChange={setStartDate}
                                                error={
                                                    errors["startDate"]?.message
                                                }
                                            />
                                        </div>

                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <TimePickerMaterial
                                                control={control}
                                                label="Start Hour"
                                                defaultValue={
                                                    moment(
                                                        activity?.startHour ||
                                                            "09:00",
                                                        "HH:mm"
                                                    ) as any
                                                }
                                                id="startHour"
                                                name="startHour"
                                                error={
                                                    errors["startHour"]?.message
                                                }
                                            />
                                        </div>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <InputLabel
                                                inputId={"endHour"}
                                                label={"End Hour"}
                                                error={false}
                                            />
                                            <div className="flex flex-row gap-4 items-center">
                                                <InputSwitch
                                                    id="showEndHour"
                                                    name="showEndHour"
                                                    defaultChecked={
                                                        activity?.startHour !==
                                                        activity?.endHour
                                                    }
                                                    onClick={handleSwitchChange}
                                                />
                                                {showEndHourInput && (
                                                    <div className="flex-grow">
                                                        <TimePickerMaterial
                                                            control={control}
                                                            defaultValue={
                                                                moment(
                                                                    activity?.endHour ||
                                                                        "09:00",
                                                                    "HH:mm"
                                                                ) as any
                                                            }
                                                            id="endHour"
                                                            name="endHour"
                                                            error={
                                                                errors[
                                                                    "endHour"
                                                                ]?.message
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="mb-6">
                                <TextareaWithValid
                                    register={register}
                                    label="Description"
                                    id="description"
                                    name="description"
                                    error={errors["description"]?.message}
                                />
                            </div>
                            <div className="mb-6">
                                <InputWithValid
                                    register={register}
                                    label="Website"
                                    type="text"
                                    id="website"
                                    name="website"
                                    error={errors["website"]?.message}
                                />
                            </div>
                            <div className="mb-6">
                                <span className="block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="text-sm font-semibold mb-4">
                                Contacts
                            </div>
                            <div
                                onClick={() => {
                                    setSelectedContact(null);
                                    handleContactToggle(true);
                                }}
                                className="flex cursor-pointer mb-3 border border-gray-200 rounded-xl p-6 justify-center"
                            >
                                <div className="flex flex-wrap items-center lg:min-w-[300px]">
                                    <img
                                        src={accountImage}
                                        className="h-16 mr-12"
                                        alt=""
                                    />
                                    <div className="flex flex-col">
                                        <div className="text-semibold text-orange-600">
                                            Add contact
                                        </div>
                                        <div className="text-sm text-neutral-400">
                                            Submit contact information
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {contacts && (
                                <div className="flex gap-6 flex-wrap">
                                    {contacts.map((c: any, index: number) => (
                                        <ContactCard
                                            handleEdit={() => {
                                                handleContactEditClick(index);
                                            }}
                                            handleRemove={() => {
                                                handleContactRemoveClick(index);
                                            }}
                                            contact={c}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            )}

                            <div className="text-sm font-semibold mt-8 mb-4">
                                Photos
                            </div>
                            <div>
                                <DropImageFileInput
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={activityForm.onSelectImages}
                                />
                            </div>

                            {activityForm.imagesProgress.map((img) => {
                                return (
                                    <ProgressBar
                                        key={img.id}
                                        progress={img.progress}
                                        name={img.file.name}
                                        onRemove={() =>
                                            activityForm.removeProgressImage(
                                                img.id,
                                                true
                                            )
                                        }
                                    />
                                );
                            })}

                            <div className="flex flex-wrap gap-4 mt-4">
                                {activityForm.imagesPreviews.map(
                                    (i, index: number) => {
                                        return (
                                            <GalleryImage
                                                key={i.src}
                                                src={i.src}
                                                onImageClick={() => {}}
                                                // onImageAdd={(e: any) => handleImageAdd(index)}
                                                onImageRemove={() => {
                                                    activityForm.handleImageRemove(
                                                        index
                                                    );
                                                }}
                                            />
                                        );
                                    }
                                )}
                                {activityForm.tempImages.map((i) => {
                                    return (
                                        <GalleryImage
                                            key={i.id}
                                            src={i.file}
                                            onImageClick={() => {}}
                                            // onImageAdd={(e: any) => handleImageAdd(index)}
                                            onImageRemove={() => {
                                                activityForm.removeTempImage(
                                                    i.id
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </div>

                            <div className="text-sm font-semibold mt-8 mb-4">
                                Files
                            </div>
                            <div>
                                <DropFileInput
                                    iconSrc={fileImage}
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={activityForm.onSelectFiles}
                                />
                            </div>
                            {activityForm.filesProgress.map((file) => {
                                return (
                                    <ProgressBar
                                        key={file.id}
                                        progress={file.progress}
                                        name={file.file.name}
                                        onRemove={() =>
                                            activityForm.removeProgressFile(
                                                file.id,
                                                true
                                            )
                                        }
                                    />
                                );
                            })}
                            <div className="flex flex-wrap gap-4 mt-4">
                                {activityForm.tempFiles.map((file) => {
                                    return (
                                        <FilePreview
                                            key={file.id}
                                            onRemove={() => {
                                                activityForm.removeTempFile(
                                                    file.id
                                                );
                                            }}
                                            name={file.file.name}
                                        />
                                    );
                                })}
                                {activityForm.filesPreviews.map(
                                    (file, index) => {
                                        return (
                                            <FilePreview
                                                key={index}
                                                onRemove={() => {
                                                    activityForm.handleFileRemove(
                                                        index
                                                    );
                                                }}
                                                name={file.name}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </>
                    )}

                    {currentRestaurant && !editingRest && (
                        <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12">
                            <Button
                                onClick={() => navigateBack()}
                                outlined
                                type="button"
                                label="Cancel"
                            />
                            <Button type="submit" label="Save" />
                        </div>
                    )}
                    {editingRest && (
                        <div className="absolute bottom-5 right-5">
                            <Button
                                onClick={() => {
                                    setEditingRest(false);
                                }}
                                outlined
                                type="button"
                                label="back"
                            />
                        </div>
                    )}
                </form>
            </>
        )
    );
}
