import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../components/TextArea";
import { addFileToTrip, editTripFile } from "../../tripSlice";
import DropFileInput from "../../../../components/DropFileInput/DropFileInput";
import InputErrorLabel from "../../../../components/InputErrorLabel";
import SelectInputWithValid from "../../../../components/InputSelect";
import {
    ALL_OPTION_VALUE,
    FilePermissionOptions,
    UserRoleOnTrip,
} from "../../../../constants";
import FileItem from "./FileItem";

interface FileFormProps {
    file?: any;
    onCancel: any;
    onComplete: any;
}

export default function FileForm(props: FileFormProps) {
    const { file, onCancel, onComplete } = props;
    const [selectedFile, setSelectedFile] = useState<any>();
    const [changingFile, setChangingFile] = useState<boolean>(false);
    const [preview, setPreview] = useState<string | undefined>("");
    let { tripId, fileId } = useParams();
    const schema = yup
    .object({
        name: yup.string().required("This field is required"),
        description: yup.string().max(5000).nullable(),
        file: yup.string(),
        tripRole: yup.string().required("This field is required"),
    })
    .test("file-or-description", "Either file or description is required", function (values) {
        return !!(values.file || values.description);
    })
    .required("This field is required");
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        setValue,
        trigger,
        control,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const values = getValues();

    useEffect(() => {
        if (fileId && file) {
            const tripRole =
                file?.permission?.rolesAllowed &&
                file?.permission?.rolesAllowed.find(
                    (r: string) => r === UserRoleOnTrip.ADMIN
                )
                    ? UserRoleOnTrip.ADMIN
                    : ALL_OPTION_VALUE;
            reset({
                name: file.name,
                description: file.description,
                tripRole: tripRole,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (selectedFile) {
            setPreview(selectedFile.name);
        } else {
            if (fileId && file && !changingFile) {
                setPreview(file.originalName);
            } else {
                setPreview(undefined);
            }
        }
    }, [selectedFile, changingFile, file, fileId]);

    const handleSubmitForm = async (data: any) => {
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);

        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("tripRole", data.tripRole);
        if (fileId) {
            await dispatch(
                editTripFile(Number(tripId), Number(fileId), formData)
            );
        } else {
            await dispatch(addFileToTrip(Number(tripId), formData));
        }

        onComplete();
    };

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
        setValue("file", e.target.files[0]);
        trigger("file");
        setChangingFile(false);
    };

    const removeSelectedFile = () => {
        setChangingFile(true);
        setSelectedFile(undefined);
        setValue("file", undefined);
        trigger("file");
        setPreview(undefined);
    };

    return (
        <>
            <div className="flex flex-wrap mb-12">
                <div className="text-left font-semibold text-lg flex-grow">
                    {file?.id ? "Edit file info" : "Add File"}
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                encType="multipart/form-data"
            >
                <div className="flex flex-col">
                    <div className="flex">
                        {preview ? (
                            <FileItem
                                file={{ name: preview }}
                                canEdit={true}
                                handleRemove={removeSelectedFile}
                            />
                        ) : (
                            <div className="flex flex-col">
                                <div className="flex-grow">
                                    <DropFileInput onChange={onSelectFile} />
                                </div>
                                <div>
                                    <InputErrorLabel
                                        error={errors["file"]?.message}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-8 lg:max-w-md">
                        <SelectInputWithValid
                            markRequired
                            control={control}
                            label="Who can see this file?"
                            id="tripRole"
                            name="tripRole"
                            options={FilePermissionOptions}
                            error={errors["tripRole"]?.message}
                            defaultValue={values?.tripRole}
                        />
                    </div>

                    <div className="mt-8 lg:max-w-md">
                        <Input
                            markRequired
                            register={register}
                            label="Name"
                            type="text"
                            id="name"
                            name="name"
                            error={errors["name"]?.message}
                        />
                    </div>
                    <div className="mt-8 lg:max-w-md">
                        <TextareaWithValid
                            register={register}
                            label="Description"
                            id="description"
                            name="description"
                            error={errors["description"]?.message}
                        />
                    </div>
                </div>

                <div className="pt-8 text-right flex gap-4 md:justify-end">
                    <Button onClick={onCancel} outlined label="Cancel" />
                    <Button disabled={!isValid} type="submit" label="Save" />
                </div>
            </form>
        </>
    );
}
