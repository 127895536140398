import moment from "moment";
import {
    forwardRef,
    useEffect,
    useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import { PAGE_ROWS, PAGE_SECTIONS } from "../../../constants";
import { parseMomentUtc } from "../../../helpers";
import { selectCurrentBreakpoint, setCurrentSection } from "../../layout/layoutSlice";
import { getAdminTransfers, selectAdminTransfers } from "../adminSlice";
import AllTransfersTable from "./components/AllTransfersTable";

const AllTransfers = forwardRef(() => {
    const dispatch = useAppDispatch();
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const [type, setType] = useState<string>("");
    const transfers = useAppSelector(selectAdminTransfers);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_ALL_TRANSFER })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, type, startDate, endDate]);

    const fetchData = (filters: any) => {
        if (filters) {
            dispatch(
                getAdminTransfers(
                    filters.take,
                    filters.skip,
                    filters.type,
                    filters.search,
                    filters.startDate,
                    filters.endDate
                )
            );
        }
    };

    const getData = () => {
        fetchData({
            take: PAGE_ROWS,
            skip: newPage.skip,
            type,
            search,
            startDate: parseMomentUtc(startDate).startOf("day").toDate(),
            endDate: parseMomentUtc(endDate).endOf("day").toDate(),
        });
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };

    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Transfers
            </div>
            <div className="grid grid-cols-12 items-end gap-5 md:gap-8">
                <div className="col-span-12 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-6 lg:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-6 lg:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
            {isDesktop ? (
                <AllTransfersTable
                    transfers={transfers}
                    handlePaginatorChange={handlePaginatorChange}
                />
            ) : (
                <></>
            )}

            {!transfers?.isLoading && !transfers.count && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no transfers to show.
                </div>
            )}
        </>
    );
});

export default AllTransfers;