import "./App.css";
import { Route, Routes, useSearchParams } from "react-router-dom";

import Layout from "./features/layout";
import RequireAuth from "./hooks/RequiresAuth";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import HotelsList from "./features/hotels";
import HotelDetail from "./features/hotels/HotelDetail";
import OtherForm from "./features/others/OtherForm";
import RestaurantHome from "./features/restaurants/restaurant";
import RestaurantForm from "./features/restaurants/RestaurantForm";
import RestaurantDetail from "./features/restaurants/restaurant/RestaurantDetail";
import RestaurantsList from "./features/restaurants/RestaurantsList";
import LocalPartnerForm from "./features/localPartners/localPartnerForm";
import LocalPartnersList from "./features/localPartners";
import NotFound from "./features/NotFound";


import { TripsEditRoutes, TripsRoutes } from "./routes/tripRoute";
import {
    AccountRoute,
    ChatRoute,
    DeleteAccountRoute,
    DeletedAccountRoute,
    ExpiredLinkRoute,
    NotificationsRoute,
    PlansRoute,
    PreferencesRoute,
    ProfileRoute,
    ResetPasswordRoute,
    SigninRoute,
    SignupRoute,
    UserProfileRoute,
} from "./routes/userRoute";
import { DefaultRoute, HomeRoute, SSORoute } from "./routes/homeRoute";
import {
    getMaintenanceMode,
    selectMaitenanceMode,
} from "./features/configSlice";
import Maintenance from "./features/maintenance";
import { InsightsRoute } from "./routes/userReviewsRoute";
import { setCurrentBreakpoint } from "./features/layout/layoutSlice";
import {
    getCurrentBreakpoint,
    isDesktop,
    isMobile,
    isTablet,
} from "./hooks/useBreakpoints";
import { AdminRoutes } from "./routes/adminRoutes";
import { ChatFormProvider } from "./features/chat/provider/chatFormProvider";
import { useConstantsContext } from "./context/Constants";
import { OrganizationRoutes } from "./routes/organizationRoutes";

function App() {
    const dispatch = useAppDispatch();
    const maintenanceMode = useAppSelector(selectMaitenanceMode);
    const [searchParams, setSearchParams] = useSearchParams();
    const { constants } = useConstantsContext();

    useEffect(() => {
        if (!!searchParams.get("isUniversalLink")) {
            setSearchParams(searchParams, {});
            const userAgent = navigator.userAgent;
            if (/iPhone/.test(userAgent)) {
                const now = new Date().valueOf();
                setTimeout(function () {
                    if (new Date().valueOf() - now > 100) return;
                    window.location.href =
                        "https://apps.apple.com/ar/app/famguru/id1549483303";
                }, 50);
                window.location.href = "famguru:" + window.location.pathname + window.location.search;
            } else if (/Android/.test(userAgent)) {
                const now = new Date().valueOf();
                setTimeout(function () {
                    if (new Date().valueOf() - now > 100) return;
                    window.location.href =
                        "https://play.google.com/store/apps/details?id=com.famguru";
                }, 50);
                window.location.href =
                    "famguru://famguru" + window.location.pathname + window.location.search;
            }
        }
    }, []);

    useEffect(() => {
        dispatch(getMaintenanceMode());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleWindowResize = () => {
        const currentB = getCurrentBreakpoint(window?.innerWidth);
        const isM = isMobile(currentB);
        const isTabl = isTablet(currentB);
        const isDeskt = isDesktop(currentB);
        dispatch(
            setCurrentBreakpoint({
                currentBreakpoint: {
                    currentBreakpoint: currentB,
                    isMobile: isM,
                    isTablet: isTabl,
                    isDesktop: isDeskt,
                },
            })
        );
    };
    const showMaintenancePage =
        maintenanceMode?.open && !maintenanceMode?.isMaintainer;
    return showMaintenancePage ? (
        <Maintenance />
    ) : (
        <Routes>
            {SSORoute}
            {DeleteAccountRoute}
            {DeletedAccountRoute}
            {ExpiredLinkRoute}

            <Route element={<Layout />}>
                {SigninRoute}
                {SignupRoute}
                {ResetPasswordRoute}
            </Route>
            <Route
                element={
                    <RequireAuth protectedRoute={true}>
                        {constants?.featureFlags?.FEATURE_CHATS == "true" ? (
                            <ChatFormProvider>
                                <Layout />
                            </ChatFormProvider>
                        ) : (
                            <Layout />
                        )}
                    </RequireAuth>
                }
            >
                {DefaultRoute}
                {ProfileRoute}
                {PreferencesRoute}
                {PlansRoute}
                {AccountRoute}
                {UserProfileRoute}
                {NotificationsRoute}
                {ChatRoute}
                {HomeRoute}
                {TripsRoutes}
                {TripsEditRoutes}
                {InsightsRoute}
                {AdminRoutes}
                <Route path="/hotels" element={<HotelsList />} />
                <Route path="/hotels/:hotelId" element={<HotelDetail />} />
                <Route path="/others/new" element={<OtherForm />} />
                <Route path="/restaurants" element={<RestaurantsList />} />
                <Route path="/restaurants/new" element={<RestaurantForm />} />
                <Route
                    path="/restaurants/:restaurantId"
                    element={<RestaurantHome />}
                >
                    <Route path="" element={<RestaurantDetail />} />
                    <Route path="edit" element={<RestaurantForm />} />
                </Route>
                {OrganizationRoutes}

                <Route path="/local-partners" element={<LocalPartnersList />} />
                <Route
                    path="/local-partners/new"
                    element={<LocalPartnerForm />}
                />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export default App;
