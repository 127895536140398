import { useRef, useState } from "react";
import {
    TOAST_TYPE,
    VALID_IMAGES_FORMATS,
    VALID_VIDEOS_FORMATS,
    BYTES_IN_KB,
    KB_IN_MB,
} from "../constants";
import { resizeImageFile, sendToast } from "../helpers";

const errorMessage =
"Some files could not be uploaded because they are not valid images or videos, or are too big.";

const useDropMediaFileInput = (onChange: Function) => {
    const [dragOverlay, setDragOverlay] = useState(false);
    const dragCounter = useRef(0);
    const [error, setError] = useState(false);

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragOverlay(true);
        }
    };

    const handleDragOut = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setDragOverlay(false);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOverlay(false);
        dragCounter.current = 0;

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            changeFile({ target: { files: e.dataTransfer.files } });
            e.dataTransfer.clearData();
        }
    };

    const changeFile = async (e: any) => {
        let withError = false;
        const filesArray: File[] = Array.from(e.target.files);
        const validFiles: File[] = [];

        for (const file of filesArray) {
            const { type, size } = file;
            const isImage = VALID_IMAGES_FORMATS.includes(type);
            const isVideo = VALID_VIDEOS_FORMATS.includes(type);
            const validFormat = isImage || isVideo;

            if (!validFormat) {
                withError = true;
                continue;
            }

            let validSize = false;
            if (isImage) {
                validSize = size / BYTES_IN_KB / KB_IN_MB < 10; // < 10 MB para imágenes
            } else if (isVideo) {
                validSize = size / BYTES_IN_KB / KB_IN_MB < 50; // < 50 MB para videos
            }

            if (!validSize) {
                withError = true;
                continue;
            }

            if (isImage) {
                const imageResized = await resizeImageFile(file);
                validFiles.push(new File([imageResized], file.name, { type }));
            } else {
                validFiles.push(file);
            }
        }

        setError(withError);

        if (validFiles.length > 0) {
            onChange({ target: { files: validFiles } });
        }

        if (withError) {
            // sendToast({
            //     type: TOAST_TYPE.ERROR,
            //     message: errorMessage,
            // });
        }
    };

    return {
        error,
        errorMessage,
        dragOverlay,
        handleDrag,
        handleDragIn,
        handleDrop,
        handleDragOut,
        changeFile,
    };
};

export default useDropMediaFileInput;
