import { FC, useState } from "react";
import Icon from "../../assets/svg/Icon";
import useTurnOffOutsideClick from "../../hooks/useTurnOffOutsideClick";
interface IDotsMenuProps {
    position?: "top" | "bottom";
    options: Array<{ label: string; key: string }>;
    handleClickMenu: Function;
    className?: string;
    wrapperClass?: string;
}
const DotsMenu: FC<IDotsMenuProps> = (props: any) => {
    const [open, setOpen] = useState(false);

    const { options, handleClickMenu, position, className, wrapperClass } =
        props;
    const toggleOpen = (e?: any) => {
        e && e.stopPropagation();
        setOpen((prev) => {
            return !prev;
        });
    };
    const { ref } = useTurnOffOutsideClick(true, toggleOpen);
    const handleClickOption = (optId: string) => {
        toggleOpen();
        if (handleClickMenu) handleClickMenu(optId);
    };

    return (
        <div className="relative">
            <div
                onClick={toggleOpen}
                className={`cursor-pointer text-neutral-300 hover:text-neutral-800 ${wrapperClass}`}
            >
                <Icon
                    icon="DotsVerticalIcon"
                    className={`w-[20px] ${className}`}
                />
            </div>

            {open && (
                <div
                    ref={ref}
                    className={`shadow-md text-left ${
                        position === "top" ? "bottom" : "top"
                    }-4 right-2 absolute rounded-md bg-white px-4 w-fit z-50`}
                >
                    {options &&
                        options.map((opt: any) => {
                            const iconClass = `${
                                opt?.disabled
                                    ? "text-gray-300"
                                    : "text-gray-400"
                            } w-[20px] cursor-pointer hover:text-orange-600 group-hover:text-orange-600`;
                            return (
                                <button
                                    disabled={opt?.disabled}
                                    key={opt?.key}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickOption(opt.key);
                                    }}
                                    className="flex items-center gap-2 cursor-pointer my-4 text-base font-semibold text-gray-400 hover:text-orange-600 whitespace-nowrap child-hover:text-orange-600 group disabled:text-gray-300"
                                >
                                    {(opt?.icon || opt?.mdiIcon) && (
                                        <Icon
                                            icon={opt?.icon}
                                            className={iconClass}
                                            materialIcon={opt?.mdiIcon}
                                        />
                                    )}
                                    <div className="group-hover:text-orange-600">
                                        {opt.label}
                                    </div>
                                </button>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default DotsMenu;
