import { FC } from "react";
import Icon from "../../../assets/svg/Icon";
import { on } from "events";

interface FileInputButtonProps {
    showDropdown: boolean;
    toggleDropdown: () => void;
    onPhotoInputClick: () => void;
    onVideoInputClick: () => void;
    onFileInputClick: () => void;
    disabled?: boolean;
}

const FileInputButton: FC<FileInputButtonProps> = ({
    showDropdown,
    toggleDropdown,
    onPhotoInputClick,
    onVideoInputClick,
    onFileInputClick,
    disabled,
}) => (
    <div className="relative">
        <button
            type="button"
            className="rounded-full bg-gray-200 cursor-pointer mr-1 p-2"
            onClick={toggleDropdown}
            disabled={disabled}
        >
            <Icon
                icon={showDropdown ? "CloseIcon" : "PlusIcon"}
                className="w-6 h-6 m-0 fill-gray-500 text-gray-500"
            />
        </button>
        {showDropdown && (
            <div className="absolute bottom-full mb-2 left-0 bg-white border text-gray-400 border-gray-300 rounded-md shadow-md z-10">
                <button
                    type="button"
                    className="flex items-center gap-2 px-4 py-2 cursor-pointer text-base font-semibold hover:text-orange-600 whitespace-nowrap"
                    onClick={onPhotoInputClick}
                >
                    <Icon
                        icon="ImageAddIcon"
                        className="w-[20px] cursor-pointer"
                    />
                    <span>Photo</span>
                </button>

                <button
                    type="button"
                    className="flex items-center gap-2 px-4 py-2 cursor-pointer text-base font-semibold hover:text-orange-600 whitespace-nowrap"
                    onClick={onVideoInputClick}
                >
                    <Icon
                        icon="VideoAddIcon"
                        className="w-[20px] cursor-pointer"
                    />
                    <span>Video (.mp4)</span>
                </button>

                <button
                    type="button"
                    className="flex items-center gap-2 px-4 py-2 cursor-pointer text-base font-semibold hover:text-orange-600 whitespace-nowrap"
                    onClick={onFileInputClick}
                >
                    <Icon
                        icon="FileAddIcon"
                        className="w-[20px] cursor-pointer"
                    />
                    <span>File</span>
                </button>
            </div>
        )}
    </div>
);

export default FileInputButton;
