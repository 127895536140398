import moment from "moment";
import TextareaWithValid from "../../../../../../components/TextArea/TextArea";
import { useState } from "react";
import { FlightForm } from "./FlightForm";
import TabComponent from "../../../../../../components/TabComponent";
import ButtonSquared from "../../../../../../components/ButtonSquare";

export const RequirementFlightFormResponse = ({
    show,
    requirement,
    register,
    errors,
    control,
    setValue,
    clearErrors,
    getValues,
    watch,
    reset,
    handleSubmitForm,
    trigger,
    sending,
    onCancel,
}: any) => {
    const [transportation, setTransportation] = useState("arrival_flight");
    const [transportationDeparture, setTransportationDeparture] =
        useState("departure_flight");
    const deadline = moment(requirement?.deadline)?.format("DD MMM, YYYY");
    const [currentStep, setCurrentStep] = useState(1);
    const onNextStep = async () => {
        const data = getValues();
        const arrivalInputs = data?.isArrivalFlightRequired
            ? [
                  "arrival_airline",
                  "arrival_flightNumber",
                  "arrival_departureAirport",
                  "arrival_departureDate",
                  "arrival_departureTime",
                  "arrival_arrivalAirport",
                  "arrival_arrivalDate",
                  "arrival_arrivalTime",
              ]
            : ["arrival_other_type_of_transportation"];
        const isValid = await trigger(arrivalInputs);
        if (isValid) {
            clearErrors();
            setCurrentStep(2);
        }
    };

    if (!show) return null;
    return (
        <>
            <div className="flex justify-center items-center">
                <div className="w-full px-6">
                    <h2
                        className="text-xl font-semibold mb-2"
                        id="submit_requirement"
                    >
                        Submit requirement: {requirement?.name}
                    </h2>
                    <p className="text-sm text-gray-600 mb-2">
                        Deadline:{" "}
                        <span className="font-semibold">{deadline}</span>
                    </p>

                    {/* Arrival Information */}
                    <h3 className="font-medium text-lg">
                        {currentStep === 1 ? "Arrival" : "Departure"}{" "}
                        information
                    </h3>
                    <div className="text-gray-700 text-xs font-semibold mb-4">
                        {requirement?.description}
                    </div>
                    <p className="text-sm text-gray-700 mb-2">
                        {currentStep === 1
                            ? requirement?.extraNotes1
                            : requirement?.extraNotes2}
                    </p>

                    <div className={`${currentStep !== 1 && "hidden"}`}>
                        <div className="my-4">
                            <TabComponent
                                buttonStyle
                                onChange={(t: string) => {
                                    clearErrors();
                                    setTransportation(t);
                                    setValue(
                                        "isArrivalFlightRequired",
                                        t === "arrival_flight"
                                    );
                                }}
                                items={[
                                    {
                                        id: "arrival_flight",
                                        text: "Arrival Flight",
                                        value: "arrival_flight",
                                    },
                                    {
                                        id: "other_type_of_transportation",
                                        text: "Other Transport",
                                        value: "other_type_of_transportation",
                                    },
                                ]}
                                defaultSelected={transportation}
                            />
                        </div>

                        {transportation === "arrival_flight" && (
                            <FlightForm
                                type="arrival"
                                errors={errors}
                                register={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                watch={watch}
                                reset={reset}
                            />
                        )}

                        {transportation === "other_type_of_transportation" && (
                            <div className="mb-2">
                                <TextareaWithValid
                                    markRequired={true}
                                    register={register}
                                    label="Arrival Comments"
                                    id="arrival_other_type_of_transportation"
                                    name={`arrival_other_type_of_transportation`}
                                    onChange={(e: any) =>
                                        setValue(
                                            `arrival_other_type_of_transportation`,
                                            e.target.value
                                        )
                                    }
                                    error={
                                        errors
                                            ?.departure_other_type_of_transportation
                                            ?.message
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className={`${currentStep !== 2 && "hidden"}`}>
                        <div className="my-4">
                            <TabComponent
                                buttonStyle
                                onChange={(t: string) => {
                                    clearErrors();
                                    setTransportationDeparture(t);
                                    setValue(
                                        "isDepartureFlightRequired",
                                        t === "departure_flight"
                                    );
                                }}
                                items={[
                                    {
                                        id: "departure_flight",
                                        text: "Departure Flight",
                                        value: "departure_flight",
                                    },
                                    {
                                        id: "departure_other_type_of_transportation",
                                        text: "Other Transport",
                                        value: "departure_other_type_of_transportation",
                                    },
                                ]}
                                defaultSelected={transportationDeparture}
                            />
                        </div>

                        {transportationDeparture === "departure_flight" && (
                            <FlightForm
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                control={control}
                                type="departure"
                                getValues={getValues}
                                watch={watch}
                                reset={reset}
                            />
                        )}

                        {transportationDeparture ===
                            "departure_other_type_of_transportation" && (
                            <div>
                                <TextareaWithValid
                                    markRequired={true}
                                    register={register}
                                    label="Departure Comments"
                                    id="departure_other_type_of_transportation"
                                    onChange={(e: any) =>
                                        setValue(
                                            `departure_other_type_of_transportation`,
                                            e?.target?.value
                                        )
                                    }
                                    name={`departure_other_type_of_transportation`}
                                    error={
                                        errors
                                            ?.departure_other_type_of_transportation
                                            ?.message
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-4 md:justify-end text-right">
                {currentStep === 1 && (
                    <>
                        <ButtonSquared
                            type="button"
                            label="Cancel"
                            className="!min-w-[200px]"
                            outlined
                            onClick={() => {
                                onCancel();
                                reset();
                            }}
                        />
                        {(watch("arrival_enterManually") ||
                            watch("arrival_showAllInputs") ||
                            transportation ===
                                "other_type_of_transportation") && (
                            <ButtonSquared
                                type="button"
                                label="Next"
                                className="!min-w-[200px]"
                                onClick={onNextStep}
                            />
                        )}
                    </>
                )}
                {currentStep === 2 && (
                    <>
                        <ButtonSquared
                            disabled={sending}
                            type="button"
                            label="Back"
                            className="!min-w-[200px]"
                            outlined
                            onClick={() => setCurrentStep(1)}
                        />
                        {(watch("departure_enterManually") ||
                            watch("departure_showAllInputs") ||
                            transportationDeparture ===
                                "departure_other_type_of_transportation") && (
                            <ButtonSquared
                                disabled={sending}
                                type="button"
                                label="Save"
                                className="!min-w-[200px]"
                                onClick={handleSubmitForm}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
