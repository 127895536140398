import moment from "moment";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useMeasure from "react-use-measure";
import { useAppDispatch } from "../../../../../../app/hooks";
import Icon from "../../../../../../assets/svg/Icon";
import ButtonSquare from "../../../../../../components/ButtonSquare";
import StatusBadgeComponent from "../../../../../../components/StatusBadge/StatusBadgeComponent";
import TextareaWithValid from "../../../../../../components/TextArea";
import { ButtonSize, GeneralStatus } from "../../../../../../constants";
import { updateRequirementFile } from "../../../../tripSlice";
import { Paragraph } from "../../../../../../components/Paragraph/Paragraph";
import FlightRequirementDetails from "../../../TripRequirements/TripRequirement/response/FlightRequirementDetails";

interface RequirementActionProps {
    file: any;
    requirement: any;
    tripId: number;
    onActionTaken: any;
}

export default function RequirementAction(props: RequirementActionProps) {
    const { file, requirement, tripId, onActionTaken } = props;
    const [expanded, setExpanded] = useState(false);
    const dispatch = useAppDispatch();
    const [ref, bounds] = useMeasure();

    const files = useMemo(() => {
        return Object.keys(file.files).map((key) => file.files[key]);
    }, [file]);

    const {
        register,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            reviewComment: "",
        },
    });

    const comment = watch("reviewComment");

    const handleFileUpdate = async (file: any, approved: boolean) => {
        await dispatch(
            updateRequirementFile(tripId, Number(requirement.id), file.id, {
                status: approved
                    ? GeneralStatus.APPROVED
                    : GeneralStatus.REJECTED,
                reviewComment: comment || "",
            })
        );
        onActionTaken();
    };

    return (
        <div className="flex flex-col my-2 p-4 bg-neutral-50 rounded-xl">
            <div className="flex items-center">
                <div>
                    {file.status !== GeneralStatus.PENDING && (
                        <Icon
                            icon={
                                !expanded ? "ChevronUpIcon" : "ChevronDownIcon"
                            }
                            className={`w-7 mr-2 text-neutral-400 cursor-pointer`}
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                        />
                    )}
                </div>
                <div className="text-sm text-neutral-400 flex-grow">
                    {moment(file.createdAt).format("DD MMM, YYYY")}
                </div>
                <StatusBadgeComponent outlined status={file.status} />
            </div>
            <div
                className={`transition[height] ease-in-out duration-200 ${
                    !(expanded || file.status === GeneralStatus.PENDING)
                        ? "overflow-hidden"
                        : ""
                }`}
                style={{
                    height:
                        expanded || file.status === GeneralStatus.PENDING
                            ? bounds.height
                            : "0px",
                }}
            >
                <div className=" pt-6 flex flex-col" ref={ref}>
                    <div className="flex flex-wrap gap-4">
                        {files?.map((f: any, index: number) => (
                            <a
                                key={index}
                                href={f.url}
                                target="_blank"
                                rel="noreferrer"
                                download={true}
                                className="hover:!text-neutral-900 text-neutral-400"
                            >
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <Icon
                                            icon="FileOutlineIcon"
                                            className="w-7"
                                        />
                                    </div>
                                    <div className="text-basefont-semibold">
                                        {f.originalName.length > 10
                                            ? "..." +
                                              f.originalName.substring(
                                                  f.originalName.length - 10,
                                                  f.originalName.length
                                              )
                                            : f.originalName}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                    {!!file?.description && <>
                        {requirement?.type === 'arrival_departure_info' && <FlightRequirementDetails data={JSON.parse(file.description)} />}
                        {requirement?.type !== 'arrival_departure_info' && (
                        <Paragraph className="text-sm text-neutral-400 flex-grow m-2">
                            {file?.description}
                        </Paragraph>)}
                    </>}
                    {![GeneralStatus.APPROVED, GeneralStatus.REJECTED].includes(
                        file.status
                    ) ? (
                        <>
                            <div>
                                <TextareaWithValid
                                    register={register}
                                    label="Comment"
                                    id="reviewComment"
                                    name="reviewComment"
                                    error={errors["reviewComment"]?.message}
                                />
                            </div>
                            <div className="pt-4 flex gap-4 justify-end">
                                <ButtonSquare
                                    size={ButtonSize.SMALL}
                                    outlined
                                    label="Approve"
                                    type="button"
                                    className="!text-[#1F8F4A] !border-[#1F8F4A]"
                                    onClick={() => {
                                        handleFileUpdate(file, true);
                                    }}
                                />
                                <ButtonSquare
                                    size={ButtonSize.SMALL}
                                    outlined
                                    label="Reject"
                                    type="button"
                                    className="!text-[#F7685B] !border-[#F7685B]"
                                    onClick={() => {
                                        handleFileUpdate(file, false);
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Paragraph className="pt-4 px-1 text-sm text-neutral-400 font-semibold mb-2">
                            {file.reviewComment}
                        </Paragraph>
                    )}
                </div>
            </div>
        </div>
    );
}
