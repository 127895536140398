import { useState } from "react";
import ModalConfirmation from "../../components/Modal/ConfirmationModal";
import { ChoosePlanModal } from "../../components/Modal/ChoosePlanModal";
import { UserTypeVariant } from "../../components/UserType/UserType";

export const TripsLimitReachedModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: Function;
}) => {
    const [showPlanModal, setShowPlanModal] = useState(false);
    const handleClose = () => {
        setShowPlanModal(false);
        onClose();
    };
    return (
        <>
            <ModalConfirmation
                className="min-h-16"
                open={open}
                title="Upgrade your plan"
                description={`You have reached the limit of trips for your plan.`}
                handleCancel={() => {
                    onClose();
                }}
                okButtonText="Choose plan"
                handleSubmit={() => setShowPlanModal(true)}
            />

            <ChoosePlanModal
                open={showPlanModal}
                handleClose={() => {
                    setShowPlanModal(false);
                }}
                onChangePlan={handleClose}
                userType={UserTypeVariant.SUPPLIER}
                closeText={"Back"}
                forceCloseModal={() => setShowPlanModal(false)}
            />
        </>
    );
};
