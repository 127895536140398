import { FC, useMemo, useState } from "react";
import { ButtonSize, PAGE_SECTIONS } from "../../../../../../../../constants";
import { ButtonPlus } from "../../../../../../../../components/ButtonPlus";
import {
    IInsightItem,
    ItemDetailsViewType,
    ListInsightItemsType,
} from "../ListInsightItems";
import Button from "../../../../../../../../components/ButtonSquare";
import { useInsightState } from "../../../../../../../../hooks/useInsightState";
import InsightComments from "./InsightComments";
import InsightHeader from "../InsightHeader";
import Icon from "../../../../../../../../assets/svg/Icon";
import ModalConfirmation from "../../../../../../../../components/Modal/ConfirmationModal";
import { BlockModal } from "../../../../../../../../components/Modal/BllockerModal";

interface FormInsightItemProps {
    showForm: boolean;
    register: any;
    errors: any;
    handleCancel: () => void;
    setType: (type: any) => void;
    setValue: (name: string, value: any) => void;
    trigger?: (name: string, value: any) => void;
    handleUploadFile: (file: any) => void;
    activeItem: IInsightItem | null;
    watch: (name: string) => any;
    isValid: boolean;
}

type FormInsightItemType = FC<FormInsightItemProps>;

interface InsightGenericViewProps {
    schema: any;
    currentSection: PAGE_SECTIONS;
    onSubmit: (data: any) => void;
    label: string;
    typesLabel?: string;
    titleKey?: string;
    subtitleKey?: string;
    commentsKey:
        | "commonComments"
        | "roomComments"
        | "dinningComments"
        | "wellnessComments";
    imagesKey:
        | "roomImages"
        | "dinningImages"
        | "wellnessImages"
        | "commonImages";
    items: IInsightItem[];
    formInsightItem: FormInsightItemType;
    listInsightItems: ListInsightItemsType;
    itemDetailsView: ItemDetailsViewType;
    customComponent?: FC<{
        register: any;
        errors: any;
        setValue: (name: string, value: any) => void;
        showComentsForm: boolean;
    }>;
    onRemove?: Function;
    backButtonLabel?: string;
}

export default function InsightGenericView({
    schema,
    currentSection,
    onSubmit,
    label,
    typesLabel,
    commentsKey,
    imagesKey,
    items,
    titleKey,
    subtitleKey,
    formInsightItem: FormInsightItem,
    listInsightItems: ListInsightItems,
    itemDetailsView: ItemDetailsView,
    customComponent,
    onRemove,
    backButtonLabel,
}: InsightGenericViewProps) {
    const {
        // local state
        activeItem,
        showForm,
        showComentsForm,
        hotelInsight,
        showDetails,
        // local state handlers
        handleCancel,
        handleEditItem,
        handleShowDetails,
        handleClickNewInsightItem,
        handleUploadFile,
        handleUploadFileToInsightSection,
        handleChangeType,
        toggleShowCommentsForm,
        handleSaveComments,
        getValues,
        // useForm props
        register,
        errors,
        handleSubmit,
        setValue,
        trigger,
        watch,
        isValid,
        isDirty,
    } = useInsightState({
        initialItem: null,
        schema,
        currentSection,
        commentsKey,
        imagesKey,
    });

    const [deleteInsight, setDeleteInsight] = useState(false);

    const handleSubmitForm = async (data: any) => {
        onSubmit && (await onSubmit(data));
        handleCancel();
    };

    if (showForm) {
        return (
            <div className="flex flex-col text-left">
                <div className="flex-grow text-left text-lg font-semibold text-neutral-900">
                    {activeItem?.id ? `Edit ${label}` : `New ${label}`}
                </div>
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    <FormInsightItem
                        showForm={showForm}
                        register={register}
                        errors={errors}
                        handleCancel={handleCancel}
                        setType={handleChangeType}
                        handleUploadFile={handleUploadFile}
                        activeItem={activeItem}
                        setValue={setValue}
                        trigger={trigger}
                        watch={watch}
                        isValid={isValid}
                    />
                </form>
            </div>
        );
    }

    if (showDetails && activeItem) {
        return (
            <>
                <div className="flex-grow text-left text-lg font-semibold text-neutral-900 mb-4">
                    <InsightHeader
                        title={`${label} Details`}
                        showEditButton={showDetails}
                        onBack={() => {
                            handleCancel();
                        }}
                        onEdit={() => {
                            handleEditItem(activeItem);
                        }}
                        backButtonLabel={backButtonLabel || `Back to ${label}`}
                    />
                </div>
                <ItemDetailsView item={activeItem} />
                {onRemove && (
                    <>
                        <div
                            className="w-fit flex flex-row items-center mt-8 text-base font-semibold text-red-500 hover:text-red-600 cursor-pointer"
                            onClick={() => {
                                setDeleteInsight(true);
                            }}
                        >
                            <Icon className="w-[24px]" icon="TrashIcon" />
                            <div>Remove {label}</div>
                        </div>
                        <ModalConfirmation
                            open={!!deleteInsight}
                            title="Are you sure?"
                            description={`You are about to remove this item`}
                            handleCancel={() => {
                                setDeleteInsight(false);
                            }}
                            handleSubmit={() => {
                                onRemove(activeItem?.id);
                                handleCancel();
                            }}
                        />
                    </>
                )}
            </>
        );
    }
    const comments = watch(commentsKey);
    const insightCommentsIsValid = comments && comments.length > 0;

    return (
        <div className="flex flex-col text-left">
            <InsightHeader
                title={
                    <div className="flex flex-grow gap-2 sm:text-xl text-lg items-center text-neutral-900 font-semibold">
                        <Icon icon={"LockIcon"} className={`w-5 h-5 `} />
                        {label}
                    </div>
                }
                showEditButton={
                    !showComentsForm && !!hotelInsight?.[commentsKey]
                }
                onEdit={toggleShowCommentsForm}
            />
            <div className="flex flex-col my-6 gap-5">
                <InsightComments
                    commentsKey={commentsKey}
                    imagesKey={imagesKey}
                    hotelInsight={hotelInsight}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    showComentsForm={showComentsForm}
                    handleUploadFileToInsightSection={
                        handleUploadFileToInsightSection
                    }
                    customComponent={customComponent}
                    toggleShowCommentsForm={toggleShowCommentsForm}
                />
            </div>
            <div className="flex-grow text-left text-lg font-semibold text-neutral-900">
                {typesLabel || `${label} Types`}
            </div>
            <ListInsightItems
                list={items}
                handleEditItem={handleEditItem}
                activeItem={activeItem}
                handleShowDetails={handleShowDetails}
            />
            <div className="py-4">
                <ButtonPlus
                    onClick={handleClickNewInsightItem}
                    label={`Add ${label}`}
                    showButton={!!activeItem?.id || !showForm}
                />
            </div>
            {(showComentsForm || !hotelInsight?.[commentsKey]) && (
                <div className="p-8 pb-0 text-right flex gap-5 w-full lg:justify-end mt-10">
                    <BlockModal shouldBlock={isDirty} />
                    <Button
                        onClick={toggleShowCommentsForm}
                        outlined
                        label="Cancel"
                        size={ButtonSize.SMALL}
                    />
                    <Button
                        onClick={handleSaveComments}
                        label={"Save"}
                        size={ButtonSize.SMALL}
                        disabled={!insightCommentsIsValid}
                    />
                </div>
            )}
        </div>
    );
}
