export const getRequirementByType = (requirementType: string) => {
    const props = {} as any;
    switch (requirementType) {
        case "arrival_departure_info":
            props.title = "Add new flight or arrival/departure requirement";
            props.showFiles = false;
            props.showFlight = true;
            props.showCheckboxSpreadsheet = true;
            props.placeholderName = "Arrival and departure information";
            props.placeholderDescription =
                "Please provide your arrival and departure details so we can arrange transfers.";
            break;
        case "passport":
            props.title = "Add new passport requirement";
            props.showFiles = false;
            props.showCheckboxSpreadsheet = true;
            props.placeholderName = "Passport copy";
            props.placeholderDescription =
                "Please provide a copy of your passport";

            break;
        default:
            props.title = "Add new requirement(s)";
            props.showFiles = true;
            props.showCheckboxSpreadsheet = true;
            break;
    }
    return props;
};
