import { useMemo } from "react";
import Icon from "../../../../assets/svg/Icon";
import { TIME_PERIOD } from "../../../../constants";

export default function OverviewElement(props: {
    label: string;
    value: number;
    title?: string;
    trend?: number;
    period?: string;
}) {
    const { label, value, trend, period, title } = props;
    const trendLabel = useMemo(() => {
        switch (period) {
            case TIME_PERIOD.DAY:
                return "last 24Hs";
            case TIME_PERIOD.WEEK:
                return "last week";
            case TIME_PERIOD.MONTH:
                return "last month";
            case TIME_PERIOD.YEAR:
                return "last year";
            default:
                return "";
        }
    }, [period]);

    return (
        <div className="flex flex-col">
            <div className="text-sm font-bold text-neutral-900 mb-1">
                {title}
            </div>
            <div className="text-sm font-semibold text-neutral-400 mb-1">
                {label}
            </div>
            <div className="text-base font-semibold mb-1">{value}</div>
            {!!trend && (
                <div className="text-xs flex flex-row">
                    <Icon
                        icon={trend > 0 ? "ArrowUpIcon" : "ArrowDownIcon"}
                        className={`${
                            trend > 0 ? "text-green-600" : "text-red-600"
                        }  w-4`}
                    />
                    <span
                        className={`font-semibold px-1 ${
                            trend > 0 ? "text-green-600" : "text-red-600"
                        }`}
                    >{`${Math.abs(trend)}%`}</span>
                    <span>{trendLabel}</span>
                </div>
            )}
        </div>
    );
}
