import { useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/ButtonSquare";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import { ButtonSize } from "../../../../../constants";
import { selectProfile } from "../../../../authentication/userSlice";
import { convertLinksToAnchors } from "../../../../notifications/utils";
import { selectedTrip } from "../../../tripSlice";
import FileItem from "../FileItem";

interface FileDetailProps {
    file: any;
    goBack: any;
    startEditting: any;
}

export default function FileDetails(props: FileDetailProps) {
    const { file, goBack, startEditting } = props;
    const profile = useAppSelector(selectProfile);
    const trip = useAppSelector(selectedTrip);

    const canEdit = useMemo(() => {
        return profile?.isAdmin || (trip?.canEdit && !trip?.suspended);
    }, [trip?.suspended, profile?.isAdmin, trip?.canEdit]);

    return (
        <>
            <div className="flex flex-wrap mb-8">
                <div className="text-left font-semibold text-lg flex-grow">
                    File
                </div>
                <span>
                    {canEdit && (
                        <Button
                            size={ButtonSize.SMALL}
                            outlined={true}
                            onClick={startEditting}
                            label="Edit"
                        />
                    )}
                </span>
            </div>
            <div className="flex flex-col">
                <div className="text-left lg:max-w-lg">
                    <FileItem file={file} />
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Name
                    </span>
                    <span className="text-base font-normal">{file.name}</span>
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Description
                    </span>
                    <Paragraph className="text-base font-normal">
                        <label className="break-before-all whitespace-pre-line text-base break-words overflow-hidden" dangerouslySetInnerHTML={{ __html: convertLinksToAnchors(file.description) }} /> 
                    </Paragraph>
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Visible to
                    </span>
                    <span className="text-base font-normal">
                        {file?.permission?.rolesAllowed?.join(', ') || "-"}
                    </span>
                </div>
                <span className="text-left mt-24">
                    <Button onClick={goBack} label="Back" />
                </span>
            </div>
        </>
    );
}
