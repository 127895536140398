import { useAppSelector } from "../../../../app/hooks";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import { PAGE_ROWS } from "../../../../constants";
import { selectedTrip } from "../../tripSlice";
import TripRequirementsTableItem from "./TripRequirementsTableItem";

export default function TripRequirementsTable({
    requirements,
    handleAddFileClick,
    handleRemoveClick,
    handleViewClick,
    handlePaginatorChange,
}: any) {
    const { canEdit } = useAppSelector(selectedTrip) || {};
    const { data, count, isLoading, currentPage, totalPages } =
        requirements || {};

    const tableHeaders = [
        "Name",
        "Type",
        "Description",
        "Deadline",
        !canEdit ? "Status" : "",
    ];

    return (
        <>
            <Table headers={tableHeaders} isLoading={isLoading}>
                {data &&
                    data.map((requirement: any) => {
                        return (
                            <TripRequirementsTableItem
                                key={`requirementList_${requirement?.id}`}
                                requirement={requirement}
                                handleViewClick={handleViewClick}
                                handleAddFileClick={handleAddFileClick}
                                handleRemoveClick={handleRemoveClick}
                            />
                        );
                    })}
            </Table>

            <div className="w-full mt-8">
                {count > PAGE_ROWS && (
                    <Paginator
                        count={count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
}
