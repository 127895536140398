import { useMemo } from "react";
import { useAppSelector } from "../../../../app/hooks";
import Icon from "../../../../assets/svg/Icon";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
import {
    DATE_FORMAT,
    formatDate,
    getStatusFromFiles,
} from "../../../../helpers";
import { selectProfile } from "../../../authentication/userSlice";
import { selectedTrip } from "../../tripSlice";

export default function TripRequirementsTableItem({
    requirement,
    handleViewClick,
    handleAddFileClick,
    handleRemoveClick,
    ...props
}: any) {
    const trip = useAppSelector(selectedTrip);
    const profile = useAppSelector(selectProfile);
    const type = useMemo(() => {
        switch (requirement?.type) {
            case 'arrival_departure_info':
                return 'Arrival/Departure Info';
            case 'passport':
                return 'Passport';
            default:
                return 'Other';
        }
    }, [requirement?.type]);

    return (
        <>
            <tr
                className={`${"cursor-pointer hover:bg-gray-50"}`}
                onClick={() => {
                    handleViewClick(Number(requirement.id));
                }}
                key={requirement.id}
            >
                <td
                    className="break-all whitespace-normal"
                    onClick={() => {
                        handleViewClick(Number(requirement.id));
                    }}
                >
                    <div className="text-left max-w-[300px] truncate">
                        <span className="text-left">{requirement.name}</span>
                    </div>
                </td>
                <td
                    className="break-all whitespace-normal"
                    onClick={() => {
                        handleViewClick(Number(requirement.id));
                    }}
                >
                    <div className="text-left max-w-[300px] truncate">
                        <span className="text-left">{type}</span>
                    </div>
                </td>
                <td className="break-all whitespace-normal">
                    <div className="text-left max-w-[300px] truncate">
                        <span className="text-left">
                            {requirement.description}
                        </span>
                    </div>
                </td>
                <td className="whitespace-normal">
                    <div className="text-left max-w-[300px] truncate">
                        <span className="text-left">
                            {formatDate(requirement.deadline, DATE_FORMAT)}
                        </span>
                    </div>
                </td>
                {!trip?.canEdit && (
                    <td className="flex gap-2 justify-start text-left items-center">
                        <StatusBadgeComponent
                            status={getStatusFromFiles(requirement.files)}
                        />
                    </td>
                )}

                {(profile?.isAdmin || (trip?.canEdit && !trip?.suspended)) && (
                    <td>
                        <div className="w-full flex justify-end">
                            <Icon
                                tabIndex={0}
                                icon={"TrashIcon"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleRemoveClick(requirement);
                                }}
                                className="w-6 h-6 cursor-pointer hover:text-gray-900"
                            />
                        </div>
                    </td>
                )}
            </tr>
        </>
    );
}
