import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Avatar from "../../../components/Avatar";
import ButtonSquared from "../../../components/ButtonSquare";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import {
    ButtonSize,
    OrganizationConsortia,
    OrganizationConsortiaOptions,
    OrganizationTypeOptions,
    PAGE_SECTIONS,
    UserRoleOnOrganization,
    UserStatusOnOrganization,
} from "../../../constants";
import { isLoadingSelector } from "../../layout";
import {
    leaveOrganization,
    removeOrganization,
    selectedOrganization,
} from "../organizationSlice";
import OrganizationInviteActions from "./InviteActions";
import { useSelector } from "react-redux";
import { setCurrentSection } from "../../layout/layoutSlice";
import defaultImage from "../../../assets/images/placeholder.png";
interface OrganizationDetailProps {
    toggleEdit?: any;
}

export default function OrganizationDetail(props: OrganizationDetailProps) {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const organization = useAppSelector(selectedOrganization);
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const isLoading = useAppSelector(isLoadingSelector);
    const navigate = useNavigate();
    //const hidrated = useAppSelector(selectOrganizationsHidrated);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    const isOwner = organization?.role === UserRoleOnOrganization.OWNER;
    const canLeave = organization?.users?.some(
        (user: { userEmail: string; status: UserStatusOnOrganization }) => {
            return (
                user.userEmail === userProfile?.email &&
                user.status === UserStatusOnOrganization.CONFIRMED
            );
        }
    );
    const pendingConfirmation =
        organization?.status === UserStatusOnOrganization.PENDING;
    const toggleDeleteModal = () => {
        setDeleteModalOpen((prevState) => {
            return !prevState;
        });
    };
    const toogleLeaveModal = () => {
        setLeaveModalOpen((prevState) => {
            return !prevState;
        });
    };
    const handleDelete = () => {
        if (organization?.id) {
            dispatch(removeOrganization(organization?.id));
            toggleDeleteModal();
            navigate("/organizations");
        }
        return;
    };
    const handleLeave = async () => {
        if (organization?.id) {
            await dispatch(leaveOrganization(organization?.id));
            toogleLeaveModal();
            navigate("/organizations");
        }
        return;
    };

    const handleClickEdit = () => {
        navigate("edit");
    };

    const consortia = useMemo(() => {
        const splitted = organization?.consortia?.split(",");
        if (splitted?.length > 1) {
            return splitted
                ?.map((cons: any) =>
                    cons === OrganizationConsortia.OTHER
                        ? organization?.consortiaOther
                        : OrganizationConsortiaOptions.find(
                              (opt) => opt.value === cons
                          )?.label
                )
                ?.join(", ");
        } else {
            return organization?.consortia === OrganizationConsortia.OTHER
                ? organization?.consortiaOther
                : OrganizationConsortiaOptions.find(
                      (opt) => opt.value === organization?.consortia
                  )?.label;
        }
    }, [organization?.consortia, organization?.consortiaOther]);
    return isLoading || !organization?.id ? null : (
        <div className="w-full grid grid-cols-3 gap-x-4 lg:grid-cols-6 sm:py-6 flex-wrap !pt-0">
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`Are you sure you want to remove ${organization?.name}? You can make owner another member of the organization`}
                handleCancel={toggleDeleteModal}
                handleSubmit={handleDelete}
            />
            <ModalConfirmation
                open={leaveModalOpen}
                title="Are you sure?"
                description={`Are you sure you want to leave ${organization?.name} organization? Leaving this organization you won't have access to the trips and info either anymore`}
                handleCancel={toogleLeaveModal}
                handleSubmit={handleLeave}
                okButtonText="Leave"
            />
            {pendingConfirmation ? (
                <OrganizationInviteActions organizationId={organization?.id} />
            ) : (
                <div className="w-full col-span-6 flex justify-end gap-4">
                    {(isOwner || userProfile?.isAdmin) && (
                        <div>
                            <ButtonSquared
                                outlined
                                size={ButtonSize.SMALL}
                                onClick={toggleDeleteModal}
                                label="Delete"
                            />
                        </div>
                    )}
                    {!isOwner && canLeave && (
                        <div>
                            <ButtonSquared
                                outlined
                                size={ButtonSize.SMALL}
                                onClick={toogleLeaveModal}
                                label="Leave"
                            />
                        </div>
                    )}
                    {(organization?.canEdit || userProfile?.isAdmin) && (
                        <div>
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                onClick={handleClickEdit}
                                label="Edit"
                            />
                        </div>
                    )}
                </div>
            )}

            <div className="text-left break-words col-span-6 mt-6">
                <div className="flex flex-row">
                    <Avatar
                        squared
                        src={
                            organization?.image_thumb ||
                            organization?.image ||
                            defaultImage
                        }
                        className="w-24 h-24"
                    />
                    <div className="flex flex-col ml-6">
                        <div className="text-neutral-900 text-xl font-semibold">
                            {organization?.name}
                        </div>
                        <div className="text-neutral-800 font-semibold">
                            {
                                OrganizationTypeOptions.find((org) => {
                                    return org.value === organization?.type;
                                })?.label
                            }
                        </div>
                    </div>
                </div>
            </div>
            {organization?.hotelGroup?.name && (
                <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        Hotel Group
                    </span>
                    {organization?.hotelGroup?.name}
                </div>
            )}
            {organization?.cruiseLine?.name && (
                <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        Cruise Line
                    </span>
                    {organization?.cruiseLine?.name}
                </div>
            )}
            {organization?.hostAgency?.name && (
                <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        Host Agency
                    </span>
                    {organization?.hostAgency?.name}
                </div>
            )}
            <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                <span className="block font-medium text-neutral-600 mb-1 text-xs">
                    Phone number
                </span>
                {organization?.phone}
            </div>
            {organization?.website && (
                <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        Website
                    </span>

                    <a
                        href={organization?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {organization?.website}
                    </a>
                </div>
            )}
            <div className="text-left lg:max-w-xs break-words col-span-6 lg:col-span-3 mt-6">
                <span className="block font-medium text-neutral-600 mb-1 text-xs">
                    Email address
                </span>
                {organization?.email}
            </div>
            {organization?.consortia && (
                <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        Consortia
                    </span>
                    {consortia}
                </div>
            )}
            {organization?.agencyIATA && (
                <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        IATA
                    </span>
                    {organization?.agencyIATA}
                </div>
            )}
            {organization?.agencyCLIA && (
                <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                    <span className="block font-medium text-neutral-600 mb-1 text-xs">
                        CLIA
                    </span>
                    {organization?.agencyCLIA}
                </div>
            )}
        </div>
    );
}
