import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import RadioBox from "../../../components/Radio/Radio";
import { updateSettings } from "../../authentication/userSlice";

export const TimeSettings = () => {
    const { settings } = useAppSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const changeTimeFormat = (value: boolean) => {
        dispatch(
            updateSettings({
              otherSettings: {...settings?.otherSettings, timeFormat24Hour: value},
            }),
          );
    };
    return (
        <>
            <div className="flex flex-col">
                <div className="text-base font-semibold text-neutral-800 mb-2">
                    Personal Time Format
                </div>
                <div className="flex flex-col gap-2">
                    <RadioBox
                        value={settings?.otherSettings?.timeFormat24Hour}
                        checked={!settings?.otherSettings?.timeFormat24Hour}
                        label={"12 Hour"}
                        onChange={() => changeTimeFormat(false)}
                    />
                    <RadioBox
                        value={settings?.otherSettings?.timeFormat24Hour}
                        checked={!!settings?.otherSettings?.timeFormat24Hour}
                        label={"24 Hour"}
                        onChange={() => changeTimeFormat(true)}
                    />
                    {settings?.otherSettings?.timeFormat24Hour}
                </div>
            </div>
        </>
    );
};
