import _ from "lodash";
import { UserTypeVariant } from "../components/UserType/UserType";
import config from "../config";
import {
    PROFILE_MENU_OPTIONS,
    TRIP_MENU_OPTIONS,
    ADMIN_MENU_OPTIONS,
    ORGANIZATION_MENU_OPTIONS,
} from "./menu";

import createTripImage from "../assets/images/support/create_trip.png";
import documentsImage from "../assets/images/support/documents.png";
import filesImage from "../assets/images/support/files.png";
import galleryImage from "../assets/images/support/gallery.png";
import homeImage from "../assets/images/support/home.png";
import itineraryImage from "../assets/images/support/itinerary.png";
import participantsImage from "../assets/images/support/participants.png";
import tripImage from "../assets/images/support/trip.png";
import tripNotificationsImage from "../assets/images/support/trip_notifications.png";
import activityInsightImage from "../assets/images/support/activity_insights.png";
import userPlanImage from "../assets/images/support/user_plan.png";
import userPreferencesImage from "../assets/images/support/preferences.png";
import manageAccountImage from "../assets/images/support/manage_account.png";
import organizationDetailsImage from "../assets/images/support/organization_details.png";
import organizationsImage from "../assets/images/support/organizations.png";
import profileImage from "../assets/images/support/user_profile.png";

export enum PAGE_SECTIONS {
    SIGN_IN = "SIGN_IN",
    TRIP_INVITE_GUEST = "TRIP_INVITE_GUEST",
    SIGN_UP = "SIGN_UP",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    HOME = "HOME",
    PROFILE = "PROFILE",
    USER_PROFILE = "USER_PROFILE",
    MANAGE_ACCOUNT = "MANAGE_ACCOUNT",
    TRIPS = "TRIPS",
    TRIP = "TRIP",
    PENDING_TRIP = "PENDING_TRIP",
    TRIP_EDIT = "TRIP_EDIT",
    TRIP_NEW = "TRIP_NEW",
    ORGANIZATIONS = "ORGANIZATIONS",
    ORGANIZATION = "ORGANIZATION",
    ORGANIZATION_MEMBERS = "ORGANIZATION_MEMBERS",
    ORGANIZATION_TRIPS = "ORGANIZATION_TRIPS",
    ORGANIZATION_LIBRARY = "ORGANIZATION_LIBRARY",
    ORGANIZATION_LIBRARY_DETAILS = "ORGANIZATION_LIBRARY_DETAILS",
    ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL = "ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL",
    ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT = "ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT",
    ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT = "ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT",
    ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER = "ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER",
    ORGANIZATION_CHAT = "ORGANIZATION_CHAT",
    TRIP_PARTICIPANTS = "TRIP_PARTICIPANTS",
    TRIP_MESSAGES = "TRIP_MESSAGES",
    TRIP_ITINERARY = "TRIP_ITINERARY",
    TRIP_ITINERARY_HOTEL = "TRIP_ITINERARY_HOTEL",
    TRIP_ITINERARY_VISIT = "TRIP_ITINERARY_VISIT",
    TRIP_ITINERARY_PUBLIC_REVIEW = "TRIP_ITINERARY_PUBLIC_REVIEW",
    TRIP_ITINERARY_PRIVATE_REVIEW = "TRIP_ITINERARY_PRIVATE_REVIEW",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS",
    TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON = "TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON",
    TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS = "TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS",
    TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS = "TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS",
    TRIP_ITINERARY_OTHER = "TRIP_ITINERARY_OTHER",
    TRIP_ITINERARY_RESTAURANT = "TRIP_ITINERARY_RESTAURANT",
    TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS = "TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS",
    TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE = "TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE",
    TRIP_LOCAL_PARTNERS = "TRIP_LOCAL_PARTNERS",
    TRIP_GALLERY = "TRIP_GALLERY",
    TRIP_FILES = "TRIP_FILES",
    TRIP_FILES_EDIT = "TRIP_FILES_EDIT",
    TRIP_REQUIREMENTS = "TRIP_REQUIREMENTS",
    TRIP_CHAT = "TRIP_CHAT",
    TRIP_CONTACTS = "TRIP_CONTACTS",
    TRIP_NOTIFICATIONS = "TRIP_NOTIFICATIONS",
    TRIP_CUSTOM_INVITE = "TRIP_CUSTOM_INVITE",
    NOTIFICATIONS_USER = "NOTIFICATIONS_USER",
    PREFERENCES_USER = "PREFERENCES_USER",
    PLAN_USER = "PLAN_USER",
    INSIGHTS = "INSIGHTS",
    ADMIN_OVERVIEW = "ADMIN_OVERVIEW",
    ADMIN_USERS = "ADMIN_USERS",
    ADMIN_TRIPS = "ADMIN_TRIPS",
    ADMIN_INCOMING_TRIPS = "ADMIN_INCOMING_TRIPS",
    ADMIN_INSIGHTS = "ADMIN_INSIGHTS",
    ADMIN_REVIEWS = "ADMIN_REVIEWS",
    ADMIN_HOTELS = "ADMIN_HOTELS",
    ADMIN_MODULES = "ADMIN_MODULES",
    ADMIN_MODULES_SUPPORT = "ADMIN_MODULES_SUPPORT",
    ADMIN_RESTAURANTS = "ADMIN_RESTAURANTS",
    ADMIN_OTHERS = "ADMIN_OTHERS",
    ADMIN_COMPLAINTS = "ADMIN_COMPLAINTS",
    ADMIN_TRANSFERS = "ADMIN_TRANSFERS",
    TRIP_ACCESS = "TRIP_ACCESS",
    ADMIN_PLANS = "ADMIN_PLANS",
    ADMIN_ALL_TRANSFER = "ADMIN_ALL_TRANSFER",
    SETTINGS = "SETTINGS",
    CHAT = "CHAT",
}

export enum ButtonType {
    NORMAL = "NORMAL",
    OUTLINED = "OUTLINED",
}

export const TripStatus = {
    DELETED: "DELETED",
    CANCELLED: "CANCELLED",
    IN_PROGRESS: "IN_PROGRESS",
    ACTIVE: "ACTIVE",
    FINISHED: "FINISHED",
    FUTURE: "FUTURE",
};

export const TripFilters = {
    ALL: "ALL",
    ACTIVE: "ACTIVE",
    PAST: "PAST",
};

export enum ButtonSize {
    NORMAL = "NORMAL",
    SMALL = "SMALL",
    FIT = "FIT",
}

export const enum ActivityType {
    VISIT = "VISIT",
    HOTEL = "HOTEL",
    RESTAURANT = "RESTAURANT",
    INSIGHT = "INSIGHT",
    FLIGHT = "FLIGHT",
    OTHER = "OTHER",
    MODULE = "MODULE",
}

export const enum ComplaintType {
    REVIEW = "REVIEW",
    INSIGHT = "INSIGHT",
    USER = "USER",
    USER_ROLE = "USER_ROLE",
    USER_EMAIL_CHANGE = "USER_EMAIL_CHANGE",
    USER_FEEDBACK = "USER_FEEDBACK",
}

export const ComplaintTypeToShow = {
    [ComplaintType.REVIEW]: "Review",
    [ComplaintType.INSIGHT]: "Insight",
    [ComplaintType.USER]: "User",
    [ComplaintType.USER_ROLE]: "Change user Role",
    [ComplaintType.USER_EMAIL_CHANGE]: "Change Email",
    [ComplaintType.USER_FEEDBACK]: "User feedback",
};

export const ComplaintReasonsOptions = [
    { label: "Spam", value: "Spam" },
    { label: "Inappropriate Bio", value: "Inappropriate Bio" },
    { label: "Fake Profile", value: "Fake Profile" },
    { label: "Nudity or sexual activity", value: "Nudity or sexual activity" },
    { label: "Hate speech or symbols", value: "Hate speech or symbols" },
    { label: "Violence or Weaponry", value: "Violence or Weaponry" },
    { label: "Mean or Cyberbullying", value: "Mean or Cyberbullying" },
    { label: "Bullying or harassment", value: "Bullying or harassment" },
    {
        label: "Selling illegal or regulated goods",
        value: "Selling illegal or regulated goods",
    },
    {
        label: "Intellectual property violations",
        value: "Intellectual property violations",
    },
    { label: "Suicide or self-injury", value: "Suicide or self-injury" },
    {
        label: "Violence or dangerous organizations",
        value: "Violence or dangerous organizations",
    },
    { label: "Eating disorders", value: "Scams or fraud" },
    { label: "False information", value: "False information" },
    { label: "Other", value: "Other" },
];

export const enum ComplaintStatus {
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
    UNDER_REVIEW = "UNDER_REVIEW",
}
export const enum PlanStatus {
    ACTIVE = "ACTIVE",
    EXPIRING_SOON = "EXPIRING SOON",
    EXPIRING_TRIAL = "EXPIRING TRIAL",
    EXPIRED = "EXPIRED",
}

export const enum UserStatus {
    ACTIVE,
    DELETED,
    BLOCKED,
    INACTIVE,
}

export const enum DocumentRequirementsStatus {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
}

export const enum UserRoleOnTrip {
    OWNER = "OWNER",
    ADMIN = "ADMIN",
    MEMBER = "MEMBER",
    VIEWER = "VIEWER",
}

export const enum UserRoleOnSharedInsight {
    VIEWER = "VIEWER",
}

export const enum SupportPlatform {
    WEB = "WEB",
    MOBILE = "MOBILE",
}

export const EditUserRoleOnTrip = [UserRoleOnTrip.OWNER, UserRoleOnTrip.ADMIN];
export const enum UserStatusOnTrip {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
    BLOCKED = "BLOCKED",
}

export const enum StripeStatus {
    SUCCEEDED = "succeeded",
    ACTIVE = "active",
    CANCELED = "canceled",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    PAST_DUE = "past_due",
    TRIALING = "trialing",
    UNPAID = "unpaid",
}

export const enum PlanDetailsStatus {
    PENDING = "PENDING",
    PAID = "PAID",
    FREE = "FREE",
}

export const enum GeneralStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
    BLOCKED = "BLOCKED",
    NOT_SUBMITTED = "NOT_SUBMITTED",
    AWAITING_APPROVAL = "AWAITING_APPROVAL",
    ACCEPTED = "ACCEPTED",
    UNDER_REVIEW = "UNDER_REVIEW",
    FREE = "FREE",
    PAID = "PAID",
    ACTIVE = "ACTIVE",
    CANCELED = "CANCELED",
    INCOMPLETE = "INCOMPLETE",
    INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
    PAST_DUE = "PAST_DUE",
    TRIALING = "TRIALING",
    UNPAID = "UNPAID",
    SUCCEEDED = "SUCCEEDED",
}

export const StatusBadge = {
    [GeneralStatus.APPROVED as any]: {
        text: "Approved",
        color: "green",
    },
    [GeneralStatus.CONFIRMED as any]: {
        text: "Confirmed",
        color: "green",
    },
    [GeneralStatus.ACCEPTED as any]: {
        text: "Accepted",
        color: "green",
    },
    [GeneralStatus.PENDING as any]: {
        text: "Pending",
        color: "gray",
    },
    [GeneralStatus.AWAITING_APPROVAL as any]: {
        text: "Awaiting approval",
        color: "gray",
    },
    [GeneralStatus.UNDER_REVIEW as any]: {
        text: "Under Review",
        color: "gray",
    },
    [GeneralStatus.REJECTED as any]: {
        text: "Rejected",
        color: "orange",
    },
    [GeneralStatus.EXPIRED as any]: {
        text: "Expired",
        color: "red",
    },
    [GeneralStatus.NOT_SUBMITTED as any]: {
        text: "Not submitted",
        color: "red",
    },
    [GeneralStatus.BLOCKED as any]: {
        text: "Suspended",
        color: "red",
    },
    [GeneralStatus.FREE as any]: {
        text: "Free",
        color: "gray",
    },
    [GeneralStatus.PAID as any]: {
        text: "Paid",
        color: "green",
    },
    [GeneralStatus.ACTIVE as any]: {
        text: "Active",
        color: "green",
    },
    [GeneralStatus.SUCCEEDED as any]: {
        text: "Success",
        color: "green",
    },
    [GeneralStatus.CANCELED as any]: {
        text: "Canceled",
        color: "red",
    },
    [GeneralStatus.INCOMPLETE as any]: {
        text: "Incomplete",
        color: "gray",
    },
    [GeneralStatus.INCOMPLETE_EXPIRED as any]: {
        text: "Incomplete Expired",
        color: "red",
    },
    [GeneralStatus.PAST_DUE as any]: {
        text: "Past Due",
        color: "red",
    },
    [GeneralStatus.TRIALING as any]: {
        text: "Trialing",
        color: "green",
    },
    [GeneralStatus.UNPAID as any]: {
        text: "Unpaid",
        color: "red",
    },
};

export const enum TripType {
    SIMPLE = "SIMPLE",
    ORGANIZER = "ORGANIZER",
}
export interface AddHotelRequest {
    name: string;
    website: string;
    lat: number;
    lng: number;
    streetAddress: string;
    streetNumber: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    phone: string;
    fullAddress: string;
}
export const enum OperationTypes {
    UPDATE = "UPDATE",
    ADD = "ADD",
}

export const TripUserRoleOptions = [
    { label: "Admin", value: UserRoleOnTrip.ADMIN },
    { label: "Advisor", value: UserRoleOnTrip.MEMBER },
    { label: "Viewer", value: UserRoleOnTrip.VIEWER },
    { label: "Owner", value: UserRoleOnTrip.OWNER },
];

export const TripUserRoleOptionsWithoutOwner = TripUserRoleOptions.filter(
    (uR) => uR.value !== UserRoleOnTrip.OWNER
);

export const ALL_OPTION_VALUE = "ALL";

export const FilePermissionOptions = [
    { label: "Admins", value: UserRoleOnTrip.ADMIN },
    { label: "All", value: ALL_OPTION_VALUE },
];
export enum UserRoleOnOrganization {
    OWNER = "OWNER",
    ADMIN = "ADMIN",
    MEMBER = "MEMBER",
}
export const EditionUserRoleOnOrganization = [
    UserRoleOnOrganization.OWNER,
    UserRoleOnOrganization.ADMIN,
];

export enum UserStatusOnOrganization {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    EXPIRED = "EXPIRED",
    DELETED = "DELETED",
    BLOCKED = "BLOCKED",
}

export enum OrganizationType {
    HOTEL_CHAIN = "HOTEL_CHAIN",
    HOTEL_INDIVIDUAL = "HOTEL_INDIVIDUAL",
    MARKETING_COMPANY = "MARKETING_COMPANY",
    DMC = "DMC",
    TOUR_OPERATOR = "TOUR_OPERATOR",
    TOURIST_BOARD = "TOURIST_BOARD",
    AGENCY = "AGENCY",
    PERSONAL = "PERSONAL",
    // GOVERNMENT_ENTITY = "GOVERNMENT_ENTITY",
    OTHER = "OTHER",
    WHOLESALER = "WHOLESALER",
}

export enum OrganizationConsortia {
    VIRTUOSO = "VIRTUOSO",
    SIGNATURE = "SIGNATURE",
    ENSEMBLE = "ENSEMBLE",
    TRAVELER_MADE = "TRAVELER_MADE",
    AMEX_CENTURION = "AMEX_CENTURION",
    OTHER = "OTHER",
}

export enum PlanType {
    ORGANIZATION = "ORGANIZATION",
    USER = "USER",
    HOTEL = "HOTEL",
}

export const OrganizationConsortiaOptions = [
    { value: OrganizationConsortia.VIRTUOSO, label: "Virtuoso" },
    { value: OrganizationConsortia.SIGNATURE, label: "Signature" },
    { value: OrganizationConsortia.ENSEMBLE, label: "Ensemble" },
    { value: OrganizationConsortia.TRAVELER_MADE, label: "Traveler Made" },
    { value: OrganizationConsortia.AMEX_CENTURION, label: "Amex Centurion" },
    { value: OrganizationConsortia.OTHER, label: "Other" },
    { value: null, label: "No Consortia" },
];

export const OrganizationTypeOptions = [
    ..._.sortBy(
        [
            {
                value: OrganizationType.AGENCY,
                label: "Agency/Advisor/Agency Team Member",
            },
            {
                value: OrganizationType.HOTEL_INDIVIDUAL,
                label: "Individual Hotel",
            },
            { value: OrganizationType.HOTEL_CHAIN, label: "Hotel Group" },
            { value: OrganizationType.DMC, label: "DMC" },
            {
                value: OrganizationType.MARKETING_COMPANY,
                label: "Representation/Marketing Company",
            },
            { value: OrganizationType.TOUR_OPERATOR, label: "Tour Operator" },
            { value: OrganizationType.TOURIST_BOARD, label: "Tourist Board" },
            { value: OrganizationType.WHOLESALER, label: "Wholesaler" },
            { value: OrganizationType.PERSONAL, label: "Personal" },
        ],
        (a) => a.label
    ),
    { value: OrganizationType.OTHER, label: "Other" },
];

export const OrganizationUserRoleOptions = [
    //{ value: UserRoleOnOrganization.OWNER, label: "Owner" },
    { value: UserRoleOnOrganization.ADMIN, label: "Admin" },
    { value: UserRoleOnOrganization.MEMBER, label: "Member" },
];

export enum FitnessRangeValues {
    NONE = 0,
    BEGINNER = 1,
    MEDIUM = 2,
    ADVANCE = 3,
    EXPERT = 4,
}

export const FitnessRangeOptions = [
    { label: "None", value: FitnessRangeValues.NONE },
    { label: "Beginner", value: FitnessRangeValues.BEGINNER },
    { label: "Medium", value: FitnessRangeValues.MEDIUM },
    { label: "Advance", value: FitnessRangeValues.ADVANCE },
    { label: "Expert", value: FitnessRangeValues.EXPERT },
];

export interface ILocation {
    fullAddress?: string;
    streetAddress?: string;
    streetNumber?: string;
    city?: string;
    state?: string;
    country?: string;
    zipCode?: string;
    lat?: string;
    lng?: string;
    name?: string;
    googlePlaceId?: string;
    images?: Array<any>;
    website?: string;
}

export interface IMarker {
    position: google.maps.LatLng;
    title?: string;
}

export interface IRestaurantBody {
    type: RestauranteType;
    name: string;
    description?: string;
    phone?: string;
    contact?: string;
    lat: number;
    lng: number;
    streetAddress: string;
    streetNumber: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    fullAddress: string;
    googlePlaceId?: string;
    cuisine?: RestaurantCuisine;
    specialties?: string;
    expenseComments?: string;
    foodComments?: string;
    drinkComments?: string;
    serviceComments?: string;
    insiderTips?: string;
}

export interface IRestaurantUpdateBody {
    type: RestauranteType;
    name: string;
    description?: string;
    phone?: string;
    contact?: string;
    lat: number;
    lng: number;
    streetAddress: string;
    streetNumber: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    fullAddress: string;
    googlePlaceId?: string;
    cuisine?: RestaurantCuisine;
    specialties?: string;
    expenseComments?: string;
    foodComments?: string;
    drinkComments?: string;
    serviceComments?: string;
    insiderTips?: string;
}
export interface ITripBody {
    title: string;
    description: string;
    startDate: Date;
    endDate: Date;
    tripType: string;
    image?: any;
}
export enum RestaurantCuisine {
    ITALIAN = "ITALIAN",
    INDIAN = "INDIAN",
    AMERICAN = "AMERICAN",
    FRENCH = "FRENCH",
    MEXICAN = "MEXICAN",
    LATIN_AMERICAN = "LATIN_AMERICAN",
    STEACKHOUSE = "STEACKHOUSE",
    SEAFOOD = "SEAFOOD",
    VEGETARIAN = "VEGETARIAN",
    VEGAN = "VEGAN",
    JAPANESE = "JAPANESE",
    THAI = "THAI",
    AFRICAN = "AFRICAN",
    MOROCCAN = "MOROCCAN",
    GREEK = "GREEK",
    GERMAN = "GERMAN",
    FUSION = "FUSION",
    OTHER = "OTHER",
}

export const restaurantCuisineOptions = [
    { value: "ITALIAN", label: "Italian" },
    { value: "INDIAN", label: "Indian" },
    { value: "AMERICAN", label: "American" },
    { value: "FRENCH", label: "French" },
    { value: "MEXICAN", label: "Mexican" },
    { value: "LATIN_AMERICAN", label: "Latin American" },
    { value: "STEACKHOUSE", label: "Steak House" },
    { value: "SEAFOOD", label: "Sea Food" },
    { value: "VEGETARIAN", label: "Vegetarian" },
    { value: "VEGAN", label: "Vegan" },
    { value: "JAPANESE", label: "Japanese" },
    { value: "THAI", label: "Thai" },
    { value: "AFRICAN", label: "African" },
    { value: "MOROCCAN", label: "Moroccan" },
    { value: "GREEK", label: "Greek" },
    { value: "GERMAN", label: "German" },
    { value: "FUSION", label: "Fusion" },
    { value: "OTHER", label: "Other" },
];

export const restaurantAmbianceOptions = [
    { label: "Trendy", value: "TRENDY" },
    { label: "Energetic", value: "ENERGETIC" },
    { label: "Loud", value: "LOUD" },
    { label: "Quiet", value: "QUIET" },
    { label: "Romantic", value: "ROMANTIC" },
    { label: "Casual", value: "CASUAL" },
    { label: "Upscale", value: "UPSCALE" },
    { label: "White Glove", value: "WHITE_GLOVE" },
    { label: "Stuffy", value: "STUFFY" },
    { label: "Relaxed", value: "RELAXED" },
    { label: "Local", value: "LOCAL" },
    { label: "Authentic", value: "AUTHENTIC" },
    { label: "Formulaic", value: "FORMULAIC" },
    { label: "Outdoor seating", value: "OUTDOOR_SEATING" },
];

export enum RestauranteType {
    BAR = "BAR",
    RESTAURANT = "RESTAURANT",
}

export const restauranteTypeOptions = [
    { label: "BAR", value: "BAR" },
    { label: "RESTAURANT", value: "RESTAURANT" },
];

export const tripTypeOptions = [
    { value: TripType.ORGANIZER, label: "Hosted by supplier" },
    { value: TripType.SIMPLE, label: "Self-directed" },
];

export const tripTypeSelectOptions = [
    { value: "ALL", label: "ALL" },
    { value: TripType.ORGANIZER, label: "Hosted by supplier" },
    { value: TripType.SIMPLE, label: "Self-directed" },
];

export const activityTypeOnModel = {
    [ActivityType.HOTEL]: "hotelActivity",
    [ActivityType.RESTAURANT]: "restaurantActivity",
    [ActivityType.OTHER]: "otherActivity",
};

export enum LOCAL_PARTNER_TYPE {
    DMC = "DMC",
    TOURISM_BOARD = "TOURISM_BOARD",
    TOUR_COMPANY = "TOUR_COMPANY",
    TOUR_GUIDE = "TOUR_GUIDE",
    VILLA_COMPANY = "VILLA_COMPANY",
    TRANSPORTATION_COMPANY = "TRANSPORTATION_COMPANY",
    OTHER = "OTHER",
}

export const localPartnerTypeOptions = [
    { value: LOCAL_PARTNER_TYPE.DMC, label: "DMC" },
    { value: LOCAL_PARTNER_TYPE.TOURISM_BOARD, label: "Tourism Board" },
    { value: LOCAL_PARTNER_TYPE.TOUR_COMPANY, label: "Tour Company" },
    { value: LOCAL_PARTNER_TYPE.TOUR_GUIDE, label: "Tour Guide" },
    {
        value: LOCAL_PARTNER_TYPE.TRANSPORTATION_COMPANY,
        label: "Transportation Company",
    },
    { value: LOCAL_PARTNER_TYPE.VILLA_COMPANY, label: "Villa Company" },
    { value: LOCAL_PARTNER_TYPE.OTHER, label: "Other" },
];

export interface ITripLocalPartner {
    images?: object;
    contacts?: object;
    startDate: Date;
    endDate: Date;
    localPartnerId: number;
}

export const SocialUserNameValidationRegex = /^[A-Za-z0-9_\-.]*$/;
export const InstagramUrlRegex =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/?(?:[A-Za-z0-9][A-Za-z0-9_\-.]*\/)*([A-Za-z0-9][A-Za-z0-9_\-.]*)/;
export const LinkedinUrlRegex =
    /(?:(?:http|https):\/\/)?(?:[a-z]{2,3}\.)?(?:linkedin.com)\/?(?:[A-Za-z0-9][A-Za-z0-9_\-.]*\/)*([A-Za-z0-9][A-Za-z0-9_\-.]*)/;
export const FacebookUrlRegex =
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[A-Za-z0-9_\-.]*\/)*([A-Za-z0-9_\-.]*)/;

export const LayoutSectionMenuItems: any = {
    [PAGE_SECTIONS.PROFILE]: PROFILE_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_MEMBERS]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_TRIPS]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_LIBRARY]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL]:
        ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT]:
        ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT]:
        ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER]:
        ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.ORGANIZATION_CHAT]: ORGANIZATION_MENU_OPTIONS,
    [PAGE_SECTIONS.MANAGE_ACCOUNT]: PROFILE_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_CONTACTS]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_FILES]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_FILES_EDIT]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_NOTIFICATIONS]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_GALLERY]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_ITINERARY]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_ITINERARY_VISIT]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_ITINERARY_OTHER]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_LOCAL_PARTNERS]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_PARTICIPANTS]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_REQUIREMENTS]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_CHAT]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_CUSTOM_INVITE]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.PREFERENCES_USER]: PROFILE_MENU_OPTIONS,
    [PAGE_SECTIONS.PLAN_USER]: PROFILE_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_INCOMING_TRIPS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_TRIPS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_USERS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_INSIGHTS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_REVIEWS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_PLANS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_HOTELS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_MODULES]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_MODULES_SUPPORT]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_RESTAURANTS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_OTHERS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_OVERVIEW]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_COMPLAINTS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_TRANSFERS]: ADMIN_MENU_OPTIONS,
    [PAGE_SECTIONS.TRIP_MESSAGES]: TRIP_MENU_OPTIONS,
    [PAGE_SECTIONS.ADMIN_ALL_TRANSFER]: ADMIN_MENU_OPTIONS,

};

export const LayoutSectionHeader: any = {
    [PAGE_SECTIONS.PROFILE]: {
        description: "Personal Information",
        icon: "ProfileIcon",
        title: "Profile",
    },
    [PAGE_SECTIONS.PREFERENCES_USER]: {
        description: "Manage user preferences",
        icon: "PreferencesIcon",
        title: "Preferences",
    },
    [PAGE_SECTIONS.MANAGE_ACCOUNT]: {
        description: "Manage account",
        icon: "AccountSettingsIcon",
        title: "Account",
    },
    [PAGE_SECTIONS.ORGANIZATIONS]: {
        description: "Manage your organizations",
        icon: "OrganizationIcon",
        title: "Organizations",
    },
    [PAGE_SECTIONS.ORGANIZATION]: {
        description: "Manage your organization",
        icon: "OrganizationIcon",
        title: "Organization",
    },
    [PAGE_SECTIONS.ORGANIZATION_MEMBERS]: {
        description: "Manage your organization members",
        icon: "ParticipantsIcon",
        title: "Members",
    },
    [PAGE_SECTIONS.ORGANIZATION_TRIPS]: {
        description: "Manage your organization trips",
        icon: "TripIcon",
        title: "Trips",
    },
    [PAGE_SECTIONS.ORGANIZATION_LIBRARY]: {
        description: "Manage your library elements",
        icon: "LibraryIcon",
        title: "Library",
    },
    [PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS]: {
        description: "Manage your library elements",
        icon: "LibraryIcon",
        title: "Library",
    },
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL]: {
        description:
            "Save and reuse hotel details, including photos and contacts, for consistent fam trip planning.",
        icon: "BedIcon",
        title: "Hotel",
    },
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT]: {
        description:
            "Save and reuse site inspection details, including photos and contacts, for consistent fam trip planning.",
        icon: "BuildingIcon",
        title: "Site inspection",
    },
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT]: {
        description:
            "Save and reuse restaurant details, including photos and contacts, for consistent fam trip planning.",
        icon: "RestaurantIcon",
        title: "Restaurant",
    },
    [PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER]: {
        description:
            "Save and reuse other itinerary items, including photos and contacts, for consistent fam trip planning.",
        icon: "CalendarPlusIcon",
        title: "Other",
    },
    [PAGE_SECTIONS.TRIPS]: {
        description:
            "Create a new fam trip or manage any past or upcoming fams",
        icon: "TripIcon",
        title: "Trips",
        tooltip: `Click on the "Create New Trip" button to start a new Fam Trip. Or, click on any one of the trip tiles to access details about an active or past trip`,
    },
    [PAGE_SECTIONS.HOME]: {
        description:
            "Starting point for Fam Trip Management. Create a new Fam Trip, access current or past Fams, see notifications",
        tooltip: `Click on the "Create New Trip" button to start a new Fam Trip. Or, click on any one of the trip tiles or the "Show More" link to access details about an active or past trip. See the latest notifications from any of your trips at the lower right`,
        icon: "HomeIcon",
        title: "Home",
        grayBackground: true,
    },
    [PAGE_SECTIONS.TRIP]: {
        description:
            "Your Fam Trip dashboard. View or edit trip details with ease here",

        icon: "TripIcon",
        title: "Trip Details",
        tooltip: `Here at the Trip Details you'll find a "dashboard" to see parts of your trip at-a-glance. Click on each section to see more or to edit the information. You can also click on the left side to go to a specific section of the trip`,
        grayBackground: true,
    },
    [PAGE_SECTIONS.TRIP_EDIT]: {
        description: "Add information to your trip",
        icon: "TripIcon",
        title: "Edit trip",
    },
    [PAGE_SECTIONS.TRIP_PARTICIPANTS]: {
        description:
            "Advisors or others invited to, confirmed on or involved with this Fam Trip",
        icon: "ParticipantsIcon",
        title: "Participants",
        tooltip: `See everyone involved on your Fam Trip at-a-glance. Track participant progress: Monitor invitation acceptances, document submissions and details for each participant attending the Fam Trip`,
    },
    [PAGE_SECTIONS.TRIP_MESSAGES]: {
        description:
            "As Fam Administrators, send important messages or last-minute itinerary changes to the entire group in real time",
        icon: "MessageIcon",
        title: "Broadcast",
        tooltip: `Create a new message to send to the entire group, e.g. "Dinner tonight changed to 7 pm".  For now, this is a one-way communication from you to the participants.  Their ability to respond is coming soon!`,
    },
    [PAGE_SECTIONS.PENDING_TRIP]: {
        description: "Check trip information",
        icon: "TripIcon",
        title: "Trip Details",
    },
    [PAGE_SECTIONS.TRIP_NEW]: {
        description: "Add information to your trip",
        icon: "TripIcon",
        title: "Create new trip",
    },
    [PAGE_SECTIONS.TRIP_FILES]: {
        description:
            "Upload any marketing collateral or info you want Advisors to have, before, during and after the trip",
        icon: "FilesIcon",
        title: "Files",
        tooltip: `Provide any files you'd like Advisors to have: packing lists, country information, health information, marketing collateral, forms to fill in, press releases, entry requirements, etc. Click “Add File” to upload, name the document and describe why it's important`,
    },
    [PAGE_SECTIONS.TRIP_FILES_EDIT]: {
        description: "Upload the files you want to add",
        icon: "FilesIcon",
        title: "Files",
    },
    [PAGE_SECTIONS.TRIP_REQUIREMENTS]: {
        description:
            "Request and collect information that Fam participants are required to provide before travel",
        icon: "RequirementsIcon",
        title: "Required documents",
        tooltip: `You may need participants to provide passports, vaccine cards, flights, visa forms, waiver forms or other details. Create the requirement under "Add New", provide a deadline and a description, and participants will automatically be prompted and reminded to submit the required information. Click on a requirement in the list to see the status of that requirement for each participant. Approve or reject a participant's submission`,
    },
    [PAGE_SECTIONS.TRIP_GALLERY]: {
        description:
            "Capture and showcase Fam Trip moments: Upload photos and add descriptions to highlight places and events",
        icon: "GalleryIcon",
        title: "Shared photo gallery",
        tooltip: `Upload your best pre-trip or on-trip photos and share  with the entire group. All participants have access to all photos and can post them to social media from this gallery`,
    },
    [PAGE_SECTIONS.TRIP_ITINERARY]: {
        description:
            "Build or edit your Fam itinerary - Add Hotel Stay, site inspections, meals and more",
        icon: "ItineraryIcon",
        title: "Itinerary",
        tooltip: `On this page build and edit your detailed Fam Trip itinerary. Click the icons to add Hotel Stays, Hotel Visits, Restaurants / Dining, or other elements to the trip. To edit, click the itinerary element itself. This itinerary will be seen by advisors on your trip, who can do reviews of each aspect of the trip you add. To hide the itinerary from Advisors while you build, click "Hidden".  When ready to share, click "Publish".`,
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL]: {
        description: "Add element to your itinerary",
        icon: "BedIcon",
        title: "Hotel stay",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT]: {
        description: "Add element to your itinerary",
        icon: "RestaurantIcon",
        title: "Restaurant",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_VISIT]: {
        description: "Add a site inspection to your itinerary",
        icon: "SuitCaseIcon",
        title: "Site Inspection",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_OTHER]: {
        description: "Add element to your itinerary",
        icon: "CalendarPlusIcon",
        title: "Other",
    },
    [PAGE_SECTIONS.TRIP_NOTIFICATIONS]: {
        description:
            "Stay informed of new reviews, new requirements, participant document progress, itinerary additions and more",
        icon: "BellIcon",
        title: "Notifications",
        tooltip: `Keep track of how the trip is developing and what communications are happening between you as the organizer and the participants. Click on a notification to see more detail`,
    },
    [PAGE_SECTIONS.NOTIFICATIONS_USER]: {
        description:
            "Stay informed of new reviews, new requirements, participant document progress, itinerary additions and more",
        icon: "BellIcon",
        title: "Notifications",
    },
    [PAGE_SECTIONS.CHAT]: {
        description: "Write your own details and record your special intel",
        icon: "MessageIcon",
        title: "Chat",
        extraClassContent: " !pb-0 ",
        extraClassHeaders: " !pb-0 !px-6",
    },
    [PAGE_SECTIONS.TRIP_CUSTOM_INVITE]: {
        description: "Trip invite customization",
        icon: "TripIcon",
        title: "Custom Invitation",
    },
    [PAGE_SECTIONS.INSIGHTS]: {
        title: "Insights",
        icon: "InsightsIcon",
        description: "Write your own details and record your special intel",
    },
    [PAGE_SECTIONS.ADMIN_TRIPS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "TripIcon",
        title: "Admin Trips",
    },
    [PAGE_SECTIONS.ADMIN_INCOMING_TRIPS]: {
        description: "See all the incoming trips",
        icon: "TripIcon",
        title: "Incoming trips",
    },
    [PAGE_SECTIONS.ADMIN_USERS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "ParticipantsIcon",
        title: "Admin Users",
    },
    [PAGE_SECTIONS.ADMIN_INSIGHTS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "InsightsIcon",
        title: "Admin Insights",
    },
    [PAGE_SECTIONS.ADMIN_REVIEWS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "MessageStarIcon",
        title: "Admin Reviews",
    },
    [PAGE_SECTIONS.ADMIN_PLANS]: {
        description: "Manage all available plans",
        icon: "PaymentIcon",
        title: "Admin Plans",
    },
    [PAGE_SECTIONS.ADMIN_TRANSFERS]: {
        description: "",
        icon: "CreditCardIcon",
        title: "Analytics",
    },
    [PAGE_SECTIONS.ADMIN_HOTELS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "BuildingIcon",
        title: "Admin Hotels",
    },
    [PAGE_SECTIONS.ADMIN_MODULES]: {
        description: "CRUD modules FamGuru's workspace",
        icon: "ModuleVideoIcon",
        title: "Admin Modules",
    },
    [PAGE_SECTIONS.ADMIN_RESTAURANTS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "RestaurantIcon",
        title: "Admin Restaurants",
    },
    [PAGE_SECTIONS.ADMIN_OTHERS]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "CalendarPlusIcon",
        title: "Admin Others",
    },
    [PAGE_SECTIONS.ADMIN_OVERVIEW]: {
        description:
            "Have an in-depth look at all the metrics within FamGuru's workspace",
        icon: "AnalyticsIcon",
        title: "Overview",
    },
    [PAGE_SECTIONS.ADMIN_COMPLAINTS]: {
        description: "Manage user requests",
        icon: "AlertIcon",
        title: "Requests",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },

    [PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS]: {
        description: "Write your own details and record your special intel",
        icon: "InsightsIcon",
        title: "Insights",
    },
    [PAGE_SECTIONS.TRIP_ACCESS]: {
        description:
            "Complete your Personal Information, Dietary Requirements and Professional Details to have full access to the Trip",
        icon: "TripIcon",
        title: "Trip Access",
    },
    [PAGE_SECTIONS.PLAN_USER]: {
        description: "Your plan information",
        icon: "PaymentIcon",
        title: "Payment",
    },
    [PAGE_SECTIONS.ADMIN_MODULES_SUPPORT]: {
        description:
            "Add, edit and have an in-depth look at all the support videos live on FamGuru",
        icon: "HelpCircleOutlineIcon",
        title: "Videos",
    },
};

export const PAGE_ROWS = 15;
export const PAGE_ROWS_TRIP_LIST = 30;

export const userStatusOrganizationFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Confirmed", value: UserStatusOnOrganization.CONFIRMED },
    { label: "Declined", value: UserStatusOnOrganization.REJECTED },
    { label: "Pending", value: UserStatusOnOrganization.PENDING },
    { label: "Expired", value: UserStatusOnOrganization.EXPIRED },
];

export const userRoleOrganizationFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Admin", value: UserRoleOnOrganization.ADMIN },
    { label: "Member", value: UserRoleOnOrganization.MEMBER },
    { label: "Owner", value: UserRoleOnOrganization.OWNER },
];

export const userStatusTripFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Confirmed", value: UserStatusOnTrip.CONFIRMED },
    { label: "Declined", value: UserStatusOnTrip.REJECTED },
    { label: "Pending", value: UserStatusOnTrip.PENDING },
    { label: "Expired", value: UserStatusOnTrip.EXPIRED },
];

export const userRoleTripFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Admin", value: UserRoleOnTrip.ADMIN },
    { label: "Advisor", value: UserRoleOnTrip.MEMBER },
];

export const DocumentRequirementsFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Pending", value: GeneralStatus.PENDING },
    { label: "Approved", value: GeneralStatus.APPROVED },
    { label: "Rejected", value: GeneralStatus.REJECTED },
];

export const ActivityTypeFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Hotel", value: ActivityType.HOTEL },
    { label: "Restaurant", value: ActivityType.RESTAURANT },
    { label: "Other", value: ActivityType.OTHER },
];

export const ComplaintStatusFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Pending", value: ComplaintStatus.PENDING },
    { label: "Rejected", value: ComplaintStatus.REJECTED },
    { label: "Accepted", value: ComplaintStatus.ACCEPTED },
    { label: "Under Review", value: ComplaintStatus.UNDER_REVIEW },
];

export const ComplaintTypesFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Change Role", value: ComplaintType.USER_ROLE },
    { label: "Change email", value: ComplaintType.USER_EMAIL_CHANGE },
    { label: "User Feedback", value: ComplaintType.USER_FEEDBACK },
    { label: "Reported review", value: ComplaintType.REVIEW },
    { label: "Reported Insight", value: ComplaintType.INSIGHT },
    { label: "Reported user", value: ComplaintType.USER },
];

export const ComplaintStatusOptions = [
    { label: "Pending", value: ComplaintStatus.PENDING },
    { label: "Rejected", value: ComplaintStatus.REJECTED },
    { label: "Accepted", value: ComplaintStatus.ACCEPTED },
    { label: "Under Review", value: ComplaintStatus.UNDER_REVIEW },
];

export const UserRolesOptions = [
    { label: "Advisor", value: UserTypeVariant.ADVISOR },
    { label: "Supplier", value: UserTypeVariant.SUPPLIER },
];

export const SupportPlatformOptions = [
    { label: "All", value: "ALL" },
    { label: "Web", value: SupportPlatform.WEB },
    { label: "Mobile", value: SupportPlatform.MOBILE },
];

export const StatusTripOrganizationOptions = [
    { label: "All", value: TripFilters.ALL },
    { label: "Active", value: TripFilters.ACTIVE },
    { label: "Past", value: TripFilters.PAST },
];

export const FIREBASE_ERRORS: any = {
    "auth/wrong-password":
        "The password is invalid or the user does not have a password.",
    "auth/claims-too-large":
        "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.",
    "auth/email-already-exists":
        "The provided email is already in use by an existing user. Each user must have a unique email.",
    "auth/id-token-expired": "The provided Firebase ID token is expired.",
    "auth/id-token-revoked": "The Firebase ID token has been revoked.",
    "auth/insufficient-permission":
        "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.",
    "auth/invalid-argument":
        "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
    "auth/invalid-claims":
        "The custom claim attributes provided to setCustomUserClaims() are invalid.",
    "auth/invalid-creation-time":
        "The creation time must be a valid UTC date string.",
    "auth/invalid-disabled-field":
        "The provided value for the disabled user property is invalid. It must be a boolean.",
    "auth/invalid-display-name":
        "The provided value for the displayName user property is invalid. It must be a non-empty string.",
    "auth/invalid-email-verified":
        "The provided value for the emailVerified user property is invalid. It must be a boolean.",
    "auth/invalid-hash-algorithm":
        "The hash algorithm must match one of the strings in the list of supported algorithms.",
    "auth/invalid-hash-block-size":
        "The hash block size must be a valid number.",
    "auth/invalid-hash-derived-key-length":
        "The hash derived key length must be a valid number.",
    "auth/invalid-hash-key": "The hash key must a valid byte buffer.",
    "auth/invalid-hash-memory-cost":
        "The hash memory cost must be a valid number.",
    "auth/invalid-hash-parallelization":
        "The hash parallelization must be a valid number.",
    "auth/invalid-hash-rounds": "The hash rounds must be a valid number.",
    "auth/invalid-hash-salt-separator":
        "The hashing algorithm salt separator field must be a valid byte buffer.",
    "auth/invalid-id-token":
        "The provided ID token is not a valid Firebase ID token.",
    "auth/invalid-last-sign-in-time":
        "The last sign-in time must be a valid UTC date string.",
    "auth/invalid-page-token":
        "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.",
    "auth/invalid-password":
        "The provided value for the password user property is invalid. It must be a string with at least six characters.",
    "auth/invalid-password-hash":
        "The password hash must be a valid byte buffer.",
    "auth/invalid-password-salt":
        "The password salt must be a valid byte buffer",
    "auth/invalid-photo-url":
        "The provided value for the photoURL user property is invalid. It must be a string URL.",
    "auth/invalid-provider-data":
        "The providerData must be a valid array of UserInfo objects.",
    "auth/invalid-oauth-responsetype":
        "Only exactly one OAuth responseType should be set to true.",
    "auth/invalid-session-cookie-duration":
        "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
    "auth/invalid-uid":
        "The provided uid must be a non-empty string with at most 128 characters.",
    "auth/invalid-user-import": "The user record to import is invalid.",
    "auth/maximum-user-count-exceeded":
        "The maximum allowed number of users to import has been exceeded.",
    "auth/missing-hash-algorithm":
        "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.",
    "auth/missing-uid":
        "A uid identifier is required for the current operation.",
    "auth/missing-oauth-client-secret":
        "The OAuth configuration client secret is required to enable OIDC code flow.",
    "auth/phone-number-already-exists":
        "The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.",
    "auth/project-not-found":
        "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.",
    "auth/reserved-claims":
        "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.",
    "auth/session-cookie-expired":
        "The provided Firebase session cookie is expired.",
    "auth/session-cookie-revoked":
        "The Firebase session cookie has been revoked.",
    "auth/uid-already-exists":
        "The provided uid is already in use by an existing user. Each user must have a unique uid.",
    "auth/admin-restricted-operation":
        "This operation is restricted to administrators only.",
    "auth/app-not-authorized":
        "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    "auth/app-not-installed":
        "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.",
    "auth/captcha-check-failed":
        "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
    "auth/code-expired":
        "The SMS code has expired. Please re-send the verification code to try again.",
    "auth/cordova-not-ready": "Cordova framework is not ready.",
    "auth/cors-unsupported": "This browser is not supported.",
    "auth/credential-already-in-use":
        "This credential is already associated with a different user account.",
    "auth/custom-token-mismatch":
        "The custom token corresponds to a different audience.",
    "auth/requires-recent-login":
        "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
    "auth/dependent-sdk-initialized-before-auth":
        "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK.",
    "auth/dynamic-link-not-activated":
        "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
    "auth/email-change-needs-verification":
        "Multi-factor users must always have a verified email.",
    "auth/email-already-in-use":
        "The email address is already in use by another account.",
    "auth/emulator-config-failed":
        "Auth instance has already been used to make a network call. Auth can no longer be configured to use the emulator. Try calling 'connectAuthEmulator()' sooner.",
    "auth/expired-action-code": "The action code has expired.",
    "auth/cancelled-popup-request":
        "This operation has been cancelled due to another conflicting popup being opened.",
    "auth/internal-error": "An internal AuthError has occurred.",
    "auth/invalid-app-credential":
        "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    "auth/invalid-app-id":
        "The mobile app identifier is not registed for the current project.",
    "auth/invalid-user-token":
        "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    "auth/invalid-auth-event": "An internal AuthError has occurred.",
    "auth/invalid-verification-code":
        "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user.",
    "auth/invalid-continue-uri":
        "The continue URL provided in the request is invalid.",
    "auth/invalid-cordova-configuration":
        "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
    "auth/invalid-custom-token":
        "The custom token format is incorrect. Please check the documentation.",
    "auth/invalid-dynamic-link-domain":
        "The provided dynamic link domain is not configured or authorized for the current project.",
    "auth/invalid-email": "The email address is badly formatted.",
    "auth/invalid-emulator-scheme":
        "Emulator URL must start with a valid scheme (http:// or https://).",
    "auth/invalid-api-key":
        "Your API key is invalid, please check you have copied it correctly.",
    "auth/invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
    "auth/invalid-credential":
        "The supplied auth credential is malformed or has expired.",
    "auth/invalid-message-payload":
        "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
    "auth/invalid-multi-factor-session":
        "The request does not contain a valid proof of first factor successful sign-in.",
    "auth/invalid-oauth-provider":
        "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
    "auth/invalid-oauth-client-id":
        "The OAuth client ID provided is either invalid or does not match the specified API key.",
    "auth/unauthorized-domain":
        "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
    "auth/invalid-action-code":
        "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
    "auth/invalid-persistence-type":
        "The specified persistence type is invalid. It can only be local, session or none.",
    "auth/invalid-phone-number":
        "The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].",
    "auth/invalid-provider-id": "The specified provider ID is invalid.",
    "auth/invalid-recipient-email":
        "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
    "auth/invalid-sender":
        "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
    "auth/invalid-verification-id":
        "The verification ID used to create the phone auth credential is invalid.",
    "auth/invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
    "auth/missing-android-pkg-name":
        "An Android Package Name must be provided if the Android App is required to be installed.",
    "auth/auth-domain-config-required":
        "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
    "auth/missing-app-credential":
        "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
    "auth/missing-verification-code":
        "The phone auth credential was created with an empty SMS verification code.",
    "auth/missing-continue-uri":
        "A continue URL must be provided in the request.",
    "auth/missing-iframe-start": "An internal AuthError has occurred.",
    "auth/missing-ios-bundle-id":
        "An iOS Bundle ID must be provided if an App Store ID is provided.",
    "auth/missing-or-invalid-nonce":
        "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
    "auth/missing-multi-factor-info":
        "No second factor identifier is provided.",
    "auth/missing-multi-factor-session":
        "The request is missing proof of first factor successful sign-in.",
    "auth/missing-phone-number":
        "To send verification codes, provide a phone number for the recipient.",
    "auth/missing-verification-id":
        "The phone auth credential was created with an empty verification ID.",
    "auth/app-deleted": "This instance of FirebaseApp has been deleted.",
    "auth/multi-factor-info-not-found":
        "The user does not have a second factor matching the identifier provided.",
    "auth/multi-factor-auth-required":
        "Proof of ownership of a second factor is required to complete sign-in.",
    "auth/account-exists-with-different-credential":
        "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
    "auth/network-request-failed":
        "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.",
    "auth/no-auth-event": "An internal AuthError has occurred.",
    "auth/no-such-provider":
        "User was not linked to an account with the given provider.",
    "auth/null-user":
        "A null user object was provided as the argument for an operation which requires a non-null user object.",
    "auth/operation-not-allowed":
        "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
    "auth/operation-not-supported-in-this-environment":
        "This operation is not supported in the environment this application is running on. 'location.protocol' must be http, https or chrome-extension and web storage must be enabled.",
    "auth/popup-blocked":
        "Unable to establish a connection with the popup. It may have been blocked by the browser.",
    "auth/popup-closed-by-user":
        "The popup has been closed by the user before finalizing the operation.",
    "auth/provider-already-linked":
        "User can only be linked to one identity for the given provider.",
    "auth/quota-exceeded":
        "The project's quota for this operation has been exceeded.",
    "auth/redirect-cancelled-by-user":
        "The redirect operation has been cancelled by the user before finalizing.",
    "auth/redirect-operation-pending":
        "A redirect sign-in operation is already pending.",
    "auth/rejected-credential":
        "The request contains malformed or mismatching credentials.",
    "auth/second-factor-already-in-use":
        "The second factor is already enrolled on this account.",
    "auth/maximum-second-factor-count-exceeded":
        "The maximum allowed number of second factors on a user has been exceeded.",
    "auth/tenant-id-mismatch":
        "The provided tenant ID does not match the Auth instance's tenant ID",
    "auth/timeout": "The operation has timed out.",
    "auth/user-token-expired":
        "The user's credential is no longer valid. The user must sign in again.",
    "auth/too-many-requests":
        "We have blocked all requests from this device due to unusual activity. Try again later.",
    "auth/unauthorized-continue-uri":
        "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
    "auth/unsupported-first-factor":
        "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
    "auth/unsupported-persistence-type":
        "The current environment does not support the specified persistence type.",
    "auth/unsupported-tenant-operation":
        "This operation is not supported in a multi-tenant context.",
    "auth/unverified-email": "The operation requires a verified email.",
    "auth/user-cancelled":
        "The user did not grant your application the permissions it requested.",
    "auth/user-not-found":
        "There is no user record corresponding to this identifier. The user may have been deleted.",
    "auth/user-disabled":
        "The user account has been disabled by an administrator.",
    "auth/user-mismatch":
        "The supplied credentials do not correspond to the previously signed in user.",
    "auth/weak-password": "The password must be 6 characters long or more.",
    "auth/web-storage-unsupported":
        "This browser is not supported or 3rd party cookies and data may be disabled.",
    "auth/already-initialized":
        "initializeAuth() has already been called with different options. To avoid this error, call initializeAuth() with the same options as when it was originally called, or call getAuth() to return the already initialized instance.",
};

export const COMMA_SEPARATED_EMAILS_REGEX =
    /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/;
export enum TOAST_TYPE {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING",
}
export const MESSAGING_TOKEN_EXPIRATION = 7;

export const TripRequirementFileStatus = {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    NOT_SUBMITTED: "NOT_SUBMITTED",
};

/* export enum ORGANIZATION_USER_POSITION {
    GM = "GM",
    DSM = "Director of Sales and Marketing",
    DLS = "Director of Leisure Sales",
    SM = "Sales Manager"
} */
export enum ORGANIZATION_USER_POSITION {
    GM = "GM",
    DSM = "DSM",
    DLS = "DLS",
    SM = "SM",
    RM = "RM",
    GS = "GS",
    OTHER = "OTHER",
}

export enum ORGANIZATION_USER_POSITION_AGENCY {
    IC = "IC",
    IHA = "IHA",
    MANAGER = "MANAGER",
    OWNER = "SM",
    ADMINISTRATOR = "ADMINISTRATOR",
}

export const OrgUserPositionOptions = [
    { label: "GM", value: ORGANIZATION_USER_POSITION.GM },
    {
        label: "Director of Sales and Marketing",
        value: ORGANIZATION_USER_POSITION.DSM,
    },
    {
        label: "Director of Leisure Sales",
        value: ORGANIZATION_USER_POSITION.DLS,
    },
    { label: "Sales Manager", value: ORGANIZATION_USER_POSITION.SM },
    { label: "Reservations Manager", value: ORGANIZATION_USER_POSITION.RM },
    { label: "Group Sales", value: ORGANIZATION_USER_POSITION.GS },
    { label: "Other", value: ORGANIZATION_USER_POSITION.OTHER },
];

export const OrgAgencyUserPositionOptions = [
    {
        label: "Independent Contractor",
        value: ORGANIZATION_USER_POSITION_AGENCY.IC,
    },
    { label: "In-house advisor", value: ORGANIZATION_USER_POSITION_AGENCY.IHA },
    { label: "Manager", value: ORGANIZATION_USER_POSITION_AGENCY.MANAGER },
    { label: "Owner", value: ORGANIZATION_USER_POSITION_AGENCY.OWNER },
    {
        label: "Administrator",
        value: ORGANIZATION_USER_POSITION_AGENCY.ADMINISTRATOR,
    },
];
export const OrgPositionOptions = {
    [OrganizationType.AGENCY]: OrgAgencyUserPositionOptions,
    [OrganizationType.DMC]: OrgUserPositionOptions,
    [OrganizationType.HOTEL_CHAIN]: OrgUserPositionOptions,
    [OrganizationType.HOTEL_INDIVIDUAL]: OrgUserPositionOptions,
    [OrganizationType.MARKETING_COMPANY]: OrgUserPositionOptions,
    [OrganizationType.TOUR_OPERATOR]: OrgUserPositionOptions,
    [OrganizationType.TOURIST_BOARD]: OrgUserPositionOptions,
    [OrganizationType.WHOLESALER]: OrgUserPositionOptions,
    [OrganizationType.PERSONAL]: OrgUserPositionOptions,
    [OrganizationType.OTHER]: OrgUserPositionOptions,
};

export const TRIP_USERS_MENU_OPTIONS_KEYS = {
    CUSTOMIZE_INVITATION: "CUSTOMIZE_INVITATION",
};
export const TRIP_USERS_MENU_OPTIONS = [
    {
        label: "Customize Invitation",
        key: TRIP_USERS_MENU_OPTIONS_KEYS.CUSTOMIZE_INVITATION,
    },
];

export const TRIP_USERS_LIST_OPTIONS_KEYS = {
    REMOVE_USER: "REMOVE_USER",
    RESEND_INVITE: "RESEND_INVITE",
    COPY_INVITE_LINK: "COPY_INVITE_LINK",
    BLOCK_USER: "BLOCK_USER",
    UNBLOCK_USER: "UNBLOCK_USER",
    CHANGE_ROLE: "CHANGE_ROLE",
};
export const TRIP_USERS_LIST_OPTIONS: any = {
    [UserStatusOnTrip.CONFIRMED]: [
        {
            icon: "AccountSwitchIcon",
            label: "Change role",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE,
        },
        {
            icon: "TrashIcon",
            label: "Delete",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
        {
            icon: "AccountBlockOutlineIcon",
            label: "Suspend",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.BLOCK_USER,
        },
    ],
    [UserStatusOnTrip.BLOCKED]: [
        {
            icon: "AccountSwitchIcon",
            label: "Change role",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE,
        },
        {
            icon: "TrashIcon",
            label: "Delete",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
        {
            icon: "AccountBlockOutlineIcon",
            label: "Unblock",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.UNBLOCK_USER,
        },
    ],
    [UserStatusOnTrip.REJECTED]: [
        {
            icon: "TrashIcon",
            label: "Delete",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
    ],
    [UserStatusOnTrip.PENDING]: [
        {
            icon: "AccountSwitchIcon",
            label: "Change role",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE,
        },
        {
            icon: "TrashIcon",
            label: "Delete",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
        {
            icon: "EmailIcon",
            label: "Resend Invite",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.RESEND_INVITE,
        },
        {
            icon: "CopyIcon",
            label: "Copy Invite Link",
            key: TRIP_USERS_LIST_OPTIONS_KEYS.COPY_INVITE_LINK,
        },
    ],
};

export const TRIP_ACTIONS_OPTIONS_KEYS = {
    EXPORT_TRIP: "EXPORT_TRIP",
    DUPLICATE_TRIP: "DUPLICATE_TRIP",
    DELETE_TRIP: "DELETE_TRIP",
    LEAVE_TRIP: "LEAVE_TRIP",
    EXPORT_TRIP_REVIEWS: "EXPORT_TRIP_REVIEWS",
    EXPORT_TRIP_REVIEWS_DOCX: "EXPORT_TRIP_REVIEWS_DOCX",
    EDIT_INVITATION: "EDIT_INVITATION",
    ASSIGN_TRIP_ORGANIZATION: "ASSIGN_TRIP_ORGANIZATION",
};

export const TRIP_ACTIONS_OPTIONS = {
    [TRIP_ACTIONS_OPTIONS_KEYS.DELETE_TRIP]: {
        icon: "TrashIcon",
        label: "Delete",
        key: TRIP_ACTIONS_OPTIONS_KEYS.DELETE_TRIP,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.EDIT_INVITATION]: {
        icon: "TripIcon",
        label: "Edit Invitation",
        key: TRIP_ACTIONS_OPTIONS_KEYS.EDIT_INVITATION,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP]: {
        icon: "DownloadIcon",
        label: "Export Trip Report",
        key: TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS]: {
        icon: "DownloadIcon",
        label: "Export Public Reviews Compilation (PDF)",
        key: TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS_DOCX]: {
        icon: "DownloadIcon",
        label: "Export Public Reviews Compilation (DOCX)",
        key: TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS_DOCX,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP]: {
        icon: "LeaveIcon",
        label: "Leave",
        key: TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP]: {
        icon: "DuplicateIcon",
        label: "Duplicate",
        key: TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP,
    },
    [TRIP_ACTIONS_OPTIONS_KEYS.ASSIGN_TRIP_ORGANIZATION]: {
        icon: "DuplicateIcon",
        label: "Assign to Organization",
        key: TRIP_ACTIONS_OPTIONS_KEYS.ASSIGN_TRIP_ORGANIZATION,
    },
};

export const TRIP_ACTIONS_LIST_OPTIONS: any = {
    [UserRoleOnTrip.ADMIN]: [
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EDIT_INVITATION],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP],
        TRIP_ACTIONS_OPTIONS[
            TRIP_ACTIONS_OPTIONS_KEYS.ASSIGN_TRIP_ORGANIZATION
        ],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS_DOCX],
    ],
    [UserRoleOnTrip.OWNER]: [
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EDIT_INVITATION],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.DELETE_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS_DOCX],
    ],
    [UserRoleOnTrip.MEMBER]: [
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP],
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP],
    ],
    [UserRoleOnTrip.VIEWER]: [
        TRIP_ACTIONS_OPTIONS[TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP],
    ],
};

export const ORG_USERS_LIST_OPTIONS_KEYS = {
    CHANGE_ROLE: "CHANGE_ROLE",
    MAKE_OWNER: "MAKE_OWNER",
    RESEND_INVITE: "RESEND_INVITE",
    REMOVE_USER: "REMOVE_USER",
};
export const ORG_USERS_LIST_OPTIONS: any = {
    [UserStatusOnOrganization.CONFIRMED]: [
        {
            icon: "AccountSwitchIcon",
            label: "Change role",
            key: ORG_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE,
        },
        {
            icon: "AccountHardHatIcon",
            label: "Make Owner",
            key: ORG_USERS_LIST_OPTIONS_KEYS.MAKE_OWNER,
        },
        {
            icon: "TrashIcon",
            label: "Delete",
            key: ORG_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
    ],
    [UserStatusOnOrganization.PENDING]: [
        {
            icon: "AccountSwitchIcon",
            label: "Change role",
            key: ORG_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE,
        },
        {
            icon: "TrashIcon",
            label: "Delete",
            key: ORG_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
        {
            icon: "EmailIcon",
            label: "Resend Invite",
            key: ORG_USERS_LIST_OPTIONS_KEYS.RESEND_INVITE,
        },
    ],
    [UserStatusOnOrganization.REJECTED]: [
        {
            icon: "TrashIcon",
            label: "Delete",
            key: ORG_USERS_LIST_OPTIONS_KEYS.REMOVE_USER,
        },
    ],
};

export const VALID_FILE_FORMATS = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.presentation",
];

export const VALID_IMAGES_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
export const VALID_VIDEOS_FORMATS = ["video/mp4"];
export const BYTES_IN_KB = 1024;
export const KB_IN_MB = 1024;

export const AuthProviders = {
    GOOGLE: "google.com",
    FACEBOOK: "facebook.com",
    APPLE: "apple.com",
    PASSWORD: "password",
};

export interface IUserPermission {
    tripRole?: Array<UserRoleOnTrip | "ALL">;
}

export const ACTIVITY_FORM_STEPS_IDS = {
    HOTEL_SELECTION: "HOTEL_SELECTION",
    HOTEL_INFO: "HOTEL_INFO",
    HOTEL_DETAILS: "HOTEL_DETAILS",
    HOTEL_CONFIRM: "HOTEL_CONFIRM",
    OTHER_SELECTION: "OTHER_SELECTION",
    OTHER_INFO: "OTHER_INFO",
    OTHER_DETAILS: "OTHER_DETAILS",
    OTHER_CONFIRM: "OTHER_CONFIRM",
    RESTAURANT_SELECTION: "RESTAURANT_SELECTION",
    RESTAURANT_INFO: "RESTAURANT_INFO",
    RESTAURANT_DETAILS: "RESTAURANT_DETAILS",
    RESTAURANT_CONFIRM: "RESTAURANT_CONFIRM",
} as const;

export const ACTIVITY_FORM_STEPS: any = {
    [ActivityType.HOTEL]: [
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_SELECTION,
            label: "Select Hotel",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_INFO,
            label: "Complete info",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_DETAILS,
            label: "Add details",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_CONFIRM,
            label: "Confirm",
        },
    ],
    [ActivityType.VISIT]: [
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_SELECTION,
            label: "Select Hotel",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_INFO,
            label: "Complete info",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_DETAILS,
            label: "Add details",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.HOTEL_CONFIRM,
            label: "Confirm",
        },
    ],
    [ActivityType.OTHER]: [
        {
            id: ACTIVITY_FORM_STEPS_IDS.OTHER_SELECTION,
            label: "Select Activity",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.OTHER_INFO,
            label: "Complete info",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.OTHER_DETAILS,
            label: "Add details",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.OTHER_CONFIRM,
            label: "Confirm",
        },
    ],
    [ActivityType.RESTAURANT]: [
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_SELECTION,
            label: "Select Restaurant",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_INFO,
            label: "Complete info",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_DETAILS,
            label: "Add details",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_CONFIRM,
            label: "Confirm",
        },
    ],
    [ActivityType.INSIGHT]: [
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_SELECTION,
            label: "Select Restaurant",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_INFO,
            label: "Complete info",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_DETAILS,
            label: "Add details",
        },
        {
            id: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_CONFIRM,
            label: "Confirm",
        },
    ],
};

const INSIGHT_CATEGORY = {
    GENERAL: "GENERAL",
    PRIVATE_INSIGHT: "PRIVATE_INSIGHT",
};

export const INSIGHT_CATEGORY_LABELS = {
    [INSIGHT_CATEGORY.GENERAL]: "General",
    [INSIGHT_CATEGORY.PRIVATE_INSIGHT]: "Your private insight",
};

export const ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS = {
    DETAILS: "DETAILS",
    CONTACTS: "CONTACTS",
    ROOMS: "ROOMS",
    DINING_DRINKING: "DINING_DRINKING",
    WELLNESS: "WELLNESS",
    COMMON_AREAS: "COMMON_AREAS",
    EXPERIENCE: "EXPERIENCE",
} as const;

const HOTEL_PRIVATE_INSIGHT_SECTIONS = [
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.DETAILS,
        label: "Notes",
        url: "details",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS,
        category: INSIGHT_CATEGORY.GENERAL,
    },
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.CONTACTS,
        label: "Contacts",
        url: "contacts",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS,
        category: INSIGHT_CATEGORY.GENERAL,
    },
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.ROOMS,
        label: "Rooms",
        url: "rooms",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS,
        category: INSIGHT_CATEGORY.PRIVATE_INSIGHT,
    },
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.DINING_DRINKING,
        label: "Dining/ Drinking",
        url: "dining-drinking",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING,
        category: INSIGHT_CATEGORY.PRIVATE_INSIGHT,
    },
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.WELLNESS,
        label: "Wellness",
        url: "wellness",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS,
        category: INSIGHT_CATEGORY.PRIVATE_INSIGHT,
    },
    {
        id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.COMMON_AREAS,
        label: "Common areas",
        url: "common-areas",
        key: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON,
        category: INSIGHT_CATEGORY.PRIVATE_INSIGHT,
    },
];

export const ACTIVITY_PRIVATE_INSIGHT_SECTIONS = {
    [ActivityType.HOTEL]: HOTEL_PRIVATE_INSIGHT_SECTIONS,
    [ActivityType.VISIT]: HOTEL_PRIVATE_INSIGHT_SECTIONS,
    [ActivityType.OTHER]: [
        {
            id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.DETAILS,
            label: "Notes",
            url: "",
            key: PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS,
            category: INSIGHT_CATEGORY.GENERAL,
        },
    ],
    [ActivityType.RESTAURANT]: [
        {
            id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.DETAILS,
            label: "Notes",
            url: "",
            key: PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS,
            category: INSIGHT_CATEGORY.GENERAL,
        },
        {
            id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.CONTACTS,
            label: "Contacts",
            url: "contacts",
            key: PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS,
            category: INSIGHT_CATEGORY.GENERAL,
        },
        {
            id: ACTIVITY_PRIVATE_INSIGHT_SECTION_IDS.EXPERIENCE,
            label: "Experience",
            url: "experience",
            key: PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE,
            category: INSIGHT_CATEGORY.GENERAL,
        },
    ],
};

export enum GoodForWhichClients {
    HONEYMOON = "HONEYMOON",
    FAMILY = "FAMILY",
    MULTIGENERATIONAL = "MULTIGENERATIONAL",
    COUPLES = "COUPLES",
    FOODIES = "FOODIES",
    SPA_LOVERS = "SPA_LOVERS",
    BEACH_LOVERS = "BEACH_LOVERS",
    YOUNG_FAMILY = "YOUNG_FAMILY",
    OLDER_FAMILY = "OLDER_FAMILY",
    OLDER_KIDS = "OLDER_KIDS",
    TEENAGERS = "TEENAGERS",
}

export const goodForWhichClientsOptions = [
    { label: "Honeymoon", value: GoodForWhichClients.HONEYMOON },
    { label: "Family", value: GoodForWhichClients.FAMILY },
    {
        label: "Multigenerational",
        value: GoodForWhichClients.MULTIGENERATIONAL,
    },
    { label: "Couples", value: GoodForWhichClients.COUPLES },
    { label: "Foodies", value: GoodForWhichClients.FOODIES },
    { label: "Spa lovers", value: GoodForWhichClients.SPA_LOVERS },
    { label: "Beach lovers", value: GoodForWhichClients.BEACH_LOVERS },
    { label: "Young family", value: GoodForWhichClients.YOUNG_FAMILY },
    { label: "Older family", value: GoodForWhichClients.OLDER_FAMILY },
    { label: "Older kids", value: GoodForWhichClients.OLDER_KIDS },
    { label: "Teenagers", value: GoodForWhichClients.TEENAGERS },
];

export const IMAGES_MIME_TYPES_SUPPORTED: any = {
    "image/png": "png",
    "image/jpeg": "jpeg",
    "image/gif": "gif",
};

export const PROFILE_TAB_ITEMS_INDEX = {
    documents: "documents",
    reviews: "reviews",
    personalInfo: "personalInfo",
    dietaryReqs: "dietaryReqs",
    professionalInfo: "professionalInfo",
};

export const PROFILE_TAB_SHOW_INDEX = {
    actions: "actions",
};

export const PROFILE_TABS = [
    { admin: true, id: PROFILE_TAB_ITEMS_INDEX.documents, text: "Documents" },
    { admin: false, id: PROFILE_TAB_ITEMS_INDEX.reviews, text: "Reviews" },
    {
        admin: false,
        id: PROFILE_TAB_ITEMS_INDEX.personalInfo,
        text: "Personal details",
    },
    {
        admin: false,
        id: PROFILE_TAB_ITEMS_INDEX.professionalInfo,
        text: "Professional details",
    },
    {
        admin: true,
        id: PROFILE_TAB_ITEMS_INDEX.dietaryReqs,
        text: "Dietary restrictions",
    },
];

export const PROFILE_ORGANIZATION_TABS = [
    {
        admin: false,
        id: PROFILE_TAB_ITEMS_INDEX.personalInfo,
        text: "Personal details",
    },
    {
        admin: false,
        id: PROFILE_TAB_ITEMS_INDEX.professionalInfo,
        text: "Professional details",
    },
    // {
    //     admin: true,
    //     id: PROFILE_TAB_ITEMS_INDEX.dietaryReqs,
    //     text: "Dietary restrictions",
    // },
];

export const UserRoles = {
    ADMIN: "ADMIN",
};

export const ActivityIcon = {
    [ActivityType.HOTEL]: "BedIcon",
    [ActivityType.VISIT]: "BuildingIcon",
    [ActivityType.RESTAURANT]: "RestaurantIcon",
    [ActivityType.OTHER]: "CalendarPlusIcon",
};

export const TRIP_DEFAULT_IMAGES = [
    {
        id: "cover_1",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_1.jpg?alt=media`,
    },
    {
        id: "cover_2",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_2.jpg?alt=media`,
    },
    {
        id: "cover_3",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_3.jpg?alt=media`,
    },
    {
        id: "cover_4",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_4.jpg?alt=media`,
    },
    {
        id: "cover_5",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_5.jpg?alt=media`,
    },
    {
        id: "cover_6",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_6.jpg?alt=media`,
    },
    {
        id: "cover_7",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_7.jpg?alt=media`,
    },
    {
        id: "cover_8",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_8.jpg?alt=media`,
    },
    {
        id: "cover_9",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_9.jpg?alt=media`,
    },
    {
        id: "cover_10",
        src: `https://firebasestorage.googleapis.com/v0/b/${config.fbStorageBucket}/o/sharedImages%2Ftrip_cover_10.jpg?alt=media`,
    },
];

export enum TIME_PERIOD {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export const periodOptions = [
    {
        label: "Daily",
        key: TIME_PERIOD.DAY,
    },
    {
        label: "Weekly",
        key: TIME_PERIOD.WEEK,
    },
    {
        label: "Monthly",
        key: TIME_PERIOD.MONTH,
    },
    {
        label: "Yearly",
        key: TIME_PERIOD.YEAR,
    },
];

export const BADGE_COLORS = {
    red: {
        text: "text-[#F7685B]",
        border: "border-[#F7685B]",
        bg: "bg-[#FEE2E2]",
    },
    green: {
        text: "text-[#166534]",
        border: "border-[#166534]",
        bg: "bg-[#E4F5ED]",
    },
    orange: {
        text: "text-[#C2410C]",
        border: "border-[#C2410C]",
        bg: "bg-[#FBF2DA]",
    },
    gray: {
        text: "text-[#585D67]",
        border: "border-[#585D67]",
        bg: "bg-[#F1F5F9]",
    },

    lightGray: {
        text: "text-[#585D67]",
        border: "border-[#585D67]",
        bg: "bg-[#F1F5F9]",
    },
    blue: {
        text: "text-blue-500",
        border: "border-blue-500",
        bg: "bg-blue-50",
    },
    yellow: {
        text: "text-white",
        border: "border-yellow-800",
        bg: "bg-yellow-500",
    },
};

export const TRIP_SECTIONS = [
    PAGE_SECTIONS.TRIP,
    PAGE_SECTIONS.TRIP_EDIT,
    PAGE_SECTIONS.TRIP_NEW,
    PAGE_SECTIONS.TRIP_FILES,
    PAGE_SECTIONS.TRIP_FILES_EDIT,
    PAGE_SECTIONS.TRIP_GALLERY,
    PAGE_SECTIONS.TRIP_ITINERARY,
    PAGE_SECTIONS.TRIP_PARTICIPANTS,
    PAGE_SECTIONS.TRIP_MESSAGES,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL,
    PAGE_SECTIONS.TRIP_ITINERARY_VISIT,
    PAGE_SECTIONS.TRIP_ITINERARY_PUBLIC_REVIEW,
    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
    PAGE_SECTIONS.TRIP_ITINERARY_OTHER,
    PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT,
    PAGE_SECTIONS.TRIP_LOCAL_PARTNERS,
    PAGE_SECTIONS.TRIP_REQUIREMENTS,
    PAGE_SECTIONS.TRIP_CONTACTS,
    PAGE_SECTIONS.TRIP_NOTIFICATIONS,
    PAGE_SECTIONS.TRIP_CUSTOM_INVITE,
    PAGE_SECTIONS.TRIP_PARTICIPANTS,
    PAGE_SECTIONS.TRIP_MESSAGES,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL,
    PAGE_SECTIONS.TRIP_ITINERARY_VISIT,
    PAGE_SECTIONS.TRIP_ITINERARY_PUBLIC_REVIEW,
    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS,
    PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON,
    PAGE_SECTIONS.TRIP_ITINERARY_OTHER,
    PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT,
    PAGE_SECTIONS.TRIP_LOCAL_PARTNERS,
    PAGE_SECTIONS.TRIP_REQUIREMENTS,
    PAGE_SECTIONS.TRIP_CONTACTS,
    PAGE_SECTIONS.TRIP_NOTIFICATIONS,
    PAGE_SECTIONS.TRIP_CUSTOM_INVITE,
];

export const specializationOptions = [
    { value: "Adventure Travel", label: "Adventure Travel" },
    { value: "Air Travel", label: "Air Travel" },
    { value: "Beach & Sun", label: "Beach & Sun" },
    { value: "Corporate Travel", label: "Corporate Travel" },
    { value: "Culture History & Arts", label: "Culture History & Arts" },
    { value: "Ocean Cruising", label: "Ocean Cruising" },
    { value: "River Cruising", label: "River Cruising" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Escorted Tours", label: "Escorted Tours" },
    { value: "Expedition Cruising", label: "Expedition Cruising" },
    { value: "Family Travel", label: "Family Travel" },
    { value: "Festivals & Events", label: "Festivals & Events" },
    { value: "Food & Wine", label: "Food & Wine" },
    {
        value: "Honeymoons & Special Occasions",
        label: "Honeymoons & Special Occasions",
    },
    { value: "Hotels", label: "Hotels" },
    { value: "Independent Tours", label: "Independent Tours" },
    { value: "LGBTQ+ Travel", label: "LGBTQ+ Travel" },
    { value: "Luxury", label: "Luxury" },
    {
        value: "Meeting & Incentive Travel",
        label: "Meeting & Incentive Travel",
    },
    { value: "Nightlife", label: "Nightlife" },
    { value: "Safari", label: "Safari" },
    { value: "Spa & Wellness", label: "Spa & Wellness" },
    { value: "Sports & Outdoors", label: "Sports & Outdoors" },
    { value: "Weddings", label: "Weddings" },
];

export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_YEAR_DATE_FORMAT = "MM/DD/YY";

export const SUPPORT_MODULE_SECTIONS_OPTIONS = _.sortBy(
    [
        { value: PAGE_SECTIONS.HOME, label: "Home page", image: homeImage },
        {
            value: PAGE_SECTIONS.TRIP_NEW,
            label: "Create trip",
            image: createTripImage,
        },
        { value: PAGE_SECTIONS.TRIP, label: "Trip Details", image: tripImage },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY,
            label: "Trip Itinerary",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY_HOTEL,
            label: "Hotel Stay Activity",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY_VISIT,
            label: "Hotel Inspection Activity",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY_OTHER,
            label: "Other Activity",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT,
            label: "Restaurant Activity",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            label: "Trip Requirements",
            image: documentsImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_FILES,
            label: "Trip Files",
            image: filesImage,
        },
        {
            value: PAGE_SECTIONS.PROFILE,
            label: "User Profile",
            image: profileImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_MESSAGES,
            label: "Trip Messages (Web only)",
            image: homeImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_GALLERY,
            label: "Trip Gallery",
            image: galleryImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_PARTICIPANTS,
            label: "Trip Participants",
            image: participantsImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_NOTIFICATIONS,
            label: "Trip Notifications",
            image: tripNotificationsImage,
        },
        {
            value: PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
            label: "Reviews and Insights",
            image: activityInsightImage,
        },
        // {
        //     value: PAGE_SECTIONS.TRIP_ITINERARY_PUBLIC_REVIEW,
        //     label: "Activity Public Review",
        //     image: itineraryImage,
        // },
        {
            value: PAGE_SECTIONS.INSIGHTS,
            label: "Insights page (Web only)",
            image: itineraryImage,
        },
        {
            value: PAGE_SECTIONS.MANAGE_ACCOUNT,
            label: "Manage Account",
            image: manageAccountImage,
        },
        {
            value: PAGE_SECTIONS.PREFERENCES_USER,
            label: "Preferences",
            image: userPreferencesImage,
        },
        {
            value: PAGE_SECTIONS.SETTINGS,
            label: "Settings (Mobile only)",
            image: homeImage,
        },
        {
            value: PAGE_SECTIONS.ORGANIZATIONS,
            label: "Organizations",
            image: organizationsImage,
        },
        {
            value: PAGE_SECTIONS.ORGANIZATION,
            label: "Organization Details",
            image: organizationDetailsImage,
        },
        {
            value: PAGE_SECTIONS.ORGANIZATION_MEMBERS,
            label: "Organization Members",
            image: homeImage,
        },
        {
            value: PAGE_SECTIONS.ORGANIZATION_TRIPS,
            label: "Organization Trips",
            image: organizationDetailsImage,
        },
        {
            value: PAGE_SECTIONS.ORGANIZATION_LIBRARY,
            label: "Organization Library",
            image: organizationDetailsImage,
        },
        {
            value: PAGE_SECTIONS.PLAN_USER,
            label: "User Plan",
            image: userPlanImage,
        },
    ],
    (s) => {
        return s.label;
    }
);

export const AdminEndpoints = {
    modules: "/admin/modules",
    supportModules: "/modules-support",
};
