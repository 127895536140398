import React, { useState } from "react";
import Icon from "@mdi/react";
import {
  mdiAirplane,
  mdiPassport,
  mdiCalendarCheckOutline,
} from "@mdi/js";

interface InfoCardsProps {
    handleType: (type: string) => void;
}

const InfoCards = ({ handleType }: InfoCardsProps) => {
    const [currentType, setCurrentType] = useState<string>();

    const items = [
        {
            icon: mdiAirplane,
            title: "Arrival/departure info",
            value: "arrival_departure_info",
        },
        {
            icon: mdiPassport,
            title: "Passport",
            value: "passport",
        },
        {
            icon: mdiCalendarCheckOutline,
            title: "Other requirement",
            value: "other_requirement",
        },
    ];

    const handleCurrentType = (type: string) => {
        setCurrentType(type);
        handleType(type);
    }

    return (
    <div className="flex justify-between gap-4">
        {items.map((item, index) => (
            <div
            key={index}
            onClick={() => handleCurrentType(item.value)}
            className="flex flex-col items-center justify-center bg-gray-100 border border-gray-200 rounded-lg p-4 w-48 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            >
            <Icon
                path={item.icon}
                size={2}
                className={`${item.value === currentType ? 'text-orange-500' : 'text-gray-500'}  mb-2`}
            />
            <div className="text-gray-600 text-center text-sm h-8">{item.title}</div>
            </div>
        ))}
    </div>
    );
};

export default InfoCards;