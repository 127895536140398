import { ReactNode, useEffect, useState } from "react";
import { ChatForm } from "../type/chatFormType";
import {
    IAttachment,
    IChatEntity,
    IChatMessage,
    IResponseChatMessage,
    IContact,
    IChatPushPayload,
} from "../../../types/chat.type";
import { ChatFormContext } from "../context/chatProviderContext";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    addMessage,
    selectConversations,
    selectError,
    selectLoading,
    fetchServerUsers,
    getUsersContacts as getUsersContactsAction,
    selectContactSearchLists,
    fetchAndListenToConversations,
    setContactSearchLists,
    addImageToChat,
    copyImageToTrip,
    sendChatPushNotification,
    handleBlockUser,
    handleReportUser,
    handleAcceptUser,
    setSelectConversation,
    handleUnBlockUser,
    addVideoToChat,
} from "../chatSlice";
// import { addImageToTrip, isLoading } from "../tripSlice";
import FB from "../../../api/firebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
interface Props {
    children: ReactNode;
}

export const ChatFormProvider = ({ children }: Props) => {
    const firebase = new FB();
    const database = firebase.database;
    const dispatch = useAppDispatch();
    const currentUser = useSelector((state: any) => state?.user?.profile);
    const chats = useAppSelector(selectConversations);
    const isLoading = useAppSelector(selectLoading);
    const error = useAppSelector(selectError);
    const contactSearchLists = useSelector(selectContactSearchLists);
    const [selectedChat, setSelectedChat] = useState<IChatEntity | null>(null);
    const [selectedUser, setSelectedUser] = useState<IContact | null>(null);
    const [selectedMessages, setSelectedMessages] = useState<IChatMessage[]>(
        []
    );
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [contactsLoaded, setContactsLoaded] = useState(false);
    const [showUsersList, setShowUsersList] = useState(false);
    const [currentSelectChatId, setCurrentSelectChatId] = useState<string>();
    const [selectedTripId, setSelectedTripId] = useState<number | undefined>(undefined);

    const handleNewConversation = () => {
        setSelectedChat(null);
        setSelectedMessages([]);
        setSelectedUser(null);
    };

    const onSelectedUser = (user: IContact) => {
        setSelectedTripId(undefined);
        const existingChat = onExistingChat(user?.uid);
        if (existingChat) {
            onSelectedChat(existingChat.id);
        } else {
            setSelectedUser(user);
            const updatedChat: IChatEntity = {
                id: "",
                members: [],
                deletedMembers: [],
                messages: [],
                isGroup: false,
                title: user.firstName + " " + user.lastName,
                lastMessageTimestamp: Date.now(),
                isBlockedBy: "",
                avatar: user.avatar,
                isFriend: true,
            };
            setSelectedChat(updatedChat);
            setSelectedMessages([]);
        }
    };

    const createNewConversation = async (
        user: IContact
    ): Promise<IChatEntity> => {
        const conversationId = uuidv4();
        const newConversation = {
            title: user.firstName + " " + user.lastName,
            members: {
                [currentUser?.uid]: {
                    id: currentUser.id,
                    uid: currentUser?.uid,
                    email: currentUser.email,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                    avatar: currentUser.avatar,
                    avatarThumb: currentUser.avatar_thumb,
                } as IContact,
                [user?.uid]: user,
            },
            messages: {},
            isGroup: false,
            avatar: user.avatar,
        };

        const conversationRef = database.ref(`conversations/${conversationId}`);
        await conversationRef.set(newConversation);

        const setChat: IChatEntity = {
            id: conversationId,
            members: Object.values(newConversation.members),
            deletedMembers: [],
            messages: [],
            isGroup: false,
            title: user.firstName + " " + user.lastName,
            lastMessageTimestamp: Date.now(),
            isBlockedBy: "",
            avatar: user.avatar,
            isFriend: true,
        };

        return setChat;
    };

    const mapMessages = (messages: IChatMessage[]) => {
        return messages.map((message) => {
            return {...message, sender: {...message.sender, isCurrentUser: message.sender.uid === currentUser?.uid || message.senderId === currentUser?.uid}}
        })
    };

    const onSelectedChat = async (id: string): Promise<IChatEntity[]> => {
        try {
            setCurrentSelectChatId(id);
            const updatedChats = chats.map((chat: IChatEntity) => {
                if (chat.id === id) {
                    const recipientUsers = chat.members.find(
                        (member) => member.uid !== currentUser?.uid
                    );
                    if (recipientUsers) {
                        const updatedChat = {
                            ...chat,
                            title: chat.isGroup
                                ? chat.title
                                : recipientUsers.firstName +
                                  " " +
                                  recipientUsers.lastName,
                        };

                        const updatedRecipientUser = {
                            ...recipientUsers,
                        };

                        setSelectedUser(updatedRecipientUser);
                        setSelectedChat(updatedChat);
                    } else {
                        setSelectedUser(null);
                        setSelectedChat(chat);
                    }
                    let messages = chat?.messages?.filter(
                        (message) =>
                            (message.content || message.mediaUrl) &&
                            message.id &&
                            message.content !== "New group created"
                    );
                    messages = messages.sort(
                        (a, b) => a.timestamp - b.timestamp
                    );
                    setSelectedMessages(mapMessages(messages));
                    if (chat.isGroup) {
                        setSelectedTripId(undefined)
                    }
                    dispatch(setSelectConversation(chat));
                }
                return chat;
            });
            setShowUsersList(false);
            return updatedChats as IChatEntity[];
        } catch (error) {
            console.log(error);
            return [] as IChatEntity[];
        }
    };

    const onSendMessage = async (event: ChatForm): Promise<void> => {
        try {
            let chat = selectedChat;
            if ((!selectedChat || selectedChat?.id === "") && selectedUser) {
                chat = await createNewConversation(selectedUser);
                setSelectedMessages([]);
                setSelectedChat(chat);
                onSelectedChat(chat!.id);
            }
    
            if (chat?.id) {
                const newMessageRef = database.ref(`conversations/${chat.id}/messages`).push();
                const chatId = chat.id;
    
                const uploadedUrls = await Promise.all(
                    selectedFiles.map(async (file) => {
                        try {
                            const formData = new FormData();
                            formData.append("file", file);
                            formData.append("name", file.name);
                            formData.append("description", "");
    
                            const isVideo = file.type.includes("video");
    
                            const data = await dispatch(
                                isVideo ? addVideoToChat(chatId, formData) : addImageToChat(chatId, formData)
                            );
    
                            return {
                                url: data?.url || data?.[0],
                                thumbnail: data?.thumbnail || data?.[1] || null,
                                file: file,
                                name: file.name,
                                duration: data?.duration || null, 
                                size: file.size,
                            };
                        } catch (uploadError) {
                            console.error("Error uploading file:", file.name, uploadError);
                            throw uploadError;
                        }
                    })
                );
    
                let attachments: IAttachment[] = uploadedUrls.map((data) => ({
                    url: data.url,
                    thumbnail: data.thumbnail,
                    type: data.url.includes("images/")
                        ? "photo"
                        : data.url.includes("videos/")
                        ? "video"
                        : "file",
                    file: data.file,
                    name: data.name,
                    duration: data.duration, 
                    size: data.size,
                }));
    
                const newMessage: IResponseChatMessage = {
                    id: newMessageRef.key || "",
                    content: event.content,
                    senderId: currentUser?.uid,
                    timestamp: Date.now(),
                    readBy: { [currentUser?.uid]: true },
                    attachments,
                };
    
                await newMessageRef.set(newMessage);
    
                const newMessageData: IChatMessage = {
                    content: event.content,
                    id: newMessageRef.key,
                    readBy: { [currentUser?.uid]: true },
                    sender: {
                        avatar: currentUser.avatar,
                        avatarThumb: currentUser.avatar_thumb,
                        email: currentUser.email,
                        firstName: currentUser.firstName,
                        id: currentUser.id,
                        lastName: currentUser.lastName,
                        uid: currentUser?.uid,
                    } as IContact,
                    timestamp: Date.now(),
                    attachments,
                };
    
                dispatch(addMessage({ message: newMessageData }));
                setShowUsersList(false);
    
                const users = chat.members.filter((member) => member.uid !== currentUser?.uid);
    
                const payloadPush: IChatPushPayload = {
                    chatId: chat.id,
                    title: chat.title,
                    usersToSendNotifications: users.map((member) => member.email),
                };
    
                dispatch(sendChatPushNotification(payloadPush));
            }
        } catch (error) {
            console.error("Error in onSendMessage:", error);
        }
    };

    const createVideoThumbnail = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const video = document.createElement("video");
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
    
            video.src = URL.createObjectURL(file);
            let thumbnailGenerated = false;
    
            video.onerror = (error) => {
                console.error(`Error generating thumbnail for video: ${file.name}`, error);
                reject(`Error generating thumbnail for video: ${file.name}`);
            };
    
            video.oncanplay = () => {
                if (thumbnailGenerated) return;
                try {
                    video.currentTime = Math.min(0.5, video.duration / 2);
                } catch (e) {
                    console.error(`Failed to set currentTime for video: ${file.name}`, e);
                    reject(`Failed to set currentTime for video: ${file.name}`);
                }
            };
    
            video.onseeked = () => {
                if (thumbnailGenerated || !ctx) return;
                canvas.width = video.videoWidth / 2;
                canvas.height = video.videoHeight / 2;
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnailUrl = canvas.toDataURL("image/png");
                thumbnailGenerated = true;
                resolve(thumbnailUrl);
            };
        });
    };
    
    

    const getInitials = (name: string) => {
        const namesArray = name.split(" ");
        if (namesArray.length > 1) {
            return namesArray[0].charAt(0) + namesArray[1].charAt(0);
        }
        return namesArray[0].charAt(0);
    };

    const onExistingChat = (id: string) => {
        const existingChat = chats.find(
            (chat) =>
                chat.members.some((member) => member.uid === id) &&
                !chat.isGroup
        );
        return existingChat as IChatEntity;
    };

    const getFormattedDate = (timestamp: number) => {
        const date = moment(timestamp);
        const now = moment();

        if (date.isSame(now, "day")) {
            return date.format("hh:mm A");
        } else if (date.isSame(now.subtract(1, "days"), "day")) {
            return "Yesterday";
        } else if (date.isAfter(now.subtract(1, "week"))) {
            return date.format("ddd");
        } else {
            return date.format("MM/DD/YY");
        }
    };

    const getUsersContacts = (value: string) => {
        dispatch(getUsersContactsAction(value));
    };

    const handleFileSelection = (files: File[]) => {
        setSelectedFiles(files);
    };

    const removeSelectedFile = (index: number) => {
        setSelectedFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
        );
    };

    const saveImageToTripGallery = async (
        save: boolean,
        messageId: string,
        chatId: string,
        userUid: string
    ) => {
        const messageRef = database.ref(
            `conversations/${chatId}/messages/${messageId}`
        );
        try {
            const snapshot = await messageRef.once("value");
            const message: IChatMessage = snapshot.val();

            if (message) {
                message.attachOnTrip = {
                    saved: save,
                    userUid: userUid,
                    timestamp: Date.now(),
                };
                await messageRef.update(message);

                if (save) {
                    const attachments = message.attachments;
                    const tripId = parseInt(chatId.split("-").pop() || "0");
                    if (!attachments) {
                        return;
                    }

                    dispatch(copyImageToTrip(tripId, chatId, attachments));
                }
            } else {
                console.error("Message not found.");
            }
        } catch (error) {
            console.error("Error updating message:", error);
        }
    };

    const handleClearSearch = () => {
        dispatch(setContactSearchLists([]));
    };

    const onBlockUser = () => {
        dispatch(handleBlockUser());
    };
    const onUnBlockUser = () => {
        dispatch(handleUnBlockUser());
    };
    const onReportUser = (reason: string) => {
        dispatch(handleReportUser(reason));
    };
    const onAcceptUser = () => {
        dispatch(handleAcceptUser());
    };

    useEffect(() => {
        showUsersList && handleNewConversation();
    }, [showUsersList]);
    useEffect(() => {
        if (currentSelectChatId) {
            // find chat with the current selected chat id
            const chat = chats.find((chat) => chat.id === currentSelectChatId);
            if (chat) {
                onSelectedChat(currentSelectChatId);
            } else {
                setSelectedChat(null);
                setSelectedMessages([]);
                setSelectedUser(null);
            }
        }
    }, [chats]);

    useEffect(() => {
        const loadContacts = async () => {
            await dispatch(fetchServerUsers());
            setContactsLoaded(true);
        };

        loadContacts();
    }, []);

    useEffect(() => {
        if (contactsLoaded) {
            dispatch(fetchAndListenToConversations());
        }
    }, [contactsLoaded, dispatch]);

    useEffect(() => {
        if (selectedChat) {
            const messagesRef = database.ref(
                `conversations/${selectedChat.id}/messages`
            );
            messagesRef.on("child_added", (snapshot: any) => {
                // const sender: IContact = mergeContacts.find(
                //     (contact) => contact.uid === snapshot.val().senderId
                // ) as IContact;

                const sender = {} as IContact;
                const newMessage = {
                    id: snapshot.key,
                    sender: sender,
                    ...snapshot.val(),
                } as IChatMessage;

                // update readBy status when the user is not the sender
                if (
                    !newMessage.readBy ||
                    !newMessage.readBy[currentUser?.uid]
                ) {
                    const updatedReadBy = {
                        ...newMessage.readBy,
                        [currentUser?.uid]: true,
                    };
                    messagesRef
                        .child(newMessage.id)
                        .update({ readBy: updatedReadBy });
                }

                setSelectedMessages((prevMessages) => {
                    const messageExists = prevMessages.some(
                        (msg) =>
                            msg.id === newMessage.id ||
                            (msg.content === newMessage.content &&
                                msg.timestamp === newMessage.timestamp &&
                                msg.sender?.uid === newMessage.sender?.uid)
                    );
                    if (!messageExists) {
                        return mapMessages([...prevMessages, newMessage]);
                    }
                    return prevMessages.sort(
                        (a, b) => a.timestamp - b.timestamp
                    );
                });
            });

            return () => messagesRef.off("child_added");
        }
    }, [selectedChat]);

    return (
        <ChatFormContext.Provider
            value={{
                chats,
                isLoading,
                error,
                selectedMessages,
                selectedChat,
                selectedUser,
                contactSearchLists,
                onSelectedChat,
                onSendMessage,
                onExistingChat,
                onSelectedUser,
                getUsersContacts,
                getInitials,
                getFormattedDate,
                showUsersList,
                setShowUsersList,
                handleClearSearch,
                handleFileSelection,
                removeSelectedFile,
                selectedFiles,
                saveImageToTripGallery,
                onBlockUser,
                onUnBlockUser,
                onAcceptUser,
                onReportUser,
                createVideoThumbnail,
                setPreviousTrip: setSelectedTripId,
                selectedTripId,
            }}
        >
            {children}
        </ChatFormContext.Provider>
    );
};
