import React, { useEffect } from "react";

interface RequirementDetailsProps {
  data: {
    arrival_airline?: string;
    arrival_flightNumber?: string;
    arrival_departureAirport?: string;
    arrival_departureDate?: string;
    arrival_departureTime?: string;
    arrival_arrivalAirport?: string;
    arrival_arrivalDate?: string;
    arrival_arrivalTime?: string;
    departure_airline?: string;
    departure_flightNumber?: string;
    departure_departureAirport?: string;
    departure_departureDate?: string;
    departure_departureTime?: string;
    departure_arrivalAirport?: string;
    departure_arrivalDate?: string;
    departure_arrivalTime?: string;
    departure_other_type_of_transportation?: string;
    arrival_other_type_of_transportation?: string;
  };
}

const RequirementDetails: React.FC<RequirementDetailsProps> = ({ data }) => {
  const [showArrival, setShowArrival] = React.useState(true);
  const [showDeparture, setShowDeparture] = React.useState(true);


  useEffect(() => {
    const keys = Object.keys(data);
    setShowArrival(keys.some((key) => key.includes("arrival_")));
    setShowDeparture(keys.some((key) => key.includes("departure_")));
  }, [data]);
  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto">
      {/* Arrival Information */}
      <section className="mb-8">
        <h3 className="text-lg font-bold text-gray-900 mb-4">
          Arrival Information: Arrival Flights
        </h3>
        {showArrival && <p className="text-sm text-gray-700 mb-4">
          Please share your arrival flight info or, if not arriving by flight, tell us when and how you are arriving.
        </p>}
        {!showArrival ? (
          <div className="text-left">
            <p className="text-sm text-gray-700">No arrival information was required for this trip.</p>
          </div>
        ) : data.arrival_other_type_of_transportation ? (
          <DetailRow
            label="Other Transportation"
            value={data.arrival_other_type_of_transportation}
            fullRow
          />
        ) : (
          <div className="grid grid-cols-2 gap-x-6 gap-y-4">
            <DetailRow label="Airline" value={data.arrival_airline} />
            <DetailRow label="Flight #" value={data.arrival_flightNumber} />
            <DetailRow label="Departure airport" value={data.arrival_departureAirport} />
            <DetailRow label="Departure date" value={formatDate(data.arrival_departureDate)} />
            <DetailRow label="Departure time" value={formatTime(data.arrival_departureTime)} />
            <DetailRow label="Arrival airport" value={data.arrival_arrivalAirport} />
            <DetailRow label="Arrival date" value={formatDate(data.arrival_arrivalDate)} />
            <DetailRow label="Arrival time" value={formatTime(data.arrival_arrivalTime)} />
          </div>
        )
      }
      </section>

      <hr className="border-gray-300 mb-8" />

      {/* Departure Information */}
      <section>
        <h3 className="text-lg font-bold text-gray-900 mb-4">
          Departure Information: Departure Flights
        </h3>
        {showDeparture && <p className="text-sm text-gray-700 mb-4">
          Please share your departure flight info or, if not departing by flight, tell us when and how you are leaving.
        </p>}

        {!showDeparture ? (
          <div className="text-left ">
            <p className="text-sm text-gray-700">No departure information was required for this trip.</p>
          </div>
        ) : data.departure_other_type_of_transportation ? (
          <DetailRow
            label="Other Transportation"
            value={data.departure_other_type_of_transportation}
            fullRow
          />
        ) : (
          <div className="grid grid-cols-2 gap-x-6 gap-y-4">
            <DetailRow label="Airline" value={data.departure_airline} />
            <DetailRow label="Flight #" value={data.departure_flightNumber} />
            <DetailRow label="Departure airport" value={data.departure_departureAirport} />
            <DetailRow label="Departure date" value={formatDate(data.departure_departureDate)} />
            <DetailRow label="Departure time" value={formatTime(data.departure_departureTime)} />
            <DetailRow label="Arrival airport" value={data.departure_arrivalAirport} />
            <DetailRow label="Arrival date" value={formatDate(data.departure_arrivalDate)} />
            <DetailRow label="Arrival time" value={formatTime(data.departure_arrivalTime)} />
          </div>
        )}
      </section>
    </div>
  );
};

// Type for DetailRow Props
interface DetailRowProps {
  label: string;
  value?: string;
  fullRow?: boolean; // New prop for full-row display
}

// Helper Component for Label and Value formatting
const DetailRow: React.FC<DetailRowProps> = ({ label, value, fullRow }) => (
  <div className={fullRow ? "col-span-2" : ""}>
    <p className="text-gray-600 font-medium">{label}</p>
    <p className="text-gray-900 text-base">{value || "N/A"}</p>
  </div>
);

// Helper Functions for Date and Time Formatting
const formatDate = (dateString?: string): string => {
  if (!dateString) return "N/A";
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const formatTime = (timeString?: string): string => {
  if (!timeString) return "N/A";
  return new Date(timeString).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export default RequirementDetails;