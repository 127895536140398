import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    activityFormCurrentStep,
    backFormStep,
    selectActivityForm,
} from "../../../../../tripSlice";
import { ActivityType } from "../../../../../../../constants";
import GalleryImage from "../../../../../../../components/ImagesGallery/Image";
import FilePreview from "../../../../../../../components/DropFileInput/FilePreview";
import ButtonSquared from "../../../../../../../components/ButtonSquare";
import Icon from "../../../../../../../assets/svg/Icon";
import ContactCard from "../../../ContactCard";
import { useMemo } from "react";
import { timeToMeridianTime } from "../../../../../../../helpers";
import { Paragraph } from "../../../../../../../components/Paragraph/Paragraph";

export default function ActivityFormPreview(props: {
    onSubmit: (activity: any) => void;
    isLibraryElement?: boolean;
}) {
    const dispatch = useAppDispatch();
    const activityForm = useAppSelector(selectActivityForm);
    const currentStep = useAppSelector(activityFormCurrentStep);
    const {
        type,
        images,
        files,
        hotel,
        contacts,
        imagesFromLibraryElement,
        filesFromLibraryElement,
    } = activityForm;

    const settings = useAppSelector((state) => state.user.settings);
    const isStay = useMemo(() => {
        return moment
            .utc(activityForm?.startDate)
            .isBefore(activityForm?.endDate, "day");
    }, [activityForm?.startDate, activityForm?.endDate]);

    const handleSubmitForm = async (e: any) => {
        e?.preventDefault();
        const activity: any = {
            type: ActivityType.HOTEL,
            startDate: new Date(
                moment.utc(activityForm.startDate).format("yyyy-MM-DD")
            ),
            endDate: new Date(
                moment.utc(activityForm.endDate).format("yyyy-MM-DD")
            ),
            startHour: activityForm.startHour,
            title: activityForm.title,
            description: activityForm.description,
            endHour: activityForm.endHour,
            hotel: activityForm.hotel,
            contacts: activityForm.contacts,
            images: images.map((img: any) => img.path),
            files: files.map((file: any) => file.path),
            website: activityForm.website,
            phone: activityForm.phone,
            imagesFromLibraryElement: activityForm.imagesFromLibraryElement,
            filesFromLibraryElement: activityForm.filesFromLibraryElement,
            libraryElementId: activityForm.libraryElementId,
        };
        props.onSubmit && props.onSubmit(activity);
    };

    const navigateBack = () => {
        dispatch(backFormStep(type, currentStep || ""));
    };

    const { endHour, startHour } = useMemo(() => {
        return {
            endHour: timeToMeridianTime(
                (activityForm || {}).endHour,
                settings?.otherSettings?.timeFormat24Hour
            ),
            startHour: timeToMeridianTime(
                (activityForm || {}).startHour,
                settings?.otherSettings?.timeFormat24Hour
            ),
        };
    }, [activityForm, settings?.otherSettings?.timeFormat24Hour]);

    return (
        <div className="mb-12 grid grid-cols-6 lg:gap-x-12 pb-16 lg:px-6">
            <div className="text-lg text-left font-semibold col-span-6">
                {activityForm?.title}
            </div>
            <div className="mt-6 col-span-6 text-left">
                <div className="text-lg font-normal  text-gray-700">
                    {hotel?.name || ""}
                </div>
            </div>

            <div className="text-sm font-medium mb-6 col-span-6 text-gray-500 flex gap-2 items-center">
                <div>
                    <Icon
                        icon={"MarkerIcon"}
                        color="gray"
                        className="text-gray-500 w-6 h-6"
                    />
                </div>

                <div>{hotel?.fullAddress || ""}</div>
            </div>
            {!props.isLibraryElement && (
                <>
                    <div className="mt-6 col-span-3 text-left">
                        <div className="text-base font-medium  text-gray-500">
                            Start Date
                        </div>
                        <div className="text-lg font-normal  text-gray-700">
                            {moment(activityForm?.startDate).format(
                                "yyyy-MM-DD"
                            )}
                        </div>
                    </div>
                    {isStay && activityForm?.endDate && (
                        <div className="mt-6 col-span-3 text-left">
                            <div className="text-base font-medium  text-gray-500">
                                End Date
                            </div>
                            <div className="text-lg font-normal  text-gray-700">
                                {moment(activityForm?.endDate).format(
                                    "yyyy-MM-DD"
                                )}
                            </div>
                        </div>
                    )}
                    {activityForm?.startHour && (
                        <div className="mt-6 col-span-3 text-left">
                            <div className="text-base font-medium  text-gray-500">
                                Start Hour
                            </div>
                            <div className="text-lg font-normal  text-gray-700">
                                {startHour}
                            </div>
                        </div>
                    )}
                    {isStay && activityForm?.endHour && (
                        <div className="mt-6 col-span-3 text-left">
                            <div className="text-base font-medium  text-gray-500">
                                End Hour
                            </div>
                            <div className="text-lg font-normal  text-gray-700">
                                {endHour}
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="mt-6 col-span-6 text-left">
                <div className="text-base font-medium  text-gray-500">
                    Description
                </div>
                <Paragraph className="text-lg font-normal  text-gray-700">
                    {activityForm?.description || "-"}
                </Paragraph>
            </div>
            <div className="mt-6 col-span-6 text-left">
                <div className="text-base font-medium  text-gray-500">
                    Phone
                </div>
                <div className="text-lg font-normal  text-gray-700">
                    {activityForm?.phone || "-"}
                </div>
            </div>
            <div className="mt-6 col-span-6 text-left">
                <div className="text-base font-medium  text-gray-500">
                    Website
                </div>
                <Paragraph className="text-lg font-normal  text-gray-700 break-all">
                    {activityForm?.website || "-"}
                </Paragraph>
            </div>
            {!!contacts?.length && (
                <>
                    <div className="text-sm font-semibold mt-8 mb-4">
                        Contacts
                    </div>
                    <div className="col-span-6 flex gap-6 flex-wrap">
                        {contacts.map((c: any, index: number) => (
                            <ContactCard hideEdit contact={c} key={index} />
                        ))}
                    </div>
                </>
            )}
            {(images?.length > 0 || imagesFromLibraryElement?.length > 0) && (
                <>
                    <div className="text-sm font-semibold mt-6">Photos</div>
                    <div className="flex flex-wrap gap-4 mt-4 col-span-6">
                        {images.map((i: any, index: number) => {
                            return (
                                <GalleryImage
                                    viewOnly
                                    key={i.file}
                                    src={i.file}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    // onImageAdd={(e: any) => handleImageAdd(index)}
                                />
                            );
                        })}
                        {imagesFromLibraryElement.map(
                            (i: any, index: number) => {
                                return (
                                    <GalleryImage
                                        viewOnly
                                        key={i.file}
                                        src={i.url}
                                        onImageClick={() => {}}
                                        imageClass="object-cover"
                                        // onImageAdd={(e: any) => handleImageAdd(index)}
                                    />
                                );
                            }
                        )}
                    </div>
                </>
            )}

            {(files?.length > 0 || filesFromLibraryElement?.length > 0) && (
                <>
                    <div className="text-sm font-semibold mt-8 mb-4">Files</div>
                    <div className="flex flex-wrap gap-4 col-span-6">
                        {files.map((file: any) => {
                            return (
                                <FilePreview
                                    key={file.id}
                                    name={file.file.name}
                                    viewOnly
                                />
                            );
                        })}
                        {filesFromLibraryElement.map((file: any) => {
                            return (
                                <FilePreview
                                    key={file.id}
                                    name={file.originalName}
                                    viewOnly
                                />
                            );
                        })}
                    </div>
                </>
            )}
            <div className="col-span-6 flex gap-3 lg:justify-end lg:px-4 lg:py-3 text-right mt-12 lg:absolute lg:right-4 lg:bottom-4">
                <ButtonSquared
                    onClick={navigateBack}
                    outlined
                    type="button"
                    label="Back"
                />
                <ButtonSquared
                    type="submit"
                    label="Confirm"
                    onClick={handleSubmitForm}
                />
            </div>
        </div>
    );
}
