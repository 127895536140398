import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../../components/Modal";
import Icon from "../../../../assets/svg/Icon";
import Avatar from "../../../../components/Avatar";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";
import { mdiChevronUp } from "@mdi/js";

export const GalleryImageModal = ({
    open,
    onClose,
    onDelete,
    onEdit,
    images = [],
    openOnIndex = 0,
}: {
    open: boolean;
    onClose: any;
    onDelete: any;
    onEdit: any;
    images: any[];
    openOnIndex: number;
}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(openOnIndex);
    useEffect(() => {
        setCurrentIndex(openOnIndex);
    }, [openOnIndex]);

    const selectedImage = useMemo(() => {
        return images[currentIndex];
    }, [images, currentIndex]);
    const handleNext = () => {
        if (images.length - 1 > currentIndex) setCurrentIndex(currentIndex + 1);
    };
    const handlePrev = () => {
        if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    };

    const showNext = useMemo(() => {
        return currentIndex !== images.length - 1;
    }, [currentIndex, images]);
    const showPrev = useMemo(() => {
        return !!currentIndex;
    }, [currentIndex]);
    const [showWholeDescription, setShowWholeDescription] = useState(false);
    return (
        <Modal
            open={open}
            size={"w-full"}
            translucid
            hideCloseButton
            onClose={onClose}
            closeOnBackClick
        >
            <div className="relative w-fit mx-auto">
                <button
                    onClick={onClose}
                    className="absolute cursor-pointer z-10 top-6 right-[1rem] bg-black/70 hover:bg-black text-white rounded-lg text-xs p-1 hover:text-lg"
                >
                    {<Icon icon="CloseIcon" className="w-7 z-10" />}
                </button>
                {selectedImage?.canEdit === "true" && (
                    <button
                        onClick={() => onDelete(selectedImage)}
                        className="absolute cursor-pointer z-10 top-6 right-[4rem] bg-black/70 hover:bg-black text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        {<Icon icon="TrashIcon" className="w-7 z-10" />}
                    </button>
                )}
                {selectedImage?.canEdit === "true" && (
                    <button
                        onClick={() => onEdit(selectedImage)}
                        className="absolute cursor-pointer z-10 top-6 right-[7rem] bg-black/70 hover:bg-black text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        {<Icon icon="PencilIcon" className="w-7 z-10" />}
                    </button>
                )}
                {!!showNext && (
                    <button
                        onClick={handleNext}
                        className="absolute cursor-pointer z-10 top-[50%] right-[1rem] bg-black/70 hover:bg-black text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        {<Icon icon="ChevronRightIcon" className="w-7 z-10" />}
                    </button>
                )}
                {!!showPrev && (
                    <button
                        onClick={handlePrev}
                        className="absolute cursor-pointer z-10 top-[50%] left-[1rem] bg-black/70 hover:bg-black text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        {<Icon icon="ChevronLeftIcon" className="w-7 z-10" />}
                    </button>
                )}
                {selectedImage?.isVideo ? (
                    <video
                        className="min-h-auto lg:min-h-[500px] max-h-[800px] rounded-xl w-auto"
                        src={selectedImage?.thumbnail || ""}
                        controls
                    />
                ) : (
                    <img
                        className="min-h-auto lg:min-h-[500px] max-h-[800px] rounded-xl w-auto"
                        src={selectedImage?.src || ""}
                        alt=""
                    />
                )}
                <div className="absolute bottom-0 left-0 right-0">
                    {selectedImage?.owner && (
                        <div className="z-10 bg-black/70 text-white rounded-lg text-xs lg:text-lg p-4 my-4 mx-16 w-fit">
                            <div className="text-xs lg:text-base flex flex-row gap-2 items-center justify-start self-start">
                                <Avatar
                                    src={
                                        !!selectedImage?.owner?.avatar_thumb
                                            ?.length
                                            ? selectedImage?.owner?.avatar_thumb
                                            : selectedImage?.owner?.avatar
                                    }
                                    className="h-4 2-4 lg:w-6 lg:h-6"
                                />
                                <div className="hidden lg:block text-center text-xs">
                                    {`${selectedImage?.owner?.firstName} ${selectedImage?.owner?.lastName}`}
                                </div>
                            </div>
                        </div>
                    )}
                    {!!selectedImage?.description?.length && (
                        <div className="z-10 bg-black/70 text-white rounded-lg text-xs lg:text-lg p-4 mx-16 mb-4 flex flex-col justify-between">
                            {!showWholeDescription ? (
                                <span
                                    onClick={() => {
                                        setShowWholeDescription(true);
                                    }}
                                    className="truncate text-xs lg:text-base cursor-pointer"
                                >
                                    {selectedImage?.description}
                                </span>
                            ) : (
                                <div className="flex flex-col">
                                    <Paragraph className="text-xs lg:text-base">
                                        {selectedImage?.description}
                                    </Paragraph>
                                    <div className="flex flex-row items-end justify-end">
                                        <div
                                            onClick={() => {
                                                setShowWholeDescription(false);
                                            }}
                                        >
                                            <Icon
                                                materialIcon={mdiChevronUp}
                                                className={`w-7 text-white cursor-pointer`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
