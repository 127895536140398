/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Timeline from "../../../../components/Timeline";
import {
    getTripActivities,
    selectedTrip,
    selectTripActivities,
    updateTrip,
} from "../../tripSlice";

import {
    ActivityType,
    activityTypeOnModel,
    EditUserRoleOnTrip,
    PAGE_SECTIONS,
    UserRoleOnTrip,
} from "../../../../constants";
import moment from "moment";
import { groupBy } from "../../../../helpers";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { ItinerarySkeleton } from "./Components/ItinerarySkeleton";
import _ from "lodash";
import PublishSwitch from "./Components/PublishSwitch";
import ItineraryHiddenPage from "./Components/ItineraryHiddenPage";
import { isLoadingSelector } from "../../../layout";
import { selectProfile } from "../../../authentication/userSlice";

export default function TripItinerary() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tripItinerary = useAppSelector(selectTripActivities);
    const trip = useAppSelector(selectedTrip);
    // const [itineraryDates, setItineraryDates] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isLoadingGeneral = useAppSelector(isLoadingSelector);
    const [searchParams] = useSearchParams();
    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_ITINERARY })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip?.id) {
            getActivities(Number(trip.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id]);

    useEffect(() => {
        if (tripItinerary) {
            setTimeout(() => {
                const date = searchParams.get("date");
                const element = document.getElementById(date || "");
                element?.scrollIntoView({ behavior: "smooth" });
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripItinerary]);

    const getActivities = async (tripId: number) => {
        setIsLoading(true);
        await dispatch(getTripActivities(Number(tripId)));
        setIsLoading(false);
    };

    const calculateActivitiesDates = (startDate: any, endDate: any) => {
        if (startDate) {
            let dates = [];
            const start = moment.utc(startDate).startOf("day");
            const end = moment.utc(endDate).startOf("day");
            dates.push(start.clone().format("YYYY-MM-DD"));
            while (start.add(1, "days").diff(end) < 0) {
                dates.push(start.clone().format("YYYY-MM-DD"));
            }
            dates.push(end.clone().format("YYYY-MM-DD"));
            if (dates[0] === dates[dates.length - 1]) {
                dates = [dates[0]];
            }
            return dates;
        }
    };

    const itineraryDates: any = useMemo(() => {
        return calculateActivitiesDates(
            tripItinerary?.startDate,
            tripItinerary?.endDate
        );
    }, [tripItinerary?.startDate, tripItinerary?.endDate]);

    const handleClickNewActivity = (
        activityType: ActivityType,
        date?: string
    ) => {
        return navigate(`new/${activityType}${date ? "?date=" + date : ""}`);
    };

    const normalizeActivities = (activities: Array<any>, dates: string[]) => {
        let actNormalized: any[] = [];
        let hotelStayIntermediateActivities: any[] = [];
        if (activities && Array.isArray(activities)) {
            activities?.forEach((act) => {
                if (act.type === ActivityType.HOTEL) {
                    const start = moment.utc(act.startDate).startOf("day");
                    const end = moment.utc(act.endDate).startOf("day");
                    const diff = end.diff(start, "days");
                    for (let index = 0; index < diff; index++) {
                        hotelStayIntermediateActivities.push({
                            ...act,
                            startDate: start.add(1, "day").toDate(),
                            startHour: index < diff - 1 ? "00:00" : act.endHour,
                            isStayDay: true,
                        });
                    }
                }
            });

            const joinedActivities = activities.concat(
                hotelStayIntermediateActivities
            );
            actNormalized = groupBy(
                joinedActivities?.map((act: any) => {
                    return {
                        id: act[
                            activityTypeOnModel[
                                act.type as keyof typeof activityTypeOnModel
                            ]
                        ].activityId,
                        ...act,

                        startDate: moment
                            .utc(act.startDate)
                            .format("YYYY-MM-DD"),
                    };
                }),
                "startDate"
            );
        }
        dates?.forEach((date: any) => {
            actNormalized[date] = _.sortBy(
                actNormalized[date],
                (a) => a.startHour
            );
        });
        return actNormalized;
    };

    const normalizedActivities = useMemo<any>(() => {
        return normalizeActivities(tripItinerary?.activities, itineraryDates);
    }, [tripItinerary, itineraryDates]);

    const handleActivityClick = (activityId: number) => {
        navigate(`${activityId}`);
    };

    const handleConstructionModeToogle = (currentVal: boolean) => {
        const itineraryOn = !currentVal;
        dispatch(
            updateTrip(trip?.id, {
                itineraryOn,
            })
        );
    };

    const isAdvisor = useMemo(
        () => trip?.role === UserRoleOnTrip.MEMBER,
        [trip?.role]
    );

    const showBuildMode = useMemo(
        () =>
            !isLoadingGeneral &&
            !isLoading &&
            !trip?.itineraryOn &&
            !trip?.canEdit,
        [isLoadingGeneral, isLoading, trip?.itineraryOn, trip?.canEdit]
    );

    const showTimeline = useMemo(
        () => itineraryDates && !isLoading && !showBuildMode,
        [itineraryDates, isLoading, showBuildMode]
    );

    return (
        <div className="flex flex-col">
            {/* {isAdvisor && trip?.itineraryOn && <AppStoreBanner />} */}
            {!isLoading && !!trip?.canEdit && (
                <div className="flex flex-col justify-end items-end gap-2 mb-2">
                    <PublishSwitch
                        disabled={
                            !(
                                profile?.isAdmin ||
                                (EditUserRoleOnTrip.includes(trip?.role) &&
                                    !trip?.suspended)
                            )
                        }
                        defaultChecked={trip?.itineraryOn}
                        onClick={() => {
                            handleConstructionModeToogle(trip?.itineraryOn);
                        }}
                    />
                </div>
            )}
            {showBuildMode && <ItineraryHiddenPage />}

            {isLoading && <ItinerarySkeleton />}
            {showTimeline &&
                itineraryDates.map((ActivityDate: string, index: number) => {
                    const title = moment(ActivityDate).format("ddd, DD MMM");
                    return (
                        <Timeline
                            canEdit={
                                profile?.isAdmin ||
                                (EditUserRoleOnTrip.includes(trip?.role) &&
                                    !trip?.suspended)
                            }
                            isFirst={index === 0}
                            isLast={index === itineraryDates?.length - 1}
                            title={title}
                            ActivityDate={ActivityDate}
                            handleClickNewActivity={handleClickNewActivity}
                            activities={normalizedActivities[ActivityDate]}
                            handleActivityClick={handleActivityClick}
                            key={ActivityDate}
                            trip={trip}
                        />
                    );
                })}
        </div>
    );
}
