import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ButtonSquared from "../ButtonSquare";
import { ChoosePlanModal } from "../Modal/ChoosePlanModal";

interface BubblePlanProps {
    plan?: any;
}

export const BubblePlan = (props: BubblePlanProps) => {
    const { plan } = props;
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const  [showPlanModal, setShowPlanModal] = useState(false);
    const currenUser = useSelector((state: any) => state?.user?.profile);

    const handlePlanChanged = (plan: any) => {
        if (plan?.defaultPlan) {
            setShowPlanModal(false);
        } else {
            handleClose();
        }
    };

    const handleClickCloseChangePlan = () => {
        setShowPlanModal(false)
        
    };

    const handleClose = () => {
        setShowPlanModal(false)
    };

    const userType = useMemo(() => {
        return plan?.userRole || userProfile?.userType;
    }, [userProfile?.userType]);
    const userTypeLabel = useMemo(() => {
        return userType === "SUPPLIER" ? "Supplier" : "Advisor";
    }, [userType]);
    const subscriptionStatus = useMemo(() => {
        switch (plan?.subscriptionStatus) {
            case "trialing":
            case "free":
            case "active":
                return "Active";
            case "canceled":
                return "Cancelled";
            case "past_due":
                return "Past Due";
            case "unpaid":
                return "Unpaid";
            case "incomplete":
            case "incomplete_expired":
                return "Incomplete";
            default:
                return "Free";
        }
    }, [plan?.annual, plan?.subscriptionStatus]);
    return (
        <div
            className={`my-4 border rounded-lg p-4 ${
                userType !== "ADVISOR"
                    ? "border-orange-500"
                    : "border-neutral-300"
            }
            ${userType !== "ADVISOR" ? "bg-[#FDF2EE]" : "bg-neutral-50"} `}
        >
            <div className="w-full flex gap-4 justify-between">
                <span className="text-neutral-900 text-left font-semibold">
                    {userTypeLabel}
                </span>
                <span className="text-neutral-900 text-left font-semibold">
                    {subscriptionStatus}
                </span>
            </div>
            <div className="mt-1 text-neutral-700 font-semibold w-full flex gap-4 justify-between">
                <span className="text-xs text-left">
                    {plan?.planName || plan?.subscriptionDescription}
                </span>
                <span className="text-xs">{plan?.trialDuration}</span>
            </div>
            {!plan?.suspended &&
                (!!plan?.trialEndDate || !!plan?.currentPeriodEnds) && (
                    <div className="mt-1 w-full flex gap-4 justify-between text-neutral-800 font-semibold">
                        <span className="text-sm text-left">Plan expires</span>
                        <span className="text-sm">
                            {plan?.subscriptionStatus === "trialing"
                                ? plan?.trialEndDate
                                : plan?.currentPeriodEnds}
                        </span>
                    </div>
                )}
            {plan?.suspended && (
                <div className="w-full flex gap-4 justify-between text-neutral-800 items-center">
                    <span className="text-neutral-700 text-xs text-left font-semibold">
                        Plan ended:{" "}
                        {plan?.subscriptionStatus === "trialing"
                            ? plan?.trialEndDate
                            : plan?.currentPeriodEnds}
                    </span>

                    {!!plan?.plan?.link && ( 
                        <ButtonSquared
                            label="Renew subscription"
                            onClick={() => setShowPlanModal(true)}
                        />
                    )}
                </div>
            )}

            <ChoosePlanModal
                open={showPlanModal}
                handleClose={() => handlePlanChanged}
                forceCloseModal={() => setShowPlanModal(false)}
                onChangePlan={() => handleClickCloseChangePlan()}
                userType={currenUser?.userType}
            />
        </div>
    );
};
