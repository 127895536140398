/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    PAGE_SECTIONS,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import {
    getTripCustomInvite,
    getTripHome,
    getTripNotifications,
    selectedTrip,
    selectedTripCustomInvite,
    selectedTripHome,
    setSelectedTripNotifications,
    tripNotifications,
} from "../tripSlice";
import { ItineraryCard } from "./components/ItineraryCard";
import { NotificationsList } from "../../../components/NotificationsList/NotificationsList";
import { ParticipantsCard } from "./components/ParticipantsCard";
import { TripDetailCard } from "./components/TripDetailCard";
import { SuspendedTripBanner } from "./components/SuspendedTripBanner";
import { useSelector } from "react-redux";
import { selectProfile } from "../../authentication/userSlice";
import { useAuthStateContext } from "../../../context/Auth";
import { TopTripBanner } from "./components/TopTripBanner";

export default function TripDetail() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tripHome = useAppSelector(selectedTripHome);
    const trip = useAppSelector(selectedTrip);
    const notifications = useAppSelector(tripNotifications);
    const customInvite = useAppSelector(selectedTripCustomInvite);
    const currentPlan = useSelector((state: any) => state.plans.currentPlan);
    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        if (trip?.id && trip?.status) {
            fetchData(Number(trip.id));
            if (
                [UserStatusOnTrip.CONFIRMED, UserStatusOnTrip.BLOCKED].includes(
                    trip?.status
                )
            ) {
                dispatch(
                    setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP })
                );
            } else {
                dispatch(
                    setCurrentSection({
                        currentSection: PAGE_SECTIONS.PENDING_TRIP,
                    })
                );
            }
        }

        return () => {
            dispatch(
                setSelectedTripNotifications({
                    selectedTripNotifications: {
                        data: [],
                        count: 0,
                        currentPage: 0,
                        isLoading: false,
                        skip: 0,
                        take: 0,
                        totalPages: 0,
                    },
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id, trip?.status]);

    const fetchData = (id: number) => {
        if (trip) {
            dispatch(getTripHome(id, false));
            dispatch(getTripCustomInvite(Number(id)));
            if (trip?.status === UserStatusOnTrip.CONFIRMED) {
                dispatch(getTripNotifications(Number(id), 1000, 0));
            }
        }
    };

    const notificationsArray = useMemo(() => {
        return notifications?.data.slice(0, 5);
    }, [notifications?.data]);

    const handleViewAllNotificationsClick = () => {
        navigate(`/trips/${tripHome?.trip?.id}/notifications`);
    };

    const { getTripsCount, isFreeSupplier, setShowPlanModal, tripsLimitReached } =
        useAuthStateContext();
    useEffect(() => {
        getTripsCount && getTripsCount();
    }, []);

    return (
        <>
            {trip && (
                <div className="flex flex-col gap-6">
                    {!!isFreeSupplier &&
                        tripsLimitReached &&
                        trip?.role === UserRoleOnTrip.OWNER && (
                            <div className="mb-4">
                                <TopTripBanner
                                    hideIcon
                                    title="Free trial"
                                    text="Explore our plans to find the perfect one for you."
                                    action={() => {
                                        setShowPlanModal(true);
                                    }}
                                    actionLabel="View Plans"
                                    className={
                                        "flex flex-row justify-between items-center"
                                    }
                                    actionWrapperClass="!mt-0"
                                />
                            </div>
                        )}
                    <SuspendedTripBanner
                        suspended={trip?.suspended && !profile?.isAdmin}
                        inGracePeriod={trip?.inGracePeriod}
                        gracePeriodStarts={trip?.gracePeriodStarts}
                        gracePeriodEnds={trip?.gracePeriodEnds}
                        billingDateFormat={trip?.billingDateFormat}
                        link={currentPlan?.plan?.link}
                        isOwner={trip?.role === UserRoleOnTrip.OWNER}
                    />
                    <TripDetailCard
                        isOwner={trip?.role === UserRoleOnTrip.OWNER}
                        organization={trip?.organization}
                        canEdit={trip?.canEdit}
                        hideActions={trip?.suspended && !profile?.isAdmin}
                        canDelete={trip?.canBeDeleted}
                        isPending={trip?.status === UserStatusOnTrip.PENDING}
                        tripHome={tripHome}
                        customInvite={customInvite}
                        hideDuplicate={!!isFreeSupplier}
                    />
                    {trip?.status === UserStatusOnTrip.CONFIRMED && (
                        <>
                            <ParticipantsCard />
                            <div className="flex flex-col lg:flex-row gap-6">
                                <div className="w-full lg:w-1/2">
                                    <NotificationsList
                                        isLoading={notifications?.isLoading}
                                        notifications={notificationsArray}
                                        viewAllClick={
                                            handleViewAllNotificationsClick
                                        }
                                    />
                                </div>
                                <div className="w-full lg:w-1/2">
                                    <ItineraryCard trip={tripHome?.trip} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}
