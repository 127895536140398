import React, { useEffect, useMemo, useState } from "react";
import { uploadTempFile } from "../../tripSlice";
import Modal, { Modal_Size } from "../../../../components/Modal";
import TextAreaInput from "../../../../components/TextArea/TextArea";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";
import DropMediaFileInput from "../../../../components/DropMediaFileInput/DropMediaFileInput";
import GalleryMedia from "../../../../components/GalleryMedia/GalleryMedia";

interface InsightMediaProps {
    handleOnChange?: (mediaLoaded: MediaLoaded) => void;
    images: ImagesUploadedInterface[];
    videos: VideoUploadedInterface[];
}

export interface MediaInterface {
    path: string;
    src?: string;
    url?: string;
    id: number;
    originalName?: string;
    description?: string;
    type: "image" | "video";
    current?: boolean;
    modified?: boolean;
    deleted?: boolean;
    thumbPath?: string;
    thumb?: string;
    isVideo?: boolean;
}

export interface ImagesUploadedInterface extends MediaInterface {
    url: string;
}

export interface VideoUploadedInterface extends MediaInterface {
    url: string;
}

interface MediaLoaded {
    newImages: MediaInterface[];
    deletedImages: string[];
    modifiedImages: MediaInterface[];
    newVideos: MediaInterface[];
    deletedVideos: string[];
    modifiedVideos: MediaInterface[];
}

enum FILE_TYPE {
    IMAGE = "image",
    VIDEO = "video",
}

const normalizeImagesListInputData = (
    images: MediaInterface[] = []
): Omit<MediaLoaded, "newVideos" | "deletedVideos" | "modifiedVideos"> => {
    let newImages: MediaInterface[] = [];
    let deletedImages: string[] = [];
    let modifiedImages: MediaInterface[] = [];

    images.forEach((img) => {
        if (!img.current) {
            newImages.push(img);
        } else if (img.deleted) {
            deletedImages.push(img.originalName || "");
        } else {
            modifiedImages.push(img);
        }
    });

    return {
        newImages,
        modifiedImages,
        deletedImages,
    };
};

const normalizeVideosListInputData = (
    videos: MediaInterface[] = []
): Omit<MediaLoaded, "newImages" | "deletedImages" | "modifiedImages"> => {
    let newVideos: MediaInterface[] = [];
    let deletedVideos: string[] = [];
    let modifiedVideos: MediaInterface[] = [];

    videos.forEach((video) => {
        if (!video.current) {
            newVideos.push(video);
        } else if (video.deleted) {
            deletedVideos.push(video.originalName || "");
        } else {
            modifiedVideos.push(video);
        }
    });

    return {
        newVideos,
        modifiedVideos,
        deletedVideos,
    };
};

export const InsightMedia = ({
    handleOnChange,
    images = [],
    videos = [],
}: InsightMediaProps) => {
    const [imagesPreview, setImagesPreview] = useState<
        ImagesUploadedInterface[]
    >([]);
    const [videosPreview, setVideosPreview] = useState<
        VideoUploadedInterface[]
    >([]);
    const [mediaModalOpen, setMediaModalOpen] = useState<MediaInterface | null>(
        null
    );
    const [newMediaDescription, setNewMediaDescription] = useState<string>("");

    const initialImages = useMemo(() => {
        let initialImagesArray: ImagesUploadedInterface[] = [];
        try {
            const imagesArray = Array.isArray(images)
                ? images
                : Object.values(images);
            initialImagesArray = imagesArray.map((img: any) => ({
                ...img,
                current: true,
            }));
        } catch {}
        return initialImagesArray;
    }, [images]);

    useEffect(() => {
        setImagesPreview((prev) => [
            ...prev?.filter((i) => !i.current),
            ...initialImages,
        ]);
    }, [initialImages]);

    const initialVideos = useMemo(() => {
        let initialVideosArray: VideoUploadedInterface[] = [];
        try {
            initialVideosArray =
                videos?.map((video) => ({
                    ...video,
                    current: true,
                })) || [];
        } catch {}
        return initialVideosArray;
    }, [videos]);

    useEffect(() => {
        setVideosPreview((prev) => [
            ...prev?.filter((i) => !i.current),
            ...initialVideos,
        ]);
    }, [initialVideos]);

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];
        const fileType = file.type.startsWith("video/")
            ? FILE_TYPE.VIDEO
            : FILE_TYPE.IMAGE;
        const response = await uploadTempFile(
            file,
            (fileType === FILE_TYPE.IMAGE
                ? imagesPreview.length
                : videosPreview.length) + 1,
            undefined,
            undefined,
            fileType === FILE_TYPE.VIDEO ? true : false
        );

        if (fileType === FILE_TYPE.IMAGE) {
            const newImage: MediaInterface = {
                path: response,
                id: new Date().getTime(),
                current: false,
                type: FILE_TYPE.IMAGE,
            };
            const updatedImages = [
                ...imagesPreview,
                {
                    ...newImage,
                    url: URL.createObjectURL(file),
                },
            ];
            setImagesPreview(updatedImages);
        } else {
            const newVideo: MediaInterface = {
                path: response.url,
                id: new Date().getTime(),
                type: FILE_TYPE.VIDEO,
                current: false,
                thumbPath: response.thumbnail,
            };
            const updatedVideos = [
                ...videosPreview,
                {
                    ...newVideo,
                    url: URL.createObjectURL(file),
                },
            ];
            setVideosPreview(updatedVideos);
        }
    };

    useEffect(() => {
        const payloadImages = normalizeImagesListInputData(imagesPreview);
        const payloadVideos = normalizeVideosListInputData(videosPreview);
        if (handleOnChange) {
            handleOnChange({
                newImages: payloadImages.newImages,
                deletedImages: payloadImages.deletedImages,
                modifiedImages: payloadImages.modifiedImages,
                newVideos: payloadVideos.newVideos,
                deletedVideos: payloadVideos.deletedVideos,
                modifiedVideos: payloadVideos.modifiedVideos,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesPreview, videosPreview]);

    const removeMedia = (item: MediaInterface) => {
        if (item.type === "video" || item.isVideo) {
            if (item.current) {
                setVideosPreview((prev) =>
                    prev.map((video) =>
                        video.originalName === item.originalName &&
                        item.thumb === video.thumb
                            ? { ...video, deleted: true }
                            : video
                    )
                );
            } else {
                const index = videosPreview.findIndex(
                    (video) => video.id === item.id
                );
                if (index !== -1) {
                    URL.revokeObjectURL(videosPreview[index].url);
                    setVideosPreview((prev) =>
                        prev.filter((video) => video.id !== item.id)
                    );
                }
            }
        } else {
            if (item.current) {
                setImagesPreview((prev) =>
                    prev.map((img) =>
                        img.originalName === item.originalName
                            ? { ...img, deleted: true }
                            : img
                    )
                );
            } else {
                const index = imagesPreview.findIndex(
                    (img) => img.id === item.id
                );
                if (index !== -1) {
                    URL.revokeObjectURL(imagesPreview[index].url);
                    setImagesPreview((prev) =>
                        prev.filter((img) => img.id !== item.id)
                    );
                }
            }
        }
    };

    const updateMediaDescription = (item: MediaInterface) => {
        if (item.type === "video" || item.isVideo) {
            setVideosPreview((prev) =>
                prev.map((video) => {
                    return video.url === item.url && video.thumb === item.thumb
                        ? { ...video, description: newMediaDescription }
                        : video;
                })
            );
        } else {
            setImagesPreview((prev) =>
                prev.map((img) =>
                    img.url === item.url && img.thumb === item.thumb
                        ? { ...img, description: newMediaDescription }
                        : img
                )
            );
        }
        setMediaModalOpen(null);
    };

    const mediaPreview = useMemo(() => {
        return [
            ...imagesPreview.filter((img) => !img.deleted),
            ...videosPreview.filter((video) => !video.deleted),
        ];
    }, [imagesPreview, videosPreview]);
    const handleDotsMenu = (key: string, item: MediaInterface) => {
        switch (key) {
            case "DESCRIPTION":
                setMediaModalOpen(item);
                setNewMediaDescription(item.description || "");
                break;
            default:
                break;
        }
    };

    return (
        <div className="col-span-6 sm:col-span-3">
            <Modal
                open={!!mediaModalOpen}
                size={Modal_Size.md}
                onClose={() => setMediaModalOpen(null)}
            >
                <div className="flex flex-col items-center justify-center mx-6">
                    {mediaModalOpen?.type === "video" ||
                    mediaModalOpen?.isVideo ? (
                        <video
                            className="rounded-xs w-[700px] h-[500px] object-cover"
                            src={
                                mediaModalOpen?.url ||
                                mediaModalOpen?.path ||
                                ""
                            }
                            controls
                        />
                    ) : (
                        <img
                            className="rounded-xs w-[700px] h-[500px] object-cover"
                            src={
                                mediaModalOpen?.url ||
                                mediaModalOpen?.path ||
                                ""
                            }
                            alt=""
                        />
                    )}
                    <div className="mx-6 mt-6 flex flex-col w-full">
                        <TextAreaInput
                            label="Comments"
                            id="reply"
                            name="reply"
                            defaultValue={mediaModalOpen?.description}
                            onChange={(e: any) => {
                                mediaModalOpen &&
                                    setMediaModalOpen({
                                        ...mediaModalOpen,
                                        description: e.target.value,
                                    });
                                setNewMediaDescription(e.target.value);
                            }}
                        />
                        <div className="flex flex-row justify-end items-end">
                            <ButtonSquared
                                onClick={() => setMediaModalOpen(null)}
                                className="px-6 mr-4"
                                size={ButtonSize.FIT}
                                outlined
                                label="Cancel"
                            />
                            <ButtonSquared
                                className="px-6"
                                size={ButtonSize.FIT}
                                label="Save"
                                onClick={() =>
                                    updateMediaDescription(
                                        mediaModalOpen || ({} as MediaInterface)
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="font-semibold text-base text-neutral-900">
                Photos & Videos
            </div>
            <div className="flex-grow text-left text-sm font-medium text-neutral-600 mb-5">
                Capture and share videos (recommended: 90 seconds max, MP4
                format)
            </div>
            <div className="flex gap-4 flex-wrap items-center">
                <div className="max-h-[124px] mb-2">
                    <DropMediaFileInput
                        mediaClass="h-[124px] w-[200px] rounded-sm overflow-hidden box-border"
                        className="!py-4 !px-4"
                        iconClass="!w-12 !h-12"
                        onChange={onSelectFile}
                        accept="image/*,video/*"
                        label="Browse or drag media files"
                    />
                </div>
                {mediaPreview.map((item, index) => {
                    let previewSrc = item.url || item.path;
                    if (item.isVideo || item.type === "video") {
                        previewSrc = item.thumbPath || item.thumb || previewSrc;
                    }

                    const type =
                        item.type || (item.isVideo ? "video" : "image");
                    return (
                        <GalleryMedia
                            key={`insight_media_${index}${type}`}
                            src={previewSrc}
                            description={item.description}
                            type={type}
                            mediaClass="h-[82px] z-10 object-cover mb-2"
                            containerClass="flex-[0_0_auto]"
                            onMediaClick={() => {}}
                            onMediaRemove={() => {
                                removeMedia(item);
                            }}
                            threeDots={[
                                {
                                    icon: "PlusIcon",
                                    label: "Add description",
                                    key: "DESCRIPTION",
                                },
                                {
                                    icon: "TrashIcon",
                                    label: "delete",
                                    key: "DELETE",
                                },
                            ]}
                            handleDotsMenu={(k) => handleDotsMenu(k, item)}
                        />
                    );
                })}
            </div>
        </div>
    );
};
