import { mdiExclamation } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";

const FailedIcon = ({
    width = 20,
    height = 20,
    color = "black",
    ...otherProps
}) => <MDIcon path={mdiExclamation} {...otherProps} />;

export default FailedIcon;