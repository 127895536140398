import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Avatar from "../../../../../components/Avatar";
import ButtonSquared from "../../../../../components/ButtonSquare";
import EmptyScreen from "../../../../../components/EmptyScreen/EmptyScreen";
import ReviewDetails from "../../../../../components/Reviews/ReviewDetails";
import TabComponent from "../../../../../components/TabComponent";
import {
    PAGE_SECTIONS,
    PAGE_ROWS,
    PROFILE_TAB_ITEMS_INDEX,
    PROFILE_TABS,
    TripType,
    ButtonSize,
} from "../../../../../constants";
import DietaryInfo from "../../../../authentication/profile/Dietary/DietaryView";
import ProfessionalInfo from "../../../../authentication/profile/Professional/ProfessionalInfo";
import ProfileDetails from "../../../../authentication/profile/ProfileDetails";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import {
    getTripUser,
    getTripUserPDF,
    getTripUserReviews,
    IPaginatedDataInitialState,
    selectedTrip,
    selectUserOnTrip,
    selectUserOnTripReviews,
    setSelectedUserOnTripReviews,
} from "../../../tripSlice";
import ParticipantDocuments from "./ParticipantDocuments";
import { selectProfile } from "../../../../authentication/userSlice";

export default function ParticipantProfile() {
    let { userId } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const trip = useAppSelector(selectedTrip);
    const user = useAppSelector(selectUserOnTrip);
    const reviews = useAppSelector(selectUserOnTripReviews);
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const navigate = useNavigate();
    const filterTabsByRole = (
        tabs: any,
        showTripProfile: boolean
    ): { admin: boolean; id: string; text: string }[] => {
        return showTripProfile ? tabs : tabs.filter((tab: any) => !tab.admin);
    };

    const tabItems = useMemo(() => {
        return filterTabsByRole(
            PROFILE_TABS,
            user?.showTripProfile ||
                profile?.isAdmin ||
                profile?.email === user?.email
        ).filter((t) => {
            return t.id === PROFILE_TAB_ITEMS_INDEX.documents
                ? trip?.type === TripType.ORGANIZER
                : true;
        });
    }, [
        profile?.email,
        profile?.isAdmin,
        trip?.type,
        user?.email,
        user?.showTripProfile,
    ]);

    const hidePersonalInfo = useMemo(() => {
        return !(
            profile?.isAdmin ||
            trip?.canEdit ||
            profile?.email === user?.email
        );
    }, [profile?.email, profile?.isAdmin, trip?.canEdit, user?.email]);

    const [selectedTab, setSelectedTab] = useState<string | number>(
        tabItems[0].id
    );

    const sortedReviews = useMemo(() => {
        const reviewsToSort = [...reviews?.data];
        return reviewsToSort.sort(
            (a, b) =>
                new Date(a.activityDate).getTime() -
                new Date(b.activityDate).getTime()
        );
    }, [reviews?.data]);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_PARTICIPANTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userId && trip?.id) {
            dispatch(getTripUser(Number(trip?.id), Number(userId)));
            fetchReviews(true);
        }

        return () => {
            dispatch(
                setSelectedUserOnTripReviews({
                    reviews: IPaginatedDataInitialState,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, trip]);

    const fetchReviews = async (first?: boolean) => {
        if (trip?.id)
            await dispatch(
                getTripUserReviews(
                    trip.id,
                    Number(userId),
                    PAGE_ROWS,
                    first ? 0 : reviews.data.length
                )
            );
    };

    const changeTab = (tabIndex: string | number) => {
        setSelectedTab(tabIndex);
    };

    const handleExportUserPdf = () => {
        dispatch(
            getTripUserPDF(
                trip.id,
                user.id,
                `${user?.firstName}_${user?.lastName}`
            )
        );
    };

    return (
        <>
            {!!user && (
                <div className="flex flex-col pb-5">
                    <div className="flex flex-col lg:flex-row w-full justify-center items-center mb-6 gap-3 lg:gap-6">
                        <Avatar
                            src={user?.avatar}
                            className="w-10 h-10 lg:w-20 lg:h-20"
                        />

                        <div className="flex flex-grow flex-col text-left text-neutral-400">
                            <span className="text-lg font-semibold">
                                {user?.firstName} {user?.lastName}
                            </span>
                            <span className="text-base">{user?.email}</span>
                        </div>
                        {!!user?.id &&
                            (!!trip?.canEdit || profile?.isAdmin) && (
                                <div className="justify-end">
                                    <ButtonSquared
                                        onClick={handleExportUserPdf}
                                        size={ButtonSize.FIT}
                                        outlined
                                        label={"Export Member Profile"}
                                    />
                                </div>
                            )}
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <TabComponent
                            className="my-4 flex-grow text-left"
                            onChange={changeTab}
                            items={tabItems}
                            defaultSelected={selectedTab}
                        />
                    </div>
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.documents && (
                        <ParticipantDocuments
                            tripId={trip?.id}
                            userEmail={user?.email}
                            documentPendingId={searchParams.get(
                                "documentPendingId"
                            )}
                        />
                    )}
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.reviews && (
                        <>
                            <InfiniteScroll
                                dataLength={reviews?.data?.length} //This is important field to render the next data
                                next={fetchReviews}
                                hasMore={
                                    reviews.currentPage < reviews.totalPages
                                }
                                loader={""}
                            >
                                <div className="grid grid-cols-6 gap-12">
                                    {sortedReviews.map((rev) => {
                                        return (
                                            <div
                                                key={rev?.id}
                                                className="col-span-6 lg:col-span-3 h-fit border rounded-lg border-gray-300 p-4"
                                            >
                                                <ReviewDetails
                                                    review={rev}
                                                    showReportButton
                                                ></ReviewDetails>
                                            </div>
                                        );
                                    })}
                                </div>
                            </InfiniteScroll>
                            {!!reviews?.data?.length &&
                                !reviews.isLoading &&
                                reviews.currentPage < reviews.totalPages && (
                                    <div
                                        onClick={() => {
                                            fetchReviews();
                                        }}
                                        className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                                    >
                                        Load more
                                    </div>
                                )}
                            {reviews.isLoading && (
                                <div className="grid grid-cols-6 gap-y-6 gap-x-24">
                                    <div className="col-span-3 ">
                                        <Skeleton
                                            className={`rounded-xl h-[50px] w-full`}
                                        />
                                    </div>
                                    <div className="col-span-3 ">
                                        <Skeleton
                                            className={`rounded-xl h-[50px] w-full`}
                                        />
                                    </div>
                                </div>
                            )}
                            {!reviews.isLoading && !reviews?.data?.length && (
                                <EmptyScreen text="There are no reviews to show." />
                            )}
                        </>
                    )}
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.personalInfo && (
                        <div>
                            <ProfileDetails
                                profile={user}
                                hideAvatar={true}
                                hidePersonalInfo={hidePersonalInfo}
                            />
                        </div>
                    )}
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.dietaryReqs && (
                        <div>
                            <DietaryInfo
                                profile={user}
                                profileExtra={user?.profileExtra}
                                isMe={profile?.email === user?.email}
                                onEdit={() => {
                                    navigate("/profile");
                                }}
                                buttonText={"Go to Profile"}
                            />
                        </div>
                    )}
                    {selectedTab ===
                        PROFILE_TAB_ITEMS_INDEX.professionalInfo && (
                        <div>
                            <ProfessionalInfo profile={user} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
