import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    getTripRequirementFiles,
    selectedTrip,
    selectedTripRequirement,
    selectMeOnTrip,
    selectTripRequirementFiles,
} from "../../../tripSlice";
import {
    ButtonSize,
    GeneralStatus,
    UserStatusOnTrip,
} from "../../../../../constants";
import moment from "moment";
import ButtonSquared from "../../../../../components/ButtonSquare";
import RequirementFile from "./RequirementFile";
import { DATE_FORMAT, getStatusFromFiles } from "../../../../../helpers";
import { selectProfile } from "../../../../authentication/userSlice";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import TripRequirementFileForm from "./RequirementFileForm";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import { DescriptionText } from "../../../../../components/DescriptionText/DescriptionText";

export default function TripRequirementAdvisor() {
    let { tripId, requirementId } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const requirementFiles = useAppSelector(selectTripRequirementFiles)?.files;
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const profile = useAppSelector(selectProfile);
    const meOnTrip = useAppSelector(selectMeOnTrip(profile?.email)) || {};
    const [uploadModal, setUploadModal] = useState(false);

    useEffect(() => {
        if (trip?.id && requirementId)
            dispatch(
                getTripRequirementFiles(Number(trip?.id), Number(requirementId))
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id, requirementId]);

    const onEdit = () => {
        navigate("edit");
    };
    const onUpdate = () => {};
    const canEdit = trip?.canEdit;
    const fileStatus = getStatusFromFiles(requirementFiles);
    const allowedByStatus = [UserStatusOnTrip.CONFIRMED].includes(
        meOnTrip?.status
    );

    const filesPreviews = useMemo(() => {
        return Object.values(requirement?.requirementIncludedFiles || {});
    }, [requirement?.requirementIncludedFiles]);

    const showAddFileForm = [
        GeneralStatus.NOT_SUBMITTED,
        GeneralStatus.AWAITING_APPROVAL,
        GeneralStatus.REJECTED,
    ].includes(fileStatus);

    return (
        <>
            <Modal
                open={uploadModal}
                size={Modal_Size.lg}
                onClose={() => {
                    setUploadModal(false);
                }}
            >
                <TripRequirementFileForm
                    onSubmit={() => navigate(`/trips/${tripId}/requirements`)}
                    onCancel={() => setUploadModal(false)}
                />
            </Modal>
            {!!requirement?.name && (
                <div className="flex flex-wrap">
                    <div className="flex flex-col flex-grow">
                        <div className="text-left font-semibold text-lg">
                            {requirement?.name} -{" "}
                            {moment(requirement?.deadline).format(DATE_FORMAT)}
                        </div>
                    </div>
                    <div>
                        {canEdit && (
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                outlined={true}
                                onClick={onEdit}
                                label="Edit"
                            />
                        )}
                    </div>
                </div>
            )}
            <Paragraph className="text-left">
                <DescriptionText text={requirement?.description} />
            </Paragraph>
            <div className="my-4">
                {filesPreviews.map((file: any) => {
                    return (
                        <a
                            className="block max-w-fit cursor-pointer"
                            key={`file_${file?.originalName}`}
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            <FilePreview viewOnly name={file?.originalName} />
                        </a>
                    );
                })}
            </div>
            <div className="p-3">
                {requirementFiles?.map((file: any) => (
                    <RequirementFile
                        key={file.id}
                        tripId={requirement.tripId}
                        requirementId={requirement.id}
                        type={requirement.type}
                        file={file}
                        onActionTaken={onUpdate}
                    />
                ))}
            </div>

            {!!requirement?.name && showAddFileForm && allowedByStatus && (
                <div className="flex items-center">
                    <ButtonSquared
                        className="p-4 mx-auto"
                        type="button"
                        label="Add info"
                        onClick={() => {
                            setUploadModal(true);
                        }}
                    />
                </div>
            )}
        </>
    );
}
