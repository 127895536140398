import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal, { Modal_Size } from ".";
import { ButtonSize, ComplaintType, TOAST_TYPE } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import Input from "../Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addUserRequest } from "../../features/authentication/userSlice";
import server from "../../api/server";
import { sendToast } from "../../helpers";

export interface ModalProps {
    open?: boolean;
    handleClose: () => void;
    email: string;
}
const schema = yup
    .object({
        email: yup.string().email("Invalid email format").required("Required"),
    })
    .required("This field is required");
export const UserRequestChangeEmailModal: React.FunctionComponent<
    ModalProps
> = (props) => {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [closeMessage, setCloseMessage] = useState<string>("Close");
    const [showConfirmButton, setShowConfirmButton] = useState<boolean>(true);
    const { open, handleClose: onClose } = props;
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const {
        setError,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (data: any) => {
        const email = data.email.trim();
        let emailExistsResponse;
        try {
            emailExistsResponse = await server.userEmailExist(email);
        } catch (error) {}

        if (emailExistsResponse?.data) {
            setError("email", {
                type: "custom",
                message: "Email already registered.",
            });
        } else {
            await dispatch(
                addUserRequest(
                    {
                        itemType: ComplaintType.USER_EMAIL_CHANGE,
                        itemId: userProfile.id,
                        reason: `User email change (ID: ${userProfile.id})`,
                        title: `Change email of (${props.email})`,
                        data: { email },
                    },
                    onRequestResponse
                )
            );
        }
    };

    const onRequestResponse = (response: {
        success?: boolean;
        alreadyRequested?: boolean;
    }) => {
        if (response.success) {
            setTitle(`Your request has been sent.`);
            setDescription(
                `We have received your request to change your email and we are currently reviewing it. We will contact you with a decision shortly.`
            );
            setCloseMessage(`Close`);
            setShowConfirmButton(false);

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (response.alreadyRequested) {
            sendToast(
                TOAST_TYPE.ERROR,
                "There is already an open request for this action."
            );
            handleClose();
        }
    };

    useEffect(() => {
        setTitle(`Request to change email`);
        setDescription(``);
    }, []);

    return (
        <Modal open={open} size={Modal_Size.xs} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)} className="text-left px-6">
                <div>
                    <div className="text-xl text-center font-semibold mb-2 text-neutral-900">
                        {title}
                    </div>

                    <div className="text-center mb-4">
                        <p className="lg:text-center text-sm text-neutral-600">
                            {description}
                        </p>
                    </div>
                    {showConfirmButton && (
                        <>
                            <div className="text-left mb-4">
                                <div className="font-medium text-neutral-600 text-sm">
                                    Current email
                                </div>
                                <div className="text-base text-neutral-600">
                                    {props.email}
                                </div>
                            </div>
                            <div className="text-left mb-4">
                                <Input
                                    register={register}
                                    id="email-address"
                                    name="email"
                                    type="text"
                                    label="New Email Address"
                                    error={errors["email"]?.message}
                                    trim
                                />
                            </div>
                        </>
                    )}

                    <div className="flex flex-row gap-4 w-full justify-center text-center py-4">
                        <ButtonSquared
                            outlined
                            type="button"
                            onClick={onClose}
                            className="px-3"
                            label={closeMessage}
                            size={ButtonSize.SMALL}
                        />
                        {showConfirmButton && (
                            <ButtonSquared
                                label="Send request"
                                className="px-3"
                                type="submit"
                                size={ButtonSize.SMALL}
                            />
                        )}
                    </div>
                </div>
            </form>
        </Modal>
    );
};
