import { FC, SVGProps } from "react";
import { Icon as MDIcon } from "@mdi/react";
interface IIconOption extends SVGProps<SVGElement> {
    icon?: any;
    width?: string | number;
    height?: string | number;
    color?: string;
    hoverColor?: string;
    className?: string;
    title?: string;
    description?: string;
    materialIcon?: any;
}
const Icon: FC<IIconOption> = (props: IIconOption) => {
    const { materialIcon, className } = props;
    if (materialIcon) {
        return <MDIcon path={materialIcon} className={className} />;
    } else {
        const { icon, ...others } = props;
        const Icon = require(`./${icon}`).default;
        return <Icon {...others} />;
    }
};

export default Icon;
