import { FC, InputHTMLAttributes, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import GoogleMap from "../../../../../components/Map";
import {
    deleteActivity,
    selectedTrip,
    selectedTripActivity,
} from "../../../tripSlice";
import {
    ActivityType,
    activityTypeOnModel,
    ButtonSize,
    PAGE_SECTIONS,
} from "../../../../../constants";
import moment from "moment";
import ContactCard from "../ContactCard";
import GalleryImage from "../../../../../components/ImagesGallery/Image";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import ModalConfirmation from "../../../../../components/Modal/ConfirmationModal";
import {
    timeToMeridianTime,
    parsePhoneNumber,
    DATE_FORMAT,
} from "../../../../../helpers";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import ActivityDetailButtons from "./ActivityDetailButtons";
import ButtonSquared from "../../../../../components/ButtonSquare";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    text?: string;
}

const DetailItem: FC<InputProps> = ({ label, text, ...rest }) => {
    return (
        <>
            <div className="font-medium text-sm text-gray-500">{label}</div>
            <Paragraph className="text-base">
                {text}
                {rest.children}
            </Paragraph>
        </>
    );
};

export default function ActivityDetails() {
    const dispatch = useAppDispatch();
    const [mapMarker, setmapMarker] = useState<any | null>(null);
    const [deleteActivityModalOpen, setDeleteActivityModalOpen] =
        useState<boolean>(false);
    const navigate = useNavigate();
    let { activityId } = useParams();
    const activity = useAppSelector(selectedTripActivity);
    const trip = useAppSelector(selectedTrip);
    const { settings } = useAppSelector((state) => state.user);
    const {
        type,
        description,
        title,
        startDate,
        endDate,
        images,
        files,
        contacts,
        error,
        website,
        phone,
    } = activity || {};
    const { endHour, startHour } = useMemo(() => {
        return {
            endHour: timeToMeridianTime(
                (activity || {}).endHour,
                settings?.otherSettings?.timeFormat24Hour
            ),
            startHour: timeToMeridianTime(
                (activity || {}).startHour,
                settings?.otherSettings?.timeFormat24Hour
            ),
        };
    }, [activity]);
    const activityTypeKey =
        type && activityTypeOnModel[type as keyof typeof activityTypeOnModel];
    const { name, fullAddress, zipCode, lat, lng } =
        (activity && type && activity[activityTypeKey][type.toLowerCase()]) ||
        {};
    const startNormalized =
        startDate && moment.utc(startDate).format(DATE_FORMAT);
    const endNormalized = endDate && moment.utc(endDate).format(DATE_FORMAT);
    useEffect(() => {
        if (error) navigate(`/trips/${trip?.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const isStay = useMemo(() => {
        return (
            type === ActivityType.HOTEL &&
            moment.utc(activity.startDate).format("DD-MM-YYYY") !==
                moment.utc(activity.endDate).format("DD-MM-YYYY")
        );
    }, [type, activity?.startDate, activity?.endDate]);

    useEffect(() => {
        switch (type) {
            case ActivityType.HOTEL:
                dispatch(
                    setCurrentSection({
                        currentSection: !isStay
                            ? PAGE_SECTIONS.TRIP_ITINERARY_VISIT
                            : PAGE_SECTIONS.TRIP_ITINERARY_HOTEL,
                    })
                );
                break;
            case ActivityType.OTHER:
                dispatch(
                    setCurrentSection({
                        currentSection: PAGE_SECTIONS.TRIP_ITINERARY_OTHER,
                    })
                );
                break;
            case ActivityType.RESTAURANT:
                dispatch(
                    setCurrentSection({
                        currentSection: PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT,
                    })
                );
                break;
            default:
                dispatch(
                    setCurrentSection({
                        currentSection: PAGE_SECTIONS.TRIP_ITINERARY_OTHER,
                    })
                );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStay, type]);

    useEffect(() => {
        if (lat && lng) {
            const marker = {
                position: {
                    lat: lat,
                    lng: lng,
                },
                title: name,
            };
            setmapMarker(marker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);

    const hasAddress = useMemo(() => {
        return !!lat && fullAddress !== "undefined";
    }, [lat, fullAddress]);

    const imagesArray =
        images &&
        Object.keys(images).map((oKey) => {
            return {
                original: images[oKey].url,
                thumbnail: images[oKey].thumb,
            };
        });

    const filesArray =
        files &&
        Object.keys(files).map((oKey) => {
            return {
                name: files[oKey].originalName,
                url: files[oKey].url,
            };
        });

    const contactsNormalized = contacts
        ? Object.keys(contacts).map((cKey) => contacts[cKey])
        : [];

    const handleDeleteClick = async () => {
        await dispatch(deleteActivity(Number(trip?.id), Number(activityId)));
        navigate(`/trips/${trip?.id}/itinerary`);
    };

    return (
        <div className="flex flex-col text-left">
            <div className="flex justify-end flex-wrap w-full mb-4">
                <ActivityDetailButtons
                    setDeleteActivityModalOpen={setDeleteActivityModalOpen}
                />
            </div>
            <div className="flex-grow text-left text-lg font-semibold text-neutral-900">
                {title || name}
            </div>
            {name && (
                <>
                    {type !== ActivityType.OTHER && (
                        <div className="col-span-6 sm:col-span-3 mt-3">
                            <DetailItem label={type}>{name}</DetailItem>
                        </div>
                    )}
                    <div className="col-span-6 sm:col-span-3 mt-3">
                        {hasAddress && mapMarker && (
                            <GoogleMap marker={mapMarker} />
                        )}
                    </div>
                    <div className="grid grid-cols-6 gap-x-12">
                        {hasAddress && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Address">
                                    {fullAddress}
                                </DetailItem>
                            </div>
                        )}
                        {!!zipCode?.length && zipCode !== "undefined" && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Postal code">
                                    {zipCode}
                                </DetailItem>
                            </div>
                        )}
                        {phone && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Phone number">
                                    {parsePhoneNumber(phone)}
                                </DetailItem>
                            </div>
                        )}
                        {website && (
                            <div className="col-span-6 mt-3 text-left">
                                <DetailItem label="Website">
                                    <a
                                        className="text-base block mt-1 mb-2 break-all"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={website}
                                    >
                                        {website}
                                    </a>
                                </DetailItem>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-6 gap-x-12">
                        <div className="mt-6 col-span-6 lg:col-span-3">
                            <DetailItem
                                label="Start date"
                                text={startNormalized}
                            />
                        </div>
                        {isStay && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem
                                    label="End date"
                                    text={endNormalized}
                                />
                            </div>
                        )}
                        <div className="mt-6 col-span-6 lg:col-span-3">
                            <DetailItem label="Start hour" text={startHour} />
                        </div>
                        {endHour && startHour !== endHour && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="End hour" text={endHour} />
                            </div>
                        )}
                    </div>
                    {description && (
                        <div className="mt-8 col-span-6">
                            <DetailItem label="Description">
                                {description}
                            </DetailItem>
                        </div>
                    )}
                    {!!contactsNormalized?.length && (
                        <>
                            <div className="text-sm font-semibold mb-4 mt-8">
                                Contacts
                            </div>
                            {contactsNormalized && (
                                <div className="flex gap-6 flex-wrap">
                                    {contactsNormalized.map(
                                        (c: any, index: number) => (
                                            <ContactCard
                                                hideEdit
                                                contact={c}
                                                key={index + c.email}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {!!imagesArray?.length && (
                        <>
                            <div className="text-sm font-semibold mt-8 mb-4">
                                Photos
                            </div>
                            <div className="flex flex-wrap gap-4">
                                {imagesArray.map((i: any, index: number) => {
                                    return (
                                        <GalleryImage
                                            key={i.original}
                                            src={i.original}
                                            onImageClick={() => {}}
                                            viewOnly
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {!!filesArray?.length && (
                        <>
                            <div className="text-sm font-semibold mt-8 mb-4">
                                Files
                            </div>
                            <div className="flex flex-wrap gap-4">
                                {filesArray.map((file: any, index: number) => {
                                    return (
                                        <a
                                            key={index}
                                            href={file.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            download={true}
                                        >
                                            <FilePreview
                                                viewOnly
                                                name={file.name}
                                            />
                                        </a>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <div className="mt-12">
                        <ButtonSquared
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                return navigate(
                                    `/trips/${trip?.id}/itinerary?date=${moment
                                        .utc(startDate)
                                        .format("YYYY-MM-DD")}`
                                );
                            }}
                            outlined
                            label="Go back"
                        />
                    </div>
                </>
            )}
            <ModalConfirmation
                open={deleteActivityModalOpen}
                title="Are you sure?"
                description={`You are about to remove this activity`}
                handleCancel={() => setDeleteActivityModalOpen(false)}
                handleSubmit={handleDeleteClick}
            />
        </div>
    );
}
