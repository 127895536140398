import React, { useEffect, useState } from "react";
import { uploadTempFile } from "../../tripSlice";
import Modal, { Modal_Size } from "../../../../components/Modal";
import TextAreaInput from "../../../../components/TextArea/TextArea";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";
import DropMediaFileInput from "../../../../components/DropMediaFileInput/DropMediaFileInput";
import GalleryMedia from "../../../../components/GalleryMedia/GalleryMedia";

interface InsightMediaProps {
    handleUploadFile: (mediaLoaded: MediaLoaded) => void;
    images: ImagesUploadedInterface[];
    videos: VideoUploadedInterface[];
}

export interface MediaInterface {
    path: string;
    src?: string;
    url?: string;
    id: number;
    originalName?: string;
    description?: string;
    type: "image" | "video";
    current?: boolean;
    modified?: boolean;
    deleted?: boolean;
    thumbPath?: string;
    thumb?: string;
    isVideo?: boolean;
}

export interface ImagesUploadedInterface extends MediaInterface {
    url: string;
}

export interface VideoUploadedInterface extends MediaInterface {
    url: string;
}

interface MediaLoaded {
    newImages?: MediaInterface[];
    deletedImages?: string[];
    modifiedImages?: MediaInterface[];
    newVideos?: MediaInterface[];
    deletedVideos?: string[];
    modifiedVideos?: MediaInterface[];
}

enum FILE_TYPE {
    IMAGE = "image",
    VIDEO = "video",
}

const normalizeImagesListInputData = (
    images: MediaInterface[] = []
): Omit<MediaLoaded, "newVideos" | "deletedVideos" | "modifiedVideos"> => {
    let newImages: MediaInterface[] = [];
    let deletedImages: string[] = [];
    let modifiedImages: MediaInterface[] = [];

    images.forEach((img) => {
        if (!img.current) {
            newImages.push(img);
        } else if (img.deleted) {
            deletedImages.push(img.originalName || "");
        } else {
            modifiedImages.push(img);
        }
    });

    return {
        newImages,
        modifiedImages,
        deletedImages,
    };
};

const normalizeVideosListInputData = (
    videos: MediaInterface[] = []
): Omit<MediaLoaded, "newImages" | "deletedImages" | "modifiedImages"> => {
    let newVideos: MediaInterface[] = [];
    let deletedVideos: string[] = [];
    let modifiedVideos: MediaInterface[] = [];

    videos.forEach((video) => {
        if (!video.current) {
            newVideos.push(video);
        } else if (video.deleted) {
            deletedVideos.push(video.originalName || "");
        } else {
            modifiedVideos.push(video);
        }
    });

    return {
        newVideos,
        modifiedVideos,
        deletedVideos,
    };
};

export const InsightMedia = ({
    handleUploadFile,
    images = [],
    videos = [],
}: InsightMediaProps) => {
    const [imagesUploaded, setImagesUploaded] = useState<
        ImagesUploadedInterface[]
    >([]);
    const [videosUploaded, setVideosUploaded] = useState<
        VideoUploadedInterface[]
    >([]);
    const [mediaModalOpen, setMediaModalOpen] = useState<MediaInterface | null>(
        null
    );
    const [newMediaDescription, setNewMediaDescription] = useState<string>("");

    useEffect(() => {
        let initialImages: ImagesUploadedInterface[] = [];
        try {
            initialImages = images.map((img) => ({
                ...img,
                current: true,
            }));
        } catch {}
        setImagesUploaded(initialImages);
    }, [images]);

    useEffect(() => {
        let initialVideos: VideoUploadedInterface[] = [];
        try {
            initialVideos = videos?.map((video) => ({
                ...video,
                current: true,
            })) || [];
        } catch {}
        setVideosUploaded(initialVideos);
    }, [videos]);

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];
        const fileType = file.type.startsWith("video/") ? FILE_TYPE.VIDEO : FILE_TYPE.IMAGE;
        const response = await uploadTempFile(  
            file,
            (fileType === FILE_TYPE.IMAGE
                ? imagesUploaded.length
                : videosUploaded.length) + 1,
                undefined,
                undefined,
                fileType === FILE_TYPE.VIDEO ? true : false
        );

        if (fileType === FILE_TYPE.IMAGE) {
            const newImage: MediaInterface = {
                path: response,
                id: new Date().getTime(),
                current: false,
                type: FILE_TYPE.IMAGE,
            };
            const updatedImages = [
                ...imagesUploaded,
                {
                    ...newImage,
                    url: URL.createObjectURL(file),
                },
            ];
            setImagesUploaded(updatedImages);
        } else {
            const newVideo: MediaInterface = {
                path: response.url,
                id: new Date().getTime(),
                type: FILE_TYPE.VIDEO,
                current: false,
                thumbPath: response.thumbnail,
            };
            const updatedVideos = [
                ...videosUploaded,
                {
                    ...newVideo,
                    url: URL.createObjectURL(file),
                },
            ];
            setVideosUploaded(updatedVideos);
        }
    };

    useEffect(() => {
        const payloadImages = normalizeImagesListInputData(imagesUploaded);
        const payloadVideos = normalizeVideosListInputData(videosUploaded);

        handleUploadFile &&
            handleUploadFile({ ...payloadImages, ...payloadVideos });
    }, [imagesUploaded, videosUploaded, handleUploadFile]);

    const removeMedia = (item: MediaInterface) => {
        if (item.type === FILE_TYPE.IMAGE) {
            if (item.current) {
                setImagesUploaded((prev) =>
                    prev.map((img) =>
                        img.originalName === item.originalName
                            ? { ...img, deleted: true }
                            : img
                    )
                );
            } else {
                const index = imagesUploaded.findIndex(
                    (img) => img.id === item.id
                );
                if (index !== -1) {
                    URL.revokeObjectURL(imagesUploaded[index].url);
                    setImagesUploaded((prev) =>
                        prev.filter((img) => img.id !== item.id)
                    );
                }
            }
        } else if (item.type === "video" || item.isVideo) {
            if (item.current) {
                setVideosUploaded((prev) =>
                    prev.map((video) =>
                        video.originalName === item.originalName
                            ? { ...video, deleted: true }
                            : video
                    )
                );
            } else {
                const index = videosUploaded.findIndex(
                    (video) => video.id === item.id
                );
                if (index !== -1) {
                    URL.revokeObjectURL(videosUploaded[index].url);
                    setVideosUploaded((prev) =>
                        prev.filter((video) => video.id !== item.id)
                    );
                }
            }
        }
    };

    const updateMediaDescription = (item: MediaInterface) => {
        if (item.type === "video" || item.isVideo) {
            setVideosUploaded((prev) =>
                prev.map((video) => {
                    return video.url === item.url && video.thumb === item.thumb
                        ? { ...video, description: newMediaDescription }
                        : video
                })
            );
        } else {
            setImagesUploaded((prev) =>
                prev.map((img) =>
                    img.url === item.url && img.thumb === item.thumb
                        ? { ...img, description: newMediaDescription }
                        : img
                )
            );
        }
        setMediaModalOpen(null);
    };

    const mediaPreview = [
        ...imagesUploaded.filter((img) => !img.deleted),
        ...videosUploaded.filter((video) => !video.deleted),
    ];

    const handleDotsMenu = (key: string, item: MediaInterface) => {
        switch (key) {
            case "DESCRIPTION":
                setMediaModalOpen(item);
                setNewMediaDescription(item.description || "");
                break;
            default:
                break;
        }
    };

    return (
        <div className="col-span-6 sm:col-span-3">
            <Modal
                open={!!mediaModalOpen}
                size={Modal_Size.md}
                onClose={() => setMediaModalOpen(null)}
            >
                <div className="flex flex-col items-center justify-center mx-6">
                    {mediaModalOpen?.type === "video" || mediaModalOpen?.isVideo ? (
                        <video
                            className="rounded-xs w-[700px] h-[500px] object-cover"
                            src={
                                mediaModalOpen?.url ||
                                mediaModalOpen?.path ||
                                ""
                            }
                            controls
                        />
                    ) : (
                        <img
                            className="rounded-xs w-[700px] h-[500px] object-cover"
                            src={
                                mediaModalOpen?.url ||
                                mediaModalOpen?.path ||
                                ""
                            }
                            alt=""
                        />
                    )}
                    <div className="mx-6 mt-6 flex flex-col w-full">
                        <TextAreaInput
                            label="Comments"
                            id="reply"
                            name="reply"
                            defaultValue={mediaModalOpen?.description}
                            onChange={(e: any) => {
                                mediaModalOpen && setMediaModalOpen({
                                    ...mediaModalOpen,
                                    description: e.target.value
                                })
                                setNewMediaDescription(e.target.value)
                            }}
                        />
                        <div className="flex flex-row justify-end items-end">
                            <ButtonSquared
                                onClick={() => setMediaModalOpen(null)}
                                className="px-6 mr-4"
                                size={ButtonSize.FIT}
                                outlined
                                label="Cancel"
                            />
                            <ButtonSquared
                                className="px-6"
                                size={ButtonSize.FIT}
                                label="Save"
                                onClick={() =>
                                    updateMediaDescription(
                                        mediaModalOpen || ({} as MediaInterface)
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="font-semibold text-base text-neutral-900">
                Photos & Videos
            </div>
            <div className="flex-grow text-left text-sm font-medium text-neutral-600 mb-5">
                Capture and share videos (recommended: 90 seconds max, MP4 format)
            </div>
            <div className="flex items-end gap-4 flex-wrap">
                <div className="max-h-[124px] mb-2">
                    <DropMediaFileInput
                        mediaClass="h-[124px] w-[200px] rounded-sm overflow-hidden box-border"
                        className="!py-4 !px-4"
                        iconClass="!w-12 !h-12"
                        onChange={onSelectFile}
                        accept="image/*,video/*"
                        label="Browse or drag media files"
                    />
                </div>
                {mediaPreview.map((item, index) => {
                    let previewSrc = item.url || item.path;
                    if (item.isVideo) {
                        previewSrc = item.thumb || previewSrc;
                    }

                    const type = item.type || (item.isVideo ? "video" : "image");
                    return <GalleryMedia
                        key={`insight_media_${index}`}
                        src={previewSrc}
                        description={item.description}
                        type={type}
                        mediaClass="h-[82px] z-10 object-cover mb-2"
                        containerClass="flex-[0_0_auto]"
                        onMediaClick={() => {}}
                        onMediaRemove={() => removeMedia(item)}
                        threeDots={[
                            {
                                icon: "PlusIcon",
                                label: "Add description",
                                key: "DESCRIPTION",
                            },
                            {
                                icon: "TrashIcon",
                                label: "delete",
                                key: "DELETE",
                            },
                        ]}
                        handleDotsMenu={(k) => handleDotsMenu(k, item)}
                    />
                })}
            </div>
        </div>
    );
};
