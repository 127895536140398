import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    getTripRequirementUsers,
    selectedTrip,
    selectedTripRequirement,
    selectedTripRequirementUsers,
    updateRequirementFile,
} from "../../../tripSlice";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../../layout/layoutSlice";
import {
    ButtonSize,
    GeneralStatus,
    PAGE_SECTIONS,
    TripRequirementFileStatus,
} from "../../../../../constants";
import moment from "moment";
import ButtonSquared from "../../../../../components/ButtonSquare";
import { DATE_FORMAT, getStatusFromFiles } from "../../../../../helpers";
import DocumentRequirementsModal from "../../TripUsers/Participant/RequirementFiles/DocumentRequirementsModal";
import RequirementUsersTable from "./RequirementUserTable";
import RequirementUsersList from "./RequirementUserList";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import { DescriptionText } from "../../../../../components/DescriptionText/DescriptionText";
import server from "../../../../../api/server";
import { selectProfile } from "../../../../authentication/userSlice";
import ModalConfirmation from "../../../../../components/Modal/ConfirmationModal";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import TextareaWithValid from "../../../../../components/TextArea";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EmptyScreen from "../../../../../components/EmptyScreen/EmptyScreen";

export default function TripRequirementAdmin() {
    let { tripId } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const participants = useAppSelector(selectedTripRequirementUsers());
    const profile = useAppSelector(selectProfile);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openRequirementModal, setOpenRequirementModal] =
        useState<boolean>(false);
    const [selectedUserReqData, setSelectedUserReqData] = useState<any>();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [resendModalOpen, setResendModalOpen] = useState<boolean>(false);
    const [showResubmitModal, setShowResubmitModal] = useState(false);
    const onEdit = () => {
        navigate(`edit/${requirement?.type}`);
    };

    const onUserClick = (user: any) => {
        setSelectedUserReqData({
            ...requirement,
            files: user?.requirementFiles || [],
            status: getStatusFromFiles(user?.requirementFiles || []),
            userEmail: user?.userEmail,
        });
        setOpenRequirementModal(true);
    };

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: move efects to parent OUTLET
    useEffect(() => {
        if (requirement?.id && tripId) {
            getUsersData(Number(tripId), Number(requirement?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, requirement?.id]);

    const getUsersData = (tripId: number, requirementId: number) => {
        dispatch(getTripRequirementUsers(tripId, requirementId));
    };

    const onUpdateRequirement = () => {
        setOpenRequirementModal(false);
        getUsersData(Number(tripId), Number(requirement?.id));
    };

    const filesPreviews = useMemo(() => {
        return Object.values(requirement?.requirementIncludedFiles || {});
    }, [requirement?.requirementIncludedFiles]);

    const onUserSelected = (user: any) => {
        const index = selectedUsers.indexOf(user.userEmail);
        if (index === -1) {
            setSelectedUsers([...selectedUsers, user.userEmail]);
        } else {
            setSelectedUsers([
                ...selectedUsers.slice(0, index),
                ...selectedUsers.slice(index + 1),
            ]);
        }
    };

    const onToggleSelectAll = () => {
        if (selectedUsers.length === participants?.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(participants?.map((p: any) => p.userEmail));
        }
    };

    const onResendRequirements = async () => {
        try {
            setLoading(true);
            await server.resendRequirementsEmail(
                Number(tripId),
                selectedUsers,
                requirement?.deadline
            );
            setSelectedUsers([]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const schema = yup
        .object({
            resubmissionComment: yup
                .string()
                .required("Please enter a reason."),
        })
        .required();
    const {
        register,
        formState: { errors },
        watch,
        trigger,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            resubmissionComment: "",
        },
    });

    const sendResubmission = async () => {
        const isValid = await trigger();
        if (isValid) {
            const resubmissionComment = watch("resubmissionComment");
            const tripId = requirement.tripId;
            const fileId = selectedUserReqData.files?.find(
                (file: any) =>
                    file.status === TripRequirementFileStatus.APPROVED
            )?.id;
            await dispatch(
                updateRequirementFile(
                    tripId,
                    Number(selectedUserReqData.id),
                    fileId,
                    {
                        status: GeneralStatus.REJECTED,
                        resubmissionComment: resubmissionComment || "",
                    }
                )
            );
            setOpenRequirementModal(false);
            setShowResubmitModal(false);
            reset();
            onUpdateRequirement();
        }
    };

    const askResubmission = () => {
        setOpenRequirementModal(false);
        setShowResubmitModal(true);
    };

    const canEdit = trip?.canEdit || profile?.isAdmin;
    return (
        <>
            {canEdit && openRequirementModal && (
                <DocumentRequirementsModal
                    onClose={() => {
                        setOpenRequirementModal(false);
                    }}
                    open={openRequirementModal}
                    onUpdate={onUpdateRequirement}
                    requirement={selectedUserReqData}
                    askResubmission={askResubmission}
                />
            )}
            <Modal
                open={showResubmitModal}
                size={Modal_Size.md}
                onClose={() => {
                    setShowResubmitModal(false);
                }}
            >
                <div className="p-4">
                    <form>
                        <div className="text-lg font-semibold mb-2">
                            Request re-submission: {requirement?.name}
                        </div>
                        <TextareaWithValid
                            autoFocus
                            markRequired
                            register={register}
                            label="Reason for re-submission"
                            id="resubmissionComment"
                            name="resubmissionComment"
                            error={errors["resubmissionComment"]?.message}
                        />
                        <div className="pt-2 flex gap-4 justify-end">
                            <ButtonSquared
                                size={ButtonSize.FIT}
                                outlined
                                label="Cancel"
                                type="button"
                                onClick={() => {
                                    setShowResubmitModal(false);
                                }}
                            />
                            <ButtonSquared
                                size={ButtonSize.FIT}
                                label="Send"
                                type="button"
                                onClick={() => {
                                    sendResubmission();
                                }}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
            <ModalConfirmation
                open={resendModalOpen}
                title="Are you sure you want to resend the document upload request to the selected members?"
                handleCancel={() => {
                    setResendModalOpen(false);
                }}
                handleSubmit={() => {
                    onResendRequirements();
                    setResendModalOpen(false);
                }}
                okButtonText={"Confirm"}
            />

            {!!requirement?.name && (
                <div className="flex flex-wrap">
                    <div className="flex flex-col flex-grow">
                        <div className="text-left font-semibold text-lg">
                            {requirement?.name} -{" "}
                            {moment
                                .utc(requirement?.deadline)
                                .format(DATE_FORMAT)}
                        </div>
                    </div>
                    <div>
                        {canEdit && (
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                outlined={true}
                                onClick={onEdit}
                                label="Edit"
                            />
                        )}
                    </div>
                </div>
            )}
            <Paragraph className="text-left">
                <DescriptionText text={requirement?.description} />
            </Paragraph>
            <div className="flex flex-wrap gap-4 mt-4">
                {filesPreviews.map((file: any) => {
                    return (
                        <a
                            className="block cursor-pointer"
                            key={`file_${file?.originalName}`}
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            <FilePreview viewOnly name={file?.originalName} />
                        </a>
                    );
                })}
            </div>

            {!!requirement &&
            (!participants || participants?.length > 0) &&
            canEdit ? (
                <div className="mt-6">
                    {!!selectedUsers.length && (
                        <div className="flex flex-row items-center gap-4 my-4">
                            <div className="text-base font-semibold text-neutral-800">
                                {selectedUsers.length} selected
                            </div>
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                label={"Resend requirements"}
                                onClick={() => {
                                    setResendModalOpen(true);
                                }}
                                disabled={loading}
                            />
                        </div>
                    )}
                    {isDesktop ? (
                        <RequirementUsersTable
                            participants={participants}
                            onUserClick={onUserClick}
                            selectedUsers={selectedUsers}
                            onUserSelected={onUserSelected}
                            onSelectAll={onToggleSelectAll}
                        />
                    ) : (
                        <RequirementUsersList
                            participants={participants}
                            onUserClick={onUserClick}
                            selectedUsers={selectedUsers}
                            onUserSelected={onUserSelected}
                        />
                    )}
                </div>
            ) : null}
            {participants && participants.length === 0 && (
                <EmptyScreen text="There are no recipients selected for this requirement." />
            )}
        </>
    );
}
