import { useMemo } from "react";

interface NumberRatingProps {
    rating?: number;
    className?: string;
    onChange?: any;
    error?: string;
    viewOnly?: boolean;
}

export default function NumberRating({
    rating = 0,
    className,
    onChange,
    error,
    viewOnly,
}: NumberRatingProps) {
    const handleClick = (value: number) => {
        onChange && onChange(value);
    };

    const renderError = useMemo(() => {
        if (error?.length) {
            return (
                <div className="border-orange-500 text-orange-700" role="alert">
                    <p>{error}</p>
                </div>
            );
        }
    }, [error]);
    return (
        <>
            <div className="flex flex-col w-fit">
                <div className="flex flex-row">
                    {[1, 2, 3, 4, 5].map((i) => (
                        <div
                            className={`px-8 text-base border-t border-b text-neutral-800 border-neutral-300 py-2 ${
                                rating === i
                                    ? "bg-primary_orange-900 border-orange-500 text-white"
                                    : ""
                            } ${
                                !viewOnly
                                    ? rating === i
                                        ? "hover:bg-orange-500  cursor-pointer"
                                        : "hover:bg-slate-200 cursor-pointer"
                                    : ""
                            } ${i !== 1 && i !== 5 ? "border-r" : ""} ${
                                i === 1 ? "border-l border-r rounded-l-md" : ""
                            } ${i === 5 ? "border-r rounded-r-md" : ""}`}
                            onClick={() => {
                                !viewOnly && handleClick(i);
                            }}
                            key={i}
                        >
                            {i}
                        </div>
                    ))}
                </div>

                <div className="flex flex-row w-full justify-between text-xs text-neutral-800 p-1">
                    <div>Poor</div>
                    <div>Good</div>
                    <div>Excellent</div>
                </div>
            </div>
            {renderError}
        </>
    );
}
