import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import "firebase/compat/database";
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from "firebase/messaging";

import config from "../config";
import { FIREBASE_ERRORS } from "../constants";
export const FirebaseContext = React.createContext(null);
export const AUTHENTICATION_LOADING = "AUTHENTICATION_LOADING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATED_ANONYMOUSLY = "AUTHENTICATED_ANONYMOUSLY";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";

const supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
];

export class Firebase {
    auth: any;
    googleLoginProvider: any;
    facebookLoginProvider: any;
    emailAuthProvider: any;
    storage: any;
    firestore: any;
    messaging: any;
    database: any;

    constructor() {
        // Do not initialize the app if this step was already done.
        if (!firebase.apps.length) {
            try {
                const extraProps: any = {};
                if (`${process.env.REACT_APP_FIREBASE_PROJECTID}` === "famguruapp") {
                    extraProps.databaseURL =`https://famguruapp.firebaseio.com/`;
                }
                firebase.initializeApp({
                    apiKey: config.fbApiKey,
                    authDomain: config.fbAuthDomain,
                    projectId: config.fbProjectId,
                    storageBucket: config.fbStorageBucket,
                    messagingSenderId: config.fbMessagingSenderId,
                    appId: config.fbAppId,
                    // measurementId: firebaseKeys.MEASUREMENT_ID
                    ...extraProps,
                });
            } catch (error) {}
        }

        if (firebase.apps.length) {
            try {
                this.storage = firebase.storage();
                this.firestore = firebase.firestore();
                this.auth = firebase.auth();
                this.messaging = this.initMessaging();
                this.googleLoginProvider =
                    new firebase.auth.GoogleAuthProvider();
                this.facebookLoginProvider =
                    new firebase.auth.FacebookAuthProvider();
                this.database = firebase.database();
                this.emailAuthProvider = new firebase.auth.EmailAuthProvider();
            } catch (error) {
                console.log("Error initializing firebase: ", error);
            }
        }
    }

    initMessaging = async () => {
        try {
            const result = await isSupported();
            return result ? getMessaging() : null;
        } catch (error) {
            return null;
        }
    };

    getProvider(providerId: string) {
        switch (providerId) {
            case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
                return this.googleLoginProvider;
            case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
                return this.facebookLoginProvider;
            case firebase.auth.EmailAuthProvider.PROVIDER_ID:
                return this.emailAuthProvider;
            default:
                throw new Error(`No provider implemented for ${providerId}`);
        }
    }

    /* Updates the authentication everytime a change is received */
    authState = (setAuthState: Function, cb: Function) =>
        this.auth.onAuthStateChanged(async (user: any) => {
            try {
                if (user) {
                    const token = await user.getIdToken(true);
                    setAuthState({
                        status: user.isAnonymous
                            ? AUTHENTICATED_ANONYMOUSLY
                            : AUTHENTICATED,
                        user,
                        token,
                    });
                    if (cb) return cb(token);
                } else {
                    //await this.signInAnonymously(setAuthState);
                    setAuthState({
                        status: UNAUTHENTICATED,
                    });
                    return;
                }
            } catch (error) {}
        });

    onIdTokenChanged = (setAuthState: Function, cb: Function) =>
        firebase.auth().onIdTokenChanged(async function (user) {
            if (user) {
                const token = await user.getIdToken(true);
                setAuthState({
                    status: user.isAnonymous
                        ? AUTHENTICATED_ANONYMOUSLY
                        : AUTHENTICATED,
                    user,
                    token,
                });
                if (cb) return cb(token);
            }
        });

    signInWithGoogle = async (callback: Function) => {
        try {
            await this.auth.signInWithPopup(this.googleLoginProvider);
            const user = this.auth.currentUser;
            return callback(user);
        } catch (error: any) {
            return this.handleError(error);
        }
    };
    handleError = (error: any) => {
        const errorMessage = FIREBASE_ERRORS[error.code] || "Unknown Error";
        throw new Error(errorMessage);
    };
    /*
     * Returns providers for currently signed in user.
     */
    providersForEmail = async (email: string) => {
        return this.auth.fetchSignInMethodsForEmail(email);
    };

    /*
     * Returns whether the user is only logged in using password provider.
     */
    userHasOnlyEmailProvider = async (email: string) => {
        let providers;
        if (email) {
            providers = await this.providersForEmail(email);
        } else {
            const user = this.auth.currentUser;
            if (!user) {
                return false;
            }
            providers = await this.providersForEmail(user.email);
        }
        return (
            providers.length === 1 &&
            providers[0] === firebase.auth.EmailAuthProvider.PROVIDER_ID
        );
    };
    /**
     * Links an email with their credential from provider A to already existing provider B.
     * This is needed when a user uses the same email to login with google and subsequently with
     * facebook for example.
     */
    linkProviders = async (email: string, credential: string) => {
        const providers = await this.auth.fetchSignInMethodsForEmail(email);
        const firstPopupProviderMethod = providers.find((p: any) =>
            supportedPopupSignInMethods.includes(p)
        );
        if (!firstPopupProviderMethod) {
            throw new Error(
                `Your account is linked to a provider that isn't supported.`
            );
        }
        const linkedProvider = this.getProvider(firstPopupProviderMethod);
        linkedProvider.setCustomParameters({ login_hint: email });

        const result = await this.auth.signInWithPopup(linkedProvider);
        result.user.linkWithCredential(credential);
    };
    signInWithFacebook = async (callback: Function, onError: Function) => {
        try {
            await this.auth.signInWithPopup(this.facebookLoginProvider);
            const user = this.auth.currentUser;
            return callback(user);
        } catch (error: any) {
            if (
                error.code === "auth/account-exists-with-different-credential"
            ) {
                const userHasOnlyEmailProvider =
                    await this.userHasOnlyEmailProvider(error.email);
                // TODO: Handle link facebook with email provider.
                if (userHasOnlyEmailProvider) {
                    return this.handleError(error);
                } else {
                    await this.linkProviders(error.email, error.credential);
                    return;
                }
            } else {
                return this.handleError(error);
            }
        }
    };
    updateEmailAddress = async (email: string, callback: Function) => {
        const user = this.auth.currentUser;
        try {
            await user.updateEmail(email);
            callback && callback();
        } catch (error) {
            console.log("updateEmail failed: ", error);
            return this.handleError(error);
        }
    };
    sendPasswordResetEmail = async (
        email: string,
        callback: Function,
        onError: Function
    ) => {
        try {
            await this.auth.sendPasswordResetEmail(email);
            callback && callback();
        } catch (error) {
            return this.handleError(error);
        }
    };
    sendEmailVerification = async (callback?: any, onError?: any) => {
        const user = this.auth.currentUser;
        try {
            await user.sendEmailVerification({
                url: window.location.href,
                handleCodeInApp: true,
            });
            callback && callback();
            return;
        } catch (error: any) {
            onError && onError(error);
            console.log("sendEmailVerification failed: ", error.message);
            return error;
        }
    };
    updateUserDisplayName = async (name: string) => {
        const user = this.auth.currentUser;
        try {
            await user.updateProfile({
                displayName: name,
            });
        } catch (error) {
            console.log("updateUserDisplayName failed: ", error);
        }
    };
    /* Creates a user using email and password. This  method also goes ahead and updates the username */
    signUpWithEmailAndPassword = async (
        email: string,
        password: string,
        name: string,
        setAuthState: Function,
        callback: Function,
        onError: Function
    ) => {
        setAuthState({ status: AUTHENTICATION_LOADING });
        try {
            await this.auth.createUserWithEmailAndPassword(email, password);
            await this.sendEmailVerification();
            await this.updateUserDisplayName(name);
            const user = this.auth.currentUser;
            callback(user);
        } catch (error) {
            return this.handleError(error);
        }
    };
    signInWithEmailAndPassword = async (
        email: string,
        password: string,
        onError: Function
    ) => {
        try {
            //    setAuthState({ status: AUTHENTICATION_LOADING });
            const userCredentials = await this.auth.signInWithEmailAndPassword(
                email,
                password
            );
            return userCredentials?.user?.refreshToken;
        } catch (error: any) {
            return this.handleError(error);
        }
    };

    signInWithCustomToken = async (token: string, onError: Function) => {
        try {
            const userCredentials = await this?.auth?.signInWithCustomToken(
                token
            );
            const newToken = await userCredentials?.user?.getIdToken(true);
            return newToken;
        } catch (error: any) {
            return this.handleError(error);
        }
    };

    signOut = async (setAuthState: any) => {
        try {
            setAuthState && setAuthState({ status: AUTHENTICATION_LOADING });
            await this.auth?.signOut();
        } catch (error) {
            console.log("signOut failed: ", error);
            setAuthState && setAuthState({ status: UNAUTHENTICATED, error });
        }
    };

    currentUser = () => {
        return this.auth.currentUser;
    };

    refreshToken = async (setAuthState: any) => {
        const user = this.auth.currentUser;
        if (user) {
            const token = await user.getIdToken(true);
            setAuthState && setAuthState({
                status: user.isAnonymous
                    ? AUTHENTICATED_ANONYMOUSLY
                    : AUTHENTICATED,
                user,
                token,
            });
        }
    };

    getUnreadNotificationsCount = async (email: number, cb: Function) => {
        try {
            const snap = await this.firestore
                .collection("notifications")
                .where("email", "==", email)
                .where("read", "==", false)
                .get();
            return snap ? snap.size : 0;
        } catch (error) {console.log('Error geting unread notifications data');}
    };

    updateNotifications = async (newNotifications: Array<any>) => {
        const batch = this.firestore.batch();
        newNotifications.forEach((noti: any) => {
            const { id, ...others } = noti;
            const ref = this.firestore.collection("notifications").doc(id);
            batch.update(ref, others);
        });
        return await batch.commit();
    };

    updateNotificationBy = async (
        conditions: Array<{ key: string; value: any }>,
        fieldsToUpdate: any
    ) => {
        const batch = this.firestore.batch();
        const snap = await this.firestore.collection("notifications");
        conditions.forEach((condition) => {
            snap.where(condition.key, "==", condition.value);
        });
        const query = await snap.orderBy("date", "desc").get();
        const notifications = query.docs;
        notifications.forEach((notification: any) => {
            let tmp = { ...notification.data(), ...fieldsToUpdate };
            batch.update(notification.ref, fieldsToUpdate);
        });

        //const response = await notification.ref.update(tmp);
        return await batch.commit();
    };

    deleteTripNotifications = async (email: string, tripId: number) => {
        const firestore = this.firestore();
        const batch = firestore.batch();
        const snap = await firestore
            .collection("notifications")
            .where("email", "==", email)
            .where("data.tripId", "==", tripId)
            .get();
        const notifications = snap.docs;
        notifications.forEach((notification: any) => {
            batch.delete(notification.ref);
        });
        return batch.commit();
    };

    markNotificationAsRead = (notificationId: string) => {
        return this.firestore
            .collection("notifications")
            .doc(notificationId)
            .update({ read: true });
    };
    markAllNotificationsAsRead = async (email: string) => {
        const batch = this.firestore.batch();
        const collection = this.firestore
            .collection("notifications")
            .where("email", "==", email);
        const snapshots = await collection.get();
        if (snapshots.size > 0) {
            snapshots.forEach((notification: any) => {
                const docRef = this.firestore
                    .collection("notifications")
                    .doc(notification.id);
                batch.update(docRef, { read: true });
            });
            return await batch.commit();
        }
        return;
    };

    onNotifications = (email: number, cb: Function) => {
        try {
            return this.firestore
                .collection("notifications")
                .where("email", "==", email)
                .where("date", ">=", Date.now())
                .onSnapshot(
                    (querySnapshot: any) => {
                        querySnapshot.docChanges().forEach((change: any) => {
                            if (change.type === "added") {
                                return cb({
                                    ...change.doc.data(),
                                    id: change.doc.id,
                                });
                            }
                        });
                    },
                    (error: any) => {console.log('Error geting notifications data');}
                );
        } catch (error) {}
    };

    requestForToken = () => {
        if (this.messaging) {
            return getToken(this.messaging, { vapidKey: config.fbVapidKey })
                .then((currentToken: any) => {
                    if (currentToken) {
                        //  console.log("current token for client: ", currentToken);
                        return currentToken;
                        // Perform any other neccessary action with the token
                    } else {
                        // Show permission request UI
                        console.log(
                            "No registration token available. Request permission to generate one."
                        );
                        return;
                    }
                })
                .catch((err: any) => {
                    console.log(
                        "An error occurred while retrieving token. ",
                        err
                    );
                });
        }
    };

    onMessageListener = () =>
        this.messaging
            ? new Promise((resolve) => {
                  onMessage(this.messaging, (payload) => {
                      resolve(payload);
                  });
              })
            : null;

    getMaintenanceMode = async (cb: Function) => {
        this.firestore
            .collection("config")
            .doc("maintenanceMode")
            .onSnapshot(
                (doc: any) => {
                    return cb && cb({ ...doc?.data(), id: doc.id });
                },
                (error: any) => {
                    console.log('Error geting manteinance mode data');
                }
            );
    };
}

export default Firebase;
