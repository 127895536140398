import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../components/TextArea";
import {
    addRequirementToTrip,
    selectedTripRequirement,
    updateTripRequirement,
    uploadTempFile,
} from "../../tripSlice";
import DatePickerMaterial from "../../../../components/DatePickerMaterial";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { PAGE_SECTIONS, UserStatusOnTrip } from "../../../../constants";
import moment from "moment";
import axios from "axios";
import FilePreview from "../../../../components/DropFileInput/FilePreview";
import DropFileInput from "../../../../components/DropFileInput/DropFileInput";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import fileImage from "../../../../assets/images/files.png";
import { RequirementFlightForm } from "./TripRequirement/RequirementFlightForm";
import CheckBox from "../../../../components/CheckBox";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";
import { Modal_Size } from "../../../../components/Modal";
import { getRequirementByType } from "./TripRequirement/requiriments.utils";
import InputRadioWithValid from "../../../../components/Radio";
import server from "../../../../api/server";
import CheckBoxInput from "../../../../components/CheckBox/Checkbox";
import Loader from "../../../../components/Loader";
import { capitalize } from "../../../../helpers";

const schema = yup
    .object({
        name: yup.string().required("This field is required"),
        description: yup.string().max(5000).required("This field is required"),
        deadline: yup.date().required("This field is required"),
        type: yup.string().required("This field is required"),
    })
    .required("This field is required");

export default function TripRequirementForm() {
    const navigate = useNavigate();
    let { tripId, requirementId, requirementType } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [tripMembers, setTripMembers] = useState<any[]>([]);
    const [avoidInformation, setAvoidInformation] = useState<{
        1: boolean;
        2: boolean;
    }>({ 1: false, 2: false });
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);
    const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
    const [loadingMembers, setLoadingMembers] = useState(false);
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            description: "",
            deadline: moment.utc().add(1, "day"),
            type: requirementType,
            extraNotes1: "",
            extraNotes2: "",
            includeInSpreadsheet: false,
            shareWithSelectedMembers: "false",
        },
    });
    const shareWithMembers = watch("shareWithSelectedMembers");
    const showMembersList = shareWithMembers === "true";
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tripId) {
            getTripMembers(tripId);
        }
    }, [tripId]);

    const getTripMembers = async (tripId: string) => {
        try {
            setLoadingMembers(true);
            const response = await server.getConfirmedTripMembers(
                Number(tripId)
            );
            const members = response?.data;
            setTripMembers(members);
            setSelectedMembers(
                requirement?.selectedMembers?.length
                    ? requirement?.selectedMembers?.filter((m: any) =>
                          members.find((member: any) => member.userEmail === m)
                      )
                    : []
            );
        } catch (error) {}
        setLoadingMembers(false);
    };

    useEffect(() => {
        if (requirementId && requirement) {
            reset({
                name: requirement.name,
                description: requirement.description,
                deadline: moment.utc(requirement.deadline),
                extraNotes1: requirement.extraNotes1,
                extraNotes2: requirement.extraNotes2,
                includeInSpreadsheet: requirement.includeInSpreadsheet,
                shareWithSelectedMembers: requirement.shareWithSelectedMembers
                    ? "true"
                    : "false",
                type: requirement.type,
            });
            setFilesPreviews(
                Object.values(requirement.requirementIncludedFiles || {})
            );
            setSelectedMembers(requirement.selectedMembers || []);
        } else {
            const date = moment(moment.now()).utc().add(1, "day");
            reset({
                deadline: date,
                shareWithSelectedMembers: "false",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement, reset]);

    const isUpdate = requirement && requirementId;
    const handleSubmitForm = async (data: any) => {
        const files = tempFiles.map((file) => file.path);
        const payload = {
            ...data,
        };
        if (!!files?.length) {
            payload["requirementIncludedFiles"] = files;
        }
        payload["shareWithSelectedMembers"] =
            data.shareWithSelectedMembers === "true";
        payload["selectedMembers"] = selectedMembers;
        if (isUpdate) {
            if (!!deletedFilesState?.length) {
                payload["deletedFiles"] = deletedFilesState;
            }
            await dispatch(
                updateTripRequirement(
                    Number(tripId),
                    Number(requirementId),
                    payload
                )
            );
        } else {
            await dispatch(addRequirementToTrip(Number(tripId), payload));
        }

        goBack();
    };

    const goBack = () => {
        navigate(`/trips/${tripId}/requirements`);
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: any, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index]?.cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach(
                async (file: any, index: number) => {
                    const newtempFile = {
                        id: `${new Date().valueOf()}-${index}`,
                        file: file,
                        progress: 0,
                        cancelToken: axios?.CancelToken.source(),
                    };
                    setFilesProgress((prevArray) => {
                        return [...prevArray, newtempFile];
                    });

                    try {
                        const response = await uploadTempFile(
                            e.target.files[index],
                            newtempFile.id,
                            onFileProgress,
                            newtempFile?.cancelToken
                        );
                        setTempFiles((prev) => {
                            return [
                                ...prev,
                                {
                                    file: file,
                                    path: response,
                                    id: newtempFile.id,
                                },
                            ];
                        });
                    } catch (error) {
                    } finally {
                        removeProgressFile(newtempFile.id);
                    }
                }
            );
            return;
        }
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([
            ...deletedFilesState,
            filesPreviews[index].originalName,
        ]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    const propsByType = useMemo(() => {
        const props = getRequirementByType(requirementType || "");

        if (props.placeholderDescription) {
            setValue("description", props.placeholderDescription);
        }
        if (props.placeholderName) {
            setValue("name", props.placeholderName);
        }
        return props;
    }, [requirementType, setValue]);

    const handleSelectMember = (userEmail: string) => {
        const index = selectedMembers.findIndex((m) => m === userEmail);
        if (index === -1) {
            setSelectedMembers((prev) => [...prev, userEmail]);
        } else {
            setSelectedMembers((prev) => {
                const newMembers = [...prev];
                newMembers.splice(index, 1);
                return newMembers;
            });
        }
    };

    const getMemberName = (member: any) => {
        if (member?.user) {
            return `${member?.user?.firstName || ""} ${member?.user?.lastName || ""} ${member?.status === UserStatusOnTrip.PENDING ? "(pending)" : ""}`;
        }
        if (member?.status === UserStatusOnTrip.PENDING) {
            return `${member?.userEmail} (pending)`;
        }
        return member?.userEmail;
    }

    return (
        <div className="mx-4">
            <div className="flex flex-col flex-wrap mb-6">
                <div className="text-left font-semibold text-lg flex-grow mb-4">
                    {propsByType?.title}
                </div>
                <div className=" text-left font-medium text-sm text-neutral-800">
                    By creating this requirement, advisors will receive a
                    pending notification to upload the requirements before the
                    trip begins.
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="grid grid-cols-6 gap-4 items-center justify-center"
            >
                <div className="col-span-6 lg:col-span-6 flex flex-row gap-4 items-start">
                    <div className="w-full lg:w-1/2 text-left break-words mt-2">
                        <input
                            type="hidden"
                            {...register("type")}
                            value={requirementType}
                        />
                        <Input
                            markRequired
                            register={register}
                            label="Name"
                            type="text"
                            id="name"
                            name="name"
                            error={errors["name"]?.message}
                            placeholder={propsByType?.placeholderName || ""}
                        />
                    </div>
                    <div className="w-full lg:w-1/2 text-left break-words mt-2">
                        <DatePickerMaterial
                            control={control}
                            minDate={moment(moment.now())
                                .add(1, "day")
                                .utc()
                                .startOf("day")
                                .toDate()}
                            name="deadline"
                            id="deadline"
                            label="Deadline"
                            error={errors["deadline"]?.message}
                        />
                    </div>
                </div>

                <div className="col-span-6 text-left break-words mt-2">
                    <TextareaWithValid
                        markRequired
                        register={register}
                        label="Description"
                        id="description"
                        name="description"
                        error={errors["description"]?.message}
                        placeholder={propsByType?.placeholderDescription || ""}
                    />
                </div>
                {propsByType.showFiles && (
                    <div className="col-span-6">
                        <div className="gap-x-12 flex-wrap mt-4 flex-grow">
                            <div className="text-left font-semibold text-sm text-neutral-800 mt-2">
                                Files
                            </div>
                            <div className="text-left font-medium text-xs text-neutral-800 my-4">
                                Add files or data needed for this requirement
                            </div>
                            <div>
                                <DropFileInput
                                    iconSrc={fileImage}
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={onSelectFiles}
                                />
                            </div>
                            {filesProgress.map((file) => {
                                return (
                                    <ProgressBar
                                        key={`fileProgress_${
                                            file.id || file?.file?.name
                                        }`}
                                        progress={file.progress}
                                        name={file.file.name}
                                        onRemove={() =>
                                            removeProgressFile(file.id, true)
                                        }
                                    />
                                );
                            })}
                            <div className="flex flex-wrap gap-4 mt-4">
                                {tempFiles.map((file) => {
                                    return (
                                        <FilePreview
                                            key={`filePrev_${
                                                file.id || file?.file?.name
                                            }`}
                                            onRemove={() => {
                                                removeTempFile(file.id);
                                            }}
                                            name={file?.file?.name}
                                        />
                                    );
                                })}
                                {filesPreviews.map((file, index) => {
                                    return (
                                        <FilePreview
                                            key={`file_${file.originalName}`}
                                            onRemove={() => {
                                                handleFileRemove(index);
                                            }}
                                            name={file.originalName}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                {!!propsByType.showCheckboxSpreadsheet && (
                    <div className="col-span-6 mb-6">
                        <CheckBox
                            name="includeInSpreadsheet"
                            id="includeInSpreadsheet"
                            control={control}
                            label="Tick here you want to include this in your spreadsheet compilation."
                        />
                    </div>
                )}

                <RequirementFlightForm
                    register={register}
                    show={propsByType.showFlight}
                    avoidInformation={avoidInformation}
                    setAvoidInformation={setAvoidInformation}
                />

                <div className="col-span-6 flex flex-col gap-4 justify-start items-start">
                    <div className="text-base font-semibold text-neutral-900">
                        Requirement recipients
                    </div>
                    <div className="text-sm font-medium text-neutral-600">
                        Select who this requirement should be sent to:
                    </div>
                    <InputRadioWithValid
                        control={control}
                        name="shareWithSelectedMembers"
                        id="shareWithAll"
                        label="Send the requirement to everyone in the trip"
                        value="false"
                    />
                    <InputRadioWithValid
                        control={control}
                        name="shareWithSelectedMembers"
                        id="shareWithSelected"
                        label="Select individual members from the trip"
                        value="true"
                    />
                    {showMembersList && (
                        <div className="ml-4 flex flex-col gap-2">
                            {loadingMembers && !tripMembers?.length && (
                                <div className="flex flex-row items-center justify-center w-full">
                                    <Loader />
                                </div>
                            )}
                            {!loadingMembers && !tripMembers?.length && (
                                <div className="flex flex-row items-center justify-center text-sm font-semibold text-neutral-600">
                                    No members to show.
                                </div>
                            )}
                            {tripMembers.map((member, index) => (
                                <div
                                    key={member.id}
                                    className="col-span-6 sm:col-span-3 md:col-span-2 lg:col-span-1"
                                >
                                    <CheckBoxInput
                                        id={`selectedMembers[${index}]`}
                                        onChange={() =>
                                            handleSelectMember(member.userEmail)
                                        }
                                        value={selectedMembers.includes(
                                            member.userEmail
                                        )}
                                        label={getMemberName(member)}
                                        subLabel={capitalize(
                                            member?.user?.userType || member?.role
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="col-span-6 flex flex-row justify-end gap-4 px-4 lg:pt-16 text-right sm:px-6">
                    <Button
                        onClick={() => setOpenModalConfirmation(true)}
                        type="button"
                        outlined
                        label="Cancel"
                    />
                    <Button
                        disabled={!isValid}
                        type="submit"
                        label={isUpdate ? "Save" : "Create"}
                    />
                </div>
                <ModalConfirmation
                    open={openModalConfirmation}
                    title="Are you sure you want to leave?"
                    description="Any unsaved changes will be lost."
                    handleCancel={() => setOpenModalConfirmation(false)}
                    handleSubmit={goBack}
                    cancelButtonText="Continue editing"
                    okButtonText="Discard changes"
                    size={Modal_Size.md}
                />
            </form>
        </div>
    );
}
