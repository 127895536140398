import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import chatApi from "../../api/chat";
import { setSelectedTrip, setTrips } from "../trips/tripSlice";
import { normalizeError, sendToast } from "../../helpers";
import { MESSAGING_TOKEN_EXPIRATION, TOAST_TYPE } from "../../constants";
import moment from "moment";
import { deNormalizeSettings } from "../../bussiness/user";
import chat from "../../api/chat";
import { getToken } from "firebase/messaging";

export interface BreadcrumbOption {
    label: string;
    url: string;
}
export interface UserState {
    isLoading: Boolean;
    error: string;
    profile: any;
    profileExtra: any;
    auth: any;
    emailSent: boolean;
    settings: any;
    bredCrumpOptions: BreadcrumbOption[];
    unauthorized: boolean;
}

const initialState: UserState = {
    isLoading: false,
    error: "",
    profile: {},
    profileExtra: {},
    settings: {},
    auth: {},
    emailSent: false,
    bredCrumpOptions: [],
    unauthorized: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setEmailSent: (
            state,
            action: PayloadAction<{ emailSent: boolean }>
        ) => {
            state.emailSent = action.payload.emailSent;
        },
        setProfile: (state, action: PayloadAction<{ profile: any }>) => {
            state.profile = { ...action.payload.profile };
        },
        setAuth: (state, action: PayloadAction<{ auth: any }>) => {
            state.auth = { ...action.payload.auth };
        },
        setProfileExtra: (
            state,
            action: PayloadAction<{ profileExtra: any }>
        ) => {
            state.profileExtra = { ...action.payload.profileExtra };
        },
        setSettings: (state, action: PayloadAction<{ settings: any }>) => {
            state.settings = { ...action.payload.settings };
        },
        setBredCrumpOptions: (
            state,
            action: PayloadAction<{ bredCrumpOptions: BreadcrumbOption[] }>
        ) => {
            state.bredCrumpOptions = [...action.payload.bredCrumpOptions];
        },
        setUnauthorized: (
            state,
            action: PayloadAction<{ unauthorized: boolean }>
        ) => {
            state.unauthorized = action.payload.unauthorized;
        },
    },
});

export const {
    isLoading,
    setProfile,
    setError,
    setProfileExtra,
    setAuth,
    setEmailSent,
    setSettings,
    setBredCrumpOptions,
    setUnauthorized,
} = userSlice.actions;

//SELECTORS
export const selectProfile = (state: RootState) => state.user?.profile;
export const selectUnauthorized = (state: RootState) =>
    state.user?.unauthorized;
export const selectCurrentPlan = (state: RootState) => state.plans.currentPlan;
export const selectProfileExtra = (state: RootState) =>
    state.user?.profileExtra;
export const selectSettings = (state: RootState) => state.user?.settings;
export const selectError = (state: RootState) => state.user?.error;
export const selectEmailSent = (state: RootState) => state.user?.emailSent;
export const selectBredCrumpOptions = (state: RootState) =>
    state.user?.bredCrumpOptions;

export const getProfile =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getProfile();
            const profile = response.data;
            const provider = await Firebase.providersForEmail(profile.email);
            dispatch(setProfile({ profile: { ...profile, provider } }));
            if (profile?.firstLogin) await dispatch(firstLogin());
            dispatch(checkMessagingToken());
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

const checkMessagingToken =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            const now = moment();
            const userTokens = Object.values(
                getState().user?.profile?.messagingTokens || {}
            );

            const messaging = await Firebase.messaging;
            const messagingToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY});

            const currentToken: any = userTokens.find((t: any) => {
                return t?.token === messagingToken;
            });
            if (
                !currentToken ||
                moment(currentToken?.date)
                    .add(MESSAGING_TOKEN_EXPIRATION, "days")
                    .diff(now, "days") <= 0
            ) {
                messagingToken && await serverApi.updateProfileMessagingToken(messagingToken);
            }
            return;
        } catch (error) {
            console.log("error checking messagin tokens", error);
            return;
        }
    };

export const createProfile =
    (user: any): AppThunk =>
    async (dispatch) => {
        try {
            const profileData = {
                email: user.multiFactor.user.email,
                firstName: user.multiFactor.user.displayName,
                lastName: "-",
                birthDate: moment("1960-08-30", "YYYY-MM-DD").toDate(),
                firstLogin: false,
            };
            await dispatch(
                setAuth({ auth: { token: user.multiFactor.user.accessToken } })
            );
            serverApi.setAuthToken(user.multiFactor.user.accessToken);
            chat.setAuthToken(user.multiFactor.user.accessToken);
            await serverApi.createProfile(profileData);
            return;
        } catch (_error) {
            dispatch(handleError(_error));
        }
    };

export const getProfileFull =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const promises = [
                serverApi.getProfile(),
                serverApi.getUserProfile(),
            ];
            const response = await Promise.all(promises);
            const provider = await Firebase.providersForEmail(
                response[0].data.email
            );
            dispatch(
                setProfile({ profile: { ...response[0].data, provider } })
            );
            dispatch(setProfileExtra({ profileExtra: response[1].data }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const signInEmailPassword =
(
    { email, password, recaptchaToken, skipToken = false }: { email: string; password: string; recaptchaToken?: string, skipToken?: boolean }
): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            if (!skipToken) {
                const response = await serverApi.validateRecaptcha(recaptchaToken || '');
                if (!response.data.success) {
                    throw new Error('Invalid reCAPTCHA');
                }
            }
            const token = await Firebase.signInWithEmailAndPassword(
                email,
                password
            );
            try {
                await serverApi.getProfile(token);
                dispatch(setAuth({ auth: { token: token } }));
            } catch (error) {}

            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
        }
    };

export const changePassword =
    (password: string, newPassword: string): AppThunk<any> =>
    async (dispatch, getState, Firebase: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                const currentProfile = getState().user.profile;
                const credential = await Firebase.signInWithEmailAndPassword(
                    currentProfile.email,
                    password
                );
                if (credential) {
                    await serverApi.changePassword(newPassword);
                } else {
                    resolve(false);
                }
                dispatch(isLoading(false));
                sendToast(TOAST_TYPE.SUCCESS, "Password updated");
                resolve(true);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                resolve(false);
            }
        });
    };

export const signInGoogle =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await Firebase.signInWithGoogle(async (user: any) => {
                await dispatch(createProfile(user));
                dispatch(isLoading(false));
                return;
            });
        } catch (error) {
            dispatch(isLoading(false));
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
        }
    };

export const signInFacebook =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await Firebase.signInWithFacebook(
                async (user: any) => {
                    await dispatch(createProfile(user));
                    dispatch(isLoading(false));
                    return;
                },
                (error: any) => {
                    dispatch(isLoading(false));
                    const nError = normalizeError(error);
                    dispatch(setError({ error: nError }));
                }
            );
        } catch (error) {
            dispatch(isLoading(false));
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
        }
    };

export const signInWithCustomToken =
    (token: string, callBack: Function): AppThunk =>
    async (dispatch, _getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            try {
                await Firebase?.signOut();
            } catch (error) {}
            const newToken = await Firebase.signInWithCustomToken(
                token,
                (error: any) => {
                    dispatch(isLoading(false));
                    const nError = normalizeError(error);
                    dispatch(setError({ error: nError }));
                }
            );
            dispatch(setAuth({ auth: { token: newToken } }));
            serverApi.setAuthToken(newToken);
            chat.setAuthToken(newToken);
            try {
                const response = await serverApi.getProfile(newToken);
                const profile = response.data;
                dispatch(setProfile({ profile: { ...profile } }));
            } catch (error) {}
            dispatch(isLoading(false));
            callBack(newToken);
        } catch (error) {
            dispatch(isLoading(false));
            callBack(null);
        }
    };
export const getTokenFromCustomToken =
    (token: string, callBack: Function): AppThunk =>
    async (dispatch, _getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const newToken = await Firebase.signInWithCustomToken(
                token,
                (error: any) => {
                    dispatch(isLoading(false));
                    const nError = normalizeError(error);
                    dispatch(setError({ error: nError }));
                }
            );
            callBack(newToken);
        } catch (error) {
            dispatch(isLoading(false));
            callBack(null);
        }
    };

export const firstLogin =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            const currentUser = Firebase.currentUser();
            if (!currentUser.emailVerified) {
                await Firebase.sendEmailVerification();
            }
            await serverApi.updateProfile({
                firstLogin: false,
            });
            return;
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const resetPassword =
    (email: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await Firebase.sendPasswordResetEmail(email);
            dispatch(setEmailSent({ emailSent: true }));
            return dispatch(isLoading(false));
        } catch (error) {
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
            dispatch(setEmailSent({ emailSent: false }));
            return dispatch(isLoading(false));
        }
    };

export const signUpEmailPassword =
    (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        recaptchaToken: string
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.validateRecaptcha(recaptchaToken || '');
            if (!response.data.success) {
                throw new Error('Invalid reCAPTCHA');
            }
            await serverApi.signUpEmailPassword(
                email,
                password,
                firstName,
                lastName
            );
            await dispatch(signInEmailPassword({email, password, skipToken: true}));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
        }
    };

export const signOut =
    (signOutFirebase?: boolean): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            if (signOutFirebase) {
                await Firebase.signOut();
            }

            dispatch(setProfile({ profile: undefined }));
            dispatch(setTrips({ trips: undefined }));
            dispatch(setSelectedTrip({ selectedTrip: null }));
            dispatch(setError({ error: "" }));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateProfile =
    (data: object): AppThunk<Promise<any>> =>
    async (dispatch, getState, Firebase: any) => {
        try {
            const currentProfile = getState().user.profile;
            dispatch(isLoading(true));
            const updatedProfile = await serverApi.updateProfileFormData(data);
            try {
                const formData = data as FormData;
                for (const value of formData.values()) {
                    try {
                        const parsedData = JSON.parse(value.toString());
                        const payload = {
                            firstName: parsedData.firstName,
                            lastName: parsedData.lastName,
                            email: parsedData.email,
                            displayName: `${parsedData.firstName} ${parsedData.lastName}`,
                            avatar: updatedProfile.data.avatar,
                            avatarThumb: updatedProfile.data.avatar_thumb,
                        }
                        await chatApi.updateProfile(currentProfile.uid, payload);
                    } catch (_e) {
                        console.log('error', _e)
                    }
                }
            } catch (_e) {
                console.log('error', _e)
            }

            dispatch(
                setProfile({
                    profile: {
                        ...currentProfile,
                        ...updatedProfile.data,
                    },
                })
            );
            return dispatch(isLoading(false));
        } catch (error) {
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
            dispatch(isLoading(false));
        }
    };

export const updateUserType =
    (data: object, onFinish?: Function): AppThunk<Promise<any>> =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateUserType(data);
            dispatch(getProfile());
            onFinish && onFinish();
            return dispatch(isLoading(false));
        } catch (error) {
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
            dispatch(isLoading(false));
        }
    };

export const addUserRequest =
    (request: any, callback?: Function): AppThunk<Promise<any>> =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.newUseRequest(request);
            dispatch(isLoading(false));
            if (response.status === 208) {
                callback && callback({ alreadyRequested: true });
                return false;
            }
            callback && callback({ success: true });
            return true;
        } catch (error) {
            const nError = normalizeError(error);
            dispatch(setError({ error: nError }));
            dispatch(isLoading(false));
            callback && callback({ success: false });
            return false;
        }
    };

export const updateProfileExtra =
    (data: object): AppThunk<Promise<any>> =>
    async (dispatch, getState, Firebase: any) => {
        try {
            const currentProfile = getState().user.profileExtra;
            dispatch(isLoading(true));
            const updatedProfile = await serverApi.updateUserProfile(data);
            dispatch(
                setProfileExtra({
                    profileExtra: {
                        ...currentProfile,
                        ...updatedProfile.data,
                    },
                })
            );
            return dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const handleError =
    (error: any): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const statusCode = error?.response?.status;
        if (getState().user.unauthorized) {
            return;
        }
        if (statusCode === 401 && !getState().user.unauthorized) {
            dispatch(setUnauthorized({ unauthorized: true }));
            dispatch(signOut(true));
        }
        const nError = normalizeError(error);
        sendToast(TOAST_TYPE.ERROR, nError);
    };

export const updateMessagingToken =
    (token: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            const newProfile = await serverApi.updateProfileMessagingToken(
                token
            );
            const currentProfile = getState().user.profile;
            dispatch(
                setProfile({
                    profile: {
                        ...currentProfile,
                        ...newProfile.data,
                    },
                })
            );
            return;
        } catch (error) {
            console.log("error updating messagin token", error);
        }
    };

export const getSettings =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getUserSettings();
            const settings = response.data;
            const settingsDenormalized = deNormalizeSettings(settings);
            dispatch(setSettings({ settings: settingsDenormalized }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateSettings =
    (fields: any): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const currentSettings = getState().user.settings;
        try {
            const newSettings = { ...currentSettings, ...fields };
            // const settingsDenormalized = deNormalizeSettings(newSettings);
            dispatch(setSettings({ settings: newSettings }));
            await serverApi.updateUserSettings(newSettings);
            return;
        } catch (error) {
            dispatch(setSettings({ settings: currentSettings }));
            dispatch(handleError(error));
        }
    };

export const updateBreadCrumbOptions =
    (bredCrumpOptions: BreadcrumbOption[]): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setBredCrumpOptions({ bredCrumpOptions }));
            return;
        } catch (error) {
            dispatch(setBredCrumpOptions({ bredCrumpOptions: [] }));
            dispatch(handleError(error));
        }
    };

export const removeAccountRequest =
    (): AppThunk => async (dispatch, getState) => {
        try {
            await serverApi.removeAccountRequest();
            return;
        } catch (error) {
            dispatch(handleError(error));
        }
    };
export default userSlice.reducer;

export const settingOptions = [
    {
        name: "notificationRequiredDocuments",
        title: "Required Documents",
        description: "Trip Requirements events",
    },
    {
        name: "notificationItinerary",
        title: "Itinerary",
        description: "Trip itinerary events",
    },
    {
        name: "notificationInsights",
        title: "Insights",
        description: "New Insights",
    },
    {
        name: "notificationReviews",
        title: "Reviews",
        description: "New Reviews",
    },
    {
        name: "notificationTripImages",
        title: "Trip Images",
        description: "New images in trip gallery",
    },
    {
        name: "notificationTripFiles",
        title: "Trip Files",
        description: "New files in the files section",
    },
    {
        name: "notificationTripPublicReview",
        title: "Trip Public Review Reminder",
        description: "Reminder public review in the trip",
    },
    {
        name: "notificationNewChat",
        title: "Chat messages",
        description: "Alert for new messages",
        disabledEmail: true
    },
];
