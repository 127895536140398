import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
    selectError,
    setUnauthorized,
    signInEmailPassword,
    signInFacebook,
    signInGoogle,
} from "../userSlice";
import { useSelector } from "react-redux";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import ButtonSquared from "../../../components/ButtonSquare";
import Input from "../../../components/Input";
import SocialLoginButton from "../../../components/SocialLoginButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPES } from "../../../constants/notifications";
import {
    getTripCustomInvite,
    selectedTripCustomInvite,
} from "../../trips/tripSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FamguruLogo from "../../../assets/svg/LogoSquared.svg";
import { isLoadingSelector } from "../../layout";
import { useAuthStateContext } from "../../../context/Auth";
import { UNAUTHENTICATED } from "../../../api/firebase";
import Icon from "../../../assets/svg/Icon";
import ReCAPTCHA from "react-google-recaptcha";

const schema = yup
    .object({
        email: yup.string().email("Invalid email format").required("Required"),
        password: yup.string().required("Password is required"),
    })
    .required("This field is required");

export function SignIn() {
    const location: any = useLocation();
    const dispatch = useAppDispatch();
    const error = useSelector(selectError);
    const loading = useSelector(isLoadingSelector);
    const invitation = useSelector(selectedTripCustomInvite);
    const [selectedSignIn, setSelectedSignIn] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const { setAuthState } = useAuthStateContext();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleRecaptchaChange = (token: string | null) => {
        setRecaptchaToken(token);
    };

    const tripId = useMemo(() => {
        return new URLSearchParams(location?.state?.search).get("tripId");
    }, [location?.state?.search]);
    const notificationType = useMemo(() => {
        return new URLSearchParams(location?.state?.search).get("type");
    }, [location?.state?.search]);

    const showTripInvite = useMemo(() => {
        return (
            notificationType === NOTIFICATION_TYPES.TRIP_INVITATION &&
            !selectedSignIn &&
            (invitation?.title || loading)
        );
    }, [notificationType, selectedSignIn, invitation?.title, loading]);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: showTripInvite
                    ? PAGE_SECTIONS.TRIP_INVITE_GUEST
                    : PAGE_SECTIONS.SIGN_IN,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTripInvite]);

    useEffect(() => {
        if (notificationType === NOTIFICATION_TYPES.TRIP_INVITATION && tripId) {
            dispatch(getTripCustomInvite(Number(tripId)));
        }
    }, [notificationType, tripId]);

    const handleLogin = async (data: any) => {
        setAuthState && setAuthState({ status: UNAUTHENTICATED });
        dispatch(setUnauthorized({ unauthorized: false }));
        const email = data?.email?.trim();
        const password = data?.password;

        if (!recaptchaToken) {
            alert("Please verify reCAPTCHA to proceed.");
            return;
        }
        const loginData = {
            email,
            password,
            recaptchaToken,
        };

        if (signInEmailPassword) {
            dispatch(signInEmailPassword(loginData));
        }
    };

    const handleSubmitGoogle = async (e: any) => {
        e.preventDefault();
        if (signInGoogle) {
            dispatch(signInGoogle());
        }
    };

    const handleSubmitFacebook = async (e: any) => {
        e.preventDefault();
        if (signInFacebook) {
            dispatch(signInFacebook());
        }
    };

    const handleClickPassword = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
    };
    return (
        <>
            {!showTripInvite ? (
                <div className="min-h-full min-w-full px-4 pt-6 sm:px-6 lg:px-8">
                    <h1 className="text-center text-4xl font-bold text-neutral-700">
                        Log in to your account
                    </h1>
                    <div className="w-full flex items-start justify-center my-14">
                        <div className="w-80">
                            <div className="flex gap-10 justify-center">
                                <SocialLoginButton
                                    onClick={handleSubmitFacebook}
                                    icon="FacebookIcon"
                                />
                                <SocialLoginButton
                                    onClick={handleSubmitGoogle}
                                    icon="GoogleIcon"
                                />
                            </div>
                            <div className="text-neutral-300 text-lg mt-8">
                                or use your email account:
                            </div>
                            <form
                                className="mt-8 w-full"
                                onSubmit={handleSubmit(handleLogin)}
                            >
                                <Input
                                    register={register}
                                    label="Email address"
                                    //ref={emailRef}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    placeholder="Email address"
                                    error={errors["email"]?.message}
                                />
                                <div className="mt-8">
                                    <Icon
                                        icon={passwordType === "password" ? "EyeIcon" : "EyeOffIcon"}
                                        className={`w-8 h-8 m-0 fill-black text-neutral-200 mr-2`}
                                        onClick={handleClickPassword}
                                        style={{ position: "absolute", marginLeft: "290px", marginTop: "25px", cursor: "pointer" }}
                                    />
                                    <Input
                                        register={register}
                                        label="Password"
                                        id="password"
                                        name="password"
                                        type={passwordType}
                                        autoComplete="current-password"
                                        required
                                        placeholder="Password"
                                        error={errors["password"]?.message}
                                    />
                                </div>

                                <div className="flex items-center justify-between my-6">
                                    <div className="text-sm text-center w-full">
                                        <Link to="/reset-password">
                                            Forgot your password?
                                        </Link>
                                    </div>
                                </div>

                                <div>
                                <div className="mt-4 mb-4">
                                        <ReCAPTCHA
                                            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
                                            onChange={handleRecaptchaChange}
                                        />
                                    </div>
                                    <ButtonSquared
                                        label="Sign in"
                                        type="submit"
                                        disabled={!recaptchaToken}
                                    />
                                    <p className="text-sm	font-semibold text-alert_red text-left mt-4">
                                        {error}
                                    </p>
                                </div>
                                <p className="mt-6 text-center text-sm text-neutral-400">
                                    Dont you have an account?{" "}
                                    <Link to="/signup">Sign up</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col text-xs w-full text-center items-center">
                    <div className="flex flex-row items-center mb-8">
                        <img src={FamguruLogo} alt="" />
                        <span className="text-neutral-900 text-4xl font-semibold ml-4">
                            Fam
                        </span>
                        <span className="text-neutral-900 text-4xl">guru</span>
                    </div>
                    <div className="overflow-hidden shadow-card w-[80%] max-w-[600px] rounded-xl max-h-[250px]">
                        <LazyLoadImage
                            className="rounded-xl object-center object-cover"
                            width="100%"
                            alt={invitation?.title}
                            effect="blur"
                            src={invitation?.image}
                            placeholderSrc={invitation?.image_thumb}
                        />
                    </div>
                    <div className="flex flex-col text-xs max-w-[500px] w-[80%] items-center mt-6 text-center">
                        <div className="text-neutral-900 font-bold text-xl">
                            Congrats!
                        </div>
                        <div className="text-neutral-900 font-bold text-xl">
                            You've been invited to
                        </div>
                        <div className="text-neutral-900 font-bold text-xl mb-2">
                            {invitation?.title}
                        </div>
                        <div className="text-neutral-400 text-sm font-normal">
                            {invitation?.description}
                        </div>
                        <Link to="/signup">
                            <ButtonSquared
                                className="py-2 mt-8"
                                label="Sign up"
                                type="button"
                            />
                        </Link>
                        <p className="mt-4 text-xs text-center text-neutral-400">
                            Already have an account?{" "}
                            <span
                                className="text-orange-600 font-semibold cursor-pointer"
                                onClick={() => {
                                    setSelectedSignIn(true);
                                }}
                            >
                                Sign in
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}
