import { useEffect, useMemo, useState } from "react";
import {
    ButtonSize,
    TRIP_ACTIONS_LIST_OPTIONS,
    TRIP_ACTIONS_OPTIONS,
    TRIP_ACTIONS_OPTIONS_KEYS,
    UserRoleOnTrip,
} from "../../../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Icon from "../../../../assets/svg/Icon";
import moment from "moment";
import placeholder from "../../../../assets/images/placeholder.png";
import Skeleton from "react-loading-skeleton";
import ButtonSquared from "../../../../components/ButtonSquare";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    acceptInvite,
    deleteTrip,
    getTrip,
    getTripBrochure,
    getTripBrochureDocx,
    getTripReviewsDocx,
    // getTripReviewsPdf,
    leaveTrip,
    rejectInvite,
    updateOrganizationOnTrip,
} from "../../tripSlice";
import { selectProfile } from "../../../authentication/userSlice";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";
import DotsMenu from "../../../../components/DotsMenu";
import { presenterTrip } from "../../../../bussiness/trip";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";
import { parseMomentUtcToLocal } from "../../../../helpers";
import { ChooseOrganizationModal } from "../../../../components/Modal/ChooseOrganizationModal";
import { IOrganization } from "../../../../types/IOrganization";
import { useConstantsContext } from "../../../../context/Constants";
import { useNavigate } from "react-router-dom";

interface ITripDetailCardProps {
    tripHome: {
        trip: {
            id: number;
            startDate: string;
            endDate: string;
            title: string;
            description: string;
            users: any[];
            coverImage: string;
            coverImage_thumb: string;
        };

        membersConfirmed: number;
        membersPending: number;
        membersRejected: number;
        membersTotal: number;
    };
    hideActions?: boolean;
    isPending: boolean;
    canEdit: boolean;
    isOwner?: boolean;
    canDelete?: boolean;
    hideDuplicate?: boolean;
    customInvite?: {
        title: string;
        description: string;
        image?: string;
        image_thumb?: string;
    };
    organization?: IOrganization;
}

export const TripDetailCard = (props: ITripDetailCardProps) => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [acceptInvitation, setAcceptInvitation] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [addOrganizationModalOpen, setAddOrganizationModalOpen] =
        useState(false);
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const [daysLeft, setDaysLeft] = useState<number>(0);
    const navigate = useNavigate();
    const { constants } = useConstantsContext();

    const {
        tripHome,
        isPending,
        canEdit,
        isOwner,
        customInvite,
        hideActions,
        hideDuplicate,
    } = props;
    const tripUsers = useMemo(() => {
        return tripHome?.trip?.users || [];
    }, [tripHome?.trip?.users]);

    const meOnTrip =
        tripUsers &&
        tripUsers.find((usr: any) => {
            return usr.userEmail === profile?.email;
        });
    const menuOptions = useMemo(() => {
        let options: any[] = meOnTrip?.role
            ? TRIP_ACTIONS_LIST_OPTIONS[meOnTrip?.role]
            : [];
        options = options.filter((o) => {
            return (
                !hideDuplicate ||
                o?.key !== TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP
            );
        });
        if (profile?.isAdmin) {
            options =
                !meOnTrip?.role || meOnTrip?.role === UserRoleOnTrip.OWNER
                    ? [...TRIP_ACTIONS_LIST_OPTIONS[UserRoleOnTrip.OWNER]]
                    : [
                          ...TRIP_ACTIONS_LIST_OPTIONS[UserRoleOnTrip.OWNER],
                          TRIP_ACTIONS_OPTIONS[
                              TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP
                          ],
                      ];
        }
        return options;
    }, [meOnTrip?.role, profile?.isAdmin]);

    useEffect(() => {
        if (tripUsers) {
            setDaysLeft(
                parseMomentUtcToLocal(tripHome?.trip.startDate)
                    .startOf("day")
                    .diff(moment(Date.now()).startOf("day"), "days")
            );
        }
    }, [tripUsers, tripHome?.trip?.startDate]);

    const handleEditClick = () => {
        navigate(`/trips/${tripHome?.trip?.id}/edit`);
    };

    const handleDuplicate = () => {
        navigate(`/trips/${tripHome?.trip?.id}/duplicate`);
    };

    const handleEditInvitation = () => {
        navigate(`/trips/${tripHome?.trip?.id}/invites`);
    };

    const handleDelete = async () => {
        await dispatch(deleteTrip(tripHome?.trip?.id));
        navigate(`/trips`);
    };

    const handleLeave = async () => {
        await dispatch(leaveTrip(tripHome?.trip?.id));
        navigate(`/trips`);
    };

    const handleDownloadBrochure = () => {
        dispatch(getTripBrochure(tripHome?.trip?.id));
    };

    // const handleDownloadTripReviewsPdf = () => {
    //     dispatch(getTripReviewsPdf(tripHome?.trip?.id));
    // };
    const handleDownloadTripReviewsDocx = () => {
        dispatch(getTripReviewsDocx(tripHome?.trip?.id));
    };
    const handleDownloadTripBrochureDocx = () => {
        dispatch(getTripBrochureDocx(tripHome?.trip?.id));
    };

    const handleInvitation = async (accepted: boolean) => {
        await dispatch(
            accepted
                ? acceptInvite(tripHome.trip.id, profile.email)
                : rejectInvite(tripHome.trip.id, profile.email)
        );

        setOpenModal(false);
        if (accepted) dispatch(getTrip(tripHome?.trip?.id, true));
        else navigate(`/trips`);
    };

    const handleClickUserDotsMenu = (optionId: string) => {
        switch (optionId) {
            case TRIP_ACTIONS_OPTIONS_KEYS.DELETE_TRIP:
                setDeleteModalOpen(true);
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.DUPLICATE_TRIP:
                handleDuplicate();
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP:
                handleDownloadBrochure();
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_BROCHURE_DOCX:
                handleDownloadTripBrochureDocx();
                break;
            // case TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS:
            //     handleDownloadTripReviewsPdf();
            //     break;
            case TRIP_ACTIONS_OPTIONS_KEYS.EXPORT_TRIP_REVIEWS_DOCX:
                handleDownloadTripReviewsDocx();
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.LEAVE_TRIP:
                setLeaveModalOpen(true);
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.EDIT_INVITATION:
                handleEditInvitation();
                break;
            case TRIP_ACTIONS_OPTIONS_KEYS.ASSIGN_TRIP_ORGANIZATION:
                setAddOrganizationModalOpen(true);
                break;
            default:
                break;
        }
        return;
    };

    const onChangeOrganizationTrip = (organizationId: number) => {
        dispatch(updateOrganizationOnTrip(tripHome?.trip?.id, organizationId));
        setAddOrganizationModalOpen(false);
    };

    const tripPresenter = useMemo(() => {
        return tripHome?.trip && presenterTrip(tripHome?.trip, "MMM DD, YYYY");
    }, [tripHome?.trip]);

    const element = document.querySelectorAll(".trip-description")[0];
    const isEllipsisActive = (el: any) => {
        return el.offsetHeight < el.scrollHeight;
    };
    const showReadMoreButton = useMemo(() => {
        return element && isEllipsisActive(element);
    }, [element]);

    if (!tripHome) {
        return (
            <div className="block">
                <Skeleton className="!rounded-xl min-h-[200px] w-full mb-6" />
                <Skeleton className="!rounded-xl min-h-[150px] w-full" />
            </div>
        );
    }

    return (
        <div>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove this trip.`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={handleDelete}
            />
            <ModalConfirmation
                open={leaveModalOpen}
                title="Are you sure?"
                description={`You are about to leave the trip.`}
                handleCancel={() => {
                    setLeaveModalOpen(false);
                }}
                okButtonText="Leave"
                handleSubmit={handleLeave}
            />
            <ChooseOrganizationModal
                open={addOrganizationModalOpen}
                onChangeOrganization={onChangeOrganizationTrip}
                handleClose={() => {
                    setAddOrganizationModalOpen(false);
                }}
                userType="PRIMARY"
                organization={props.organization}
                children={null}
                tripId={tripHome?.trip?.id}
            />
            {isPending && (
                <div className="mb-6 text-xs bg-[#FDF2EE] border border-orange-500 shadow-card rounded-xl py-4 px-6 w-full">
                    <div className="flex text-xs text-center items-center w-full flex-wrap md:flex-nowrap gap-2">
                        <ModalConfirmation
                            open={openModal}
                            title={`Are you sure you want to ${
                                acceptInvitation ? "accept" : "reject"
                            } this trip invitation?`}
                            handleCancel={() => {
                                setOpenModal(false);
                            }}
                            okButtonText="Join Trip"
                            cancelButtonText="Cancel"
                            handleSubmit={() => {
                                handleInvitation(acceptInvitation);
                            }}
                        />
                        <div className="self-baseline">
                            <Icon
                                icon="InfoIcon"
                                className="w-6 text-primary_magenta-500"
                            />
                        </div>
                        <div className="flex flex-grow flex-col">
                            <div className="font-semibold text-base text-left">
                                You have been invited to this trip
                            </div>
                            <span className="text-left text-sm text-neutral-400">
                                Accepting this invitation will allow your host's
                                administrators to have access to your profile
                                information.
                            </span>
                        </div>

                        <div className="gap-x-2 flex">
                            <ButtonSquared
                                className="w-[100px]"
                                size={ButtonSize.SMALL}
                                type="button"
                                label="Accept"
                                onClick={() => {
                                    setOpenModal(true);
                                    setAcceptInvitation(true);
                                }}
                            />
                            <ButtonSquared
                                className="w-[100px]"
                                size={ButtonSize.SMALL}
                                outlined
                                label="Decline"
                                type="button"
                                onClick={() => {
                                    setOpenModal(true);
                                    setAcceptInvitation(false);
                                }}
                            />
                        </div>
                    </div>
                    {customInvite && (
                        <div className="flex flex-col text-xs w-full border-t border-gray-200 mt-6 pt-6">
                            <div className="text-left font-semibold	text-xl mb-4">
                                {customInvite?.title}
                            </div>

                            <LazyLoadImage
                                className="rounded-xl min-h-[250px] object-cover"
                                width="100%"
                                alt={customInvite?.title}
                                effect="blur"
                                src={customInvite?.image || placeholder}
                                placeholderSrc={
                                    customInvite?.image_thumb || placeholder
                                }
                            />

                            <Paragraph className="text-neutral-400 text-base font-normal mt-4 text-left">
                                {customInvite?.description}
                            </Paragraph>
                        </div>
                    )}
                </div>
            )}
            <div className="flex flex-wrap lg:flex-nowrap gap-6">
                <div className="text-left bg-white shadow-card rounded-xl p-6 pb-4 w-full lg:w-3/5">
                    <div className="w-full flex gap-4 justify-between items-center pb-2">
                        <span className="text-xl break-words font-semibold w-full">
                            {tripPresenter?.title}
                        </span>
                        {!hideActions && !!menuOptions?.length && (
                            <div className="lg:text-right flex flex-row gap-x-2 flex-grow items-start justify-end">
                                {canEdit && (
                                    <div
                                        onClick={handleEditClick}
                                        className="cursor-pointer border p-1 rounded-lg text-neutral-300 hover:text-neutral-800 hover:border-neutral-800"
                                    >
                                        <Icon
                                            icon="PencilIconOutline"
                                            className="w-5"
                                            title="Edit Trip"
                                            description="Edit Trip"
                                        />
                                    </div>
                                )}

                                <DotsMenu
                                    wrapperClass="cursor-pointer border hover:border-neutral-800 p-1 rounded-lg"
                                    options={menuOptions}
                                    handleClickMenu={handleClickUserDotsMenu}
                                />
                            </div>
                        )}
                    </div>
                    {constants?.featureFlags?.ORGANIZATIONS &&
                        !!props.organization &&
                        !!props.organization.name.trim().length && (
                            <div className="text-sm pb-2 text-neutral-600">
                                Organization: {props.organization.name}
                            </div>
                        )}

                    <div className="flex flex-row items-center gap-x-1 text-xs text-neutral-400 font-semibold">
                        <Icon
                            icon="CalendarIcon"
                            className="text-neutral-400 w-4 h-4"
                        />
                        <span>
                            Start date:{" "}
                            <span className="text-neutral-900">
                                {tripPresenter?.startDate}
                            </span>
                        </span>
                        <span className="ml-2">
                            End date:{" "}
                            <span className="text-neutral-900">
                                {tripPresenter?.endDate}
                            </span>
                        </span>
                    </div>
                    <Paragraph
                        className={`text-xs mt-4 ${
                            readMore ? "" : "line-clamp-4"
                        } trip-description`}
                    >
                        {tripPresenter?.description}
                    </Paragraph>
                    {!!showReadMoreButton && (
                        <div className="flex flex-row justify-start">
                            <div
                                onClick={() => {
                                    setReadMore(!readMore);
                                }}
                                className="text-sm font-semibold text-orange-600 cursor-pointer w-fit"
                            >
                                {readMore ? "Read less" : "Read more"}
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-full lg:w-2/5 lg:h-60 overflow-hidden">
                    <LazyLoadImage
                        className="shadow-card w-full lg:h-60 rounded-xl min-h-[190px] object-cover"
                        width="100%"
                        alt={tripPresenter?.title}
                        effect="blur"
                        src={tripPresenter?.coverImage || placeholder}
                        placeholderSrc={
                            tripPresenter?.coverImage_thumb || placeholder
                        }
                    />
                </div>
            </div>
            <div className="mt-6 flex text-xs text-center text-neutral-300 bg-white shadow-card rounded-xl p-4 w-full divide-x divide-x-neutral-200">
                <div className="flex-grow flex flex-col items-center">
                    <div>
                        {" "}
                        <Icon
                            icon="ParticipantsIcon"
                            className="text-neutral-300 w-8"
                        />
                    </div>{" "}
                    <div className="text-xl text-neutral-400">
                        {tripHome?.membersTotal - (isOwner ? 1 : 0) || 0}
                    </div>{" "}
                    <div className="text-xs mt-1">Invited</div>{" "}
                </div>
                <div className=" flex-grow flex flex-col items-center">
                    <div>
                        {" "}
                        <Icon
                            icon="AccountClockOutline"
                            className="text-neutral-300 w-8"
                        />
                    </div>{" "}
                    <div className="text-xl text-neutral-400">
                        {tripHome?.membersPending || 0}
                    </div>{" "}
                    <div className="text-xs mt-1">Pending</div>{" "}
                </div>
                <div className="flex-grow flex flex-col items-center">
                    <div>
                        {" "}
                        <Icon
                            icon="AccountCheckOutline"
                            className="text-neutral-300 w-8"
                        />
                    </div>{" "}
                    <div className="text-xl text-neutral-400">
                        {tripHome?.membersConfirmed - (isOwner ? 1 : 0) || 0}
                    </div>{" "}
                    <div className="text-xs mt-1">Confirmed</div>{" "}
                </div>
                <div className="flex-grow flex flex-col items-center">
                    <div>
                        {" "}
                        <Icon
                            icon="TimerOutline"
                            className="text-neutral-300 w-8"
                        />
                    </div>{" "}
                    <div className="text-xl text-neutral-400">
                        {(daysLeft || 0) > 0 ? daysLeft : 0} days
                    </div>{" "}
                    <div className="text-xs mt-1">Countdown</div>{" "}
                </div>
            </div>
        </div>
    );
};
