import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";

export const InfoBanner = ({
    title,
    descriptionTitle,
    description,
    actionLabel,
    action,
}: {
    title: string;
    descriptionTitle?: string;
    description?: string;
    action?: Function;
    actionLabel?: string;
    icon?: any;
}) => {
    return (
        <div className="flex flex-row border border-primary_orange-900 bg-[#FDF2EE] rounded-md p-4 my-2 justify-between items-center gap-6 flex-wrap">
            <div className="flex flex-col items-start">
                <div className="text-left text-base font-semibold text-neutral-900">
                    {title}
                </div>
                {descriptionTitle && (
                    <div className="text-left text-sm font-medium text-neutral-400">
                        {descriptionTitle}
                    </div>
                )}
                <div className="text-left text-sm font-semibold text-neutral-900">
                    {description}
                </div>
                {actionLabel && (
                    <div className="pt-2">
                        <ButtonSquared
                            size={ButtonSize.FIT}
                            label={actionLabel}
                            onClick={() => action && action()}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
