import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Button from "../../../../../components/ButtonSquare";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import GoogleMap from "../../../../../components/Map";
import HotelsAutocomplete from "../../../../../components/HotelsAutocomplete/HotelsAutocomplete";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import { ActivityType } from "../../../../../constants";
import {
    addActivityToTrip,
    selectedTrip,
    uploadTempFile,
} from "../../../tripSlice";
import ContactForm from "../ContactForm";
import ContactCard from "../ContactCard";
import useActivityContact from "../../../../../hooks/useActivityContact";
import moment from "moment";
import useGoogleMaps from "../../../../../hooks/usePlacesService";
import DatePickerMaterial from "../../../../../components/DatePickerMaterial";
import accountImage from "../../../../../assets/images/accountAdd.png";
import fileImage from "../../../../../assets/images/files.png";
import DropImageFileInput from "../../../../../components/DropImageFileInput/DropImageFileInput";
import axios from "axios";
import GalleryImage from "../../../../../components/ImagesGallery/Image";
import DropFileInput from "../../../../../components/DropFileInput/DropFileInput";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import TextareaWithValid from "../../../../../components/TextArea";
import HotelForm from "../../../../hotels/HotelForm";
import TimePickerMaterial from "../../../../../components/TimePickerMaterial";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import Input from "../../../../../components/Input";
import { selectedHotel, setSelectedHotel } from "../../../../hotels/hotelSlice";
import Icon from "../../../../../assets/svg/Icon";
import InputWithValid from "../../../../../components/Input";
import { schemaHotel } from "./schemas";
import { selectProfile } from "../../../../authentication/userSlice";
import { parseMomentUtcToLocal, resizeImageFile } from "../../../../../helpers";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import PhoneInput from "../../../../../components/PhoneInput";

let imgProgressArray: any[] = [];
let fileProgressArray: any[] = [];
let imgTempfilesArray: any[] = [];
let fileTempfilesArray: any[] = [];
let fileCounter = 0;

export default function HotelActivityForm(props: any) {
    const [searchParams] = useSearchParams();
    const [editingHotel, setEditingHotel] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [addHotelModalOpen, setAddHotelModalOpen] = useState(false);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [imagesProgressState, setImagesProgressState] = useState<any[]>([]);
    const [filesProgressState, setFilesProgressState] = useState<any[]>([]);
    const [tempFilesState, setTempFilesState] = useState<any[]>([]);
    const [tempImagesState, setTempImagesState] = useState<any[]>([]);
    const [deletedImagesState, setDeletedImagesState] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [imagesPreviews, setImagesPreviews] = useState<Array<any>>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);
    const [mapMarker, setMapMarker] = useState<any | null>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    const profile = useAppSelector(selectProfile);
    let { activityId } = useParams();
    const {
        activity,
        type,
        navigateBack,
        onSubmit,
        currentSection,
        isLibraryElement,
    } = props;
    const isVisit = useMemo(() => {
        return (
            type === ActivityType.VISIT ||
            activity?.isVisit ||
            (activity &&
                activity.startDate &&
                activity.endDate &&
                moment.utc(activity.startDate).format("DD-MM-YYYY") ===
                    moment.utc(activity.endDate).format("DD-MM-YYYY"))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, activity?.startDate, activity?.endDate]);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schemaHotel(isVisit)),
    });
    const mapService = useGoogleMaps();
    const {
        contacts,
        contactsModalOpen,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactSubmit,
        handleContactToggle,
        selectedContact,
        setSelectedContact,
        setContacts,
    } = useActivityContact([]);

    const currentHotel = useAppSelector(selectedHotel);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisit]);

    useEffect(() => {
        setValue("contacts", contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    useEffect(() => {
        setValue("isLibraryElement", !!isLibraryElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLibraryElement]);

    useEffect(() => {
        return () => {
            imgProgressArray = [];
            fileProgressArray = [];
            imgTempfilesArray = [];
            fileTempfilesArray = [];
            fileCounter = 0;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activity?.hotelActivity?.hotel?.id !== currentHotel?.id) {
            handleSelectHotel(currentHotel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentHotel?.id, currentHotel?.name]);

    useEffect(() => {
        if (
            activity &&
            activityId &&
            mapService.initialized &&
            !mapService.isLoading
        ) {
            const setHotelToEdit = () => {
                setIsUpdate(true);
                const {
                    startHour = "09:00",
                    endHour = "10:00",
                    contacts,
                    images,
                    files,
                    title,
                } = activity;
                let serverHotel = {
                    ...activity?.hotelActivity?.hotel,
                };
                dispatch(setSelectedHotel({ selectedHotel: serverHotel }));
                const startDateInit =
                    activity.startDate ||
                    searchParams.get("date") ||
                    new Date();
                const tripEndDate = moment
                    .utc(trip?.endDate)
                    .endOf("day")
                    .toDate();
                const endDateFallback = moment
                    .utc(startDateInit)
                    .add(1, "day")
                    .isAfter(tripEndDate)
                    ? tripEndDate
                    : moment.utc(startDateInit).add(1, "day").toDate();
                const endDateInit = activity.endDate || endDateFallback;
                reset({
                    title: title || "",
                    startDate: moment.utc(startDateInit),
                    endDate: moment.utc(endDateInit),
                    description: activity.description,
                    phone: activity?.phone || currentHotel?.phone,
                    website: activity?.website || currentHotel?.website,
                    startHour: moment(startHour, "HH:mm"),
                    endHour: moment(endHour, "HH:mm"),
                    hotel: serverHotel,
                    isLibraryElement,
                });
                setStartDate(moment.utc(startDateInit));
                setEndDate(moment.utc(endDateInit));
                setContacts(Object.values(contacts || {}));
                const currentImagesNormalized: Array<any> =
                    Object.values(images || {}) || [];
                const currentFilesNormalized: Array<any> =
                    Object.values(files || {}) || [];
                setImagesPreviews(
                    currentImagesNormalized.map((i) => {
                        return {
                            name: i.originalName,
                            src: i.url,
                            width: i.width,
                            height: i.height,
                            isCurrent: true,
                        };
                    })
                );
                setFilesPreviews(
                    currentFilesNormalized.map((i) => {
                        return {
                            src: i.url,
                            name: i.originalName,
                            isCurrent: true,
                        };
                    })
                );

                const marker = {
                    position: {
                        lat: serverHotel?.lat,
                        lng: serverHotel?.lng,
                    },
                    title: serverHotel?.name,
                };
                setMapMarker(marker);
            };
            setHotelToEdit();
        } else {
            dispatch(setSelectedHotel({ selectedHotel: null }));
            const startDateInit = searchParams.get("date")
                ? moment(searchParams.get("date"), "MM-DD-YYYY").toDate()
                : new Date();
            const endDateInit = moment
                .utc(startDateInit)
                .add(1, "day")
                .toDate();
            setStartDate(startDateInit);
            setEndDate(endDateInit);
            reset({
                title: "",
                startDate: startDateInit,
                endDate: endDateInit,
                endHour: moment("09:00", "HH:mm"),
                startHour: moment("10:00", "HH:mm"),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity?.id, mapService.initialized]);

    const handleSubmitForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity: any = {
            type: ActivityType.HOTEL,
            startDate: moment(startDate).toDate(),
            endDate: moment(isVisit ? startDate : endDate).toDate(),
            startHour: startHour,
            title: data.title,
            description: data.description,
            website: data.website,
            endHour: isVisit ? startHour : endHour,
            hotel: data.hotel,
            contacts: data.contacts,
            images: tempImagesState.map((img) => img.path),
            files: tempFilesState.map((file) => file.path),
        };
        await dispatch(addActivityToTrip(Number(trip.id), { activity }));
        return navigateBack();
    };

    const handleUpdateForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity: any = {
            type: ActivityType.HOTEL,
            hotel: currentHotel,
            title: data.title,
            description: data.description,
            website: data.website,
            phone: data.phone,
            startDate: moment(startDate).toDate(),
            endDate: moment(isVisit ? startDate : endDate).toDate(),
            startHour: startHour,
            endHour: isVisit ? startHour : endHour,
            contacts: data.contacts,
            deletedImages: deletedImagesState.length
                ? deletedImagesState
                : undefined,
            deletedFiles: deletedFilesState.length
                ? deletedFilesState
                : undefined,
            images: tempImagesState.map((img) => img.path),
            files: tempFilesState.map((file) => file.path),
        };
        const addedFromLibrary = searchParams.get("fromLibrary") === "true";
        if (addedFromLibrary) {
            activity.libraryElementId = props.activity?.id;
            // Remove deleted images and files from the library element images and files
            activity.imagesFromLibraryElement = Object.values(
                props.activity?.images || {}
            ).filter(
                (img: any) => !deletedImagesState.includes(img.originalName)
            );
            activity.filesFromLibraryElement = Object.values(
                props.activity?.files || {}
            ).filter(
                (file: any) => !deletedFilesState.includes(file.originalName)
            );
            delete activity.deletedImages;
            delete activity.deletedFiles;
        }
        await onSubmit(activity);
    };

    const handleChangeHotel = (hotel: any) => {
        setValue("hotel", hotel);
        dispatch(setSelectedHotel({ selectedHotel: hotel }));

        if (hotel) {
            const marker = {
                position: {
                    lat: hotel.lat,
                    lng: hotel.lng,
                },
                title: hotel?.name,
            };
            setMapMarker(marker);
        } else {
            setMapMarker(null);
        }
    };

    const onImageProgress = (id: number, progress: number) => {
        imgProgressArray = imgProgressArray.map((img) => {
            return {
                ...img,
                progress: id === img.id ? progress : img.progress,
            };
        });
        setImagesProgressState(imgProgressArray);
    };

    const onFileProgress = (id: number, progress: number) => {
        fileProgressArray = fileProgressArray.map((file) => {
            return {
                ...file,
                progress: id === file.id ? progress : file.progress,
            };
        });
        setFilesProgressState(fileProgressArray);
    };

    const removeTempImage = (id: number) => {
        let index = imgTempfilesArray.findIndex((img) => img.id === id);
        if (index > -1) {
            URL.revokeObjectURL(imgTempfilesArray[index].file);
            imgTempfilesArray.splice(index, 1);
            setTempImagesState([...imgTempfilesArray]);
        }
    };

    const removeTempFile = (id: number) => {
        let index = fileTempfilesArray.findIndex((img) => img.id === id);
        if (index > -1) {
            URL.revokeObjectURL(fileTempfilesArray[index].file);
            fileTempfilesArray.splice(index, 1);
            setTempFilesState([...fileTempfilesArray]);
        }
    };

    const removeProgressImage = (id: number, cancel = false) => {
        const index = imgProgressArray.findIndex((img) => img.id === id);
        if (index > -1) {
            cancel && imgProgressArray[0].cancelToken.cancel();
            imgProgressArray.splice(index, 1);
            setImagesProgressState([...imgProgressArray]);
        }
    };

    const removeProgressFile = (id: number, cancel = false) => {
        const index = fileProgressArray.findIndex((file) => file.id === id);
        if (index > -1) {
            cancel && fileProgressArray[0].cancelToken.cancel();
            fileProgressArray.splice(index, 1);
            setFilesProgressState([...fileProgressArray]);
        }
    };

    const onSelectImages = async (e: any) => {
        if (e.target.files) {
            fileCounter = fileCounter + 1;

            const filesArray: any[] = Array.from(e.target.files);
            for (const file of filesArray) {
                const imageResized = await resizeImageFile(file);
                const newtempImg = {
                    id: fileCounter,
                    file: imageResized,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                try {
                    imgProgressArray.push(newtempImg);
                    const response = await uploadTempFile(
                        imageResized,
                        newtempImg.id,
                        onImageProgress,
                        newtempImg.cancelToken
                    );
                    imgTempfilesArray.push({
                        file: URL.createObjectURL(imageResized),
                        path: response,
                        id: newtempImg.id,
                    });
                    removeProgressImage(newtempImg.id);
                    setTempImagesState([...imgTempfilesArray]);
                    fileCounter++;
                } catch (error) {}
            }

            setImagesProgressState(imgProgressArray);

            return;
        }
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            fileCounter = fileCounter + 1;
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: fileCounter,
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };

                fileProgressArray.push(newtempFile);
                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    fileTempfilesArray.push({
                        file: file,
                        path: response,
                        id: newtempFile.id,
                    });
                    removeProgressFile(newtempFile.id);
                    setTempFilesState([...fileTempfilesArray]);
                });
                fileCounter++;
            });

            setFilesProgressState(fileProgressArray);

            return;
        }
    };

    const handleImageRemove = (index: number) => {
        setDeletedImagesState([
            ...deletedImagesState,
            imagesPreviews[index].name,
        ]);
        const spliceArray = imagesPreviews;
        spliceArray.splice(index, 1);
        setImagesPreviews(spliceArray);
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([...deletedFilesState, filesPreviews[index].name]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    const handleHotelModalToggle = (open: boolean) => {
        setAddHotelModalOpen(open);
    };
    const handleSelectHotel = (hotel: any) => {
        const title = `${!isVisit ? "Hotel Stay in" : "Site Inspection at"} ${
            hotel?.name || ""
        }`;
        setValue("title", title);
        setEditingHotel(false);
    };

    return (
        (activity || !isUpdate) && (
            <>
                <Modal
                    open={addHotelModalOpen}
                    size={Modal_Size.lg}
                    onClose={() => {
                        handleHotelModalToggle(false);
                    }}
                >
                    <HotelForm
                        addNew={addNew}
                        name={searchValue}
                        onCancel={() => {
                            handleHotelModalToggle(false);
                        }}
                        onChange={() => handleHotelModalToggle(false)}
                    />
                </Modal>
                <Modal
                    open={contactsModalOpen}
                    onClose={() => handleContactToggle(false)}
                    hideCloseButton
                >
                    <ContactForm
                        onClose={() => handleContactToggle(false)}
                        onSubmit={handleContactSubmit}
                        contact={contacts && contacts[selectedContact]}
                    />
                </Modal>
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(
                        isUpdate ? handleUpdateForm : handleSubmitForm
                    )}
                    className="text-left lg:px-6"
                >
                    {currentHotel && !editingHotel && (
                        <div className="mb-6 col-span-6 flex flex-row items-center gap-2">
                            <Input
                                register={register}
                                name="title"
                                id="title"
                                type="text"
                                label="Title"
                                defaultValue={activity?.title || ""}
                                placeholder="Activity title"
                            />
                            <Icon
                                onClick={() => {
                                    setEditingHotel(true);
                                }}
                                title="Replace Hotel"
                                icon={"FileReplaceIcon"}
                                className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                            />

                            {currentHotel.userId === profile.id && (
                                <Icon
                                    onClick={() => {
                                        setAddNew(false);
                                        handleHotelModalToggle(true);
                                    }}
                                    title="Edit Hotel"
                                    icon={"PencilIcon"}
                                    className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                                />
                            )}
                        </div>
                    )}
                    {editingHotel && (
                        <div className="flex flex-col">
                            <div className="text-base font-semibold text-neutral-300">
                                Please select the hotel you want to add
                            </div>{" "}
                        </div>
                    )}

                    {editingHotel && (
                        <div className="mt-6 searchbox">
                            <HotelsAutocomplete
                                className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                                onChange={handleChangeHotel}
                                onTextChange={setSearchValue}
                                type="text"
                                //defaultValue={currentHotel}
                                placeholder="Search"
                                handleSelect={handleSelectHotel}
                            />
                        </div>
                    )}

                    {editingHotel && (
                        <div className="ml-4 mt-4 flex text-sm">
                            We couldn't find the activity. Would you like to{" "}
                            <span
                                onClick={() => {
                                    handleHotelModalToggle(true);
                                    setAddNew(true);
                                }}
                                className="mx-2 cursor-pointer font-semibold text-orange-600"
                            >
                                add
                            </span>
                            one?
                        </div>
                    )}
                    {currentHotel && !editingHotel && (
                        <>
                            {errors["hotel"]
                                ? "Incomplete address information"
                                : ""}
                            <div className="grid grid-cols-6 gap-x-12 mt-6 shadow-sm border border-gray-300 rounded-xl">
                                <div className="col-span-6">
                                    {mapMarker && (
                                        <GoogleMap marker={mapMarker} />
                                    )}
                                </div>
                                <div className="mt-6 col-span-6 lg:col-span-3 p-4 ">
                                    <div className="flex flex-row gap-2 text-sm font-medium text-neutral-600">
                                        <div>Address</div>
                                    </div>
                                    <div className="text-base">
                                        {currentHotel.fullAddress}
                                    </div>
                                </div>
                                {!!currentHotel?.zipCode?.length &&
                                    currentHotel.zipCode !== "undefined" && (
                                        <div className="mt-6 col-span-6 lg:col-span-3 p-4 ">
                                            <div className="text-sm font-medium text-neutral-600">
                                                Postal code
                                            </div>
                                            <div className="text-base">
                                                {currentHotel.zipCode}
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <div className="grid grid-cols-6 gap-x-12">
                                {currentHotel.phone && (
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Phone number
                                        </div>
                                        <div className="text-base">
                                            {currentHotel.phone}
                                        </div>
                                    </div>
                                )}
                                {currentHotel.website && (
                                    <div className="mt-6 col-span-6">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Website
                                        </div>
                                        <Paragraph className="text-base">
                                            <a
                                                className="text-base block mt-1 mb-2 break-all"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={currentHotel.website}
                                            >
                                                {currentHotel.website}
                                            </a>
                                        </Paragraph>
                                    </div>
                                )}
                            </div>
                            <div className="mb-6 grid grid-cols-6 gap-x-12">
                                {!isLibraryElement && (
                                    <>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <DatePickerMaterial
                                                control={control}
                                                minDate={
                                                    parseMomentUtcToLocal(
                                                        trip?.startDate
                                                    ).toDate() as any
                                                }
                                                maxDate={
                                                    moment
                                                        .utc(trip?.endDate)
                                                        .endOf("day")
                                                        .toDate() as any
                                                }
                                                name="startDate"
                                                value={startDate}
                                                id="startDate"
                                                label="Start date"
                                                onChange={setStartDate}
                                                error={
                                                    errors["startDate"]?.message
                                                }
                                            />
                                        </div>

                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            {!isVisit && (
                                                <DatePickerMaterial
                                                    control={control}
                                                    minDate={
                                                        moment
                                                            .utc(startDate)
                                                            .add(1, "day")
                                                            .toDate() as any
                                                    }
                                                    maxDate={
                                                        moment
                                                            .utc(trip?.endDate)
                                                            .endOf("day")
                                                            .toDate() as any
                                                    }
                                                    value={endDate}
                                                    name="endDate"
                                                    id="endDate"
                                                    label="End date"
                                                    error={
                                                        errors["endDate"]
                                                            ?.message
                                                    }
                                                    onChange={setEndDate}
                                                />
                                            )}
                                        </div>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <TimePickerMaterial
                                                control={control}
                                                label="Start Hour"
                                                defaultValue={
                                                    moment(
                                                        activity?.startHour ||
                                                            "09:00",
                                                        "HH:mm"
                                                    ) as any
                                                }
                                                id="startHour"
                                                name="startHour"
                                                error={
                                                    errors["startHour"]?.message
                                                }
                                            />
                                        </div>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            {!isVisit && (
                                                <TimePickerMaterial
                                                    control={control}
                                                    label="End Hour"
                                                    defaultValue={
                                                        moment(
                                                            activity?.endHour ||
                                                                "10:00",
                                                            "HH:mm"
                                                        ) as any
                                                    }
                                                    id="endHour"
                                                    name="endHour"
                                                    error={
                                                        errors["endHour"]
                                                            ?.message
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="mb-6">
                                <TextareaWithValid
                                    register={register}
                                    label="Description"
                                    id="description"
                                    name="description"
                                    error={errors["description"]?.message}
                                />
                            </div>
                            <div className="mb-6">
                                <InputWithValid
                                    register={register}
                                    label="Website"
                                    type="text"
                                    id="website"
                                    name="website"
                                    error={errors["website"]?.message}
                                />
                            </div>
                            <div className="mb-6">
                                <span className="block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="text-sm font-semibold mb-4">
                                Contacts
                            </div>
                            <div
                                onClick={() => {
                                    setSelectedContact(null);
                                    handleContactToggle(true);
                                }}
                                className="flex cursor-pointer mb-3 border border-gray-200 rounded-xl p-6 justify-center"
                            >
                                <div className="flex flex-wrap items-center lg:min-w-[300px]">
                                    <img
                                        src={accountImage}
                                        className="h-16 mr-12"
                                        alt=""
                                    />
                                    <div className="flex flex-col">
                                        <div className="text-semibold text-orange-600">
                                            Add contact
                                        </div>
                                        <div className="text-sm text-neutral-400">
                                            Submit contact information
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {contacts && (
                                <div className="flex gap-6 flex-wrap">
                                    {contacts.map((c: any, index: number) => (
                                        <ContactCard
                                            handleEdit={() => {
                                                handleContactEditClick(index);
                                            }}
                                            handleRemove={() => {
                                                handleContactRemoveClick(index);
                                            }}
                                            contact={c}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            )}

                            <div className="text-sm font-semibold mt-8 mb-4">
                                Photos
                            </div>
                            <div>
                                <DropImageFileInput
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={onSelectImages}
                                />
                            </div>

                            {imagesProgressState.map((img) => {
                                return (
                                    <ProgressBar
                                        key={img.id}
                                        progress={img.progress}
                                        name={img.file.name}
                                        onRemove={() =>
                                            removeProgressImage(img.id, true)
                                        }
                                    />
                                );
                            })}

                            <div className="flex flex-wrap gap-4 mt-4">
                                {imagesPreviews.map((i, index: number) => {
                                    return (
                                        <GalleryImage
                                            key={i.src}
                                            src={i.src}
                                            onImageClick={() => {}}
                                            // onImageAdd={(e: any) => handleImageAdd(index)}
                                            onImageRemove={() => {
                                                handleImageRemove(index);
                                            }}
                                        />
                                    );
                                })}
                                {tempImagesState.map((i) => {
                                    return (
                                        <GalleryImage
                                            key={i.id}
                                            src={i.file}
                                            onImageClick={() => {}}
                                            // onImageAdd={(e: any) => handleImageAdd(index)}
                                            onImageRemove={() => {
                                                removeTempImage(i.id);
                                            }}
                                        />
                                    );
                                })}
                            </div>

                            <div className="text-sm font-semibold mt-8 mb-4">
                                Files
                            </div>
                            <div>
                                <DropFileInput
                                    iconSrc={fileImage}
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={onSelectFiles}
                                />
                            </div>
                            {filesProgressState.map((file) => {
                                return (
                                    <ProgressBar
                                        key={file.id}
                                        progress={file.progress}
                                        name={file.file.name}
                                        onRemove={() =>
                                            removeProgressFile(file.id, true)
                                        }
                                    />
                                );
                            })}
                            <div className="flex flex-wrap gap-4 mt-4">
                                {tempFilesState.map((file) => {
                                    return (
                                        <FilePreview
                                            key={file.id}
                                            onRemove={() => {
                                                removeTempFile(file.id);
                                            }}
                                            name={file.file.name}
                                        />
                                    );
                                })}
                                {filesPreviews.map((file, index) => {
                                    return (
                                        <FilePreview
                                            key={index}
                                            onRemove={() => {
                                                handleFileRemove(index);
                                            }}
                                            name={file.name}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {currentHotel && !editingHotel && (
                        <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12">
                            <Button
                                onClick={() => navigateBack()}
                                outlined
                                type="button"
                                label="Cancel"
                            />
                            <Button type="submit" label="Save" />
                        </div>
                    )}
                    {editingHotel && (
                        <div className="absolute bottom-5 right-5">
                            <Button
                                onClick={() => {
                                    setEditingHotel(false);
                                }}
                                outlined
                                type="button"
                                label="back"
                            />
                        </div>
                    )}
                </form>
            </>
        )
    );
}
