import TextareaWithValid from "../../../../../components/TextArea"

export const RequirementFlightForm = ({register, show}: any) => {

    if (!show) return null;
    return <div className="col-span-6 flex flex-col">
        {/* Arrival Information */}
        <div className="mb-4">
            <h3 className="text-gray-700 font-semibold text-left text-base">Arrival information</h3>
            <TextareaWithValid
                register={register}
                label="Notes for participant"
                id="extraNotes1"
                name="extraNotes1"
                placeholder="(e.g. please share your arrival flight info or if not arriving by flight tell us when and how you are arriving.)"
            />
        </div>

        {/* Departure Information */}
        <div className="mb-4">
            <h3 className="text-gray-700 font-semibold text-left text-base">Departure information</h3>
            <TextareaWithValid
                register={register}
                label="Notes for participant"
                id="extraNotes2"
                name="extraNotes2"
                placeholder="(e.g. please share your departure flight info or if not departing by flight tell us when and how you are departing.)"
            />
        </div>
</div>
}
