import moment from "moment";
import DatePickerMaterial from "../../../../../../components/DatePickerMaterial";
import Input from "../../../../../../components/Input";
import TimePickerMaterial from "../../../../../../components/TimePickerMaterial";
import TextAreaInput from "../../../../../../components/TextArea/TextArea";

export const FlightForm = ({ register, control, setValue, type='arrival' }: any) => {

    return <>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">Airline</label>
            <Input
              type="text"
              register={register}
              name={`${type}_airline`}
              className="w-full px-4 py-2 border rounded-md text-gray-600"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">Flight #</label>
            <Input
              type="text"
              register={register}
              name={`${type}_flightNumber`}
              className="w-full px-4 py-2 border rounded-md text-gray-600"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">
              Departure airport
            </label>
            <Input
              type="text"
              register={register}
              name={`${type}_departureAirport`}
              className="w-full px-4 border rounded-md text-gray-600"
            />
          </div>
          <div>
            <DatePickerMaterial
                name={`${type}_departureDate`}
                id="departureDate"
                label="Departure date"
                register={register}
                control={control}
                onChange={(value: any) => setValue && setValue(`${type}_departureDate`, value.format('YYYY-MM-DD'))}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">
              Departure time
            </label>
            <TimePickerMaterial
                control={control}
                defaultValue={
                    moment(
                        "10:00",
                        "HH:mm"
                    ) as any
                }
                id="departureTime"
                name={`${type}_departureTime`}
                onChange={(value: any) => setValue && setValue(`${type}_departureTime`, value.format('HH:mm'))}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">
              Arrival airport
            </label>
            <Input
              type="text"
              register={register}
              name={`${type}_arrivalAirport`}
              className="w-full px-4 border rounded-md text-gray-600"
            />
          </div>
          <div>
            <DatePickerMaterial
                name={`${type}_arrivalDate`}
                id="arrivalDate"
                label="Arrival date"
                register={register}
                control={control}
                onChange={(value: any) => setValue && setValue(`${type}_arrivalDate`, value.format('YYYY-MM-DD'))}
            />
          </div>
        
        </div>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1 text-neutral-600">Arrival time</label>
            <TimePickerMaterial
                control={control}
                defaultValue={
                    moment(
                        "10:00",
                        "HH:mm"
                    ) as any
                }
                id="arrivalTime"
                name={`${type}_arrivalTime`}
                onChange={(value: any) => setValue && setValue(`${type}_arrivalTime`, value.format('HH:mm'))}
            />
          </div>
        </div>

        {/* Comments Section */}
        <div className="mb-6">
          <TextAreaInput
              register={register}
              label="Add comments (optional)"
              id="reply"
              name={`${type}_comments`}
              onChange={(e: any) => setValue && setValue(`${type}_comments`, e.target.value)}
          />
        </div>
        </>
};