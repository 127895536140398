import moment from "moment";
import { useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import Icon from "../../../../../assets/svg/Icon";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import FlightRequirementDetails from "./response/FlightRequirementDetails";

interface RequirementFileProps {
    file: any;
    requirementId: number;
    tripId: number;
    onActionTaken: any;
    type: string;
}

export default function RequirementFile(props: RequirementFileProps) {
    const { file, type } = props;
    const [expanded, setExpanded] = useState(false);
    const [ref, bounds] = useMeasure();

    const files = useMemo(() => {
        return Object.keys(file.files).map((key) => file.files[key]);
    }, [file]);

    return (
        <div className="flex flex-col my-2 p-4 bg-neutral-50 rounded-xl">
            <div className="flex items-center">
                <div>
                    <Icon
                        icon={!expanded ? "ChevronUpIcon" : "ChevronDownIcon"}
                        className={`w-7 mr-2 text-neutral-400 cursor-pointer`}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    />
                </div>
                <div className="text-sm text-neutral-400 flex-grow">
                    {moment(file.createdAt).format("DD MMM, YYYY")} 123
                </div>
                <StatusBadgeComponent outlined status={file.status} />
            </div>
            <div
                className={`transition[height] ease-in-out duration-500 overflow-hidden`}
                style={{ height: expanded ? bounds.height : "0px" }}
            >
                <div className=" pt-4 flex flex-col" ref={ref}>
                    {type === 'arrival_departure_info' && <FlightRequirementDetails data={JSON.parse(file.description)} />}
                    {type !== 'arrival_departure_info' && (
                        <Paragraph className="text-left text-sm text-neutral-800 pb-2">
                            {file.description}
                        </Paragraph>
                    )}
                    <div className="flex flex-wrap gap-4">
                        {files?.map((f: any, index: number) => (
                            <div>
                                <a
                                    key={index}
                                    href={f.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    download={true}
                                    className="hover:!text-neutral-900 text-neutral-400"
                                >
                                    <div className="flex items-center">
                                        <div className="mr-4">
                                            <Icon
                                                icon="FileOutlineIcon"
                                                className="w-7"
                                            />
                                        </div>
                                        <div className="text-basefont-semibold">
                                            {f.originalName.length > 10
                                                ? "..." +
                                                  f.originalName.substring(
                                                      f.originalName.length -
                                                          10,
                                                      f.originalName.length
                                                  )
                                                : f.originalName}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>

                    <Paragraph className="pt-4 px-1 text-sm text-neutral-400 font-semibold">
                        {file?.reviewComment}
                    </Paragraph>
                </div>
            </div>
        </div>
    );
}
