import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    getTripRequirementFiles,
    selectedTrip,
    selectedTripRequirement,
    selectTripRequirementFiles,
} from "../../../tripSlice";
import {
    ButtonSize,
    GeneralStatus,
    TripRequirementFileStatus,
} from "../../../../../constants";
import moment from "moment";
import ButtonSquared from "../../../../../components/ButtonSquare";
import RequirementFile from "./RequirementFile";
import {
    getLastFileUploaded,
    getStatusFromFiles,
} from "../../../../../helpers";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import TripRequirementFileForm from "./RequirementFileForm";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import { DescriptionText } from "../../../../../components/DescriptionText/DescriptionText";
import { InfoBanner } from "../../../../../components/InfoBAnner/InfoBanner";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";

export default function TripRequirementAdvisor() {
    let { tripId, requirementId } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const requirementFiles = useAppSelector(selectTripRequirementFiles)?.files;
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [uploadModal, setUploadModal] = useState(false);

    const requirementFilesSorted = useMemo(() => {
        if (requirementFiles?.length > 0) {
            const filesSorted = requirementFiles
                .slice()
                .sort((a: any, b: any) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    );
                });
            return filesSorted;
        }
        return [];
    }, [requirementFiles]);

    useEffect(() => {
        if (trip?.id && requirementId)
            dispatch(
                getTripRequirementFiles(Number(trip?.id), Number(requirementId))
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id, requirementId]);

    const onEdit = () => {
        navigate("edit");
    };
    const onUpdate = () => {};
    const canEdit = trip?.canEdit;
    const fileStatus = getStatusFromFiles(requirementFiles);
    const lastFile = getLastFileUploaded(requirementFiles);

    const filesPreviews = useMemo(() => {
        return Object.values(requirement?.requirementIncludedFiles || {});
    }, [requirement?.requirementIncludedFiles]);

    const showAddFileForm = [GeneralStatus.NOT_SUBMITTED].includes(fileStatus);

    const buttonLabel = useMemo(() => {
        switch (requirement?.type) {
            case "arrival_departure_info":
                return "Add travel details";
            case "passport":
                return "Upload passport";
            default:
                return "Complete requirement";
        }
    }, [requirement?.type]);

    const reSubmitButtonLabel = useMemo(() => {
        switch (requirement?.type) {
            case "arrival_departure_info":
                return "Re-submit travel details";
            case "passport":
                return "Re-submit passport";
            default:
                return "Re-submit requirement";
        }
    }, [requirement?.type]);

    const customBadgeText = useMemo(() => {
        if (fileStatus === "PENDING") {
            return "Awaiting Approval";
        }
        if (fileStatus === "REJECTED") {
            return "Needs Resubmission";
        }
        return undefined;
    }, [fileStatus]);

    return (
        <>
            <Modal
                open={uploadModal}
                size={Modal_Size.lg}
                onClose={() => {
                    setUploadModal(false);
                }}
            >
                <TripRequirementFileForm
                    onSubmit={() => navigate(`/trips/${tripId}/requirements`)}
                    onCancel={() => setUploadModal(false)}
                />
            </Modal>
            {lastFile &&
                lastFile?.status === TripRequirementFileStatus.REJECTED && (
                    <InfoBanner
                        title={"Resubmission Requested"}
                        descriptionTitle={
                            lastFile?.resubmissionComment?.length ||
                            lastFile?.reviewComment?.length
                                ? "Your host has asked you to resubmit your requirement details for this reason:"
                                : undefined
                        }
                        description={
                            lastFile?.resubmissionComment ||
                            lastFile?.reviewComment
                        }
                        actionLabel={reSubmitButtonLabel}
                        action={() => {
                            setUploadModal(true);
                        }}
                    />
                )}
            {!!requirement?.name && (
                <div className="flex flex-col gap-2">
                    <div className="flex flex-wrap">
                        <div className="flex flex-col flex-grow">
                            <div className="text-left font-semibold text-lg">
                                {requirement?.name}
                            </div>
                        </div>
                        <div>
                            {canEdit && (
                                <ButtonSquared
                                    size={ButtonSize.SMALL}
                                    outlined={true}
                                    onClick={onEdit}
                                    label="Edit"
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-baseline">
                        <div className="text-sm text-neutral-600 mr-2">
                            Current Status:
                        </div>
                        <StatusBadgeComponent
                            status={fileStatus}
                            customText={customBadgeText}
                        />
                    </div>
                    <div className="flex flex-row flex-wrap items-baseline">
                        <div className="text-sm text-neutral-600 mr-2">
                            Deadline:
                        </div>
                        <div className="text-sm">
                            {moment(requirement?.deadline).format(
                                "MMM DD, YYYY"
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-baseline">
                        <div className="text-sm text-neutral-600 mr-2">
                            Description:
                        </div>
                        <Paragraph className="text-left text-sm">
                            <DescriptionText text={requirement?.description} />
                        </Paragraph>
                    </div>
                </div>
            )}

            {!!filesPreviews?.length && (
                <div className="flex flex-row w-full flex-wrap gap-4 my-4">
                    {filesPreviews.map((file: any) => {
                        return (
                            <a
                                className="block max-w-fit cursor-pointer"
                                key={`file_${file?.originalName}`}
                                href={file.url}
                                target="_blank"
                                rel="noreferrer"
                                download={true}
                            >
                                <FilePreview
                                    viewOnly
                                    name={file?.originalName}
                                />
                            </a>
                        );
                    })}
                </div>
            )}
            {requirementFiles?.length > 0 && (
                <div className="py-4">
                    <div className="text-lg text-neutral-900 font-semibold text-left">
                        Submission History
                    </div>
                    {lastFile?.status ===
                        TripRequirementFileStatus.APPROVED && (
                        <div className="flex flex-col gap-2 my-4">
                            <div className="text-left text-sm text-neutral-400">
                                <ButtonSquared
                                    type="button"
                                    label={reSubmitButtonLabel}
                                    size={ButtonSize.FIT}
                                    onClick={() => {
                                        setUploadModal(true);
                                    }}
                                />
                            </div>
                            <div className="text-left text-sm text-neutral-400">
                                <span className="font-semibold">
                                    *Important:
                                </span>{" "}
                                Re-submitting will automatically change the
                                status of the previous requirement to "Rejected"
                            </div>
                        </div>
                    )}

                    {requirementFilesSorted?.map((file: any) => (
                        <RequirementFile
                            key={file.id}
                            tripId={requirement.tripId}
                            requirementId={requirement.id}
                            type={requirement.type}
                            file={file}
                            onActionTaken={onUpdate}
                            isAdmin={canEdit}
                        />
                    ))}
                </div>
            )}
            {!!requirement?.name && showAddFileForm && (
                <ButtonSquared
                    className="px-4 mt-6"
                    type="button"
                    label={buttonLabel}
                    size={ButtonSize.FIT}
                    onClick={() => {
                        setUploadModal(true);
                    }}
                />
            )}
        </>
    );
}
