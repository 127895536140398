import { FC, InputHTMLAttributes, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

import Modal from "../Modal";
import Input from "../Input/Input";
import Icon from "../../assets/svg/Icon";
import colors from "tailwindcss/colors";
import CruiseLinesAutocomplete from "./CruiseLinesAutocomplete";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    className?: string;
    control?: any;
    onNew?: any;
    markRequired?: boolean;
    currentValue?: any;
}

const CruiseLinesAutocompleteWithValid: FC<InputProps> = ({
    name,
    error,
    label,
    control,
    onChange,
    onNew,
    markRequired,
    currentValue,
    ...rest
}) => {
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
    const handleChange = (other: any, handleOnChangeValidator: Function) => {
        if (onChange) onChange(other);
        if (handleOnChangeValidator) handleOnChangeValidator(other);
    };

    const textInputValue = useMemo(() => {
        return currentValue?.name;
    }, [currentValue]);
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <>
                    <Modal
                        closeOnBackClick
                        open={showSearchModal}
                        onClose={() => {
                            setShowSearchModal(false);
                        }}
                    >
                        <CruiseLinesAutocomplete
                            name={name}
                            label={label}
                            onChange={(a: any) => {
                                handleChange(a, onChange);
                                setShowSearchModal(false);
                            }}
                            onBlur={onBlur}
                            ref={ref}
                            {...rest}
                        />
                        <div className="mt-4 text-sm">
                            Can't find the cruise line? Would you like to{" "}
                            <span
                                onClick={() => {
                                    onNew(true);
                                    setShowSearchModal(false);
                                }}
                                className="mx-1 cursor-pointer font-semibold text-orange-600"
                            >
                                add
                            </span>
                            one?
                        </div>
                    </Modal>
                    <div className="flex flex-row items-center">
                        <Input
                            readOnly
                            onClick={() => setShowSearchModal(true)}
                            value={textInputValue || ""}
                            markRequired={markRequired}
                            placeholder={"Select"}
                            className={"cursor-pointer bg-transparent"}
                            label="Cruise Line"
                            type="text"
                            error={error}
                        />
                        {!!textInputValue && (
                            <div className="">
                                <Icon
                                    icon={"CloseIcon"}
                                    color={colors.gray[400]}
                                    width={20}
                                    className="text-gray-400 cursor-pointer"
                                    onClick={() => {
                                        handleChange(undefined, onChange);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        />
    );
};

export default CruiseLinesAutocompleteWithValid;
