import React, { useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../assets/svg/Icon";
import useDebounce from "../../hooks/useDebounce";
import Input from "../Input/Input";
import Loader from "../Loader";

function useOnClickOutside(ref: any, onClickOutside: any, onClickInside?: any) {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                onClickInside && onClickInside(event);
                return;
            }
            onClickOutside(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, onClickOutside, onClickInside]);
}

const AutocompleteInput = React.forwardRef((props: any, ref: any) => {
    const [searchText, setSearchText] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = useState<any>();
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        autoComplete,
        changeTextOnUpdate,
        error,
        onChange,
        onSearch,
        onTextChange,
        searchResults,
        searchResultLabel,
        searchResultSubheader,
        loading,
        className,
        showOptions = true,
        ...others
    } = props;

    const wrapperRef = useRef(null);
    useOnClickOutside(
        wrapperRef,
        () => {
            if (showNoResultsLabel) {
                setValue("");
            }

            setShowResults(false);
            setActiveIndex(0);
        },
        () => {
            if (!showResults) {
                setShowResults(searchResults?.length > 0);
                setActiveIndex(0);
            }
        }
    );
    const debouncedSearchText = useDebounce(searchText, 500);
    useEffect(() => {
        if (debouncedSearchText) onSearch(debouncedSearchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);

    useEffect(() => {
        const newValue = defaultValue;
        setValue(newValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleChange = (e: any) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        setValue(newValue);
        if (newValue.length <= 3) {
            setShowResults(false);
            setActiveIndex(0);
        }
        setShowResults(true);
        setActiveIndex(0);
        onTextChange && onTextChange(e.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            handleOptionSelect(activeIndex);
        }

        if (event.key === "Escape") {
            event.preventDefault();
            event.stopPropagation();
            setShowResults(false);
            setActiveIndex(0);
        }

        switch (event.keyCode) {
            case 38: {
                event.preventDefault();
                activePrevious();
                break;
            }
            case 40: {
                event.preventDefault();
                activeNext();
                break;
            }

            default: {
                break;
            }
        }
    };

    const activeNext = () => {
        if (searchResults && activeIndex < searchResults?.length - 1) {
            const next = activeIndex + 1;
            setActiveIndex(next);
        }
    };

    const activePrevious = () => {
        if (activeIndex > 0) {
            const next = activeIndex - 1;
            setActiveIndex(next);
        }
    };

    const handleOptionSelect = async (activeIndex: number) => {
        setShowResults(false);
        if (!searchResults?.length) return;
        const selectedResult = searchResults[activeIndex];
        if (changeTextOnUpdate) {
            setSearchText(selectedResult.name);
            setValue(selectedResult.name);
        }

        if (onChange) onChange(selectedResult);
    };

    const showNoResultsLabel = useMemo(() => {
        return (
            showResults && !loading && searchText && searchResults?.length === 0
        );
    }, [showResults, loading, searchText, searchResults?.length]);

    const showResultsList = useMemo(() => {
        return showResults && searchResults?.length > 0;
    }, [showResults, searchResults?.length]);
    return (
        <div ref={wrapperRef} className="w-full">
            <div className="relative flex flex-row items-center">
                <Input
                    className={`${className || ""} ${loading ? "pr-7" : ""}`}
                    value={value || ""}
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    label={label}
                    ref={ref}
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    error={error}
                    autoComplete={autoComplete}
                    {...others}
                />
                {!!loading && (
                    <div className="-ml-7">
                        <Loader />
                    </div>
                )}
            </div>

            {showResultsList && showOptions && (
                <div className="relative w-full child:w-full">
                    <ul className="shadow absolute z-10 bg-white text-sm text-neutral-800 text-left rounded-md border">
                        {searchResults.slice(0,8).map((r: any, index: number) => (
                            <li
                                onClick={() => {
                                    handleOptionSelect(index);
                                }}
                                tabIndex={0}
                                key={`${r.name} - ${r.id}`}
                                className={`px-9 cursor-pointer py-4 hover:bg-gray-50 border-b border-b-gray-200`}
                            >
                                <div className="flex gap-2 items-center">
                                    <Icon
                                        icon={"MarkerIcon"}
                                        className="w-6 h-6"
                                    />
                                    <div>
                                        <div className="text-sm font-medium text-gray-600">
                                            {r[searchResultLabel]}
                                        </div>
                                        <div className="text-xs font-medium text-gray-400 pt-1">
                                            {r[searchResultSubheader]}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {showNoResultsLabel && (
                <div className="mt-1 text-base text-red-500">
                    No results found
                </div>
            )}
        </div>
    );
});
export default AutocompleteInput;
