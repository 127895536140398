import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import InputErrorLabel from "../InputErrorLabel";
import TimePicker from "./TimePickerMaterial";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    className?: string;
    minDate?: Date;
    control?: any;
    markRequired?: boolean;
}

const TimePickerWithValid: FC<InputProps> = ({
    name,
    error,
    label,
    control,
    onChange,
    minDate,
    ...rest
}) => {
    const handleChange = (data: any, handleOnChangeValidator: Function) => {
        if (onChange) onChange(data);
        if (handleOnChangeValidator) handleOnChangeValidator(data);
    };
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, ref } }: any) => (
                <>
                    <TimePicker
                        name={name}
                        label={label}
                        minDate={minDate}
                        onChange={(a: any) => {
                            handleChange(a, onChange);
                        }}
                        onBlur={onBlur}
                        ref={ref}
                        {...rest}
                    />
                    <InputErrorLabel error={error} />
                </>
            )}
        />
    );
};

export default TimePickerWithValid;
