export default ({
    width = 17,
    height = 29,
    color = "white",
    ...otherProps
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 29"
        width={width}
        height={height}
        {...otherProps}
    >
        <path
            //fill={color}
            d="M0 11.2778V17.7222H4.63636V29H10.8182V17.7222H15.4545L17 11.2778H10.8182V8.05556C10.8182 7.62826 10.981 7.21847 11.2708 6.91633C11.5607 6.61419 11.9538 6.44444 12.3636 6.44444H17V0H12.3636C10.3142 0 8.34877 0.848708 6.89963 2.35942C5.45048 3.87013 4.63636 5.91909 4.63636 8.05556V11.2778H0Z"
        />
    </svg>
);
