import React, { useEffect, useMemo, useState } from "react";
import Avatar from "../../../components/Avatar";
import DotsMenu from "../../../components/DotsMenu";
import { useChatForm } from "../hooks/useChat.hook";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import ReportModal from "./modals/ReportModal";
import { useSelector } from "react-redux";

enum ActionChatType {
    BLOCK = "block",
    REPORT = "report",
    UNBLOCK = "unblock",
}

const ChatHeader: React.FC<{
    title: string;
    avatarUrl: string;
    onTitleClick: () => void;
    isGroup: boolean;
}> = ({ title, avatarUrl, onTitleClick, isGroup }) => {
    const currentUser = useSelector((state: any) => state?.user?.profile);
    const { selectedUser, selectedChat, onBlockUser, onUnBlockUser, onReportUser, selectedTripId, onSelectedChat } = useChatForm();
    const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [showBackTrip, setShowBackTrip] = useState(false);

    const menuOptions = useMemo(
        () => {
            const isBlockedByCurrentUser = selectedChat?.isBlockedBy === currentUser.uid && !selectedChat?.isGroup;

            if (isBlockedByCurrentUser) {
                return [
                    {
                        label: "UnBlock",
                        key: ActionChatType.UNBLOCK,
                        icon: "BlockIcon",
                    }
                ]

            }

            if (selectedChat?.isBlockedBy) {
                return []
            }
            return [
                {
                    label: "Block",
                    key: ActionChatType.BLOCK,
                    icon: "BlockIcon",
                },
                {
                    label: "Report",
                    key: ActionChatType.REPORT,
                    icon: "ReportIcon",
                },
            ]
        },
        [selectedChat, currentUser]
    );
    

    // Report
    const handleReportClick = () => {
        setIsReportModalOpen(true);
    };
    const handleReportConfirm = (reason: string) => {
        if (!selectedUser) return;
        if (reason === "") {
            alert("Please enter a reason");
            return;
        }
        onReportUser(reason);
        setIsReportModalOpen(false);
    };
    const handleReportCancel = () => {
        setIsReportModalOpen(false);
    };

    // Block
    const handleBlockClick = () => {
        setIsBlockModalOpen(true);
    };
    const handleBlockConfirm = () => {
        if (!selectedUser) return;
        onBlockUser();
        setIsBlockModalOpen(false);
    };
    const handleBlockCancel = () => {
        setIsBlockModalOpen(false);
    };

    const handleClickMenu = (key: ActionChatType) => {
        switch (key) {
            case ActionChatType.BLOCK:
                handleBlockClick();
                break;
            case ActionChatType.REPORT:
                handleReportClick();
                break;
            case ActionChatType.UNBLOCK:
                onUnBlockUser();
                break;
            default:
                break
        }
    };

    useEffect(() => {
        if (selectedTripId && !isGroup) {
            setShowBackTrip(true);
        } else {
            setShowBackTrip(false);
        }
    }, [selectedTripId, isGroup]);

    const handleTripChat = () => {
        onSelectedChat(`trip-${selectedTripId}`);
    };
  

    return (
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
            {showBackTrip && <p
                className={`text-small font-semibold text-primary_orange-900 cursor-pointer`}
                style={{
                    marginLeft: "-35px",
                    marginTop: "-70px",
                }}
                onClick={handleTripChat}
            >
                &lt; Back to trip chat
            </p>}
            <div className="flex-grow" />
            <div className="flex-grow flex justify-center items-center flex-col">
                <Avatar src={avatarUrl} className="w-8 h-8 mb-1" />
                <h3
                    className={`text-lg font-semibold ${isGroup && 'cursor-pointer'}`}
                    onClick={onTitleClick}
                >
                    {title} &gt;
                </h3>
            </div>
            <div className="flex items-center justify-end flex-grow">
                {!selectedChat?.isGroup && !!menuOptions.length && <DotsMenu
                    options={menuOptions}
                    handleClickMenu={handleClickMenu}
                    className="text-xl"
                />}
            </div>
            <ModalConfirmation
                open={isBlockModalOpen}
                title={`Are you sure you want to block ${selectedUser?.firstName} ${selectedUser?.lastName}?`}
                description={`Blocking ${selectedUser?.firstName} ${selectedUser?.lastName} will prevent them from messaging you.`}
                okButtonText="Confirm"
                cancelButtonText="Cancel"
                handleCancel={handleBlockCancel}
                handleSubmit={handleBlockConfirm}
                className="min-h-[250px]"
            />
            <ReportModal
                open={isReportModalOpen}
                handleCancel={handleReportCancel}
                handleSubmit={handleReportConfirm}
            />
        </div>
    );
};

export default ChatHeader;
