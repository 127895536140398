import { FC, useEffect, useState } from "react";
import TextAreaInput from "../../../../../../../../components/TextArea";
import InsightCommentBlock from "../InsightCommentBlock";
import InsightGalleryContainer from "../InsightGalleryContainer";
import GalleryImage from "../../../../../../../../components/ImagesGallery/Image";
import { InsightMedia } from "../../../../../components/InsightMedia";


interface InsightCommentsProps {
    showComentsForm: boolean;
    hotelInsight: any;
    commentsKey: string;
    imagesKey: string;
    register: any;
    errors: any;
    setValue: (name: string, value: any) => void;
    customComponent?: FC<{
        register: any;
        errors: any;
        setValue: (name: string, value: any) => void;
        showComentsForm: boolean;
    }>;
    handleUploadFileToInsightSection: (e: any) => void;
    toggleShowCommentsForm: () => void;
}

const InsightComments = ({
    showComentsForm,
    hotelInsight,
    commentsKey,
    imagesKey,
    register,
    errors,
    setValue,
    customComponent: CustomComponent,
    handleUploadFileToInsightSection,
 }: InsightCommentsProps) => {
    const [videos, setVideos] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [mediaFiles, setMediaFiles] = useState<any[]>([]);


    useEffect(() => {
        const mediaFiles = hotelInsight?.[imagesKey] ? Object.values(hotelInsight?.[imagesKey]) : [];
        setMediaFiles(mediaFiles);
        const images: any = [];
        const videos: any = [];
        mediaFiles.forEach((media: any) => {
            if (media.isVideo || media.type === 'video') {
                videos.push(media);
            } else {
                images.push(media);
            }
        });
        setImages(images);
        setVideos(videos);

    }, [hotelInsight]);

    const showForm = showComentsForm || (!hotelInsight?.[commentsKey] && !images.length)

    const renderCustomComponent = () =>
        CustomComponent && (
            <CustomComponent
                showComentsForm={showForm}
                register={register}
                errors={errors}
                setValue={setValue}
            />
        );

    if (showForm) {
        return (
            <div className="flex flex-col w-full gap-10">
                <InsightMedia
                    handleOnChange={handleUploadFileToInsightSection}
                    images={images|| []}
                    videos={videos || []}
                />
                <div>
                    <TextAreaInput
                        register={register}
                        label={`General comments`}
                        type="text"
                        markRequired
                        defaultValue={hotelInsight?.[commentsKey]}
                        name={commentsKey}
                        error={errors[commentsKey]?.message}
                    />
                </div>
                {renderCustomComponent()}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-5">
            {!!mediaFiles?.length && (
                <InsightGalleryContainer>
                    {mediaFiles.map(
                        (
                            image: any,
                            index: number
                        ) => {
                            const path = image.isVideo ? image.thumb : image.url;
                            return <div className="w-fit" key={image.url}>
                                <GalleryImage
                                    viewOnly
                                    src={path}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    description={image?.description}
                                    isVideo={image.isVideo}
                                    srcVideo={image.url}
                                />
                            </div>
                        }
                    )}
                </InsightGalleryContainer>
            )}
            {hotelInsight?.[commentsKey] && (
                <InsightCommentBlock title={`General comments`}>
                    {hotelInsight?.[commentsKey]}
                </InsightCommentBlock>
            )}
            {renderCustomComponent()}
        </div>
    );
};

export default InsightComments;
