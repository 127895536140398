import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonGroup from "../../../components/ButtonGroup";
import { PAGE_SECTIONS, periodOptions, TIME_PERIOD } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import { getOverview, selectOverview } from "../adminSlice";
import OverviewElement from "./components/overviewElement";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import SelectInput from "../../../components/InputSelect/SelectInput";
import { UserTypeVariant } from "../../../components/UserType/UserType";

const userTypeOptions = [
    { label: "Advisors", value : "ADVISOR" },
    { label: "Suppliers", value: "SUPPLIER" },
    { label: "Not Classified", value: "" },
];

export default function OverviewAdminHome(props: any) {
    const dispatch = useAppDispatch();
    const [period, setPeriod] = useState(TIME_PERIOD.DAY);
    const overview = useAppSelector(selectOverview);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [userType, setUserType] = useState<UserTypeVariant | string>(userTypeOptions[2].value);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_OVERVIEW })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, startDate, endDate,  userType]);

    const selectedUserTypeLabel = userTypeOptions.find(option => option.value === userType)?.label || "Not Classified";

    const getData = () => {
        dispatch(getOverview(period, startDate, endDate,  userType));
    };

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
    };


    const handleUserTypeChange = (value: UserTypeVariant | "Not Classified") => {
        const formattedValue = value === "Not Classified" ? "" : value;
        setUserType(formattedValue);
    };

    return (
        <div className="mb-12">
            <div className="text-neutral-900 text-lg text-left font-semibold mb-6">
                Performance
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 lg:gap-10 ">
                <div className="col-span-1 lg:col-span-5">
                    <ButtonGroup
                        selectedOption={period}
                        options={periodOptions}
                        onSelectOption={setPeriod}
                    />
                </div>

                <div className="col-span-1 lg:col-span-2">
                    <div className="flex-grow">
                        <DatePickerMaterial
                            name="startDate"
                            value={startDate}
                            id="startDate"
                            label="From date"
                            onChange={handleStartDateChange}
                        />
                    </div>
                </div>

                <div className="col-span-1 lg:col-span-2">
                    <div className="flex-grow">
                        <DatePickerMaterial
                            name="endDate"
                            value={endDate}
                            id="endDate"
                            label="To date"
                            onChange={handleEndDateChange}
                        />
                    </div>
                </div>

                <div className="col-span-1 lg:col-span-2">
                    <div className="flex-grow">
                        <SelectInput
                            onChange={handleUserTypeChange}
                            label="User Type"
                            options={userTypeOptions}
                            defaultValue={userTypeOptions[2].value}
                        />
                    </div>
                </div>
            </div>

            <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                Users
            </div>
            <div className="flex flex-row flex-wrap gap-12 text-left">
                <OverviewElement
                    title="Active Users"
                    label={selectedUserTypeLabel}
                    value={overview?.totalUsers}
                />
                <OverviewElement
                    title="Last Logins"
                    label={selectedUserTypeLabel}
                    value={overview?.logIns}
                    trend={overview?.logInsRate}
                    period={period}
                />
                <OverviewElement
                    title="Sign Ups"
                    label={selectedUserTypeLabel}
                    value={overview?.signUps}
                    trend={overview?.signUpsRate}
                    period={period}
                />
            </div>

            <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                Trips
            </div>
            <div className="flex flex-row flex-wrap gap-x-12 text-left">
                <OverviewElement
                    label="Total Trips"
                    value={overview?.totalTrips}
                    trend={overview?.totalTripsRate}
                    period={period}
                />
                <OverviewElement
                    label="Active Trips"
                    value={overview?.activeTrips}
                    trend={overview?.activeTripsRate}
                    period={period}
                />
            </div>
            <div className="flex flex-row flex-wrap  gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Insights
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalInsights}
                            trend={overview?.newInsightsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newInsights}
                        />
                    </div>
                </div>
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Reviews
                    </div>
                    <div className="flex flex-row flex-wrap gap-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalReviews}
                            trend={overview?.newReviewsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newReviews}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row flex-wrap  gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Hotels
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalHotels}
                            trend={overview?.newHotelsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newHotels}
                        />
                    </div>
                </div>
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Restaurants
                    </div>
                    <div className="flex flex-row flex-wrap gap-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalRestaurants}
                            trend={overview?.newRestaurantsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newRestaurants}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Others
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalOthers}
                            trend={overview?.newOthersRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newOthers}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
