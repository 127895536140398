import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Button from "../../../../../components/ButtonSquare";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import GoogleMap from "../../../../../components/Map";
import OthersAutocomplete from "../../../../../components/OthersAutocomplete";
import {
    addActivityToTrip,
    selectedTrip,
    uploadTempFile,
} from "../../../tripSlice";
import { ActivityType } from "../../../../../constants";
import ContactCard from "../ContactCard";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import ContactForm from "../ContactForm";
import useActivityContact from "../../../../../hooks/useActivityContact";
import moment from "moment";
import OtherForm from "../../../../others/OtherForm";
import DatePickerMaterial from "../../../../../components/DatePickerMaterial";
import accountImage from "../../../../../assets/images/accountAdd.png";
import axios from "axios";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import TextareaWithValid from "../../../../../components/TextArea";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import DropFileInput from "../../../../../components/DropFileInput/DropFileInput";
import GalleryImage from "../../../../../components/ImagesGallery/Image";
import fileImage from "../../../../../assets/images/files.png";
import DropImageFileInput from "../../../../../components/DropImageFileInput/DropImageFileInput";
import TimePickerMaterial from "../../../../../components/TimePickerMaterial";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import {
    selectedOther,
    setSelectedOther,
} from "../../../../others/othersSlice";
import Input from "../../../../../components/Input";
import InputSwitch from "../../../../../components/InputSwitch/InputSwitch";
import InputLabel from "../../../../../components/InputLabel";
import Icon from "../../../../../assets/svg/Icon";
import InputWithValid from "../../../../../components/Input";
import { schemaOtherActivity } from "./schemas";
import {
    parseMomentUtcToLocal,
    parsePhoneNumber,
    resizeImageFile,
} from "../../../../../helpers";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import PhoneInput from "../../../../../components/PhoneInput";
import { selectProfile } from "../../../../authentication/userSlice";

let imgProgressArray: any[] = [];
let fileProgressArray: any[] = [];
let imgTempfilesArray: any[] = [];
let fileTempfilesArray: any[] = [];
let fileCounter = 0;

export default function OtherActivityForm(props: any) {
    const [editingOther, setEditingOther] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [startDate, setStartDate] = useState<any>(new Date());
    const [showEndHourInput, setShowEndHourInput] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [addOtherModalOpen, setAddOtherModalOpen] = useState(false);
    const [imagesProgressState, setImagesProgressState] = useState<any[]>([]);
    const [filesProgressState, setFilesProgressState] = useState<any[]>([]);
    const [tempFilesState, setTempFilesState] = useState<any[]>([]);
    const [tempImagesState, setTempImagesState] = useState<any[]>([]);
    const [deletedImagesState, setDeletedImagesState] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [imagesPreviews, setImagesPreviews] = useState<Array<any>>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);
    const [mapMarker, setMapMarker] = useState<any>(null);
    const [addNew, setAddNew] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    const currentOther = useAppSelector(selectedOther);
    const profile = useAppSelector(selectProfile);
    const {
        contacts,
        contactsModalOpen,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactSubmit,
        handleContactToggle,
        selectedContact,
        setSelectedContact,
        setContacts,
    } = useActivityContact([]);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schemaOtherActivity),
    });
    const {
        activity,
        navigateBack,
        onSubmit,
        currentSection,
        isLibraryElement,
    } = props;
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue("contacts", contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    useEffect(() => {
        setValue("isLibraryElement", !!isLibraryElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLibraryElement]);

    useEffect(() => {
        return () => {
            imgProgressArray = [];
            fileProgressArray = [];
            imgTempfilesArray = [];
            fileTempfilesArray = [];
            fileCounter = 0;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activity?.otherActivity?.other?.id !== currentOther?.id) {
            handleSelectOther(currentOther);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOther?.id, currentOther?.name]);

    useEffect(() => {
        if (activity) {
            setIsUpdate(true);

            let serverOther = {
                ...activity?.otherActivity?.other,
            };
            dispatch(setSelectedOther({ selectedOther: serverOther }));
            const {
                startHour = "09:00",
                endHour = "10:00",
                description,
                title,
                contacts,
                images,
                files,
            } = activity;
            const startDateInit =
                activity.startDate || searchParams.get("date") || new Date();
            reset({
                startDate: moment.utc(startDateInit),
                startHour: moment(startHour, "HH:mm"),
                endHour: moment(endHour, "HH:mm"),
                description,
                website: activity?.website || serverOther?.website,
                phone: activity?.phone || serverOther?.phone,
                title,
                other: serverOther,
            });
            setStartDate(moment.utc(startDateInit));
            setShowEndHourInput(activity.startHour !== activity.endHour);
            setContacts(Object.values(contacts || {}));
            const currentImagesNormalized: Array<any> =
                Object.values(images || {}) || [];
            const currentFilesNormalized: Array<any> =
                Object.values(files || {}) || [];
            setImagesPreviews(
                currentImagesNormalized.map((i) => {
                    return {
                        name: i.originalName,
                        src: i.url,
                        width: i.width,
                        height: i.height,
                        isCurrent: true,
                    };
                })
            );
            setFilesPreviews(
                currentFilesNormalized.map((i) => {
                    return {
                        src: i.url,
                        name: i.originalName,
                        isCurrent: true,
                    };
                })
            );

            if (serverOther?.lat && serverOther?.lat !== "undefined") {
                const marker = {
                    position: {
                        lat: serverOther.lat,
                        lng: serverOther.lng,
                    },
                    title: serverOther?.name,
                };
                setMapMarker(marker);
            }
        } else {
            const startDateInit = searchParams.get("date")
                ? moment.utc(searchParams.get("date"), "MM-DD-YYYY").toDate()
                : new Date();
            setStartDate(startDateInit);
            reset({
                startDate: startDateInit,
                endHour: moment("09:00", "HH:mm"),
                startHour: moment("09:00", "HH:mm"),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity?.id]);

    const handleSubmitForm = async (data: any) => {
        if (!isUpdate) await handleAddForm(data);
        else await handleUpdateForm(data);
    };

    const handleAddForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity = {
            type: ActivityType.OTHER,
            otherId: data.other.id,
            startDate: moment(startDate).toDate(),
            endDate: moment(startDate).toDate(),
            startHour: startHour,
            endHour: showEndHourInput ? endHour : startHour,
            description: data.description,
            title: data.title,
            contacts: data.contacts,
            images: tempImagesState.map((img) => img.path),
            files: tempFilesState.map((file) => file.path),
            website: data.website,
            phone: data.phone,
        };
        return await dispatch(addActivityToTrip(Number(trip.id), { activity }));
    };
    const handleUpdateForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity: any = {
            type: ActivityType.OTHER,
            otherId: currentOther.id,
            startDate: moment(data.startDate).toDate(),
            endDate: moment(data.startDate).toDate(),
            startHour: startHour,
            endHour: showEndHourInput ? endHour : startHour,
            contacts: data.contacts,
            description: data.description,
            title: data.title,
            deletedImages: deletedImagesState.length
                ? deletedImagesState
                : undefined,
            deletedFiles: deletedFilesState.length
                ? deletedFilesState
                : undefined,
            images: tempImagesState.map((img) => img.path),
            files: tempFilesState.map((file) => file.path),
            website: data.website,
            phone: data.phone,
        };

        const addedFromLibrary = searchParams.get("fromLibrary") === "true";
        if (addedFromLibrary) {
            activity.libraryElementId = props.activity?.id;
            // Remove deleted images and files from the library element images and files
            activity.imagesFromLibraryElement = Object.values(
                props.activity?.images || {}
            ).filter(
                (img: any) => !deletedImagesState.includes(img.originalName)
            );
            activity.filesFromLibraryElement = Object.values(
                props.activity?.files || {}
            ).filter(
                (file: any) => !deletedFilesState.includes(file.originalName)
            );
            delete activity.deletedImages;
            delete activity.deletedFiles;
        }
        return await onSubmit(activity);
    };
    const handleChangeOther = (other: any) => {
        setValue("other", other);
        dispatch(setSelectedOther({ selectedOther: other }));

        setValue("title", other?.title);
        if (other?.lat && other?.lng) {
            const marker = {
                position: {
                    lat: other?.lat,
                    lng: other?.lng,
                },
                title: other?.name,
            };
            setMapMarker(marker);
        }
    };

    const onSelectImages = async (e: any) => {
        if (e.target.files) {
            fileCounter = fileCounter + 1;

            const filesArray: any[] = Array.from(e.target.files);
            for (const file of filesArray) {
                const imageResized = await resizeImageFile(file);
                const newtempImg = {
                    id: fileCounter,
                    file: imageResized,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                try {
                    imgProgressArray.push(newtempImg);
                    const response = await uploadTempFile(
                        imageResized,
                        newtempImg.id,
                        onImageProgress,
                        newtempImg.cancelToken
                    );
                    imgTempfilesArray.push({
                        file: URL.createObjectURL(imageResized),
                        path: response,
                        id: newtempImg.id,
                    });
                    removeProgressImage(newtempImg.id);
                    setTempImagesState([...imgTempfilesArray]);
                    fileCounter++;
                } catch (error) {}
            }

            setImagesProgressState(imgProgressArray);

            return;
        }
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            fileCounter = fileCounter + 1;
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: fileCounter,
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };

                fileProgressArray.push(newtempFile);
                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    fileTempfilesArray.push({
                        file: file,
                        path: response,
                        id: newtempFile.id,
                    });
                    removeProgressFile(newtempFile.id);
                    setTempFilesState([...fileTempfilesArray]);
                });
                fileCounter++;
            });

            setFilesProgressState(fileProgressArray);

            return;
        }
    };

    const handleImageRemove = (index: number) => {
        setDeletedImagesState([
            ...deletedImagesState,
            imagesPreviews[index].name,
        ]);
        const spliceArray = imagesPreviews;
        spliceArray.splice(index, 1);
        setImagesPreviews(spliceArray);
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([...deletedFilesState, filesPreviews[index].name]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    const onImageProgress = (id: number, progress: number) => {
        imgProgressArray = imgProgressArray.map((img) => {
            return {
                ...img,
                progress: id === img.id ? progress : img.progress,
            };
        });
        setImagesProgressState(imgProgressArray);
    };

    const onFileProgress = (id: number, progress: number) => {
        fileProgressArray = fileProgressArray.map((file) => {
            return {
                ...file,
                progress: id === file.id ? progress : file.progress,
            };
        });
        setFilesProgressState(fileProgressArray);
    };

    const removeTempImage = (id: number) => {
        let index = imgTempfilesArray.findIndex((img) => img.id === id);
        if (index > -1) {
            URL.revokeObjectURL(imgTempfilesArray[index].file);
            imgTempfilesArray.splice(index, 1);
            setTempImagesState([...imgTempfilesArray]);
        }
    };

    const removeTempFile = (id: number) => {
        let index = fileTempfilesArray.findIndex((img) => img.id === id);
        if (index > -1) {
            URL.revokeObjectURL(fileTempfilesArray[index].file);
            fileTempfilesArray.splice(index, 1);
            setTempFilesState([...fileTempfilesArray]);
        }
    };

    const removeProgressImage = (id: number, cancel = false) => {
        const index = imgProgressArray.findIndex((img) => img.id === id);
        if (index > -1) {
            cancel && imgProgressArray[0].cancelToken.cancel();
            imgProgressArray.splice(index, 1);
            setImagesProgressState([...imgProgressArray]);
        }
    };

    const removeProgressFile = (id: number, cancel = false) => {
        const index = fileProgressArray.findIndex((file) => file.id === id);
        if (index > -1) {
            cancel && fileProgressArray[0].cancelToken.cancel();
            fileProgressArray.splice(index, 1);
            setFilesProgressState([...fileProgressArray]);
        }
    };

    const hasAddress = useMemo(() => {
        return !!(currentOther?.lat && currentOther?.fullAddress);
    }, [currentOther?.lat, currentOther?.fullAddress]);

    const handleSwitchChange = (e: any) => {
        setShowEndHourInput(!!e.target.checked);
    };

    const handleOtherModalToggle = (open: boolean) => {
        setAddOtherModalOpen(open);
    };

    const handleSelectOther = (other: any) => {
        setValue("title", other?.name || "");
        setEditingOther(false);
    };
    return (
        (activity || !isUpdate) && (
            <>
                <Modal open={contactsModalOpen}>
                    <ContactForm
                        onClose={() => handleContactToggle(false)}
                        onSubmit={handleContactSubmit}
                        contact={contacts && contacts[selectedContact]}
                    />
                </Modal>
                <Modal
                    open={addOtherModalOpen}
                    size={Modal_Size.lg}
                    onClose={() => {
                        handleOtherModalToggle(false);
                    }}
                    hideCloseButton
                >
                    <OtherForm
                        addNew={addNew}
                        name={searchValue}
                        onCancel={() => {
                            handleOtherModalToggle(false);
                        }}
                        onChange={() => {
                            handleOtherModalToggle(false);
                        }}
                    />
                </Modal>

                <form
                    action="#"
                    method="POST"
                    className="text-left lg:px-6"
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    {editingOther && (
                        <div className="flex flex-col">
                            <div className="text-base font-semibold text-neutral-300">
                                Please select the activity you want to add
                            </div>{" "}
                        </div>
                    )}
                    {editingOther && (
                        <div className="mt-6 searchbox">
                            <OthersAutocomplete
                                className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                                control={control}
                                onChange={handleChangeOther}
                                onTextChange={setSearchValue}
                                handleSelect={handleSelectOther}
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search"
                            />
                        </div>
                    )}
                    {editingOther && (
                        <div className="ml-4 mt-4 flex text-sm">
                            We couldn't find the activity. Would you like to{" "}
                            <span
                                onClick={() => {
                                    handleOtherModalToggle(true);
                                    setAddNew(true);
                                }}
                                className="mx-2 cursor-pointer font-semibold text-orange-600"
                            >
                                add
                            </span>
                            one?
                        </div>
                    )}
                    {currentOther && !editingOther && (
                        <>
                            <div className="col-span-6 flex flex-row items-center gap-2">
                                <Input
                                    register={register}
                                    name="title"
                                    id="title"
                                    type="text"
                                    label="Title"
                                    defaultValue={activity?.title || ""}
                                    placeholder="Activity title"
                                />

                                <Icon
                                    onClick={() => {
                                        setEditingOther(true);
                                    }}
                                    title="Replace Element"
                                    icon={"FileReplaceIcon"}
                                    className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                                />

                                {currentOther.userId === profile.id && (
                                    <Icon
                                        onClick={() => {
                                            handleOtherModalToggle(true);
                                            setAddNew(false);
                                        }}
                                        title="Edit Element"
                                        icon={"PencilIcon"}
                                        className="text-gray-300 w-5 h-5 cursor-pointer hover:text-gray-600"
                                    />
                                )}
                            </div>

                            <div className="mt-6">
                                <div className="flex items-center justify-start gap-2 col-span-6"></div>
                                {hasAddress && (
                                    <div className="grid grid-cols-6 gap-x-12 mt-6 shadow-sm border border-gray-300 rounded-xl">
                                        <div className="col-span-6">
                                            {mapMarker && (
                                                <GoogleMap marker={mapMarker} />
                                            )}
                                        </div>

                                        <div className="mt-6 col-span-6 lg:col-span-3 p-4 ">
                                            <div className="text-sm font-medium text-neutral-600">
                                                Address
                                            </div>
                                            <div className="text-base">
                                                {currentOther.fullAddress}
                                            </div>
                                        </div>
                                        {!!currentOther?.zipCode?.length &&
                                            currentOther?.zipCode !==
                                                "undefined" && (
                                                <div className="mt-6 col-span-6 lg:col-span-3 p-4 ">
                                                    <div className="text-sm font-medium text-neutral-600">
                                                        Postal code
                                                    </div>
                                                    <div className="text-base">
                                                        {currentOther?.zipCode}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                            <div className="grid grid-cols-6 gap-x-12">
                                {hasAddress && (
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Address
                                        </div>
                                        <div className="text-base">
                                            {currentOther.fullAddress}
                                        </div>
                                    </div>
                                )}
                                {!!currentOther?.zipCode?.length &&
                                    currentOther.zipCode !== "undefined" && (
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <div className="text-sm font-medium text-neutral-600">
                                                Postal code
                                            </div>
                                            <div className="text-base">
                                                {currentOther.zipCode}
                                            </div>
                                        </div>
                                    )}
                                {currentOther.phone && (
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Phone number
                                        </div>
                                        <div className="text-base">
                                            {parsePhoneNumber(
                                                currentOther.phone
                                            )}
                                        </div>
                                    </div>
                                )}
                                {currentOther.website && (
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Website from Google
                                        </div>
                                        <Paragraph className="text-base">
                                            {currentOther.website}
                                        </Paragraph>
                                    </div>
                                )}
                            </div>
                            <div className="grid grid-cols-6 gap-x-12">
                                {!isLibraryElement && (
                                    <>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <DatePickerMaterial
                                                control={control}
                                                minDate={
                                                    parseMomentUtcToLocal(
                                                        trip?.startDate
                                                    ).toDate() as any
                                                }
                                                maxDate={
                                                    moment
                                                        .utc(trip?.endDate)
                                                        .endOf("day")
                                                        .toDate() as any
                                                }
                                                name="startDate"
                                                value={startDate}
                                                id="startDate"
                                                label="Start date"
                                                onChange={setStartDate}
                                                error={
                                                    errors["startDate"]?.message
                                                }
                                            />
                                        </div>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <TimePickerMaterial
                                                control={control}
                                                label="Start Hour"
                                                defaultValue={
                                                    moment(
                                                        activity?.startHour ||
                                                            "09:00",
                                                        "HH:mm"
                                                    ) as any
                                                }
                                                id="startHour"
                                                name="startHour"
                                                error={
                                                    errors["startHour"]?.message
                                                }
                                            />
                                        </div>
                                        <div className="mt-6 col-span-6 lg:col-span-3">
                                            <InputLabel
                                                inputId={"endHour"}
                                                label={"End Hour"}
                                                error={false}
                                            />
                                            <div className="flex flex-row gap-4 items-center">
                                                <InputSwitch
                                                    id="showEndHour"
                                                    name="showEndHour"
                                                    defaultChecked={
                                                        activity?.startHour !==
                                                        activity?.endHour
                                                    }
                                                    onClick={handleSwitchChange}
                                                />
                                                {showEndHourInput && (
                                                    <div className="flex-grow">
                                                        <TimePickerMaterial
                                                            control={control}
                                                            defaultValue={
                                                                moment(
                                                                    activity?.endHour ||
                                                                        "09:00",
                                                                    "HH:mm"
                                                                ) as any
                                                            }
                                                            id="endHour"
                                                            name="endHour"
                                                            error={
                                                                errors[
                                                                    "endHour"
                                                                ]?.message
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="mt-6">
                                <TextareaWithValid
                                    register={register}
                                    label="Description"
                                    id="description"
                                    name="description"
                                    error={errors["description"]?.message}
                                />
                            </div>
                            <div className="mt-6">
                                <InputWithValid
                                    register={register}
                                    label="Website"
                                    type="text"
                                    id="website"
                                    name="website"
                                    error={errors["website"]?.message}
                                />
                            </div>
                            <div className="mb-6">
                                <span className="block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="text-sm font-semibold mt-8 mb-4">
                                Contacts
                            </div>
                            <div
                                onClick={() => {
                                    setSelectedContact(null);
                                    handleContactToggle(true);
                                }}
                                className="flex cursor-pointer mb-3 border border-gray-200 rounded-xl p-6 justify-center"
                            >
                                <div className="flex flex-wrap items-center lg:min-w-[300px]">
                                    <img
                                        src={accountImage}
                                        className="h-16 mr-12"
                                        alt=""
                                    />
                                    <div className="flex flex-col">
                                        <div className="text-semibold text-orange-600">
                                            Add contact
                                        </div>
                                        <div className="text-sm text-neutral-400">
                                            Submit contact information
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {contacts && (
                                <div className="flex gap-6 flex-wrap">
                                    {contacts.map((c: any, index: number) => (
                                        <ContactCard
                                            handleEdit={() => {
                                                handleContactEditClick(index);
                                            }}
                                            handleRemove={() => {
                                                handleContactRemoveClick(index);
                                            }}
                                            contact={c}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            )}

                            <div className="text-sm font-semibold mt-6 mb-4">
                                Photos
                            </div>
                            <div>
                                <DropImageFileInput
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={onSelectImages}
                                />
                            </div>

                            {imagesProgressState.map((img) => {
                                return (
                                    <ProgressBar
                                        key={img.id}
                                        progress={img.progress}
                                        name={img.file.name}
                                        onRemove={() =>
                                            removeProgressImage(img.id, true)
                                        }
                                    />
                                );
                            })}

                            <div className="flex flex-wrap gap-4 mt-4">
                                {imagesPreviews.map((i, index: number) => {
                                    return (
                                        <GalleryImage
                                            key={i.src}
                                            src={i.src}
                                            onImageClick={() => {}}
                                            // onImageAdd={(e: any) => handleImageAdd(index)}
                                            onImageRemove={() => {
                                                handleImageRemove(index);
                                            }}
                                        />
                                    );
                                })}
                                {tempImagesState.map((i) => {
                                    return (
                                        <GalleryImage
                                            key={i.id}
                                            src={i.file}
                                            onImageClick={() => {}}
                                            // onImageAdd={(e: any) => handleImageAdd(index)}
                                            onImageRemove={() => {
                                                removeTempImage(i.id);
                                            }}
                                        />
                                    );
                                })}
                            </div>

                            <div className="text-sm font-semibold mt-6 mb-4">
                                Files
                            </div>
                            <div>
                                <DropFileInput
                                    iconSrc={fileImage}
                                    iconClass={"w-16"}
                                    className={"!p-4"}
                                    multiple={true}
                                    onChange={onSelectFiles}
                                />
                            </div>
                            {filesProgressState.map((file) => {
                                return (
                                    <ProgressBar
                                        key={file.id}
                                        progress={file.progress}
                                        name={file.file.name}
                                        onRemove={() =>
                                            removeProgressFile(file.id, true)
                                        }
                                    />
                                );
                            })}
                            <div className="flex flex-wrap gap-4 mt-4">
                                {tempFilesState.map((file) => {
                                    return (
                                        <FilePreview
                                            key={file.id}
                                            onRemove={() => {
                                                removeTempFile(file.id);
                                            }}
                                            name={file.file.name}
                                        />
                                    );
                                })}
                                {filesPreviews.map((file, index) => {
                                    return (
                                        <FilePreview
                                            key={index}
                                            onRemove={() => {
                                                handleFileRemove(index);
                                            }}
                                            name={file.name}
                                        />
                                    );
                                })}
                            </div>

                            {currentOther && !editingOther && (
                                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-6">
                                    <Button
                                        onClick={() => navigateBack()}
                                        outlined
                                        type="button"
                                        label="Cancel"
                                    />
                                    <Button type="submit" label="Save" />
                                </div>
                            )}
                        </>
                    )}
                    {editingOther && (
                        <div className="absolute bottom-5 right-5">
                            <Button
                                onClick={() => {
                                    setEditingOther(false);
                                }}
                                outlined
                                type="button"
                                label="back"
                            />
                        </div>
                    )}
                </form>
            </>
        )
    );
}
