import Icon from "../../assets/svg/Icon";
import { mdiCheckboxBlankOutline } from "@mdi/js";
import { mdiCheckboxMarked } from "@mdi/js";
import { Paragraph } from "../Paragraph/Paragraph";

export default function CheckBoxInput(props: any) {
    const { label, comment, value, onChange, subLabel, disabled } = props;
    return (
        <div className="flex items-start">
            <div
                className={`flex items-center h-5`}
                onClick={() => !disabled && onChange(!value)}
            >
                {value ? (
                    <Icon
                        className={`w-[20px] ${disabled ? "cursor-not-allowed" : "cursor-pointer"} text-orange-500`}
                        materialIcon={mdiCheckboxMarked}
                    />
                ) : (
                    <Icon
                        className={`w-[20px] ${disabled ? "cursor-not-allowed" : "cursor-pointer"} text-orange-500`}
                        materialIcon={mdiCheckboxBlankOutline}
                    />
                )}
            </div>
            <div className="ml-3 text-sm">
                <div className="flex items-center gap-2">
                    <label className="font-medium text-gray-700">{label}</label>
                    <span className="text-xs text-neutral-600">{subLabel}</span>
                </div>

                {comment && (
                    <Paragraph className="text-gray-500">{comment}</Paragraph>
                )}
            </div>
        </div>
    );
}
