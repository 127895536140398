import Checkbox from "../../../../components/CheckBox";
import Input from "../../../../components/Input";

interface CheckboxOption {
    name: string;
    label: string;
    span?: boolean;
  }
  
  interface CheckboxGroupProps {
    title: string;
    options: CheckboxOption[];
    control: any;
    setValue: any
    watch: any
    register: any
    disabled?: boolean
  }

export const CheckboxGroup = ({ title, options, control, setValue, watch, register, disabled }: CheckboxGroupProps) => {

    return (
      <div>
        <h3 className="font-semibold text-lg text-left pb-4 text-base">{title}</h3>
        <div className="grid grid-cols-2 gap-4">
          {options.map(({ name, label, span }) => {

            const value = watch(name)
            const valueComment = watch(`${name}Comment`)
            let showInput = value

            if (disabled && !valueComment) {
              showInput = false
            }
            
            return <div key={name} className={`flex flex-col ${span ? "col-span-2" : ""}`}>
              <label className="flex items-center">
                <Checkbox control={control} name={name} disabled={disabled} />
                <span>{label}</span>
              </label>
              {showInput && (
                <Input
                  type="text"
                  value={valueComment}
                  disabled={disabled}
                  placeholder={disabled ? "" : "Add comments..."}
                  className={`border rounded-md p-2 mt-2 text-sm ${disabled ? "cursor-default !focus-within:border-gray-200 hover:border-gray-200" : ""}`}
                  register={register}
                  name={`${name}Comment`}
                  onChange={(e) => setValue(`${name}Comment`, e.target.value)}
                  hideError={disabled}
                  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                />
              )}
            </div>
            })}
        </div>
      </div>
    );
  };
