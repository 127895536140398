export const FEATURE_FLAGS = {
    ORGANIZATIONS: "ORGANIZATIONS",
    LIBRARY: "LIBRARY",
};

export enum PAGE_SECTIONS {
    SIGN_IN = "SIGN_IN",
    SIGN_UP = "SIGN_UP",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    HOME = "HOME",
    PROFILE = "PROFILE",
    USER_PROFILE = "USER_PROFILE",
    MANAGE_ACCOUNT = "MANAGE_ACCOUNT",
    TRIPS = "TRIPS",
    TRIP = "TRIP",
    PENDING_TRIP = "PENDING_TRIP",
    TRIP_EDIT = "TRIP_EDIT",
    TRIP_NEW = "TRIP_NEW",
    ORGANIZATIONS = "ORGANIZATIONS",
    ORGANIZATION = "ORGANIZATION",
    ORGANIZATION_MEMBERS = "ORGANIZATION_MEMBERS",
    ORGANIZATION_TRIPS = "ORGANIZATION_TRIPS",
    ORGANIZATION_LIBRARY = "ORGANIZATION_LIBRARY",
    ORGANIZATION_CHAT = "ORGANIZATION_CHAT",
    TRIP_PARTICIPANTS = "TRIP_PARTICIPANTS",
    TRIP_ITINERARY = "TRIP_ITINERARY",
    TRIP_ITINERARY_HOTEL = "TRIP_ITINERARY_HOTEL",
    TRIP_ITINERARY_VISIT = "TRIP_ITINERARY_VISIT",
    TRIP_ITINERARY_PUBLIC_REVIEW = "TRIP_ITINERARY_PUBLIC_REVIEW",
    TRIP_ITINERARY_PRIVATE_REVIEW = "TRIP_ITINERARY_PRIVATE_REVIEW",
    TRIP_ITINERARY_PRIVATE_INSIGHT = "TRIP_ITINERARY_PRIVATE_INSIGHT",
    TRIP_ITINERARY_OTHER = "TRIP_ITINERARY_OTHER",
    TRIP_ITINERARY_RESTAURANT = "TRIP_ITINERARY_RESTAURANT",
    TRIP_LOCAL_PARTNERS = "TRIP_LOCAL_PARTNERS",
    TRIP_GALLERY = "TRIP_GALLERY",
    TRIP_FILES = "TRIP_FILES",
    TRIP_FILES_EDIT = "TRIP_FILES_EDIT",
    TRIP_REQUIREMENTS = "TRIP_REQUIREMENTS",
    TRIP_CONTACTS = "TRIP_CONTACTS",
    TRIP_NOTIFICATIONS = "TRIP_NOTIFICATIONS",
    TRIP_CUSTOM_INVITE = "TRIP_CUSTOM_INVITE",
    TRIP_CHAT = "TRIP_CHAT",
    NOTIFICATIONS_USER = "NOTIFICATIONS_USER",
    PREFERENCES_USER = "PREFERENCES_USER",
    PLAN_USER = "PLAN_USER",
    ADMIN_OVERVIEW = "ADMIN_OVERVIEW",
    ADMIN_USERS = "ADMIN_USERS",
    ADMIN_TRIPS = "ADMIN_TRIPS",
    ADMIN_INCOMING_TRIPS = "ADMIN_INCOMING_TRIPS",
    ADMIN_INSIGHTS = "ADMIN_INSIGHTS",
    ADMIN_REVIEWS = "ADMIN_REVIEWS",
    ADMIN_HOTELS = "ADMIN_HOTELS",
    ADMIN_MODULES_SUPPORT = "ADMIN_MODULES_SUPPORT",
    ADMIN_MODULES_VIDEOS = "ADMIN_MODULES_VIDEOS",
    ADMIN_RESTAURANTS = "ADMIN_RESTAURANTS",
    ADMIN_OTHERS = "ADMIN_OTHERS",
    ADMIN_COMPLAINTS = "ADMIN_COMPLAINTS",
    TRIP_MESSAGES = "TRIP_MESSAGES",
    ADMIN_PLANS = "ADMIN_PLANS",
    ADMIN_TRANSFERS = "ADMIN_TRANSFERS",
}

export const MENU_OPTIONS_SHOW_FOR = {
    canEdit: "canEdit",
    all: "all",
    emailUsers: "emailUsers",
    admin: "admin",
    organizedTrip: "organizedTrip",
    organizationConfirmedUser: "organizationConfirmedUser",
};

export const PROFILE_MENU_OPTIONS = [
    {
        label: "Profile",
        icon: "ProfileIcon",
        url: "/profile",
        key: PAGE_SECTIONS.PROFILE,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "My Plan",
        icon: "PaymentIcon",
        url: "/plans",
        key: PAGE_SECTIONS.PLAN_USER,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Manage Account",
        icon: "AccountSettingsIcon",
        url: "/account",
        key: PAGE_SECTIONS.MANAGE_ACCOUNT,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Preferences",
        icon: "PreferencesIcon",
        url: "/preferences",
        key: PAGE_SECTIONS.PREFERENCES_USER,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
];

export const TRIP_MENU_OPTIONS = [
    {
        label: "Trip Details",
        icon: "TripIcon",
        url: "",
        key: PAGE_SECTIONS.TRIP,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Members",
        icon: "ParticipantsIcon",
        url: "users",
        key: PAGE_SECTIONS.TRIP_PARTICIPANTS,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Itinerary",
        icon: "ItineraryIcon",
        url: "itinerary",
        key: PAGE_SECTIONS.TRIP_ITINERARY,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Gallery",
        icon: "GalleryIcon",
        url: "images",
        key: PAGE_SECTIONS.TRIP_GALLERY,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Files/Info",
        icon: "FilesIcon",
        url: "files",
        key: PAGE_SECTIONS.TRIP_FILES,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Requirements",
        icon: "RequirementsIcon",
        url: "requirements",
        key: PAGE_SECTIONS.TRIP_REQUIREMENTS,
        showFor: [MENU_OPTIONS_SHOW_FOR.organizedTrip],
    },
    {
        label: "Chat",
        icon: "MessageIcon",
        url: "chat",
        key: PAGE_SECTIONS.TRIP_CHAT,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Notifications",
        icon: "BellIcon",
        url: "notifications",
        key: PAGE_SECTIONS.TRIP_NOTIFICATIONS,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Broadcast",
        icon: "HornIcon",
        url: "messages",
        key: PAGE_SECTIONS.TRIP_MESSAGES,
        showFor: [MENU_OPTIONS_SHOW_FOR.canEdit],
    },
];

export const ADMIN_MENU_OPTIONS = [
    {
        label: "Overview",
        icon: "AnalyticsIcon",
        url: "/admin/overview",
        key: PAGE_SECTIONS.ADMIN_OVERVIEW,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Users",
        icon: "ParticipantsIcon",
        url: "/admin/users",
        key: PAGE_SECTIONS.ADMIN_USERS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Trips",
        icon: "TripIcon",
        url: "/admin/trips",
        key: PAGE_SECTIONS.ADMIN_TRIPS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Incoming trips",
        icon: "TripIcon",
        url: "/admin/incoming-trips",
        key: PAGE_SECTIONS.ADMIN_INCOMING_TRIPS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Insights",
        icon: "InsightsIcon",
        url: "/admin/insights",
        key: PAGE_SECTIONS.ADMIN_INSIGHTS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Reviews",
        icon: "MessageStarIcon",
        url: "/admin/reviews",
        key: PAGE_SECTIONS.ADMIN_REVIEWS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Plans",
        icon: "PaymentIcon",
        url: "/admin/plans",
        key: PAGE_SECTIONS.ADMIN_PLANS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    //{
    //    label: "Transfers",
    //    icon: "CreditCardIcon",
    //    url: "/admin/transfers",
    //    key: PAGE_SECTIONS.ADMIN_TRANSFERS,
    //    showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    //},
    {
        label: "Hotels",
        icon: "BuildingIcon",
        url: "/admin/hotels",
        key: PAGE_SECTIONS.ADMIN_HOTELS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Restaurants",
        icon: "RestaurantIcon",
        url: "/admin/restaurants",
        key: PAGE_SECTIONS.ADMIN_RESTAURANTS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },

    {
        label: "Others",
        icon: "CalendarPlusIcon",
        url: "/admin/others",
        key: PAGE_SECTIONS.ADMIN_OTHERS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Requests",
        icon: "AlertIcon",
        url: "/admin/complaints",
        key: PAGE_SECTIONS.ADMIN_COMPLAINTS,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
    {
        label: "Support Videos",
        icon: "ModuleVideoIcon",
        url: "/admin/support-modules",
        key: PAGE_SECTIONS.ADMIN_MODULES_SUPPORT,
        showFor: [MENU_OPTIONS_SHOW_FOR.admin],
    },
];

export const ORGANIZATION_MENU_OPTIONS = [
    {
        label: "Details",
        icon: "OrganizationIcon",
        url: "",
        key: PAGE_SECTIONS.ORGANIZATION,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
    {
        label: "Members",
        icon: "ParticipantsIcon",
        url: "members",
        key: PAGE_SECTIONS.ORGANIZATION_MEMBERS,
        showFor: [MENU_OPTIONS_SHOW_FOR.organizationConfirmedUser],
    },
    {
        label: "Trips",
        icon: "TripIcon",
        url: "trips",
        key: PAGE_SECTIONS.ORGANIZATION_TRIPS,
        showFor: [MENU_OPTIONS_SHOW_FOR.organizationConfirmedUser],
    },
    {
        label: "Library",
        icon: "LibraryIcon",
        url: "library",
        key: PAGE_SECTIONS.ORGANIZATION_LIBRARY,
        showFor: [MENU_OPTIONS_SHOW_FOR.organizationConfirmedUser],
        featureFlags: [FEATURE_FLAGS.LIBRARY],
    },
    {
        label: "Chat",
        icon: "MessageIcon",
        url: "chat",
        key: PAGE_SECTIONS.ORGANIZATION_CHAT,
        showFor: [MENU_OPTIONS_SHOW_FOR.all],
    },
];
