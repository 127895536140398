import { useDispatch } from "react-redux";
import { SettingItem } from "../../components/SettingItem";
import { updateSettings } from "../authentication/userSlice";
import { useEffect, useState } from "react";
import { HIDE_NOTIFICATION_PREFERENCES_EMAIL } from "../../constants/notifications";

interface settingOption {
    name: string;
    title: string;
    description: string;
    disabledEmail?: boolean;
    value: any;
}

export const ItemSettings = (option: settingOption) => {
    const [value, setValue] = useState(option.value);

    const dispatch = useDispatch();
    const handleChange = (key: string, value: boolean) => {
        const fields = {
            [key]: value,
        };
        dispatch(updateSettings(fields));
    };

    useEffect(() => {
        setValue(option.value);
    }, [option.value]);

    return (
        <SettingItem
            name={option.name}
            title={option.title}
            description={option.description}
            value={value}
            handleChange={handleChange}
            hideEmailSwitch={HIDE_NOTIFICATION_PREFERENCES_EMAIL.includes(
                option.name
            )}
            disabledEmail={option.disabledEmail}
        />
    );
};
