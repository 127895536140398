import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import {
    ACTIVITY_FORM_STEPS_IDS,
    ActivityType,
    EditionUserRoleOnOrganization,
    PAGE_ROWS,
    TOAST_TYPE,
    UserRoleOnOrganization,
    UserStatusOnOrganization,
} from "../../constants";
import { handleError, setProfile } from "../authentication/userSlice";
import { editNotificationBy } from "../notifications/notificationsSlice";
import { NOTIFICATION_TYPES } from "../../constants/notifications";
import { sendToast } from "../../helpers";
import {
    setActivityForm,
    setActivityFormCurrentStep,
    setSelectedTripActivity,
    submitActivityFormStep,
} from "../trips/tripSlice";
import { libraryElementToActivityPayload } from "./Library/library.util";
import { setSelectedHotel } from "../hotels/hotelSlice";
import { setSelectedRestaurant } from "../restaurants/restaurantSlice";
import { setSelectedOther } from "../others/othersSlice";

interface IPaginatedData {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
}
export interface OrganizationState {
    isLoading: boolean;
    organizations: Array<any>;
    selectedOrganization: any | null;
    selectedOrganizationMembers: IPaginatedData;
    selectedOrganizationMember: any | null;
    error: string;
    hidrated: boolean;
    selectedOrganizationLibraryElements: IPaginatedData;
    selectedLibraryElement: any | null;
    libraryElementToAddToTrip: any | null;
    nextRouteAfterNewElement: string;
}
const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};
const initialState: OrganizationState = {
    isLoading: false,
    organizations: [],
    selectedOrganization: null,
    selectedOrganizationMembers: IPaginatedDataInitialState,
    error: "",
    hidrated: false,
    selectedOrganizationMember: null,
    selectedOrganizationLibraryElements: IPaginatedDataInitialState,
    selectedLibraryElement: null,
    libraryElementToAddToTrip: null,
    nextRouteAfterNewElement: "",
};

export const organizationSlice = createSlice({
    name: "organization",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setHidrated: (state, action: PayloadAction<boolean>) => {
            state.hidrated = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setOrganizations: (
            state,
            action: PayloadAction<{ organizations: any }>
        ) => {
            state.organizations = [...action.payload.organizations];
        },
        organizationsRemove: (
            state,
            action: PayloadAction<{ organizationId: number }>
        ) => {
            state.organizations = state.organizations?.length
                ? state.organizations.filter(
                      (org: any) => org?.id !== action.payload.organizationId
                  )
                : [];
        },
        organizationsAdd: (
            state,
            action: PayloadAction<{ newOrganizartion: any }>
        ) => {
            state.organizations = [
                ...(state?.organizations || []),
                action.payload.newOrganizartion,
            ];
        },
        setSelectedOrganization: (
            state,
            action: PayloadAction<{
                selectedOrganization: any | null;
            }>
        ) => {
            state.selectedOrganization = action.payload.selectedOrganization
                ? {
                      ...action.payload.selectedOrganization,
                  }
                : null;
        },
        setSelectedOrganizationMember: (
            state,
            action: PayloadAction<{
                selectedOrganizationMember: any | null;
            }>
        ) => {
            state.selectedOrganizationMember = action.payload
                .selectedOrganizationMember
                ? {
                      ...action.payload.selectedOrganizationMember,
                  }
                : null;
        },
        updateSelectedOrganization: (
            state,
            action: PayloadAction<{
                selectedOrganization: any | null;
            }>
        ) => {
            state.selectedOrganization = action.payload.selectedOrganization
                ? {
                      ...state.selectedOrganization,
                      ...action.payload.selectedOrganization,
                  }
                : null;
        },
        setSelectedOrganizationMembers: (
            state,
            action: PayloadAction<{
                selectedOrganizationMembers: IPaginatedData;
            }>
        ) => {
            state.selectedOrganizationMembers = action.payload
                .selectedOrganizationMembers
                ? { ...action.payload.selectedOrganizationMembers }
                : IPaginatedDataInitialState;
        },
        removeMemberFromOrganization: (
            state,
            action: PayloadAction<{
                userEmail: string;
            }>
        ) => {
            state.selectedOrganizationMembers = {
                ...state.selectedOrganizationMembers,
                count: state.selectedOrganizationMembers.count - 1,
                data: state.selectedOrganizationMembers?.data?.filter(
                    (u) => u?.userEmail !== action.payload.userEmail
                ),
            };
        },
        updateMemberFromOrganization: (
            state,
            action: PayloadAction<{
                userEmail: string;
                data: any;
            }>
        ) => {
            state.selectedOrganizationMembers = {
                ...state.selectedOrganizationMembers,
                data: state.selectedOrganizationMembers?.data.map((u: any) =>
                    u.userEmail !== action.payload.userEmail
                        ? u
                        : { ...u, ...(action.payload.data || {}) }
                ),
            };
        },
        setSelectedOrganizationMembersIsLoading: (
            state,
            action: PayloadAction<{ isLoading: boolean }>
        ) => {
            state.selectedOrganizationMembers = {
                ...state.selectedOrganizationMembers,
                isLoading: action.payload.isLoading,
            };
        },
        setSelectedOrganizationMembersCurrentPage: (
            state,
            action: PayloadAction<{ currentPage: number }>
        ) => {
            state.selectedOrganizationMembers = {
                ...state.selectedOrganizationMembers,
                currentPage: action.payload.currentPage,
            };
        },
        setSelectedOrganizationLibraryElements: (
            state,
            action: PayloadAction<{
                selectedOrganizationLibraryElements: IPaginatedData;
            }>
        ) => {
            state.selectedOrganizationLibraryElements = action.payload
                .selectedOrganizationLibraryElements
                ? { ...action.payload.selectedOrganizationLibraryElements }
                : IPaginatedDataInitialState;
        },
        setSelectedLibraryElement: (
            state,
            action: PayloadAction<{
                selectedLibraryElement: any | null;
            }>
        ) => {
            state.selectedLibraryElement = action.payload.selectedLibraryElement
                ? {
                      ...action.payload.selectedLibraryElement,
                  }
                : null;
        },
        setLibraryElementToAddToTrip: (
            state,
            action: PayloadAction<{
                libraryElementToAddToTrip: any | null;
            }>
        ) => {
            state.libraryElementToAddToTrip = action.payload
                .libraryElementToAddToTrip
                ? {
                      ...action.payload.libraryElementToAddToTrip,
                  }
                : null;
        },
        setNextRouteAfterNewElement: (
            state,
            action: PayloadAction<{
                nextRouteAfterNewElement: string;
            }>
        ) => {
            state.nextRouteAfterNewElement =
                action.payload.nextRouteAfterNewElement;
        },
    },
});

export const {
    isLoading,
    setOrganizations,
    setSelectedOrganization,
    setError,
    setSelectedOrganizationMembers,
    setSelectedOrganizationMembersCurrentPage,
    setHidrated,
    updateSelectedOrganization,
    organizationsRemove,
    organizationsAdd,
    updateMemberFromOrganization,
    removeMemberFromOrganization,
    setSelectedOrganizationMember,
    setSelectedOrganizationLibraryElements,
    setSelectedLibraryElement,
    setLibraryElementToAddToTrip,
    setNextRouteAfterNewElement,
} = organizationSlice.actions;
export const selectOrganizations = (state: RootState) =>
    state.organization?.organizations;
export const selectedOrganization = (state: RootState) =>
    state.organization?.selectedOrganization;
export const selectedOrganizationUsers = (state: RootState) =>
    state.organization?.selectedOrganizationMembers;
export const selectedOrganizationUser = (state: RootState) =>
    state.organization?.selectedOrganizationMember;
export const selectOrganizationsHidrated = (state: RootState) =>
    state.organization?.hidrated;
export const selectOrganizationLibraryElements = (state: RootState) =>
    state.organization?.selectedOrganizationLibraryElements;
export const selectedLibraryElement = (state: RootState) =>
    state.organization?.selectedLibraryElement;
export const selectActivityElementToAddToTrip = (state: RootState) =>
    state.organization?.libraryElementToAddToTrip;

export const getOrganizations =
    (showLoading = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(showLoading));
            const response = await serverApi.getOrganizations();
            dispatch(setOrganizations({ organizations: response.data }));
            const organizations = response?.data;
            dispatch(setOrganizations({ organizations: organizations }));
            dispatch(isLoading(false));
            dispatch(setHidrated(true));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getOrganization =
    (organizationId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getOrganization(organizationId);
            const organization = response?.data[0];
            const userEmail = getState().user?.profile?.email;
            const permissions = getUserPermissions(organization, userEmail);
            dispatch(
                setSelectedOrganization({
                    selectedOrganization: { ...organization, ...permissions },
                })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getTripsByOrganization =
    (organizationId: number, filters: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getTripsByOrganization(
                organizationId,
                filters
            );
            const trips = response?.data;
            dispatch(isLoading(false));
            return trips;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return [];
        }
    };

export const getOrganizationMembers =
    (
        organizationId: number,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = "",
        status: UserStatusOnOrganization | "" | null | undefined,
        role: UserRoleOnOrganization | "" | null | undefined,
        canEdit: boolean = false
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState =
            getState().organization?.selectedOrganizationMembers ||
            IPaginatedDataInitialState;
        try {
            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: {
                        count:
                            getState().organization?.selectedOrganizationMembers
                                ?.count || 0,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        currentPage: newCurrentPage,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getOrganizationMembers(
                organizationId,
                take,
                skip,
                search,
                status,
                role,
                canEdit
            );
            const selectedOrganizationMembers = response.data;

            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: selectedOrganizationMembers,
                })
            );
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));

            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: currentState,
                })
            );
        }
    };

export const createOrganization =
    (data: any, callback?: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addOrganization(data);
            const newOrg = {
                ...response.data,
            };

            dispatch(organizationsAdd({ newOrganizartion: newOrg }));
            const actualOrganizations = getState().user.profile.organizations;
            dispatch(
                setProfile({
                    profile: {
                        ...getState().user.profile,
                        organizations: [
                            ...actualOrganizations,
                            {
                                userEmail: newOrg?.users[0].userEmail,
                                organization: newOrg,
                                organizationId: newOrg.id,
                                status: UserStatusOnOrganization.CONFIRMED,
                            },
                        ],
                    },
                })
            );
            callback && callback(newOrg.id, "members");
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateOrganization =
    (organizationId: number, fields: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateOrganization(
                organizationId,
                fields
            );
            dispatch(isLoading(false));
            const newOrg = response.data;
            dispatch(
                updateSelectedOrganization({
                    selectedOrganization: newOrg,
                })
            );
            const actualUserOrganizations =
                getState().user.profile.organizations;
            const newOrganizations = actualUserOrganizations.map((org: any) => {
                return org.organizationId === newOrg.id
                    ? {
                          ...org,
                          organization: newOrg,
                      }
                    : org;
            });
            dispatch(
                setProfile({
                    profile: {
                        ...getState().user.profile,
                        organizations: newOrganizations,
                    },
                })
            );
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const removeOrganization =
    (organizationId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.removeOrganization(organizationId);
            if (response.status !== 200) {
                dispatch(isLoading(false));
                dispatch(handleError(response));
                return;
            }
            dispatch(setSelectedOrganization({ selectedOrganization: {} }));
            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: IPaginatedDataInitialState,
                })
            );
            dispatch(organizationsRemove({ organizationId: organizationId }));
            const actualUserOrganizations =
                getState().user.profile.organizations;
            const newOrganizations = actualUserOrganizations.filter(
                (org: any) => org.organizationId !== organizationId
            );
            dispatch(
                setProfile({
                    profile: {
                        ...getState().user.profile,
                        organizations: newOrganizations,
                    },
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const leaveOrganization =
    (organizationId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const email = getState().user.profile.email;
            await serverApi.removeUserFromOrganization(organizationId, email);
            dispatch(setSelectedOrganization({ selectedOrganization: {} }));
            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: IPaginatedDataInitialState,
                })
            );
            dispatch(organizationsRemove({ organizationId: organizationId }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addUsersToOrganization =
    (
        organizationId: number,
        users: Array<{ email: string; role: UserRoleOnOrganization }>
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const data = await serverApi.addUsersToOrganization(
                organizationId,
                users
            );
            dispatch(isLoading(false));
            const currentMembers =
                getState().organization?.selectedOrganizationMembers.data;
            const updatedMembers = [...currentMembers, ...data.data];
            dispatch(
                setSelectedOrganizationMembers({
                    selectedOrganizationMembers: {
                        ...getState().organization.selectedOrganizationMembers,
                        data: updatedMembers,
                        count: updatedMembers.length,
                    },
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateUserRoleOnOrganization =
    (
        organizationId: number,
        email: string,
        role: UserRoleOnOrganization,
        callback?: Function
    ): AppThunk =>
    async (dispatch, getData) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateUserOnOrganization(organizationId, email, {
                role: role,
            });
            callback && callback();
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateUserStatusOnOrganization =
    (
        organizationId: number,
        email: string,
        status: UserStatusOnOrganization
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateUserOnOrganization(organizationId, email, {
                status: status,
            });
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const rejectInvitation =
    (orgId: number, userEmail: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateUserOnOrganization(orgId, userEmail, {
                status: UserStatusOnOrganization.REJECTED,
            });

            const state = getState();
            const allOrganizations = state.organization?.organizations || [];

            const updatedOrganizations = allOrganizations.filter((org: any) => {
                return org.id !== orgId;
            });

            dispatch(setOrganizations({ organizations: updatedOrganizations }));

            dispatch(
                editNotificationBy(
                    [
                        { key: "email", value: userEmail },
                        { key: "data.organizationId", value: orgId },
                        {
                            key: "type",
                            value: NOTIFICATION_TYPES.ORGANIZATION_INVITATION,
                        },
                    ],
                    { showCTA: false, read: true }
                )
            );

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const acceptInvitation =
    (organizationId: number, email: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const currentState =
                getState().organization?.selectedOrganization || {};
            dispatch(isLoading(true));
            await serverApi.updateUserOnOrganization(organizationId, email, {
                status: UserStatusOnOrganization.CONFIRMED,
            });
            dispatch(
                editNotificationBy(
                    [
                        { key: "email", value: email },
                        { key: "data.organizationId", value: organizationId },
                        {
                            key: "type",
                            value: NOTIFICATION_TYPES.ORGANIZATION_INVITATION,
                        },
                    ],
                    { showCTA: false, read: true }
                )
            );
            dispatch(
                setSelectedOrganization({
                    selectedOrganization: {
                        ...currentState,
                        status: UserStatusOnOrganization.CONFIRMED,
                        canEdit:
                            UserRoleOnOrganization.MEMBER !== currentState.role,
                        users: [
                            ...(currentState?.users || []).map((u: any) =>
                                u.userEmail === email
                                    ? {
                                          ...u,
                                          status: UserStatusOnOrganization.CONFIRMED,
                                      }
                                    : u
                            ),
                        ],
                    },
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const getOrganizationUserProfile =
    (organizationId: number, userId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getOrganizationUserProfile(
                organizationId,
                userId
            );
            dispatch(
                setSelectedOrganizationMember({
                    selectedOrganizationMember: response.data,
                })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };
export const removeUserFromOrganization =
    (organizationId: number, email: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeUserFromOrganization(organizationId, email);
            dispatch(removeMemberFromOrganization({ userEmail: email }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

const getUserPermissions = (organization: any, userEmail: string) => {
    let status = null;
    let role = null;
    let canEdit = false;
    let position = "";
    if (organization && organization?.users) {
        const users = organization.users as any[];
        const user: any =
            users?.length === 1
                ? users[0]
                : users?.find((u) => {
                      return u.userEmail === userEmail;
                  });
        status = user?.status;
        role = user?.role;
        position = user?.position;
    }
    canEdit =
        EditionUserRoleOnOrganization.includes(role) &&
        status === UserStatusOnOrganization.CONFIRMED;
    return { status, role, canEdit, position };
};

export const addHotelGroup =
    (name: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addHotelGroup({
                name: name,
            });
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return null;
        }
    };

export const addCruiseLine =
    (name: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addCruiseLine({
                name: name,
            });
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return null;
        }
    };

export const addHostAgency =
    (name: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addHostAgency({
                name: name,
            });
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return null;
        }
    };

export const resendOrganizationUserInvitation =
    (orgId: number, email: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.resendInvitationUserToOrganization(
                orgId,
                email
            );
            dispatch(isLoading(false));
            sendToast(TOAST_TYPE.SUCCESS, "Invitation resent");
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const getOrganizationLibraryElements =
    (organizationId: number, filters: any): AppThunk =>
    async (dispatch, getState) => {
        const currentState =
            getState().organization?.selectedOrganizationLibraryElements ||
            IPaginatedDataInitialState;
        const {
            activityType,
            take = 10,
            skip = 0,
            newCurrentPage,
            search = "",
            hotelType,
        } = filters;
        try {
            dispatch(
                setSelectedOrganizationLibraryElements({
                    selectedOrganizationLibraryElements: {
                        count:
                            getState().organization
                                ?.selectedOrganizationLibraryElements?.count ||
                            0,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        currentPage: newCurrentPage,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getLibraryElements(
                organizationId,
                activityType,
                take,
                skip,
                search,
                hotelType
            );
            const selectedOrganizationLibraryElements = response.data;

            dispatch(
                setSelectedOrganizationLibraryElements({
                    selectedOrganizationLibraryElements:
                        selectedOrganizationLibraryElements,
                })
            );
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));

            dispatch(
                setSelectedOrganizationLibraryElements({
                    selectedOrganizationLibraryElements: currentState,
                })
            );
        }
    };

export const getLibraryElement =
    (organizationId: number, elementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getLibraryElement(
                organizationId,
                elementId
            );
            dispatch(
                setSelectedLibraryElement({
                    selectedLibraryElement: response.data,
                })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const addLibraryElement =
    (organizationId: number, data: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addLibraryElement(
                organizationId,
                data
            );
            return response.data;
        } catch (error) {
            dispatch(handleError(error));
            return null;
        } finally {
            dispatch(isLoading(false));
        }
    };

export const updateLibraryElement =
    (organizationId: number, elementId: number, data: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateLibraryElement(
                organizationId,
                elementId,
                data
            );
            dispatch(
                setSelectedLibraryElement({
                    selectedLibraryElement: response.data,
                })
            );
            return response.data;
        } catch (error) {
            dispatch(handleError(error));
            return null;
        } finally {
            dispatch(isLoading(false));
        }
    };

export const removeLibraryElement =
    (organizationId: number, elementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeLibraryElement(organizationId, elementId);
        } catch (error) {
            dispatch(handleError(error));
            return;
        } finally {
            dispatch(isLoading(false));
        }
    };

export const duplicateLibraryElement =
    (organizationId: number, elementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.duplicateLibraryElement(organizationId, elementId);
        } catch (error) {
            dispatch(handleError(error));
            return;
        } finally {
            dispatch(isLoading(false));
        }
    };

export const redirectToAddLibraryElementToTrip =
    (tripId: number, navigate: Function): AppThunk =>
    async (dispatch, getState) => {
        const libraryElementToAddToTrip =
            getState().organization.libraryElementToAddToTrip;
        if (!libraryElementToAddToTrip) {
            navigate(`/trips/${tripId}`);
            return;
        }

        dispatch(
            setSelectedTripActivity({
                selectedTripActivity: libraryElementToAddToTrip,
            })
        );
        navigate(
            `/trips/${tripId}/itinerary/${Date.now()}/edit?fromLibrary=true`
        );
    };

export const handleRedirectToTripActivityFormAfterNewElement =
    (activity: any, navigate: Function): AppThunk =>
    async (dispatch, getState) => {
        const nextRouteAfterNewElement =
            getState().organization.nextRouteAfterNewElement;
        if (nextRouteAfterNewElement) {
            const activityPayload = libraryElementToActivityPayload(
                activity as any
            );
            dispatch(
                setLibraryElementToAddToTrip({
                    libraryElementToAddToTrip: activityPayload,
                })
            );
            navigate(`${nextRouteAfterNewElement}`);
            dispatch(
                setNextRouteAfterNewElement({ nextRouteAfterNewElement: "" })
            );
        }
    };

export default organizationSlice.reducer;
