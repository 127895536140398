import MomentUtils from "@date-io/moment";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import { TimePicker } from "@material-ui/pickers/TimePicker";
import moment, { Moment } from "moment";
import React, { useMemo, useState } from "react";
import Icon from "../../assets/svg/Icon";
import InputLabel from "../InputLabel";
import { useAppSelector } from "../../app/hooks";

const TimePickerMaterial = React.forwardRef(
    (
        { onChange, id, label, error, minDate, defaultValue, ...others }: any,
        ref: any
    ) => {
        const [time, setTime] = useState(moment(defaultValue));
        const settings = useAppSelector((state) => state?.user?.settings);
        const handleChange = (newVal: Moment) => {
            setTime(newVal);
            onChange && onChange(newVal);
        };
        const timeFormat = useMemo(() => {
            return settings?.otherSettings?.timeFormat24Hour
                ? "HH:mm"
                : "hh:mm A";
        }, [settings?.otherSettings?.timeFormat24Hour]);
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <InputLabel inputId={id} label={label} error={error} />
                <div className="flex flex-row">
                    <Icon icon="ClockIcon" className="w-6 mr-2" />
                    <TimePicker
                        id={id}
                        name={id}
                        format={timeFormat}
                        variant="inline"
                        fullWidth
                        onChange={handleChange}
                        value={time}
                        onAccept={handleChange}
                        helperText={false}
                        {...others}
                    />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
);
export default TimePickerMaterial;
