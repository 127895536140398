import React, { useState } from "react";
import Avatar from "../../../components/Avatar";
import { IChatEntity, IContact } from "../../../types/chat.type";
import { useChatForm } from "../hooks/useChat.hook";
import ButtonSquared from "../../../components/ButtonSquare";
import { ButtonSize } from "../../../constants";
import { useNavigate } from "react-router";
import Icon from "../../../assets/svg/Icon";
import { useSelector } from "react-redux";

interface ParticipantsListProps {
    chat: IChatEntity | null;
    setShowParticipants: (value: boolean) => void;
    tripId?: number;
}

const ParticipantsList: React.FC<ParticipantsListProps> = ({ chat, setShowParticipants, tripId }) => {
    const { onSelectedUser, setPreviousTrip } = useChatForm();
    const currentUser = useSelector((state: any) => state?.user?.profile);
    const navigate = useNavigate();
    const [hoveredUser, setHoveredUser] = useState<string | null>(null);

    const goToEntityDetails = () => {
        const [chatType, entityId] = chat?.id.split("-") ?? [];
        const path = chatType === "trip" ? "trips" : "organizations";
        navigate(`/${path}/${entityId}`);
    };

    if (!chat || !chat.members) {
        return (
            <div className="flex justify-center items-center h-full">
                <p>No members found.</p>
            </div>
        );
    }

    const onSelectUser = (user: IContact) => {
        if (!user) {
            return;
        }
        onSelectedUser(user);
        setShowParticipants(false);
    }


    return (
        <div className="flex flex-col h-full">
            <div className="border-b border-gray-200 pb-1">
                <div className="pl-4 pt-4">
                    <div className="mb-2 text-left">
                        <span className="font-medium text-xs">
                            Group • {chat.members.length} members
                        </span>
                    </div>
                    <div className="text-left mb-4">
                        <span>
                            <h2 className="text-lg font-semibold mb-4">
                                Members
                            </h2>
                        </span>
                    </div>
                </div>
            </div>

            <div className="p-4">
                <ButtonSquared
                    outlined={true}
                    label={`Go to ${chat?.id.split("-")[0] || 'trip'} details`}
                    onClick={goToEntityDetails}
                    size={ButtonSize.NORMAL}
                    className="w-full mb-4"
                />
                <div className="overflow-y-auto h-4/6" style={{ minHeight: "400px" }}>
                     {chat.members.map((member) => (
                        <div
                            key={member.uid}
                            className="flex items-center mb-2 border-b border-gray-200 p-2 relative"
                            onMouseEnter={() => setHoveredUser(member.uid)}
                            onMouseLeave={() => setHoveredUser(null)}
                        >
                            <Avatar
                                src={member.avatar}
                                className="w-8 h-8 mr-4 rounded-full"
                            />
                            <div className="flex-1 text-left">
                                <p className="font-medium">{`${member.firstName} ${member.lastName}`}</p>
                            </div>
                            {hoveredUser === member.uid &&
                                currentUser.uid !== member.uid && (
                                    <div className="relative">
                                        <div
                                            className={`w-8 h-8 flex items-center justify-center rounded-full bg-orange-500 text-white cursor-pointer ${
                                                hoveredUser === member.uid
                                                    ? "text-white"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                onSelectUser(member)
                                                setPreviousTrip(tripId)
                                            }}
                                        >
                                            <Icon
                                                icon={"MessageTextIcon"}
                                                className="w-5 h-5 m-0"
                                            />
                                        </div>
                                        {hoveredUser === member.uid && (
                                            <div className="absolute left-0 transform translate-x-[-75%] mt-2 bg-gray-700 text-white text-sm font-bold px-2 py-2 rounded-md shadow-lg whitespace-nowrap z-10">
                                                <div className="absolute top-0 right-3 transform translate-x-[10%] -translate-y-full w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-gray-700"></div>
                                                Send message
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ParticipantsList;
