import moment from "moment";
import TextareaWithValid from "../../../../../../components/TextArea/TextArea";
import { useState } from "react";
import { FlightForm } from "./FlightForm";
import TabComponent from "../../../../../../components/TabComponent";
import Checkbox from "../../../../../../components/CheckBox/Checkbox";

export const RequirementFlightFormResponse = ({ show, requirement, register, control, setValue, step = 1, avoidInformation,  setAvoidInformation}: any) => {

  const [transportation, setTransportation] = useState('arrival_flight');
  //const [avoidInformation, setAvoidInformation] = useState<{1: boolean, 2: boolean}>({1: false, 2: false});
  const [transportationDeparture, setTransportationDeparture] = useState('departure_flight');
  const deadline = moment(requirement?.deadline).format('DD MMM, YYYY');

  if (!show) return null;


  return (
    <div className="flex justify-center items-center overflow-y-scroll">
      <div className="bg-white shadow-lg rounded-lg w-full pl-6 pr-6">
        <h2 className="text-xl font-semibold mb-2" id="submit_requirement">
          Submit requirement: Arrival/departure info
        </h2>
        <p className="text-sm text-gray-600 mb-4">
          Deadline: <span className="font-semibold">{deadline}</span>
        </p>
        <p className="text-gray-700 mb-6">
          Please provide your arrival and departure flight details so we may
          know your arrival time and arrange transfers.
        </p>

        {/* Arrival Information */}
        <h3 className="font-medium text-lg mb-3">{step === 1 ? 'Arrival' : 'Departure'} information</h3>
        <p className="text-sm text-gray-700 mb-4">
          {step === 1 ? requirement?.extraNotes1 : requirement?.extraNotes2}
        </p>

        {/* Checkbox Section */}
        <div className="col-span-6 flex flex-row bg-white items-center hidden">
          <Checkbox
              control={console}
              register={register}
              name={"avoidInformation"}
              onChange={() => setAvoidInformation({ ...avoidInformation, [step]: !avoidInformation?.[step === 1 ? 1 : 2] })}
              value={avoidInformation?.[step === 1 ? 1 : 2]}
          />
          <span className="text-sm text-gray-700">No {step === 1 ? 'Arrival' : 'Departure'} information is required in this trip.</span>
        </div>

        <div className={`${(step !== 1 || avoidInformation[1]) && 'hidden'}`}>

        <div className="mb-6">
          <TabComponent
                className="flex-grow text-left md:my-6"
                onChange={setTransportation}
                items={[
                    {id: 'arrival_flight', text: "Arrival flight", value: "arrival_flight" },
                    {id: 'other_type_of_transportation', text: "Other Transport", value: "other_type_of_transportation" },
                ]}
                defaultSelected={transportation}
            />
          </div>

        {transportation === 'arrival_flight' && <FlightForm register={register} control={control} setValue={setValue} />}

        {/* Departure Information */}


        {transportation === 'other_type_of_transportation' && <div className="mb-6">
          <TextareaWithValid
              register={register}
              label="Arrival Comments"
              id="arrival_other_type_of_transportation"
              name={`arrival_other_type_of_transportation`}
              onChange={(e: any) => setValue(`arrival_other_type_of_transportation`, e.target.value)}
          />
        </div>}
        </div>

        <div className={`${(step !== 2 || avoidInformation[2]) && 'hidden'}`}>
        
        <div className="mb-6">
          <TabComponent
                className="flex-grow text-left md:my-6"
                onChange={setTransportationDeparture}
                items={[
                    {id: 'departure_flight', text: "Departure flight", value: "departure_flight" },
                    {id: 'departure_other_type_of_transportation', text: "Other Transport", value: "departure_other_type_of_transportation" },
                ]}
                defaultSelected={transportationDeparture}
            />
          </div>

        {transportationDeparture === 'departure_flight' && <FlightForm register={register} control={control} type='departure' />}

        {transportationDeparture === 'departure_other_type_of_transportation' && <div className="mb-6">
          <TextareaWithValid
              register={register}
              label="Departure Comments"
              id="departure_other_type_of_transportation"
              onChange={(e: any) => setValue(`departure_other_type_of_transportation`, e?.target?.value)}
              name={`departure_other_type_of_transportation`}
          />
        </div>}
        
      </div>
      </div>
    </div>
  );
};