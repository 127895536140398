import React, { useRef, useState } from "react";
import Icon from "../../assets/svg/Icon";
import { VALID_FILE_FORMATS } from "../../constants";
import InputErrorLabel from "../InputErrorLabel";
const errorMessage = "Some files are not valid images or are too big.";
const DropFileInput = React.forwardRef(
    ({ onChange, iconSrc, iconClass, className, ...others }: any, ref: any) => {
        let [dragOverlay, setDragOverlay] = useState(false);
        let dragCounter = useRef(0);
    const [error, setError] = useState(false);

        const handleDrag = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const handleDragIn = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current++;
            if (e.dataTransfer.items.length > 0) {
                setDragOverlay(true);
            }
        };
        const handleDragOut = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current--;
            if (dragCounter.current === 0) {
                setDragOverlay(false);
            }
        };
        const handleDrop = (e: any) => {
            const files = e.dataTransfer.files;
            e.preventDefault();
            e.stopPropagation();
            setDragOverlay(false);
            dragCounter.current = 0;

            changeFile({ target: { files } });
        };

        const changeFile = (e: any) => {
            let withError = false;
            const myFiles = Array.prototype.filter.call(
                e.target.files,
                (file: any) => {
                    const { type, size } = file;

                    const validSize = size / 1024 / 1024 < 10;
                    const validFormat = VALID_FILE_FORMATS.includes(type);
                    withError = withError || !validFormat || !validSize
                    return validFormat && validSize;
                }
            );


        setError(withError);

            if (myFiles.length) {
                onChange({ target: { files: myFiles } });
            }
        };

        return (
            <label
                htmlFor="file-upload"
                className={`${
                    dragOverlay ? "bg-gray-300" : ""
                } rounded-xl cursor-pointer`}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <div className="flex flex-col text-left min-h-full items-center justify-center py-4 px-6 text-sm border-orange-600 rounded-xl border border-dashed">
                    <div className="flex pb-2">
                        <div className="mr-6">
                            {iconSrc ? (
                                <img
                                    className={`${iconClass}`}
                                    src={iconSrc}
                                    alt=""
                                />
                            ) : (
                                <Icon
                                    icon="FileOutlineIcon"
                                    className={`${
                                        iconClass ? iconClass : "w-7"
                                    }`}
                                />
                            )}
                        </div>

                        <div className="flex flex-col">
                            <span className="pb-4 font-semibold self-center">
                                <span className="text-orange-600">Browse</span>{" "}
                                or drop your file here.
                            </span>
                            <span className="text-xs text-gray-600">
                                Accepted format: .pdf .doc .xls .pptx .png, .jpg
                            </span>
                            <span className="text-xs text-gray-600">
                                Maximum size: 10MB
                            </span>
                        </div>
                    </div>
                </div>
                <InputErrorLabel
                    className="mb-8"
                    error={error && errorMessage}
                />

                <input
                    onChange={changeFile}
                    ref={ref}
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept={VALID_FILE_FORMATS.join(", ")}
                    className="sr-only hidden"
                    {...others}
                />
            </label>
        );
    }
);
export default DropFileInput;
