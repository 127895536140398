import { useEffect, useState } from "react";
import { ButtonSize } from "../../../constants";
import Button from "../../../components/ButtonSquare";

import TabComponent from "../../../components/TabComponent";
import EditProfile from "../../authentication/profile/EditProfile";
import ProfileDetails from "../../authentication/profile/ProfileDetails";
import DietaryEdit from "../../authentication/profile/Dietary/DietaryEdit";
import DietaryView from "../../authentication/profile/Dietary/DietaryView";
import ProfessionalInfoForm from "../../authentication/profile/Professional/ProfessionalInfoForm";
import ProfessionalInfo from "../../authentication/profile/Professional/ProfessionalInfo";
import { useDispatch } from "react-redux";
import { updateUserData, updateUserProfileData } from "../adminSlice";

const tabItemsIndex = { personalInfo: 0, dietaryReqs: 1, professionalInfo: 2 };
const tabItems = [
    {
        id: tabItemsIndex.personalInfo,
        text: "Personal data",
    },
    {
        id: tabItemsIndex.dietaryReqs,
        text: "Dietary requirements",
    },
    {
        id: tabItemsIndex.professionalInfo,
        text: "Professional information",
    },
];

const UserProfile = ({ profile, onGoBack }: any) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string | number>(
        tabItems[0].id
    );
    const toggleEdit = () => {
        setEditing(!editing);
    };
    const dispatch = useDispatch();

    const changeTab = (tabIndex: string | number) => {
        setSelectedItem(tabIndex);
        setEditing(false);
    };

    const handleUpdateUserProfile = async (fields: any) => {
        try {
            await dispatch(updateUserData(profile.id, fields));
            setEditing(false);
        } catch (_e) {}
    };
    const handleDietaryUpdate = async (fields: any) => {
        try {
            await dispatch(updateUserProfileData(profile.id, fields));
            setEditing(false);
        } catch (_e) {}
    };

    useEffect(() => {
        if (editing || !profile) {
            return;
        }
        if (tabItemsIndex.dietaryReqs !== selectedItem) {
            return;
        }
        if (JSON.stringify(profile?.profileExtra) === "{}") {
            setEditing(true);
        }
    }, [profile?.profileExtra, selectedItem, editing, profile])

    return (
        <>
            <div className="flex flex-col mb-4 flex-wrap">
                {!editing && (
                    <div className="flex flex-row gap-x-4 justify-end">
                        <Button
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                onGoBack && onGoBack();
                            }}
                            outlined
                            label="Go back"
                        />
                        <Button
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                toggleEdit();
                            }}
                            label="Edit"
                        />
                    </div>
                )}
                <TabComponent
                    className="flex-grow text-left md:my-6"
                    onChange={changeTab}
                    items={tabItems}
                    defaultSelected={selectedItem}
                />
            </div>
            {selectedItem === tabItemsIndex.personalInfo &&
                (editing ? (
                    <EditProfile
                        profile={profile}
                        onSubmit={handleUpdateUserProfile}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <ProfileDetails profile={profile} />
                ))}
            {selectedItem === tabItemsIndex.dietaryReqs &&
                (editing ? (
                    <DietaryEdit
                        profileExtra={profile?.profileExtra}
                        onSubmit={handleDietaryUpdate}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <DietaryView profileExtra={profile?.profileExtra} />
                ))}
            {selectedItem === tabItemsIndex.professionalInfo &&
                (editing ? (
                    <ProfessionalInfoForm
                        profile={profile}
                        onSubmit={handleUpdateUserProfile}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <ProfessionalInfo profile={profile} />
                ))}
        </>
    );
};

export default UserProfile;
