export default ({
    width = 28,
    height = 28,
    color = "white",
    ...otherProps
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        width={width}
        height={height}
        {...otherProps}
    >
        <path
            //fill={color}
            d="M14 11.2V16.8H21.9212C20.7648 20.0592 17.6512 22.4 14 22.4C9.3688 22.4 5.6 18.6312 5.6 14C5.6 9.3688 9.3688 5.6 14 5.6C16.0076 5.6 17.9396 6.3196 19.4404 7.6272L23.1196 3.4048C20.5996 1.2096 17.3628 0 14 0C6.2804 0 0 6.2804 0 14C0 21.7196 6.2804 28 14 28C21.7196 28 28 21.7196 28 14V11.2H14Z"
        />
    </svg>
);
