import { useEffect, useMemo, useState } from "react";
import Modal, { Modal_Size } from ".";
import { ButtonSize, PlanType } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { PlanItem } from "../PlanItem/PlanItem";
import { useDispatch, useSelector } from "react-redux";
import {
    getAvailablePlans,
    getPaymentMethods,
} from "../../features/plans/plansSlice";
import Loader from "../Loader";
import _ from "lodash";
import { IPlan } from "../../types/plan.type";
import PaymentSubscriptionWrapper from "./PaymentSubscription";
import { UserTypeVariant } from "../UserType/UserType";

export interface ChoosePlanModalProps {
    open?: boolean;
    handleClose: () => void;
    forceCloseModal: () => void;
    onChangePlan?: (plan?: any) => void;
    handleEvent?: (planId: string) => void;
    title?: string;
    userType: string;
    closeText?: string;
    showDefault?: boolean;
    isFromAdmin?: boolean;
    user?: any;
    showFreePlan?: boolean;
}

export enum VariantPlan {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

export const ChoosePlanModal: React.FunctionComponent<ChoosePlanModalProps> = (
    props
) => {
    const {
        open,
        handleClose,
        title,
        userType,
        closeText,
        showDefault,
        forceCloseModal,
        isFromAdmin,
        user,
        showFreePlan,
    } = props;

    const [activePlan, setActivePlan] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>();
    const [_plan, _setPlan] = useState<any>({});
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const dispatch = useDispatch();
    const currentPlan = useSelector((state: any) => state.plans.currentPlan);

    const storedPlans = useSelector((state: any) => state?.plans?.plans);

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    const fetchPlans = async () => {
        if (open) {
            setIsLoading(true);
            await dispatch(
                getAvailablePlans(
                    PlanType.USER,
                    (userType !== "null" && userType) || undefined
                )
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    const plans: IPlan[] = useMemo(() => {
        let plansArray =
            storedPlans?.filter((p: any) => showDefault || !p.defaultPlan) ||
            [];
        if (showDefault) {
            plansArray = _.sortBy(storedPlans, (a) => !a.defaultPlan);
        }

        plansArray = plansArray.map((plan: IPlan) => {
            const offerData = plan?.data?.offerData;

            if (offerData?.price) {
                return {
                    ...plan,
                    data: {
                        ...plan.data,
                        offerData: {
                            ...offerData,
                            price: offerData.price,
                        },
                    },
                };
            }

            return plan;
        });

        if (!!showFreePlan) {
            plansArray.push(freePlanData);
        }
        return plansArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedPlans, showDefault]);

    const handleChangePlan = () => {
        if (activePlan === "free") {
            forceCloseModal();
            return;
        }
        setShowPaymentModal(true);
    };

    const handleSetPLan = (plan: any) => {
        _setPlan(plan);
        setActivePlan(plan.id);
        setShowPaymentModal(false);
    };

    let modalSize = Modal_Size.lg;
    let gridSize = `lg:grid-cols-3 gap-3`;

    switch (plans?.length) {
        case 1:
            modalSize = Modal_Size.xs;
            gridSize = `lg:grid-cols-1 gap-1`;
            break;
        case 2:
            modalSize = Modal_Size.lg;
            gridSize = `lg:grid-cols-2 gap-2`;
            break;
        default:
            modalSize = Modal_Size.xl;
            break;
    }

    return (
        <div>
            <Modal open={open} hideCloseButton size={modalSize}>
                <div className="p-2">
                    <div className="text-xl text-center font-semibold mb-6">
                        {title || "Choose your plan"}
                    </div>
                    {!!isLoading && (
                        <div className="flex flex-row items-center justify-center w-full">
                            <Loader />
                        </div>
                    )}
                    <div
                        className={`grid grid-cols-1 md:grid-cols-2 justify-center ${gridSize}`}
                    >
                        {!isLoading &&
                            plans?.map((plan: IPlan) => {
                                return (
                                    <PlanItem
                                        lockedItem={
                                            !plan?.defaultPlan &&
                                            !!currentPlan?.subscriptionId &&
                                            !plan?.data?.offerData?.price
                                        }
                                        key={plan?.id}
                                        offerData={plan?.data.offerData}
                                        myPlanData={plan?.data.myPlanData}
                                        checked={activePlan === plan.id}
                                        handleClick={() => handleSetPLan(plan)}
                                    />
                                );
                            })}
                    </div>
                    <div className="flex flex-row gap-4 mt-4 w-full justify-end text-right">
                        {plans[0]?.role !== UserTypeVariant.ADVISOR && (
                            <ButtonSquared
                                outlined
                                label={"Not now"}
                                style={{
                                    border: "none",
                                    justifyContent: "right",
                                    fontWeight: "normal",
                                }}
                                onClick={forceCloseModal}
                                className="p-4"
                            />
                        )}
                        <ButtonSquared
                            outlined
                            label={closeText || "Back"}
                            onClick={handleClose}
                            className="p-4"
                            size={ButtonSize.FIT}
                        />
                        <ButtonSquared
                            disabled={!activePlan && activePlan !== 0}
                            label="Continue"
                            onClick={handleChangePlan}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                hideCloseButton={false}
                size={Modal_Size.md}
            >
                <PaymentSubscriptionWrapper
                    selectedPlan={_plan}
                    handleSetPLan={handleSetPLan}
                    isFromAdmin={isFromAdmin}
                    user={user}
                />
            </Modal>
        </div>
    );
};

const freePlanData = {
    id: "free",
    name: "Free",
    createdAt: "2024-01-10T12:15:18.594Z",
    updatedAt: "2024-01-10T12:15:18.594Z",
    data: {
        offerData: {
            price: 0,
            title1: "Free",
            planInfo:
                "All travel advisors receive a complimentary subscription to FamGuru, today and always.",
            offerText: "",
            description: "",
            recommended: false,
        },
        myPlanData: {
            annual: true,
            userRole: "ADVISOR",
            disclaimers: [
                "*Self-directed Fam trips valid for one member only in free version.",
            ],
            planBenefits: [
                "Accept Fam invitations from Suppliers",
                "Set up your own self-directed Fam Trips*",
                "Take notes",
                "Store your best photos and videos",
                "Share insights with clients and colleagues",
                "Keep your Fam Trip Archive at your fingertips forever",
            ],
            planConditions: [],
            subscriptionDescription: "Free",
        },
    },
    role: "ADVISOR",
    showByDefault: true,
    type: "USER",
    defaultPlan: false,
};
