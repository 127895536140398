import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Icon from "../../../assets/svg/Icon";
import Avatar from "../../../components/Avatar";
import DotsMenu from "../../../components/DotsMenu";
import { UsersDotAdminMenuOptions, UsersDotMenuKeys, UsersDotUserMenuOptions } from "../../../constants/admin";
import { ChoosePlanModal } from "../../../components/Modal/ChoosePlanModal";
import { UserTypeVariant } from "../../../components/UserType/UserType";
import { addSubscriptionForAdmin, extendSubscriptionToUser } from "../adminSlice";
import { PlanModalExtend } from "../../../components/Modal/PlanModalExtend";
import { UserStatusEnum } from "../../plans/constants";

interface UserItemProps {
    user: any;
    handleClickDotsMenu: Function;
    getData: Function;
}

export const UserItem = (props: UserItemProps) => {
    const { user, handleClickDotsMenu, getData } = props;
    const dispatch = useDispatch();
    const [showChangePlan, setShowChangePlan] = useState<boolean>(false);
    const [showExtendPlan, setShowExtendPlan] = useState<boolean>(false);

    const userTypeLabel = useMemo(() => {
        return user?.userType
            ? user?.userType === UserTypeVariant.SUPPLIER
                ? "Supplier"
                : "Advisor"
            : "No user type defined";
    }, [user]);

    let options = user.isAdmin ? UsersDotAdminMenuOptions : UsersDotUserMenuOptions;

    if (!user?.planName) {
        options = options.filter((option: any) => option.key !== UsersDotMenuKeys.extendSubscription);
    }

    if (user?.status !== UserStatusEnum.DELETED) {
        options = options.filter((option: any) => option.key !== UsersDotMenuKeys.activateUser);
    }

    const handleClickMenu = (key: string) => {
        switch (key) {
            case UsersDotMenuKeys.addSubscription:
                setShowChangePlan(true);
                return;
            case UsersDotMenuKeys.extendSubscription:
                setShowExtendPlan(true);
                return;
            default:
                handleClickDotsMenu(key, user);
                break;
        }
    };

    const handleChooseChangePlan = (planId: string) => {
        dispatch(addSubscriptionForAdmin(user.id, planId, getData));
    };

    const handleCloseChangePlan = () => {
        setShowChangePlan(false);
    };


    const handleCloseExtendPlan = () => {
        setShowExtendPlan(false);
    };

    const handleExtendPlan = (days: number) => {
        dispatch(extendSubscriptionToUser(user.stripeSubscriptionId, days, user?.id, getData));
    };

    return (
        <div
            key={`allUserItemList_${user?.id}`}
            className="py-4 border-b border-b-gray-300"
        >
            {showChangePlan && <ChoosePlanModal
                open={showChangePlan}
                handleClose={handleCloseChangePlan}
                handleEvent={handleChooseChangePlan}
                title={`Choose a plan for ${user?.fullName}`}
                userType={`${user?.userType}`}
                forceCloseModal={() => setShowChangePlan(false)}
            />}
            <PlanModalExtend
                open={showExtendPlan}
                handleClose={handleCloseExtendPlan}
                handleExtend={handleExtendPlan}
                userFullName={user?.fullName}
            />
            <div className="text-left flex justify-between">
                <div className="text-gray-600">
                    <div className="flex gap-2 items-center">
                        <Avatar
                            src={
                                user?.avatar_thumb?.length
                                    ? user?.avatar_thumb
                                    : user.user?.avatar
                            }
                            className="w-10 h-10"
                        />

                        <div className="flex flex-col">
                            <div className="flex flex-row items-center gap-2">
                                {user?.isAdmin && (
                                    <div className="">
                                        <Icon
                                            icon="CrownIcon"
                                            className="text-blue-500 w-7"
                                        />
                                    </div>
                                )}
                                <div
                                    className="w-full text-left"
                                    title={`${user?.fullName}`}
                                >
                                    {user?.fullName}
                                </div>
                            </div>
                            <div className="text-xs font-bold">
                                {userTypeLabel}
                            </div>
                        </div>
                    </div>
                </div>
                <DotsMenu
                    options={options}
                    handleClickMenu={handleClickMenu}
                />
            </div>
            <div className="text-gray-400 text-left flex justify-between">
                <div className="flex flex-row gap-2 items-center pt-2">
                    <div>
                        <Icon
                            icon={"EmailIcon"}
                            className="text-orange-600 w-4 h-4"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-4">
                        <div className="flex flex-row flex-wrap items-baseline gap-x-2">
                            <div className="text-xs font-semibold">
                                {user?.email}
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-baseline gap-x-2">
                            <div className="text-xs font-semibold">
                                User status:
                            </div>
                            <div>{user?.status || UserStatusEnum.ACTIVE}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-gray-400 text-left flex justify-between">
                <div className="flex flex-row gap-2 items-center pt-2">
                    <div>
                        <Icon
                            icon={"CalendarIcon"}
                            className="text-orange-600 w-4 h-4"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-4">
                        <div className="flex flex-row flex-wrap items-baseline gap-x-2">
                            <div className="text-xs font-semibold">
                                Joined on:
                            </div>
                            <div className="max-w-[150px]">
                                {user?.createdAtFormatted}
                            </div>
                        </div>

                        {user?.lastActiveFormatted && (
                            <div className="flex flex-row flex-wrap items-baseline gap-x-2">
                                <div className="text-xs font-semibold">
                                    Last active:
                                </div>
                                <div className="max-w-[150px]">
                                    {user?.lastActiveFormatted}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="text-gray-400 text-left flex items-center">
                <div className="flex flex-row gap-2 items-center pt-2">
                    <div>
                        <Icon
                            icon={"PaymentIcon"}
                            className="w-4 h-4 fill-orange-600"
                        />
                    </div>

                    <div className="flex flex-row flex-wrap items-baseline gap-x-2">
                        <div className="text-xs font-semibold">
                            Plan status:
                        </div>
                        <div>{user?.planStatus || "-"}</div>
                        <div className="text-xs font-semibold">
                            Plan Name:
                        </div>
                        <div>{user?.planName || "-"}</div>
                        <div className="text-xs font-semibold">
                            Current Period End:
                        </div>
                        <div>{user?.currentPeriodEnds || "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};