import { Route } from "react-router-dom";
import RequireAuth from "../hooks/RequiresAuth";
import TripDetail from "../features/trips/Trip/TripDetail";
import TripsList from "../features/trips/TripsList";
import TripUsers from "../features/trips/Trip/TripUsers";
import TripMessages from "../features/trips/Trip/TripMessages";
import TripHome from "../features/trips/Trip";
import TripFile from "../features/trips/Trip/TripFiles/File";
import TripFilesList from "../features/trips/Trip/TripFiles";
import TripRequirementForm from "../features/trips/Trip/TripRequirements/RequirementForm";
import TripRequirementsList from "../features/trips/Trip/TripRequirements";
import TripItinerary from "../features/trips/Trip/TripItinerary";
import ActivityDetails from "../features/trips/Trip/TripItinerary/Activity/ActivityDetails";
import TripImagesGallery from "../features/trips/Trip/TripImagesGallery";
import AddImageToTrip from "../features/trips/Trip/TripImagesGallery/AddImage";
import LocalPartnerTripForm from "../features/trips/Trip/LocalPartners/LocalPartnerTripForm";
import LocalPartnerDetails from "../features/trips/Trip/LocalPartners/LocalPartnerDetails";
import LocalPartnersOnTripList from "../features/trips/Trip/LocalPartners";
import ParticipantProfile from "../features/trips/Trip/TripUsers/Participant/ParticipantProfile";
import TripNotifications from "../features/trips/Trip/TripNotifications";
import NonRegisteredUser from "../features/trips/Trip/TripUsers/Participant/NonRegisteredUser";
import DuplicateTripForm from "../features/trips/DuplicateTripForm";
import TripCustomInvite from "../features/trips/Trip/CustomInvite";
import TripCustomInviteForm from "../features/trips/Trip/CustomInvite/CustomInviteForm";
import TripCustomInviteDetail from "../features/trips/Trip/CustomInvite/CustomInviteDetail";
import Activity from "../features/trips/Trip/TripItinerary/Activity";
import ActivityForm from "../features/trips/Trip/TripItinerary/Activity/EditActivityForm";
import NewActivityForm from "../features/trips/Trip/TripItinerary/Activity/NewActivityForm";
import CreateTripForm from "../features/trips/TripForm";
import ContactInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Contact/ContactInsightReview";
import WellnessInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Wellness/WellnessInsightReview";
import CommonInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Common/CommonInsightReview";
import NotesInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Notes/NotesInsightReview";
import RoomInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Room/RoomInsightReview";
import TripRequirementHome from "../features/trips/Trip/TripRequirements/TripRequirement";
import TripRequirement from "../features/trips/Trip/TripRequirements/TripRequirement/TripRequirement";
import DinningInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Hotel/Dinning/DinningInsightReview";
import AddInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight";
import RestaurantNotesInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Restaurant/Notes/RestaurantNotesInsightReview";
import RestaurantContactInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Restaurant/Contact/RestaurantContactInsightReview";
import RestaurantExperienceInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Restaurant/Experience/RestaurantExperienceInsightReview";
import OtherDetailsInsightReview from "../features/trips/Trip/TripItinerary/Activity/Insight/Other/Notes/OtherNotesInsightReview";
import TripChat from "../features/trips/Trip/TripChat";

export const TripsRoutes = (
    <Route path="/trips">
        <Route
            path=""
            element={
                <RequireAuth protectedRoute={true}>
                    <TripsList />
                </RequireAuth>
            }
        />
        <Route
            path="new"
            element={
                <RequireAuth protectedRoute={true}>
                    <CreateTripForm />
                </RequireAuth>
            }
        />
    </Route>
);

export const TripsEditRoutes = (
    <Route path="/trips/:tripId" element={<TripHome />}>
        <Route
            path=""
            element={
                <RequireAuth protectedRoute={true}>
                    <TripDetail />
                </RequireAuth>
            }
        />
        <Route
            path="invites"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripCustomInvite />
                </RequireAuth>
            }
        >
            <Route
                path=""
                element={
                    <RequireAuth protectedRoute={true}>
                        <TripCustomInviteDetail />
                    </RequireAuth>
                }
            />
            <Route
                path="edit"
                element={
                    <RequireAuth protectedRoute={true}>
                        <TripCustomInviteForm />
                    </RequireAuth>
                }
            />
        </Route>

        <Route
            path="detail"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripDetail />
                </RequireAuth>
            }
        />
        <Route
            path="users"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripUsers />
                </RequireAuth>
            }
        />
        <Route
            path="messages"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripMessages />
                </RequireAuth>
            }
        />
        <Route
            path="users/:userId"
            element={
                <RequireAuth protectedRoute={true}>
                    <ParticipantProfile />
                </RequireAuth>
            }
        />
        <Route
            path="nonuser/:userEmail"
            element={
                <RequireAuth protectedRoute={true}>
                    <NonRegisteredUser />
                </RequireAuth>
            }
        />
        <Route
            path="edit"
            element={
                <RequireAuth protectedRoute={true}>
                    <CreateTripForm />
                </RequireAuth>
            }
        />
        <Route
            path="duplicate"
            element={
                <RequireAuth protectedRoute={true}>
                    <DuplicateTripForm />
                </RequireAuth>
            }
        />
        <Route
            path="files"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripFilesList />
                </RequireAuth>
            }
        />
        <Route
            path="files/:fileId"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripFile />
                </RequireAuth>
            }
        />
        <Route
            path="files/new"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripFile />
                </RequireAuth>
            }
        />
        <Route
            path="images"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripImagesGallery />
                </RequireAuth>
            }
        />
        <Route
            path="images/new"
            element={
                <RequireAuth protectedRoute={true}>
                    <AddImageToTrip />
                </RequireAuth>
            }
        />

        <Route
            path="itinerary"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripItinerary />
                </RequireAuth>
            }
        />
        <Route path="itinerary/:activityId" element={<Activity />}>
            <Route
                path=""
                element={
                    <RequireAuth protectedRoute={true}>
                        <ActivityDetails />
                    </RequireAuth>
                }
            />
            <Route
                path="add-private-hotel-insight"
                element={<AddInsightReview />}
            >
                <Route
                    path="details"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <NotesInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="contacts"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <ContactInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="rooms"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <RoomInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="dining-drinking"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <DinningInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="wellness"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <WellnessInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="common-areas"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <CommonInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path=""
                    element={
                        <RequireAuth protectedRoute={true}>
                            <NotesInsightReview />
                        </RequireAuth>
                    }
                />
            </Route>
            <Route
                path="add-private-restaurant-insight"
                element={<AddInsightReview />}
            >
                <Route
                    path=""
                    element={
                        <RequireAuth protectedRoute={true}>
                            <RestaurantNotesInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="contacts"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <RestaurantContactInsightReview />
                        </RequireAuth>
                    }
                />
                <Route
                    path="experience"
                    element={
                        <RequireAuth protectedRoute={true}>
                            <RestaurantExperienceInsightReview />
                        </RequireAuth>
                    }
                />
            </Route>
            <Route
                path="add-private-other-insight"
                element={<AddInsightReview />}
            >
                <Route
                    path=""
                    element={
                        <RequireAuth protectedRoute={true}>
                            <OtherDetailsInsightReview />
                        </RequireAuth>
                    }
                />
            </Route>
            <Route
                path="edit"
                element={
                    <RequireAuth protectedRoute={true}>
                        <ActivityForm />
                    </RequireAuth>
                }
            />
        </Route>
        <Route
            path="itinerary/new/:activityType"
            element={
                <RequireAuth protectedRoute={true}>
                    <NewActivityForm />
                </RequireAuth>
            }
        />
        <Route
            path="requirements"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripRequirementsList />
                </RequireAuth>
            }
        />
        <Route
            path="requirements/new"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripRequirementForm />
                </RequireAuth>
            }
        />

        <Route
            path="requirements/new/:requirementType"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripRequirementForm />
                </RequireAuth>
            }
        />

        <Route
            path="requirements/:requirementId"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripRequirementHome />
                </RequireAuth>
            }
        >
            <Route
                path=""
                element={
                    <RequireAuth protectedRoute={true}>
                        <TripRequirement />
                    </RequireAuth>
                }
            />
            <Route
                path="edit"
                element={
                    <RequireAuth protectedRoute={true}>
                        <TripRequirementForm />
                    </RequireAuth>
                }
            />
            <Route
                path="edit/:requirementType"
                element={
                    <RequireAuth protectedRoute={true}>
                        <TripRequirementForm />
                    </RequireAuth>
                }
            />
        </Route>

        <Route
            path="local-partners"
            element={
                <RequireAuth protectedRoute={true}>
                    <LocalPartnersOnTripList />
                </RequireAuth>
            }
        />
        <Route
            path="local-partners/new"
            element={
                <RequireAuth protectedRoute={true}>
                    <LocalPartnerTripForm />
                </RequireAuth>
            }
        />
        <Route
            path="local-partners/:localPartnerId"
            element={
                <RequireAuth protectedRoute={true}>
                    <LocalPartnerDetails />
                </RequireAuth>
            }
        />
        <Route
            path="notifications"
            element={
                <RequireAuth protectedRoute={true}>
                    <TripNotifications />
                </RequireAuth>
            }
        />
        <Route
            path="chat" 
            element={
                <RequireAuth protectedRoute={true}>
                    <TripChat />
                </RequireAuth>
            }
        />
    </Route>
);
