import { useState } from "react";
import Icon from "../../assets/svg/Icon";
import Modal from "../Modal";
import ModalConfirmation from "../Modal/ConfirmationModal";
import DotsMenu from "../DotsMenu";
import { Paragraph } from "../Paragraph/Paragraph";
import { mdiChevronUp } from "@mdi/js";

interface GalleryMediaProps {
    src: string;
    type: "image" | "video";
    thumb?: string;
    onMediaClick: () => void;
    onMediaRemove?: () => void;
    viewOnly?: boolean;
    mediaClass?: string;
    containerClass?: string;
    description?: string;
    threeDots?: any[];
    handleDotsMenu?: (key: string) => void;
}

const GalleryMedia: React.FC<GalleryMediaProps> = ({
    src,
    type,
    onMediaClick,
    onMediaRemove,
    viewOnly,
    mediaClass,
    thumb,
    containerClass,
    threeDots,
    handleDotsMenu,
    description,
    ...others
}) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [mediaModalOpen, setMediaModalOpen] = useState(false);
    const [showWholeDescription, setShowWholeDescription] = useState(false);

    return (
        <>
            <Modal
                open={mediaModalOpen}
                size={"w-auto m-6"}
                translucid
                hideCloseButton
                onClose={() => setMediaModalOpen(false)}
                closeOnBackClick
            >
                <div>
                    <div className="relative w-fit mx-auto">
                        <button
                            onClick={() => setMediaModalOpen(false)}
                            className="absolute cursor-pointer z-10 top-6 right-[1rem] bg-neutral-400/70 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                        >
                            <Icon icon="CloseIcon" className="w-7 z-10" />
                        </button>

                        <a
                            className="absolute cursor-pointer z-10 top-6 right-[4rem] bg-neutral-400/70 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                            href={src}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            <Icon
                                icon="DownloadIcon"
                                className="w-7 cursor-pointer z-10"
                            />
                        </a>

                        {type === "video" ? (
                            <video
                                className="rounded-xl min-h-auto lg:min-h-[500px] max-h-[90vh]"
                                src={src}
                                controls
                            />
                        ) : (
                            <img
                                className="rounded-xl min-h-auto lg:min-h-[500px] max-h-[90vh]"
                                src={src}
                                alt=""
                            />
                        )}
                        <div className="absolute bottom-0 left-0 right-0">
                            {!!description?.length && (
                                <div className="z-10 bg-neutral-400/70 text-white rounded-lg text-xs lg:text-lg p-4 mx-4 mb-4 flex flex-col justify-between">
                                    {!showWholeDescription ? (
                                        <span
                                            onClick={() => setShowWholeDescription(true)}
                                            className="truncate text-xs lg:text-base cursor-pointer"
                                        >
                                            {description}
                                        </span>
                                    ) : (
                                        <div className="flex flex-col">
                                            <Paragraph className="text-xs lg:text-base">
                                                {description}
                                            </Paragraph>
                                            <div className="flex flex-row items-end justify-end">
                                                <div
                                                    onClick={() => setShowWholeDescription(false)}
                                                >
                                                    <Icon
                                                        materialIcon={mdiChevronUp}
                                                        className="w-7 text-white cursor-pointer"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to delete this ${type}.`}
                handleCancel={() => setDeleteModalOpen(false)}
                handleSubmit={() => {
                    onMediaRemove && onMediaRemove();
                    setDeleteModalOpen(false);
                }}
            />
            <div
                className={`relative ${
                    viewOnly ? "cursor-pointer hover:scale-105" : ""
                } ${containerClass}`}
            >
                {!viewOnly && threeDots && handleDotsMenu && (
                    <div className="absolute top-2 right-2 bg-neutral-400/50 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg cursor-pointer z-50">
                        <DotsMenu
                            className="text-white hover:text-white"
                            options={threeDots}
                            handleClickMenu={(k: any) => {
                                if (k === "DELETE") {
                                    setDeleteModalOpen(true);
                                } else {
                                    handleDotsMenu(k);
                                }
                            }}
                        />
                    </div>
                )}
                {!viewOnly && !threeDots && (
                    <div
                        onClick={() => setDeleteModalOpen(true)}
                        className="absolute top-2 right-2 bg-neutral-400/50 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        <Icon
                            icon="TrashIcon"
                            className="w-7 cursor-pointer z-10"
                        />
                    </div>
                )}
                {type === "video" && viewOnly ? (
                    <video
                        src={thumb ? thumb : src}
                        className={`rounded-md h-24 w-24 ${mediaClass} object-contain`}
                        onClick={
                            viewOnly
                                ? () => setMediaModalOpen(true)
                                : onMediaClick
                        }
                        controls={false}
                    />
                ) : (
                    <img
                        alt=""
                        src={thumb ? thumb : src}
                        className={`rounded-md h-24 w-24 ${mediaClass} object-contain`}
                        onClick={
                            viewOnly
                                ? () => setMediaModalOpen(true)
                                : onMediaClick
                        }
                    />
                )}
            </div>
        </>
    );
};

export default GalleryMedia;
