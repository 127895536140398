import Table from "../../components/Table";
import { ITransfers } from "../../types/plan.type";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import StatusBadgeComponent from "../../components/StatusBadge/StatusBadgeComponent";
import { PAGE_ROWS } from "../../constants";
import Paginator from "../../components/Paginator";

export default function TableDetails() {
    const { paymentIntents, isLoading } = useSelector(
        (state: RootState) => state.plans
    );

    const tableHeaders = [
        "Date",
        "plan name",
        "invoice code",
        "price",
        "status",
    ];

    const handlePaginatorChange = (page: number) => {};

    return (
        <>
            <Table headers={tableHeaders} isLoading={isLoading}>
                {paymentIntents.data?.map((review: ITransfers) => (
                    <tr key={review?.invoiceCode}>
                        <td className="text-left ">{review?.createAt}</td>
                        <td className="text-left max-w-[200px] truncate">
                            {review?.planName}
                        </td>
                        <td className="text-left max-w-[200px] truncate">
                            {review?.invoiceCode}
                        </td>
                        <td className="text-left">${review?.price}</td>
                        <td className="text-left">
                            <div className="flex items-center">
                                {review?.status && <StatusBadgeComponent
                                    status={review?.status?.toUpperCase() as any}
                                    customText={review?.status}
                                    size="sm"
                                />}
                            </div>
                        </td>
                    </tr>
                ))}
            </Table>
            {paymentIntents?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={paymentIntents?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={paymentIntents?.totalPages}
                        currentPage={paymentIntents?.currentPage}
                    />
                </div>
            )}
        </>
    );
}
