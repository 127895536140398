import React, { FC, InputHTMLAttributes, useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "./DatePickerMaterial";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    className?: string;
    minDate?: Date;
    maxDate?: Date;
    control?: any;
    trigger?: any;
    markRequired?: boolean;
}

const OthersAutocompleteWithValid: FC<InputProps> = ({
    name,
    error,
    label,
    control,
    onChange,
    minDate,
    maxDate,
    ...rest
}) => {
    const handleChange = (other: any, handleOnChangeValidator: Function) => {
        if (onChange) onChange(other);
        if (handleOnChangeValidator) handleOnChangeValidator(other);
    };
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, ref, value } }: any) => (
                <DatePicker
                    name={name}
                    label={label}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(a: any) => {
                        handleChange(a, onChange);
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    {...rest}
                    value={value}
                    error={error}
                />
            )}
        />
    );
};

export default OthersAutocompleteWithValid;
