import { useEffect, useState } from "react";
import moment from "moment";
import DatePickerMaterial from "../../../../../../components/DatePickerMaterial";
import Input from "../../../../../../components/Input";
import TimePickerMaterial from "../../../../../../components/TimePickerMaterial";
import TextAreaInput from "../../../../../../components/TextArea/TextArea";
import { normalizeFlightData } from "../../../../../../helpers";
import server from "../../../../../../api/server";
import FlightLinesAutocompleteWithValid from "../../../../../../components/FlightLinesAutocomplete";
import AirportsAutocompleteWithValid from "../../../../../../components/AirportsAutocomplete";
import Loader from "../../../../../../components/Loader";

export const FlightForm = ({
    register,
    control,
    setValue,
    errors,
    getValues,
    watch,
    type = "arrival",
    reset,
}: any) => {
    const airlineWatch = watch(`${type}_airline`);
    const departureairportWatch = watch(`${type}_departureAirport`);
    const arrivalairportWatch = watch(`${type}_arrivalAirport`);
    const showAllInputs = watch(`${type}_showAllInputs`);
    const enterManually = watch(`${type}_enterManually`);
    const [loadingFlight, setLoadingFlight] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const departureTime = watch(`${type}_departureTime`);
    const arrivalTime = watch(`${type}_arrivalTime`);

    useEffect(() => {
        setValue(`${type}_departureDate`, moment().format("YYYY-MM-DD"));
        setValue(`${type}_departureTime`, "10:00");
        setValue(`${type}_arrivalAirport`, undefined);
        setValue(`${type}_arrivalDate`, undefined);
        setValue(`${type}_arrivalTime`, "10:00");
        setValue(`${type}_currentFlight`, undefined);
        setValue(`${type}_showAllInputs`, false);
    }, []);

    const getFlightInfo = async () => {
        if (enterManually) {
            return;
        }
        const {
            [`${type}_airline`]: airline,
            [`${type}_flightNumber`]: flightNumber,
            [`${type}_departureAirport`]: departureAirport,
            [`${type}_departureDate`]: departureDate,
        } = getValues();
        const isValid =
            airline?.length &&
            flightNumber?.length &&
            departureAirport?.length &&
            moment(departureDate).isValid();
        if (isValid) {
            try {
                setHasSearched(true);
                setLoadingFlight(true);
                const response = await server.getFlightsByNumber(
                    airline,
                    flightNumber,
                    departureAirport
                );
                onSelectFlight(response.data?.data[0]);
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingFlight(false);
            }
        }
    };

    const onSelectFlight = (flight: any) => {
        if (flight) {
            const { [`${type}_departureDate`]: departureDate } = getValues();
            const flightData = normalizeFlightData(flight, departureDate);
            setValue(`${type}_showAllInputs`, true);
            setValue(`${type}_departureTime`, flightData.departureTime);
            setValue(`${type}_arrivalAirport`, flightData.arrivalAirport);
            setValue(`${type}_arrivalDate`, flightData.arrivalDate);
            setValue(`${type}_arrivalTime`, flightData.arrivalTime);
            setValue(`${type}_currentFlight`, flight);
        } else {
            setValue(`${type}_departureTime`, "10:00");
            setValue(`${type}_arrivalAirport`, undefined);
            setValue(`${type}_arrivalDate`, undefined);
            setValue(`${type}_arrivalTime`, "10:00");
            setValue(`${type}_currentFlight`, undefined);
            setValue(`${type}_showAllInputs`, false);
        }
    };

    return !airlineWatch && !showAllInputs && !enterManually ? (
        <div className="mb-8">
            <div>
                <FlightLinesAutocompleteWithValid
                    label="Airline"
                    name={`${type}_airline`}
                    error={errors[`${type}_airline`]?.message}
                    control={control}
                    currentValue={airlineWatch}
                    onChange={(a: any) => {
                        setValue(`${type}_airline`, a);
                        getFlightInfo();
                    }}
                />
            </div>
            <span>
                Or{" "}
                <span
                    className="text-orange-600 cursor-pointer font-medium"
                    onClick={() => setValue(`${type}_enterManually`, true)}
                >
                    Enter manually
                </span>
            </span>
        </div>
    ) : (
        <div className="mb-8">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <FlightLinesAutocompleteWithValid
                        label="Airline"
                        name={`${type}_airline`}
                        error={errors[`${type}_airline`]?.message}
                        control={control}
                        currentValue={airlineWatch}
                        onChange={(a: any) => {
                            setValue(`${type}_airline`, a);
                            getFlightInfo();
                        }}
                    />
                </div>
                <div>
                    <Input
                        markRequired
                        label="Flight #"
                        type="text"
                        register={register}
                        name={`${type}_flightNumber`}
                        error={errors[`${type}_flightNumber`]?.message}
                        onChange={(e: any) => {
                            setValue(`${type}_flightNumber`, e.target.value);
                            getFlightInfo();
                        }}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <AirportsAutocompleteWithValid
                        label="Departure airport"
                        name={`${type}_departureAirport`}
                        error={errors[`${type}_departureAirport`]?.message}
                        currentValue={departureairportWatch}
                        control={control}
                        onChange={(a: any) => {
                            setValue(`${type}_departureAirport`, a);
                            getFlightInfo();
                        }}
                    />
                </div>
                <div>
                    <DatePickerMaterial
                        markRequired
                        name={`${type}_departureDate`}
                        id="departureDate"
                        label="Departure date"
                        register={register}
                        control={control}
                        onChange={(value: any) => {
                            setValue &&
                                setValue(
                                    `${type}_departureDate`,
                                    value.format("YYYY-MM-DD")
                                );
                            getFlightInfo();
                        }}
                        error={errors[`${type}_departureDate`]?.message}
                    />
                </div>
            </div>
            {!!loadingFlight && (
                <div className="flex flex-row items-center justify-center w-full my-4">
                    <Loader />
                </div>
            )}
            {!loadingFlight &&
                !showAllInputs &&
                !enterManually &&
                hasSearched && (
                    <div>
                        We coudn't find any results.{" "}
                        <span
                            className="cursor-pointer text-medium text-orange-600"
                            onClick={() =>
                                setValue(`${type}_enterManually`, true)
                            }
                        >
                            Enter manually
                        </span>
                    </div>
                )}
            {(!!showAllInputs || !!enterManually) && !loadingFlight && (
                <>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <TimePickerMaterial
                                markRequired
                                label="Departure time"
                                defaultValue={departureTime}
                                control={control}
                                id="departureTime"
                                name={`${type}_departureTime`}
                                onChange={(value: any) => {
                                    setValue &&
                                        setValue(
                                            `${type}_departureTime`,
                                            value.format("HH:mm")
                                        );
                                }}
                                error={errors[`${type}_departureTime`]?.message}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <AirportsAutocompleteWithValid
                                label="Arrival airport"
                                name={`${type}_arrivalAirport`}
                                currentValue={arrivalairportWatch}
                                error={
                                    errors[`${type}_arrivalAirport`]?.message
                                }
                                control={control}
                                onChange={(a: any) =>
                                    setValue(`${type}_arrivalAirport`, a)
                                }
                            />
                        </div>
                        <div>
                            <DatePickerMaterial
                                markRequired
                                name={`${type}_arrivalDate`}
                                id="arrivalDate"
                                label="Arrival date"
                                register={register}
                                control={control}
                                onChange={(value: any) =>
                                    setValue &&
                                    setValue(
                                        `${type}_arrivalDate`,
                                        value.format("YYYY-MM-DD")
                                    )
                                }
                                error={errors[`${type}_arrivalDate`]?.message}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <TimePickerMaterial
                                markRequired
                                label="Arrival time"
                                control={control}
                                id="arrivalTime"
                                defaultValue={arrivalTime}
                                name={`${type}_arrivalTime`}
                                onChange={(value: any) => {
                                    setValue &&
                                        setValue(
                                            `${type}_arrivalTime`,
                                            value.format("HH:mm")
                                        );
                                }}
                                error={errors[`${type}_arrivalTime`]?.message}
                            />
                        </div>
                    </div>

                    {/* Comments Section */}
                    {type === "departure" && (
                        <div>
                            <TextAreaInput
                                register={register}
                                label="Arrival/departure comments"
                                id="reply"
                                name={`${type}_comments`}
                                onChange={(e: any) =>
                                    setValue &&
                                    setValue(`${type}_comments`, e.target.value)
                                }
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
