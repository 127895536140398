import { useState, ChangeEvent, useEffect, useRef, useMemo } from "react";
import Icon from "../../../assets/svg/Icon";
import { ChatForm } from "../type/chatFormType";
import FileInputButton from "./FileInputButton";
import FilePreview from "./FilePreview";
import { useChatForm } from "../hooks/useChat.hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { chatMessageSchema } from "../schema/schema";
import { VALID_FILE_FORMATS, VALID_IMAGES_FORMATS } from "../../../constants";

const ChatInput = () => {
    const {
        selectedFiles,
        handleFileSelection,
        removeSelectedFile,
        onSendMessage,
    } = useChatForm();
    const [showDropdown, setShowDropdown] = useState(false);
    const [messageContent, setMessageContent] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const [fileInputKey, setFileInputKey] = useState(0);

    const {
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<ChatForm>({
        resolver: yupResolver(chatMessageSchema),
        defaultValues: {
            content: "",
            files: [],
        },
    });
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSendMessage = async (data: ChatForm) => {
        try {
            setIsLoading(true);

            await onSendMessage(data);

            setMessageContent("");
            handleFileSelection([]);
            setValue("content", "");
            setValue("files", []);

            setFileInputKey((prevKey) => prevKey + 1);
        } catch (error) {
            console.error("Error in handleSendMessage:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const newFiles = (selectedFiles || []).concat(
                Array.from(e.target.files)
            );
            handleFileSelection(newFiles);
            setShowDropdown(false);
            setValue("files", newFiles);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderFiles = useMemo(() => {
        const groupSize = 8;
        const groupedFiles = [];

        for (let i = 0; i < selectedFiles.length; i += groupSize) {
            groupedFiles.push(selectedFiles.slice(i, i + groupSize));
        }

        return groupedFiles.map((group, index) => {
            const groupKey = group.reduce(
                (acc, file) => `${acc}-${file.name}-${file.size}`,
                `${index}`
            );

            return (
                <div className="mt-2 ml-2" key={groupKey}>
                    <FilePreview
                        files={group}
                        removeFile={removeSelectedFile}
                        isLoading={isLoading}
                    />
                </div>
            );
        });
    }, [selectedFiles, removeSelectedFile, isLoading]);

    return (
        <form
            autoComplete="off"
            action="#"
            method="POST"
            onSubmit={handleSubmit((data) =>
                handleSendMessage({
                    ...data,
                    content: messageContent,
                    files: selectedFiles,
                })
            )}
            className="flex flex-col w-full mt-auto"
        >
            <div className="flex relative justify-between items-end">
                <div className="flex relative w-[100%]">
                    <div
                        ref={dropdownRef}
                        className="flex items-end"
                        style={{ zIndex: 10 }}
                    >
                        <FileInputButton
                            showDropdown={showDropdown}
                            toggleDropdown={toggleDropdown}
                            disabled={isLoading}
                            onPhotoInputClick={() =>
                                document.getElementById("imgInput")?.click()
                            }
                            onVideoInputClick={() =>
                                document.getElementById("videoInput")?.click()
                            }
                            onFileInputClick={() =>
                                document.getElementById("fileInput")?.click()
                            }
                        />
                    </div>
                    <div className="w-[100%] border border-gray-300 rounded-lg ml-2">
                        {renderFiles}
                        <input
                            type="file"
                            id="imgInput"
                            multiple
                            accept={VALID_IMAGES_FORMATS.join(", ")}
                            style={{ display: "none" }}
                            key={fileInputKey}
                            onChange={handleFileChange}
                            disabled={isLoading}
                        />
                        <input
                            type="file"
                            id="videoInput"
                            multiple
                            accept="video/mp4"
                            style={{ display: "none" }}
                            key={fileInputKey + 1}
                            onChange={handleFileChange}
                            disabled={isLoading}
                        />
                        <input
                            type="file"
                            id="fileInput"
                            multiple
                            accept={VALID_FILE_FORMATS.join(", ")}
                            style={{ display: "none" }}
                            key={fileInputKey + 1}
                            onChange={handleFileChange}
                            disabled={isLoading}
                        />

                        <div className="flex-grow p-2 pl-3 pr-10 relative flex items-center overflow-hidden">
                            <input
                                type="text"
                                value={messageContent}
                                onChange={(e) => {
                                    setMessageContent(e.target.value);
                                    setValue("content", e.target.value);
                                }}
                                className="flex-grow border-none focus:outline-none"
                                placeholder="iMessage"
                            />
                            <button
                                type="submit"
                                className="absolute right-0 top-0 mt-2 mr-2"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div className="animate-spin w-6 h-6 border-4 border-orange-500 border-t-transparent rounded-full"></div>
                                ) : (
                                    <Icon
                                        icon="SendArrowIcon"
                                        className={`w-6 h-6 m-0 ${
                                            messageContent ||
                                            selectedFiles.length > 0
                                                ? "fill-orange-500 text-orange-500"
                                                : "fill-gray-400 text-gray-400"
                                        }`}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ChatInput;
