import { mdiFilePlus } from '@mdi/js';
import { Icon as MDIcon } from "@mdi/react";

const FileAddIcon = ({
  width = 20,
  height = 20,
  color = "black",
  ...otherProps
}) => (
  <MDIcon path={mdiFilePlus} {...otherProps}/>
);
export default FileAddIcon
