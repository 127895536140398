import { mdiDownloadOutline } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";

const DownloadIcon = ({
    width = 20,
    height = 20,
    color = "black",
    ...otherProps
}) => (
    <MDIcon path={mdiDownloadOutline} {...otherProps} />
);

export default DownloadIcon;
