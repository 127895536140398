import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../../components/ButtonSquare";
import { CheckboxGroup } from "./CheckboxGroup";
import CheckBox from "../../../../components/CheckBox";

export const dietarySchema = yup.object().shape({
    // Dietary Preferences and Restrictions
    dietaryVegetarian: yup.boolean().default(false),
    dietaryVegetarianComment: yup.string().optional().nullable(),
    dietaryVegan: yup.boolean().default(false),
    dietaryVeganComment: yup.string().optional().nullable(),
    dietaryGlutenIntolerance: yup.boolean().default(false),
    dietaryGlutenIntoleranceComment: yup.string().optional().nullable(),
    dietaryLactoseIntolerance: yup.boolean().default(false),
    dietaryLactoseIntoleranceComment: yup.string().optional().nullable(),
    dietaryOther: yup.boolean().default(false),
    dietaryOtherComment: yup.string().optional().nullable(),

    // Preferred Beverages
    beverageCoffee: yup.boolean().default(false),
    beverageCoffeeComment: yup.string().optional().nullable(),
    beverageTea: yup.boolean().default(false),
    beverageTeaComment: yup.string().optional().nullable(),
    beverageJuice: yup.boolean().default(false),
    beverageJuiceComment: yup.string().optional().nullable(),
    beverageSoda: yup.boolean().default(false),
    beverageSodaComment: yup.string().optional().nullable(),
    beverageWater: yup.boolean().default(false),
    beverageWaterComment: yup.string().optional().nullable(),
    beverageOther: yup.boolean().default(false),
    beverageOtherComment: yup.string().optional().nullable(),

    // Alcohol Preferences
    alcoholNoAlcohol: yup.boolean().default(false),
    alcoholNoAlcoholComment: yup.string().optional().nullable(),
    alcoholWine: yup.boolean().default(false),
    alcoholWineComment: yup.string().optional().nullable(),
    alcoholBeer: yup.boolean().default(false),
    alcoholBeerComment: yup.string().optional().nullable(),
    alcoholSpirits: yup.boolean().default(false),
    alcoholSpiritsComment: yup.string().optional().nullable(),
    alcoholOther: yup.boolean().default(false),
    alcoholOtherComment: yup.string().optional().nullable(),

    // Additional Notes (Optional, but validated for length)
    additionalNotes: yup
        .string()
        .max(500, "Notes should not exceed 500 characters")
        .default(""),
});

export default function DietaryEdit(props: any) {
    const { onSubmit, onCancel, profileExtra } = props;
    const { handleSubmit, reset, control, register, setValue, watch } = useForm(
        {
            resolver: yupResolver(dietarySchema),
        }
    );

    const handleDietaryUpdate = (data: any) => {
        if (data.dietaryNoRestrictions) {
            data.dietaryVegetarian = false;
            data.dietaryVegetarianComment = "";
            data.dietaryVegan = false;
            data.dietaryVeganComment = "";
            data.dietaryGlutenIntolerance = false;
            data.dietaryGlutenIntoleranceComment = "";
            data.dietaryLactoseIntolerance = false;
            data.dietaryLactoseIntoleranceComment = "";
            data.dietaryOther = false;
            data.dietaryOtherComment = "";
            data.beverageCoffee = false;
            data.beverageCoffeeComment = "";
            data.beverageTea = false;
            data.beverageTeaComment = "";
            data.beverageJuice = false;
            data.beverageJuiceComment = "";
            data.beverageSoda = false;
            data.beverageSodaComment = "";
            data.beverageWater = false;
            data.beverageWaterComment = "";
            data.beverageOther = false;
            data.beverageOtherComment = "";
            data.alcoholNoAlcohol = false;
            data.alcoholNoAlcoholComment = "";
            data.alcoholWine = false;
            data.alcoholWineComment = "";
            data.alcoholBeer = false;
            data.alcoholBeerComment = "";
            data.alcoholSpirits = false;
            data.alcoholSpiritsComment = "";
            data.alcoholOther = false;
            data.alcoholOtherComment = "";
            data.additionalNotes = "";
        }
        onSubmit(data);
    };
    const noRestrictions = watch("dietaryNoRestrictions");
    useEffect(() => {
        reset({
            dietaryNoRestrictions: profileExtra?.dietaryNoRestrictions ?? false,
            dietaryNoRestrictionsComment:
                profileExtra?.dietaryNoRestrictionsComment ?? "",
            // Dietary Preferences and Restrictions
            dietaryVegetarian: profileExtra?.dietaryVegetarian ?? false,
            dietaryVegetarianComment:
                profileExtra?.dietaryVegetarianComment ?? "",
            dietaryVegan: profileExtra?.dietaryVegan ?? false,
            dietaryVeganComment: profileExtra?.dietaryVeganComment ?? "",
            dietaryGlutenIntolerance:
                profileExtra?.dietaryGlutenIntolerance ?? false,
            dietaryGlutenIntoleranceComment:
                profileExtra?.dietaryGlutenIntoleranceComment ?? "",
            dietaryLactoseIntolerance:
                profileExtra?.dietaryLactoseIntolerance ?? false,
            dietaryLactoseIntoleranceComment:
                profileExtra?.dietaryLactoseIntoleranceComment ?? "",
            dietaryOther: profileExtra?.dietaryOther ?? false,
            dietaryOtherComment: profileExtra?.dietaryOtherComment ?? "",

            // Preferred Beverages
            beverageCoffee: profileExtra?.beverageCoffee ?? false,
            beverageCoffeeComment: profileExtra?.beverageCoffeeComment ?? "",
            beverageTea: profileExtra?.beverageTea ?? false,
            beverageTeaComment: profileExtra?.beverageTeaComment ?? "",
            beverageJuice: profileExtra?.beverageJuice ?? false,
            beverageJuiceComment: profileExtra?.beverageJuiceComment ?? "",
            beverageSoda: profileExtra?.beverageSoda ?? false,
            beverageSodaComment: profileExtra?.beverageSodaComment ?? "",
            beverageWater: profileExtra?.beverageWater ?? false,
            beverageWaterComment: profileExtra?.beverageWaterComment ?? "",
            beverageOther: profileExtra?.beverageOther ?? false,
            beverageOtherComment: profileExtra?.beverageOtherComment ?? "",

            // Alcohol Preferences
            alcoholNoAlcohol: profileExtra?.alcoholNoAlcohol ?? false,
            alcoholNoAlcoholComment:
                profileExtra?.alcoholNoAlcoholComment ?? "",
            alcoholWine: profileExtra?.alcoholWine ?? false,
            alcoholWineComment: profileExtra?.alcoholWineComment ?? "",
            alcoholBeer: profileExtra?.alcoholBeer ?? false,
            alcoholBeerComment: profileExtra?.alcoholBeerComment ?? "",
            alcoholSpirits: profileExtra?.alcoholSpirits ?? false,
            alcoholSpiritsComment: profileExtra?.alcoholSpiritsComment ?? "",
            alcoholOther: profileExtra?.alcoholOther ?? false,
            alcoholOtherComment: profileExtra?.alcoholOtherComment ?? "",

            // Additional Notes
            additionalNotes: profileExtra?.additionalNotes ?? "",
        });
    }, [profileExtra, reset]);

    return (
        <>
            <div className="flex flex-col">
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleDietaryUpdate)}
                >
                    <div className="space-y-6">
                        <CheckBox
                            control={control}
                            register={register}
                            name={"dietaryNoRestrictions"}
                            label="I have no: dietary restrictions, dietary/beverage preferences, other allergies, mobility restrictions, or other needs to share"
                        />
                        {!noRestrictions && (
                            <>
                                <CheckboxGroup
                                    title="Dietary Preferences and Restrictions"
                                    control={control}
                                    options={[
                                        {
                                            name: "dietaryVegetarian",
                                            label: "Vegetarian",
                                        },
                                        {
                                            name: "dietaryVegan",
                                            label: "Vegan",
                                        },
                                        {
                                            name: "dietaryGlutenIntolerance",
                                            label: "Gluten intolerance",
                                        },
                                        {
                                            name: "dietaryLactoseIntolerance",
                                            label: "Lactose intolerance",
                                        },
                                        {
                                            name: "dietaryOther",
                                            label: "Other allergies/restrictions",
                                            span: true,
                                        },
                                    ]}
                                    setValue={setValue}
                                    watch={watch}
                                    register={register}
                                />

                                <CheckboxGroup
                                    title="Preferred Beverages"
                                    control={control}
                                    options={[
                                        {
                                            name: "beverageCoffee",
                                            label: "Coffee",
                                        },
                                        { name: "beverageTea", label: "Tea" },
                                        {
                                            name: "beverageJuice",
                                            label: "Juice",
                                        },
                                        { name: "beverageSoda", label: "Soda" },
                                        {
                                            name: "beverageWater",
                                            label: "Water",
                                            span: true,
                                        },
                                        {
                                            name: "beverageOther",
                                            label: "Other",
                                            span: true,
                                        },
                                    ]}
                                    setValue={setValue}
                                    watch={watch}
                                    register={register}
                                />

                                <CheckboxGroup
                                    title="Alcohol Preferences"
                                    control={control}
                                    options={[
                                        {
                                            name: "alcoholNoAlcohol",
                                            label: "No alcohol",
                                        },
                                        { name: "alcoholWine", label: "Wine" },
                                        { name: "alcoholBeer", label: "Beer" },
                                        {
                                            name: "alcoholSpirits",
                                            label: "Spirits",
                                        },
                                        {
                                            name: "alcoholOther",
                                            label: "Other",
                                            span: true,
                                        },
                                    ]}
                                    setValue={setValue}
                                    watch={watch}
                                    register={register}
                                />

                                <div>
                                    <h3 className="font-semibold text-lg text-left pb-4 text-base">
                                        Additional Notes (including other
                                        allergies and physical limitations)
                                    </h3>
                                    <textarea
                                        className="w-full border rounded-md p-2"
                                        {...register("additionalNotes")}
                                        placeholder="Comments"
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mt-24 flex justify-end">
                        <span className="mr-4">
                            <Button
                                outlined
                                label="Cancel"
                                onClick={onCancel}
                            />
                        </span>
                        <span>
                            <Button type="submit" label="Save" />
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
}
