import { useEffect, useState } from "react";
import { IInsightItem } from "../features/trips/Trip/TripItinerary/Activity/Insight/components/ListInsightItems";
import { PAGE_SECTIONS } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import {
    selectActivityInsight,
    selectActivityReview,
} from "../features/trips/tripSlice";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { setCurrentSection } from "../features/layout/layoutSlice";
import { addTripActivityHotelInsight } from "../features/hotelInsight/hotelInsightSlice";

interface IInsightState {
    initialItem: IInsightItem | null;
    schema: any;
    currentSection?: PAGE_SECTIONS;
    commentsKey?:
        | "generalComments"
        | "commonComments"
        | "roomComments"
        | "dinningComments"
        | "wellnessComments"
        | "goodForWhichClients";
    imagesKey?:
        | "roomImages"
        | "dinningImages"
        | "wellnessImages"
        | "commonImages";
}

export function useInsightState({
    initialItem,
    schema,
    currentSection,
    commentsKey,
    imagesKey,
}: IInsightState) {
    const {
        control,
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const activityInsight = useSelector(selectActivityInsight);
    const activityReview = useSelector(selectActivityReview);
    const hotelInsight = activityInsight?.hotelInsight;
    const restaurantInsight = activityInsight?.restaurantInsight;
    const otherInsight = activityInsight?.otherInsight;
    const { activityId, tripId } = useParams();
    const [activeItem, setActiveItem] = useState(initialItem);
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showComentsForm, setShowComentsForm] = useState(false);
    // usePrompt(
    //     "It looks like you've made some edits. Are you sure you want to leave without saving?",
    //     !!isDirty
    // );
    useEffect(() => {
        if (currentSection) {
            dispatch(
                setCurrentSection({
                    currentSection,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = () => {
        setActiveItem(null);
        setShowForm(false);
        setShowDetails(false);
        reset({});
    };

    const handleEditItem = (item: IInsightItem) => {
        reset(item);
        setActiveItem(item);
        setShowDetails(false);
        setShowForm(true);
        reset();
    };

    const handleShowDetails = (item: IInsightItem) => {
        reset(item);
        setActiveItem(item);
        setShowForm(false);
        setShowDetails(true);
        reset();
    };

    const handleClickNewInsightItem = () => {
        reset({});
        setActiveItem(null);
        setShowForm(true);
    };

    // Sets the images in the root body: [key]: value. Eg: modifiedImages: [images]
    const handleUploadFile = (values: object) =>
        Object.entries(values).forEach(([key, value]) => {
            register(key);
            setValue(key, value, { shouldDirty: true });
        });

    // Sets the images to the insight section: [imagesKey]: { modifiedImages, newImages, deletedImages}
    const handleUploadFileToInsightSection = (values: object) => {
        imagesKey && register(imagesKey);
        imagesKey && setValue(imagesKey, values);
    };

    const handleChangeType = (value: string, key: string = "type") => {
        setValue(key, value, { shouldValidate: true, shouldDirty: true });
    };

    const toggleShowCommentsForm = () => {
        setShowComentsForm((prev: boolean) => !prev);
        reset();
    };

    const handleSaveComments = async () => {
        if (!commentsKey) return;
        const comments = getValues(commentsKey);
        const images = imagesKey && getValues(imagesKey);
        const payload = {
            id: hotelInsight?.id,
            insightId: hotelInsight?.insightId,
            activityId: activityInsight?.id,
            hotelId: activityInsight?.id,
            stars: hotelInsight?.stars,
            roomsCount:
                Number(getValues("roomsCount")) ?? hotelInsight?.roomsCount,
            connectingRooms:
                getValues("connectingRooms") ?? hotelInsight?.connectingRooms,
            generalComments: hotelInsight?.generalComments,
            wellnessComments: hotelInsight?.wellnessComments,
            dinningComments: hotelInsight?.dinningComments,
            roomComments: hotelInsight?.roomComments,
            goodForWhichClients: hotelInsight?.goodForWhichClients,
            images: {
                modifiedImages: hotelInsight?.images,
            },
            commonComments: hotelInsight?.commonComments,
            [commentsKey]: comments,
        };

        if (imagesKey) {
            payload[imagesKey] = images;
        }

        await dispatch(
            addTripActivityHotelInsight(
                Number(tripId),
                Number(activityId),
                payload,
                hotelInsight?.insightId
            )
        );
        setShowComentsForm(false);
        reset();
    };

    return {
        // local state
        activeItem,
        showForm,
        showDetails,
        showComentsForm,
        activityInsight,
        restaurantInsight,
        otherInsight,
        hotelInsight,
        activityId,
        tripId,
        activityReview,
        // local state handlers
        dispatch,
        handleCancel,
        handleEditItem,
        handleClickNewInsightItem,
        handleUploadFile,
        handleUploadFileToInsightSection,
        handleChangeType,
        toggleShowCommentsForm,
        handleSaveComments,
        handleShowDetails,
        // useForm props
        control,
        register,
        errors,
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch,
        getValues,
        isValid,
        isDirty,
    };
}
