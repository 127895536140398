import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input/Input";
import ButtonSquared from "../../../components/ButtonSquare";
import { useAppDispatch } from "../../../app/hooks";
import { addHostAgency } from "../organizationSlice";
import { ButtonSize } from "../../../constants";

const schema = yup
    .object({
        name: yup.string().required("This field is required"),
    })
    .required("This field is required");

interface formProps {
    onSubmitFinish: Function;
    onCancel: Function;
}

export default function HostAgencyForm(props: formProps) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const handleSubmitForm = async (data: any) => {
        const { name } = data;
        const newagency = await dispatch(addHostAgency(name));
        if (props.onSubmitFinish) props.onSubmitFinish(newagency);
    };
    const goBack = () => {
        if (props.onCancel) props.onCancel();
    };

    return (
        <div>
            <div className="flex flex-wrap mb-12">
                <div className="text-left font-semibold text-lg flex-grow">
                    New Host Agency
                </div>
            </div>

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="grid grid-cols-6">
                    <div className="col-span-6 lg:col-span-6">
                        <Input
                            {...register("name", { required: true })}
                            label="Name"
                            type="text"
                            id="name"
                            name="name"
                            error={errors["name"]?.message}
                        />
                    </div>
                </div>
                <div className="pt-24 text-right">
                    <span className="mr-6">
                        <ButtonSquared
                            onClick={goBack}
                            outlined
                            label="Cancel"
                            size={ButtonSize.SMALL}
                        />
                    </span>
                    <ButtonSquared
                        type="submit"
                        label={"Save"}
                        size={ButtonSize.SMALL}
                    />
                </div>
            </form>
        </div>
    );
}
