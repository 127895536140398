import { FC, useEffect, useState } from "react";
import { useChatForm } from "../hooks/useChat.hook";
import Icon from "../../../assets/svg/Icon";

interface FilePreviewProps {
    files: File[];
    removeFile: (index: number) => void;
    isLoading: boolean;
}

const FilePreview: FC<FilePreviewProps> = ({
    files,
    removeFile,
    isLoading,
}) => {
    const [videoThumbnails, setVideoThumbnails] = useState<string[]>([]);
    const { createVideoThumbnail } = useChatForm();

    useEffect(() => {
        const generateThumbnails = async () => {
            setVideoThumbnails([]);

            const thumbnails = await Promise.all(
                files.map((file) => {
                    if (
                        file.type.includes("video") &&
                        file.type === "video/mp4"
                    ) {
                        return createVideoThumbnail(file).catch((error) => {
                            console.error(
                                `Failed to generate thumbnail for video: ${file.name}`,
                                error
                            );
                            return "";
                        });
                    }
                    return "";
                })
            );
            setVideoThumbnails(thumbnails);
        };

        generateThumbnails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    return (
        <div className="flex space-x-1 h-16 items-center overflow-x-auto gap-2 p-2">
            {files.map((file, index) => {
                const isImage = file.type.includes("image");
                const isVideo = file.type.includes("video");

                const uniqueKey = `${file.name}-${file.size}`;

                return (
                    <div key={uniqueKey} className="relative h-full w-16">
                        {isImage ? (
                            <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                className="h-full w-full object-cover rounded-lg"
                            />
                        ) : isVideo ? (
                            videoThumbnails[index] ? (
                                <img
                                    src={videoThumbnails[index]}
                                    alt={file.name}
                                    className="h-full w-full object-cover rounded-lg"
                                />
                            ) : (
                                <video
                                    src={URL.createObjectURL(file)}
                                    className="h-full w-full object-cover rounded-lg"
                                    controls
                                />
                            )
                        ) : (
                            <div className="flex flex-col items-center gap-1">
                                <Icon
                                    icon="FileOutlineIcon"
                                    className="w-8 h-8 text-gray-500"
                                />
                                <div className="text-xs font-medium text-neutral-700 truncate w-16 text-center">
                                    {file?.name}
                                </div>
                            </div>
                        )}
                        {!isLoading && (
                            <button
                                type="button"
                                onClick={() => removeFile(index)}
                                className="absolute top-0 right-0 bg-gray-200 rounded-full p-1 transform translate-x-1/2 -translate-y-1/2"
                            >
                                <Icon
                                    icon="CloseIcon"
                                    className="w-3 h-3 text-gray-500"
                                />
                            </button>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default FilePreview;
