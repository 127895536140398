import { IChatEntity } from "../../../types/chat.type";
import { DataSnapshot } from "firebase/database";
import {
    addOrUpdateConversation,
    formatConversation,
    setConversations,
} from "../chatSlice";


/********* CONVERSATIONS *****/

// This function is used to detect new messages in a conversation
export const newMessageDetected = (dispatch: any, getState: any) => {
    return (snapshot: DataSnapshot) => {
        const conversation = snapshot.val();
        const user = getState().user.profile;
        if (conversation) {
            const conversationId = snapshot.key;
            const members = conversation.members;

            if (members && Object.keys(members).includes(user?.uid)) {
                const contacts = getState().chat.contacts;
                const blockedBy = getState().chat.blockedBy;
                const formattedConversation: IChatEntity = formatConversation(
                    {
                        id: conversationId,
                        ...conversation,
                    },
                    user,
                );
                dispatch(
                    addOrUpdateConversation({
                        conversation: formattedConversation,
                        user: user,
                    })
                );

                const state = getState();
                let allConversations = [...state.chat.conversations];
                dispatch(setConversations(allConversations));
            }
        }
    };
};

export const messageChangeDetected = (dispatch: any, getState: any) => {
    return (messageSnapshot: DataSnapshot, conversationId: string) => {
        const message = messageSnapshot.val();
        const updatedConversations = getState().chat.conversations.map(
            (conv: IChatEntity) => {
                if (conv.id === conversationId) {
                    return {
                        ...conv,
                        lastMessageTimestamp: message.timestamp,
                    };
                }
                return conv;
            }
        );
    };
}

// This function is used to detect changes in the members of a conversation
export const membersChangeDetected = (dispatch: any, getState: any) => {
    return (memberSnapshot: DataSnapshot, conversationId: string) => {
        const removedMemberId = memberSnapshot.key;
        const user = getState().user.profile;

        if (removedMemberId === user.uid) {
            const updatedConversations = getState().chat.conversations.filter(
                (conv: IChatEntity) => conv.id !== conversationId
            );
            dispatch(setConversations(updatedConversations));
        }
    };
};

// Listener of conversations
export const initializeConversationsListener = (
    db: any,
    handleNewMessage: any,
    handleMemberChange: any
) => {
    const conversationsRef = db.ref("conversations");

    // Listen members in a conversation
    conversationsRef.on("child_added", (snapshot: any) => {
        const membersRef = snapshot.ref.child("members");
        membersRef.on("child_removed", (memberSnapshot: any) => {
            handleMemberChange(memberSnapshot, snapshot.key);
        });
    });
    conversationsRef.on("child_changed", (snapshot: any) => {
        const membersRef = snapshot.ref.child("members");
        membersRef.on("child_removed", (memberSnapshot: any) => {
            handleMemberChange(memberSnapshot, snapshot.key);
        });

    });

    // Listen for messages in a conversation
    conversationsRef.on("child_added", handleNewMessage);
    conversationsRef.on("child_changed", handleNewMessage);
};


// This function is used to turn off the conversation listeners
export const offConversationListeners = (
    db: any,
    handleNewMessage: (snapshot: DataSnapshot) => void,
    handleMembersChange: (
        memberSnapshot: DataSnapshot,
        conversationId: string
    ) => void
) => {
    db.ref("conversations").off("child_added", handleNewMessage);
    db.ref("conversations").off("child_changed", handleNewMessage);
    db.ref("conversations/members").off("child_removed", handleMembersChange);
    db.ref("conversations/members").off("child_added", handleMembersChange);
};
