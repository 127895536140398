import { useBlocker } from "react-router-dom";
import Modal from ".";
import ButtonSquared from "../ButtonSquare";
import { ButtonSize } from "../../constants";

export const BlockModal = ({
    shouldBlock,
    message,
    title,
}: {
    shouldBlock: boolean;
    message?: string;
    title?: string;
}) => {
    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            shouldBlock && currentLocation.pathname !== nextLocation.pathname
    );

    return (
        <Modal open={blocker.state === "blocked"} hideCloseButton>
            <div className="m-4 text-center flex flex-col gap-6 justify-center items-center">
                <div className="text-xl text-neutral-900 font-semibold max-w-xs">
                    {title || `Discard changes?`}
                </div>
                <div className="text-base text-neutral-900 max-w-xs">
                    {message ||
                        `It looks like you've made some edits. Are you sure you want to leave without saving?`}
                </div>
                <div className="flex flex-row gap-4 items-center justify-center">
                    <ButtonSquared
                        outlined
                        size={ButtonSize.SMALL}
                        label={"Cancel"}
                        onClick={() => blocker?.reset && blocker?.reset()}
                    />
                    <ButtonSquared
                        size={ButtonSize.SMALL}
                        label={"Discard"}
                        onClick={() => blocker?.proceed && blocker?.proceed()}
                    />
                </div>
            </div>
        </Modal>
    );
};
