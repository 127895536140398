import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../../components/TextArea";
import {
    addTripRequirementFile,
    selectedTripRequirement,
    uploadTempFile,
} from "../../../tripSlice";
import axios from "axios";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import DropFileInput from "../../../../../components/DropFileInput/DropFileInput";
import fileImage from "../../../../../assets/images/files.png";
import InputErrorLabel from "../../../../../components/InputErrorLabel";
import CheckBox from "../../../../../components/CheckBox";
import { RequirementFlightFormResponse } from "./response/RequirementFlightFormResponse";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function TripRequirementFileForm({
    onSubmit,
    onCancel,
    userEmail,
    requirement,
}: {
    onSubmit: any;
    onCancel: any;
    userEmail?: string;
    requirement?: any;
}) {
    const [sending, setSending] = useState(false);
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);

    let { tripId } = useParams();
    const selectedRequirement = useAppSelector(selectedTripRequirement);

    const req = useMemo(() => {
        return requirement || selectedRequirement;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement?.id, selectedRequirement?.id]);

    const schema = yup
        .object({
            arrival_airline: yup.string().when("isArrivalFlightRequired", {
                is: true,
                then: yup.string().required("This field is required"),
                otherwise: yup.string(),
            }),
            arrival_flightNumber: yup.string().when("isArrivalFlightRequired", {
                is: true,
                then: yup.string().required("This field is required"),
                otherwise: yup.string(),
            }),
            arrival_departureAirport: yup
                .string()
                .when("isArrivalFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            arrival_departureDate: yup.date().when("isArrivalFlightRequired", {
                is: true,
                then: yup.date().required("This field is required"),
                otherwise: yup.date(),
            }),
            arrival_departureTime: yup
                .string()
                .when("isArrivalFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            arrival_arrivalAirport: yup
                .string()
                .when("isArrivalFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            arrival_arrivalDate: yup.date().when("isArrivalFlightRequired", {
                is: true,
                then: yup.date().required("This field is required"),
                otherwise: yup.date(),
            }),
            arrival_arrivalTime: yup.string().when("isArrivalFlightRequired", {
                is: true,
                then: yup.string().required("This field is required"),
                otherwise: yup.string(),
            }),
            departure_airline: yup.string().when("isDepartureFlightRequired", {
                is: true,
                then: yup.string().required("This field is required"),
                otherwise: yup.string(),
            }),
            departure_flightNumber: yup
                .string()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            departure_departureAirport: yup
                .string()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            departure_departureDate: yup
                .date()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.date().required("This field is required"),
                    otherwise: yup.date(),
                }),
            departure_departureTime: yup
                .string()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            departure_arrivalAirport: yup
                .string()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            departure_arrivalDate: yup
                .date()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.date().required("This field is required"),
                    otherwise: yup.date(),
                }),
            departure_arrivalTime: yup
                .string()
                .when("isDepartureFlightRequired", {
                    is: true,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            departure_other_type_of_transportation: yup
                .string()
                .when("isArrivalFlightRequired", {
                    is: false,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            arrival_other_type_of_transportation: yup
                .string()
                .when("isArrivalFlightRequired", {
                    is: false,
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            isArrivalFlightRequired: yup.boolean(),
            isDepartureFlightRequired: yup.boolean(),
            description: yup.string().max(5000).notRequired(),
            sensitiveInformation: yup.boolean(),
        })
        .required("This field is required");

    const {
        register,
        control,
        formState: { errors },
        setError,
        clearErrors,
        setValue,
        watch,
        reset,
        trigger,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (req?.type === "arrival_departure_info") {
            reset({
                isArrivalFlightRequired: true,
                isDepartureFlightRequired: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [req?.type]);

    const handleSubmitForm = async () => {
        try {
            setSending(true);
            const data = getValues();
            const { description, sensitiveInformation } = data;

            if (req?.type === "arrival_departure_info") {
                const departureInputs = !data.isDepartureFlightRequired
                    ? ["departure_other_type_of_transportation"]
                    : [
                          "departure_airline",
                          "departure_flightNumber",
                          "departure_departureAirport",
                          "departure_departureDate",
                          "departure_departureTime",
                          "departure_arrivalAirport",
                          "departure_arrivalDate",
                          "departure_arrivalTime",
                      ];
                const isValid = await trigger(departureInputs);

                if (!isValid) {
                    setSending(false);
                    return;
                }
                if (!data.isArrivalFlightRequired) {
                    for (const key in data) {
                        if (
                            [
                                "arrival_airline",
                                "arrival_flightNumber",
                                "arrival_departureAirport",
                                "arrival_departureDate",
                                "arrival_departureTime",
                                "arrival_arrivalAirport",
                                "arrival_arrivalDate",
                                "arrival_arrivalTime",
                            ].includes(key)
                        ) {
                            delete data[key];
                        }
                    }
                } else {
                    delete data["arrival_other_type_of_transportation"];
                    delete data["arrival_airline_id"];
                }

                if (!data.isDepartureFlightRequired) {
                    for (const key in data) {
                        if (
                            [
                                "departure_airline",
                                "departure_flightNumber",
                                "departure_departureAirport",
                                "departure_departureDate",
                                "departure_departureTime",
                                "departure_arrivalAirport",
                                "departure_arrivalDate",
                                "departure_arrivalTime",
                            ].includes(key)
                        ) {
                            delete data[key];
                        }
                    }
                } else {
                    delete data["departure_other_type_of_transportation"];
                    delete data["departure_airline_id"];
                }
                await dispatch(
                    addTripRequirementFile(
                        Number(tripId),
                        Number(req.id),
                        JSON.stringify(data),
                        [],
                        userEmail,
                        sensitiveInformation
                    )
                );
                await onSubmit();
                setSending(false);
                return;
            }

            if (!tempFiles?.length && !description?.length) {
                setError("files", {
                    type: "custom",
                    message: "You must upload a file or complete the text info",
                });
                setSending(false);
                return;
            }

            const files = tempFiles.map((file) => file.path);
            await dispatch(
                addTripRequirementFile(
                    Number(tripId),
                    Number(req.id),
                    description || "",
                    files,
                    userEmail,
                    sensitiveInformation
                )
            );
            setSending(false);
            onSubmit();
        } catch (error) {
            setSending(false);
        }
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: number, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const onSelectFiles = (e: any) => {
        clearErrors("files");
        if (e.target.files) {
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: new Date().valueOf(),
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setFilesProgress((prevArray) => {
                    return [...prevArray, newtempFile];
                });

                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    removeProgressFile(newtempFile.id);
                    setTempFiles((prev) => {
                        return [
                            ...prev,
                            {
                                file: file,
                                path: response,
                                id: newtempFile.id,
                            },
                        ];
                    });
                });
            });
            return;
        }
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([...deletedFilesState, filesPreviews[index].name]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    const type = req?.type;

    const deadline = req?.deadline ? new Date(req?.deadline) : new Date();



    return (
        <div className="mx-4">
            <div
                className={`flex flex-wrap ${
                    req?.type === "arrival_departure_info" && "hidden"
                }`}
            >
                <div className="text-left font-semibold text-lg flex-grow text-gray-600">
                    Submit requirement: {req?.name}
                </div>
            </div>
            {(type === "other" || type === "other_requirement") && (
                <div
                    className={`flex flex-wrap ${
                        req?.type === "arrival_departure_info" && "hidden"
                    }`}
                >
                    <div className="text-left py-2 text-lg flex-grow text-gray-600">
                        <div className="text-sm">
                            To complete this requirement, please upload a file,
                            provide a response in the text box, or both.
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`flex flex-wrap ${
                    req?.type === "arrival_departure_info" && "hidden"
                }`}
            >
                <div className="text-left py-4 text-lg flex-grow text-gray-600">
                    <div className="text-sm">
                        Deadline:{" "}
                        <span className="font-semibold">
                            {deadline.toDateString()}
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`flex flex-wrap ${
                    req?.type === "arrival_departure_info" && "hidden"
                }`}
            >
                <div className="text-left py-4 text-lg flex-grow text-gray-600">
                    <div className="text-sm">
                        Description:{" "}
                        <span className="font-semibold">
                            {req?.description}
                        </span>
                    </div>
                </div>
            </div>
            <form className="flex flex-col flex-grow">
                {req?.type !== "arrival_departure_info" && (
                    <div className="gap-x-12 flex-wrap mt-4 flex-grow">
                        <>
                            <div className={"!py-2"}>
                                <DropFileInput
                                    iconSrc={fileImage}
                                    iconClass={"w-16"}
                                    multiple={true}
                                    onChange={onSelectFiles}
                                />
                                {!!errors["files"]?.message && (
                                    <InputErrorLabel
                                        error={errors["files"]?.message}
                                    />
                                )}
                            </div>
                            {filesProgress.map((file) => {
                                return (
                                    <ProgressBar
                                        key={`fileProgress_${
                                            file.id || file?.file?.name
                                        }`}
                                        progress={file.progress}
                                        name={file.file.name}
                                        onRemove={() =>
                                            removeProgressFile(file.id, true)
                                        }
                                    />
                                );
                            })}
                            <div className="flex flex-wrap gap-4 mt-4">
                                {tempFiles.map((file) => {
                                    return (
                                        <FilePreview
                                            key={`filePrev_${
                                                file.id || file?.file?.name
                                            }`}
                                            onRemove={() => {
                                                removeTempFile(file.id);
                                            }}
                                            name={file?.file?.name}
                                        />
                                    );
                                })}
                                {filesPreviews.map((file, index) => {
                                    return (
                                        <FilePreview
                                            key={`file_${file.name}`}
                                            onRemove={() => {
                                                handleFileRemove(index);
                                            }}
                                            name={file.name}
                                        />
                                    );
                                })}
                            </div>
                        </>

                        <div className="text-left break-words mt-2">
                            <TextareaWithValid
                                register={register}
                                label={
                                    req?.type === "passport"
                                        ? "Additional Information"
                                        : "Info"
                                }
                                id="description"
                                name="description"
                                error={errors["description"]?.message}
                            />
                        </div>

                        <div className="col-span-6 lg:col-span-3 text-left mb-4">
                            <label className="flex items-center space-x-3">
                                <CheckBox
                                    name="sensitiveInformation"
                                    id="sensitiveInformation"
                                    control={control}
                                    label="Delete my requirements after the trip ends."
                                />
                            </label>
                        </div>
                    </div>
                )}
                <div className="gap-x-12 flex-wrap mt-4 flex-grow">
                    <RequirementFlightFormResponse
                        show={type === "arrival_departure_info"}
                        requirement={req}
                        register={register}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        clearErrors={clearErrors}
                        getValues={getValues}
                        watch={watch}
                        reset={reset}
                        trigger={trigger}
                        handleSubmitForm={handleSubmitForm}
                        sending={sending}
                        onCancel={onCancel}
                    />
                </div>
                {type !== "arrival_departure_info" && (
                    <Button
                        disabled={
                            (!tempFiles?.length && !watch("description")) ||
                            sending
                        }
                        type="button"
                        label="Save"
                        onClick={handleSubmitForm}
                    />
                )}
            </form>
        </div>
    );
}
